import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import Loader from 'components/Loader';
import LottiLoader from 'components/LottiLoader';

import ApplicantsProvider from 'contexts/applicantsContext';

import UseJobStagelabelName from 'hooks/useJobStageLabel';

import BasePrivateLayout from '../../basePrivateLayout';

import InterviewStagesConatiner from './InterviewStages/container';
import NoPageFound from 'pages/common/error/noPageFound';
import ComponentLevelErrorBoundary from 'pages/common/error/CompnentLevelErrorBoundary';

const ApplicantsOverviewPage = lazy(() => import('./applicantsOverviewPage'));

const CvscreeningResult = lazy(() => import('./InterviewStages/CvScreening'));
const SkillAssessmentResult = lazy(() =>
  import('./InterviewStages/SkillAssessment')
);
const PrehireAssessmentResult = lazy(() =>
  import('./InterviewStages/Prehireinterview')
);
const TechnicalInterviewResult = lazy(() =>
  import('./InterviewStages/TechnicalInterview')
);
const HRInterviewResult = lazy(() => import('./InterviewStages/HrInterview'));
const PanelInterviewResult = lazy(() =>
  import('./InterviewStages/PanelInterview')
);
const PsychometricAssessmentResult = lazy(() =>
  import('./InterviewStages/PsychometricAssesment')
);
const PersonalityProfilingResult = lazy(() =>
  import('./InterviewStages/PersonalityProfiling')
);
const VideoMeetingJitsi = lazy(() =>
  import('./InterviewStages/VideoMeetingJitsi')
);

const ApplicantsInterviewStages = () => {
  const { path } = useRouteMatch();

  // eslint-disable-next-line no-unused-vars
  const hh = UseJobStagelabelName({ enable: true });

  return (
    <BasePrivateLayout>
      <ApplicantsProvider>
        <ComponentLevelErrorBoundary>
          <Suspense
            fallback={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='max-w-main-page h-70vh flex justify-center items-center '>
                  <LottiLoader />
                </div>
              </div>
            }
          >
            <Switch>
              <Redirect exact from={path} to={`${path}/overview/:jobId`} />
              <Route
                path={`${path}/overview/:jobId`}
                component={ApplicantsOverviewPage}
              />
              <Route
                path={`${path}/interview/:jobId/:interviewType/:applicantName/:applicantId`}
                component={VideoMeetingJitsi}
              />
              <InterviewStagesConatiner>
                <Suspense
                  fallback={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className='mt-20'>
                        <Loader width={40} height={40} color='#6600C9' />
                      </div>
                    </div>
                  }
                >
                  <Switch>
                    <Route
                      path={`${path}/:jobId/CV_Screening/:applicantName/:applicantID`}
                      component={CvscreeningResult}
                    />
                    <Route
                      path={`${path}/:jobId/Skill_Assessment/:applicantName/:applicantID`}
                      component={SkillAssessmentResult}
                    />
                    <Route
                      path={`${path}/:jobId/Holistic_Assessment/:applicantName/:applicantID`}
                      component={PrehireAssessmentResult}
                    />
                    <Route
                      path={`${path}/:jobId/Psychometric_Assessment/:applicantName/:applicantID`}
                      component={PsychometricAssessmentResult}
                    />
                    <Route
                      path={`${path}/:jobId/Personality_Profiling/:applicantName/:applicantID`}
                      component={PersonalityProfilingResult}
                    />
                    <Route
                      path={`${path}/:jobId/Technical_Interview/:applicantName/:applicantID`}
                      component={TechnicalInterviewResult}
                    />
                    <Route
                      path={`${path}/:jobId/Panel_Interview/:applicantName/:applicantID`}
                      component={PanelInterviewResult}
                    />
                    <Route
                      path={`${path}/:jobId/HR_Interview/:applicantName/:applicantID`}
                      component={HRInterviewResult}
                    />
                  </Switch>
                </Suspense>
              </InterviewStagesConatiner>

              {/* DO NOT CHNAGE this empty route position Add everything above this line */}
              <Route component={NoPageFound} />
            </Switch>
          </Suspense>
        </ComponentLevelErrorBoundary>
      </ApplicantsProvider>
    </BasePrivateLayout>
  );
};

export default ApplicantsInterviewStages;
