import React from 'react';
import { useTranslation } from 'react-i18next';
// import cookies from 'js-cookie';

import Loader from '../Loader';

const LoadMoreButton = ({ isLoading = false }) => {
  const { t } = useTranslation(['common']);
  // const currentLanguageCode = cookies.get('i18next');

  if (isLoading) {
    return (
      <div className='text-gray-600 cursor-not-allowed bg-opacity-60 w-32 flex items-center justify-center px-2 py-2 rounded-md leading-5 bg-gray-200'>
        <p className='opacity-40 mx-2'>{t('load_more')}</p>
        <div className='opacity-80'>
          <Loader color='#6600C9' />
        </div>
      </div>
    );
  }
  return (
    <div className='text-gray-600 cursor-pointer space-x-2 w-32 flex items-center justify-center  px-2 py-2 rounded-md leading-5 bg-gray-200 hover:bg-gray-300 transition-colors'>
      <p>{t('load_more')}</p>
      <svg
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
        className='mt-1'
      >
        <path d='M0.5 0.71875L7 6.71875L12.5 0.71875' stroke='#6B6B6B' />
        <path d='M0.5 5.71875L7 11.7188L12.5 5.71875' stroke='#6B6B6B' />
      </svg>
    </div>
  );
};

export default LoadMoreButton;
