import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from 'contexts/authContext';

const TeamMemberRestrictedRouteProfile = ({
  component: Component,
  ...rest
}) => {
  const { authState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authState.is_hiring_manager || authState.is_recruiter ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/profile/myprofile',
            }}
          />
        )
      }
    />
  );
};

export default TeamMemberRestrictedRouteProfile;
