import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { AuthContext } from 'contexts/authContext';

import Lottie from 'react-lottie-player';

import loder_lottie from 'assets/lottieFiles/loder_lottie.json';

const CheckAuthPage = () => {
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  useEffectOnce(() => {
    if (authState.isAuthenticated) {
      history.replace('/dashboard/overview');
    } else {
      history.replace('/auth/login');
    }
  });
  return (
    <div className='flex justify-center items-center relative h-screen w-screen'>
      <Lottie
        animationData={loder_lottie}
        background='transparent'
        speed='1'
        style={{ width: '100px', height: '100px' }}
        loop
        autoplay
      />
    </div>
  );
};

export default CheckAuthPage;
