import { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { ApplicantsContext } from 'contexts/applicantsContext';

import getModifiedStagename from 'helper/getModifiedStagename';

import PrimaryButton from 'components/Button/primaryButton';
import Lottie from 'react-lottie-player';

import { ReactComponent as BG } from 'assets/images/Interviews/success_bg_modal.svg';
import SuccessLottie from 'assets/lottieFiles/SuccessLottie.json';

const ApplicantMovedToNextStageModal = ({ headerText }) => {
  const { t } = useTranslation(['common']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const handelCloseScheduleSuccessModal = () => {
    applicantsDispatch({
      type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
      payload: false,
    });
  };

  const header = (
    <p className='text-xl text-center tracking-wide text-black'>
      Applicant moved to{' '}
      <span className='text-primary-rose'>
        {getModifiedStagename(headerText, t)}
      </span>{' '}
      successfully
    </p>
  );

  return (
    <div>
      <Dialog
        visible={applicantsState.is_applicants_moved_next_stage_modal_visible}
        onHide={handelCloseScheduleSuccessModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        position='middle'
        resizable={false}
        header={header}
        className='w-10/12 md:w-3/5  lg:w-1/2 mx-auto rounded-xl'
      >
        <div className='relative'>
          <div className='bg-F9F9F9 w-60 h-60 mx-auto rounded-full mb-4 mt-3 z-50'>
            <Lottie
              animationData={SuccessLottie}
              background='transparent'
              speed='1'
              loop
              play
            />
          </div>
          <div className='absolute w-full top-10'>
            <BG />
          </div>
          <div className='flex justify-center mt-10 mb-6'>
            <button className='w-28' onClick={handelCloseScheduleSuccessModal}>
              <PrimaryButton>
                <p className='text-base'>{t('common:back')}</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ApplicantMovedToNextStageModal;
