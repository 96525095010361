import React, { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTour } from '@reactour/tour';
import { AuthContext } from 'contexts/authContext';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import PrimaryButton from 'components/Button/primaryButton';

function ReactTourInstructionsModal({ isVisible, setVisible, isRecruiter }) {
  const { isOpen, setIsOpen, setCurrentStep } = useTour();
  const { authDispatch } = useContext(AuthContext);
  const { t } = useTranslation(['productTour']);
  const currentLanguageCode = cookies.get('i18next');

  const header = (
    <div>
      <div className='z-50 flex justify-between'>
        <div className='w-10 h-10'></div>
        <div className='flex items-center'>
          <p className='text-center text-normal  text-primary-rose text-xl'>
            {t('productTour:lets_get_started')}
          </p>
        </div>
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
          onClick={() => {
            setVisible(false);
            authDispatch({
              type: 'UPDATETOURSTATUS',
              payload: false,
            });
          }}
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        visible={isVisible}
        closable={false}
        onHide={() => setVisible(false)}
        className='w-full max-w-xl rounded-lg'
        modal
        draggable={false}
        dismissableMask={false}
        blockScroll={true}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col w-full items-center justify-center p-4'>
          <div className='flex flex-col'>
            <p className='text-neutrals-black font-bold my-4'>
              {t('productTour:react_tour_instructions_desc')}{' '}
            </p>
            <div className='flex flex-col text-Neutrals-gray-1'>
              {isRecruiter ? (
                <ul className='list-disc px-6'>
                  <li className='my-2'>
                    {t('productTour:Step1')} -{' '}
                    {t('productTour:invite_your_team_members')}
                  </li>
                  <li className='my-2'>
                    {t('productTour:Step2')} -{' '}
                    {t('productTour:invite_your_clients')}
                  </li>
                  <li className='my-2'>
                    {t('productTour:Step3')} -{' '}
                    {t('productTour:build_your_assessment_banks')}
                  </li>
                  <li className='my-2'>
                    {t('productTour:Step4')} -{' '}
                    {t('productTour:list_your_job_positions')}
                  </li>
                </ul>
              ) : (
                <ul className='list-disc px-6'>
                  <li className='my-2'>
                    {t('productTour:Step1')} -{' '}
                    {t('productTour:invite_your_team_members')}
                  </li>
                  <li className='my-2'>
                    {t('productTour:Step2')} -{' '}
                    {t('productTour:build_your_assessment_banks')}
                  </li>
                  <li className='my-2'>
                    {t('productTour:Step3')} -{' '}
                    {t('productTour:list_your_job_positions')}
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className='w-full flex justify-center mt-6'>
            <button
              onClick={() => {
                setVisible(false);
                if (!isOpen) {
                  setCurrentStep(1);
                  setTimeout(() => {
                    setCurrentStep(0);
                  }, 200);
                  setIsOpen(true);
                }
              }}
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
            >
              <PrimaryButton isLoading={false}>
                <p className='text-sm leading-5'>
                  {t('productTour:start_quick_tour')}
                </p>
              </PrimaryButton>
            </button>
            <button
              onClick={() => {
                setVisible(false);
                authDispatch({
                  type: 'UPDATETOURSTATUS',
                  payload: false,
                });
              }}
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
              }`}
            >
              <PrimaryButton isLoading={false}>
                <p className='text-sm leading-5'>
                  {t('productTour:quit_tour')}
                </p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ReactTourInstructionsModal;
