import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
// import { lazy } from '@loadable/component';

import Loader from 'components/Loader';
import BasePrivateLayout from '../../basePrivateLayout';
import Help from './components/Help';

const HelpSupport = () => {
  const { path } = useRouteMatch();

  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='mt-12'>
              <Loader width={40} height={40} color='#6600C9' />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/help`} />
          <Route path={`${path}/help`} component={Help} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
};
export default HelpSupport;
