import React from 'react';
import i18next from 'i18next';
import cookies from 'js-cookie';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

const PublicHeader = () => {
  const currentLanguageCode = cookies.get('i18next');
  return (
    <div className='sticky top-0 z-50  px-2 sm:px-4 py-2 h-20 bg-white shadow-md '>
      <div className='flex md:justify-between px-4 2xl:px-1 py-2 max-w-main-page mx-auto items-center'>
        <div className='sm:1/12 lg:w-2/12'>
          {/* <a href='https://apta-hr.com/'> */}
          <Logo className='w-36 h-16 pb-2' />
          {/* </a> */}
        </div>

        <div className='9/12 lg:w-8/12 flex justify-center items-center lg:text-lg'></div>
        <div className='w-2/12 justify-end items-center hidden sm:flex flex-col'>
          {/* {location.pathname.includes('client') ? (
            <Link to='/auth/login'>
              <div className='text-sm text-center  whitespace-nowrap font-medium  bg-primary-rose text-white  px-6 py-2 rounded-md leading-5 hover:bg-primary-rose-dark transition-colors w-32'>
                User Login
              </div>
            </Link>
          ) : (
            <Link to='/auth/client/login'>
              <div className='text-sm text-center  whitespace-nowrap font-medium  bg-primary-rose text-white  px-6 py-2 rounded-md leading-5 hover:bg-primary-rose-dark transition-colors w-32'>
                Client Login
              </div>
            </Link>
          )} */}
          {/* translation */}
          <div
            className={`${
              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
            } mb-4 flex items-center `}
          >
            <button
              type='button'
              onClick={() => {
                i18next.changeLanguage('en');
                localStorage.setItem('dir', JSON.stringify('ltr'));
              }}
              className={`${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
            >
              <p
                className={`${
                  currentLanguageCode === 'en'
                    ? 'text-purple'
                    : 'text-custome_gray'
                } `}
              >
                En
              </p>
            </button>
            <button
              className={`flex items-start mb-1 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              type='button'
              onClick={() => {
                i18next.changeLanguage('ar');
                localStorage.setItem('dir', JSON.stringify('rtl'));
              }}
            >
              <p
                className={`w-full rounded text-xl mt-1 ${
                  currentLanguageCode === 'ar'
                    ? 'text-purple'
                    : 'text-custome_gray'
                } `}
              >
                العربية
              </p>
            </button>
          </div>
          {/* translation */}
        </div>
      </div>
    </div>
  );
};

export default PublicHeader;
