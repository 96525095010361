import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';
import UseJobStagelabelName from 'hooks/useJobStageLabel';
import JobAnalytics from '../Dashboard/jobAnalytics/index';
import './dashboard.styles.scss';
// import { useTour } from '@reactour/tour';

import Overview from './Components/Overview';

import BasePrivateLayout from '../../basePrivateLayout';

const PendingAssignments = lazy(() =>
  import('./Components/PendingAssignments')
);

function Dashboard() {
  const { path } = useRouteMatch();
  // const { setCurrentStep } = useTour();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCurrentStep(0);
  //   }, 500);
  //   // eslint-disable-next-line
  // }, []);

  // eslint-disable-next-line
  UseJobStagelabelName({ enable: true });
  return (
    <BasePrivateLayout>
      <Switch>
        <Redirect exact from={path} to={`${path}/overview`} />
        <Route path={`${path}/overview`} component={Overview} />

        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='max-w-main-page h-70vh flex justify-center items-center '>
                <LottiLoader />
              </div>
            </div>
          }
        >
          <Switch>
            <Route
              path={`${path}/pending-assignments`}
              component={PendingAssignments}
            />

            <Route path={`${path}/job-analytics`} component={JobAnalytics} />

            {/* DO NOT CHNAGE this empty route position Add everything above this line */}
            <Route component={NoPageFound} />
          </Switch>
        </Suspense>
      </Switch>
    </BasePrivateLayout>
  );
}

export default Dashboard;
