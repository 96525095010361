import { useState, useEffect } from 'react';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';

const AnimatedCircularProgressbarProvider = ({
  valueStart,
  valueEnd,
  children,
  duration,
  ...props
}) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated((val) => !val);
    // return () => {};
  }, []);

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
          ease: easeQuadInOut,
        },
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};

export default AnimatedCircularProgressbarProvider;
