import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import cookies from 'js-cookie';

import dateFormatter from 'helper/dateFormatter';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import { AuthContext } from 'contexts/authContext';

const SingleNotification = (props) => {
  const { authState } = useContext(AuthContext);
  const currentLanguageCode = cookies.get('i18next');

  const history = useHistory();

  const date = dateFormatter(props.created_at, 'DD, MMM');

  const handleClick = (data) => {
    if (
      data.url.includes('Skill_Assessment') ||
      data.url.includes('Holistic_Assessment')
    ) {
      if (data?.for_users[authState.user_id] === false) {
        readNotification.mutate({ notifications: [data._id] });
      }

      history.push(data.url);
      props.setNotificationPanelVisible(false);
    } else {
      if (data?.for_users[authState.user_id] === false) {
        readNotification.mutate({ notifications: [data._id] });
      }
      history.push(data.url);
      props.setNotificationPanelVisible(false);
    }
  };

  const readNotification = useMutation(async (data) => {
    const res = await makeAPIRequest.put(apiEndPoints.NOTIFICATIONS, data);
    return res.data;
  });

  return (
    <div
      className='flex space-x-2 my-6 cursor-pointer relative z-10'
      onClick={() => handleClick(props)}
    >
      <div
        className={`w-2/12 ${
          currentLanguageCode === 'ar' ? '-ml-3 md:-ml-6' : '-mr-3 md:-mr-6'
        }`}
      >
        <div className='bg-primary-rose rounded-md w-10 h-10 text-white'>
          <p className='w-max mx-auto text-xs pt-1'>{date.split(',')[0]}</p>
          <p className='w-max mx-auto text-xs'>{date.split(',')[1]}</p>
        </div>
      </div>
      <div
        className={`text-black w-9/12 md:w-10/12 md:text-justify select-none text-xs md:text-base ${currentLanguageCode === 'ar' ? ' md:pl-3' : ' md:pr-3'}`}
        dangerouslySetInnerHTML={getSanitizeMarkupData({
          markup: props.message,
          renderHTML: true,
        })}
      ></div>
      {props.for_users && props.for_users[authState.user_id] ? null : (
        <div className='z-0'>
          <span
            className={`animate-ping absolute h-2 w-2 rounded-full bg-primary-rose top-1 ${
              currentLanguageCode === 'ar' ? 'md:left-2' : 'md:right-2'
            }`}
          ></span>
          <span
            className={`absolute rounded-full h-2 w-2 bg-primary-rose top-1 ${
              currentLanguageCode === 'ar' ? 'md:left-2' : 'md:right-2'
            }`}
          ></span>
        </div>
      )}
    </div>
  );
};

export default SingleNotification;
