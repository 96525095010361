import axios from 'axios';
// import Notify from '../Notify';
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_API_URL
      : process.env.REACT_APP_PROD_API_URL,
});

const isClientLogin =
  localStorage.getItem('is_client_admin') === 'true' ||
  localStorage.getItem('is_client_team') === 'true';

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  let jwt = `jwt ${JSON.parse(token)}`;

  if (token && jwt !== 'jwt false') {
    config.headers.Authorization = jwt;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    let errTxt = JSON.parse(error?.request?.response);
    if (
      errTxt.detail === 'Error decoding signature.' ||
      errTxt.detail === 'Signature has expired.' ||
      errTxt.detail === 'User account is disabled.'
    ) {
      //   Notify(
      //     'error',
      //     'Session expired',
      //     'Please login again',
      //     'login__fddfdffailed'
      //   );
      setTimeout(function () {
        if (isClientLogin) {
          window.location.replace('/auth/client/login');
        } else {
          window.location.replace('/auth/login');
        }
        localStorage.clear();
      }, 1000);
    }
    return Promise.reject(error);
  }
);
export default instance;
