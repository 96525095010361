import React from 'react';

import fontRegular from '../../../../../assets/fonts/Tajawal-Regular.ttf';
import fontBold from '../../../../../assets/fonts/Tajawal-Bold.ttf';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import dateFormatter from 'helper/dateFormatter';
import dayjs from 'dayjs';
import personIcon from 'assets/images/applicants/personIcon.png';
import PsyCoverPage from 'assets/images/applicants/PsyCoverPage.png';
import { useTranslation } from 'react-i18next';

Font.register({
  family: 'Tajawal',
  fonts: [
    { src: fontRegular, fontWeight: 'normal' },
    { src: fontBold, fontWeight: 'bold' },
  ],
});

const staticScale = [
  { value: 1, id: 'ds1' },
  { value: 2, id: 'ds2' },
  { value: 3, id: 'ds3' },
  { value: 4, id: 'ds4' },
  { value: 5, id: 'ds5' },
  { value: 6, id: 'ds6' },
  { value: 7, id: 'ds7' },
  { value: 8, id: 'ds8' },
  { value: 9, id: 'ds9' },
  { value: 10, id: 'ds10' },
];

export default function PsychometricReportPdf({
  getApplicantData,
  imageUrl,
  radarChartData,
  bigSixteenData,
}) {
  const { t } = useTranslation(['psychometricReport']);
  const convertLowerAndUnderScore = (string) => {
    return string.toLowerCase().replaceAll(' ', '_');
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Tajawal',
    },
    mainView: {
      backgroundColor: '#ED2C94',
      fontFamily: 'Tajawal',
    },
    header: {
      textAlign: 'center',
      fontSize: 20,
      color: '#ffffff',
      padding: 20,
      fontWeight: 'bold',
      fontFamily: 'Tajawal',
    },
    content: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#ffffff',
      paddingBottom: 20,
      fontFamily: 'Tajawal',
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: 20,
      fontFamily: 'Tajawal',
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexWithJusCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    subHeading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontFamily: 'Tajawal',
    },

    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      fontFamily: 'Tajawal',
    },
    chartMain: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    textRotate90: {
      fontSize: 9,
      paddingBottom: 30,
      color: 'rgba(142, 142, 142, 1)',
    },
    chartImage: {
      display: 'flex',
      width: '100%',
    },
    colorAndText: {
      display: 'flex',
      flexDirection: 'row',
    },
    orangeSquare: {
      width: 10,
      height: 10,
      backgroundColor: '#ED2C94',
      marginRight: 5,
      marginLeft: 10,
    },
    greySquare: {
      width: 10,
      height: 10,
      backgroundColor: '#858585',
      marginRight: 5,
      marginLeft: 10,
    },
    smallText: {
      fontSize: 8,
      fontFamily: 'Tajawal',
    },
    sectionTwo: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      marginTop: 20,
      fontFamily: 'Tajawal',
    },
    footerSection: {
      position: 'absolute',
      paddingLeft: 20,
      paddingRight: 20,
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
      opacity: 0.6,
      fontFamily: 'Tajawal',
    },
    // pageNumber: {
    //   position: 'absolute',
    //   fontSize: 12,
    //   bottom: 5,
    //   right: 10,
    //   textAlign: 'center',
    //   color: 'grey',
    // },
    traitsHeader: {
      display: 'flex',
      flexDirection: 'row',
      padding: 6,
      paddingTop: 10,
      paddingBottom: 10,
      margin: 1,
      color: '#6b7280',
      backgroundColor: '#ffffff',
      fontSize: 11,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 1,
      fontFamily: 'Tajawal',
    },
    traitsBody: {
      display: 'flex',
      flexDirection: 'row',
      padding: 6,
      paddingTop: 10,
      paddingBottom: 10,
      margin: 1,
      color: '#6b7280',
      backgroundColor: '#ffffff',
      fontSize: 10,
      fontWeight: 700,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 1,
      fontFamily: 'Tajawal',
    },
    traitsNumber: {
      padding: 5,
      paddingTop: 6,
      paddingBottom: 6,
      borderRadius: 2,
      fontFamily: 'Tajawal',
    },
  });

  const getScore = (data) => {
    const res = radarChartData?.find((item) => item.traits === data)?.score;
    return res;
  };

  return (
    <>
      <Document>
        <Page
          size='A4'
          wrap={true}
          style={{
            flexDirection: 'row',
            backgroundColor: '#ED2C94',
            width: '100%',
            height: '100%',
            orientation: 'portrait',
          }}
        >
          <View
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Image
              source={PsyCoverPage}
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            />
          </View>
        </Page>
        <Page size='A4' wrap>
          <View style={styles.mainView} fixed>
            {/* <Text style={styles.header}>
              {JSON.parse(localStorage.getItem('psychometric_stage_name'))}
            </Text> */}
            <View style={styles.headerContent}>
              <Text style={[styles.content, { fontSize: 12 }]}>
                {getApplicantData?.name}
              </Text>
              <Text style={[styles.content, { fontSize: 12 }]}>
                {t('assessment_date')}:{' '}
                {dateFormatter(
                  getApplicantData?.Psychometric_Assessment_completion_date ||
                    getApplicantData?.Holistic_Assessment_completion_date,
                  'DD MMM YYYY'
                )}
              </Text>
              <Text style={[styles.content, { fontSize: 12 }]}>
                {t('assessment_date')} : {getApplicantData?.job_name}
              </Text>
              {/* <Text style={styles.content}>
                Total Score : {totalPercentage && `${totalPercentage}%`}
              </Text> */}
            </View>
          </View>

          {/* Big 5 traits start */}
          <View style={styles.bodyContainer}>
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: '#ED2C94',
                  }}
                >
                  {t('big_5_traits')}
                </Text>
              </View>
              <View style={styles.chartMain}>
                <View
                  style={{
                    display: 'flex',
                    width: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingRight: 20,
                  }}
                >
                  <Image source={imageUrl} style={styles.chartImage} />
                </View>
                <View
                  style={{
                    display: 'flex',
                    width: '50%',
                    flexDirection: 'column',
                    fontSize: 11,
                    color: '#515151',
                    padding: 5,
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingRight: 10,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {' '}
                          {t('agreeable')}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: '#ED2C94',
                            marginLeft: 10,
                          }}
                        >
                          ({getScore('Agreeable')}%)
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontWeight: 'normal',
                            marginTop: 5,
                            marginBottom: 5,
                            opacity: 0.8,
                          }}
                        >
                          {t('indicative_traits')}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          opacity: 0.8,
                        }}
                      >
                        {getScore('Agreeable') < 50 ? (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('critical')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('uncooperative')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('suspicious')}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('helpful')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('trusting')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('empathetic')}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingLeft: 10,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {t('conscientiousness')}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: '#ED2C94',
                            marginLeft: 10,
                          }}
                        >
                          ({getScore('Conscientiousness')}%)
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontWeight: 'normal',
                            marginTop: 5,
                            marginBottom: 5,
                            opacity: 0.8,
                          }}
                        >
                          {t('indicative_traits')}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          opacity: 0.8,
                        }}
                      >
                        {getScore('Conscientiousness') < 50 ? (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('impulsive')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('careless')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('disorganized')}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('hardworking')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('dependable')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('organized')}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingRight: 10,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {t('extraversion')}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: '#ED2C94',
                            marginLeft: 10,
                          }}
                        >
                          ({getScore('Extraversion')}%)
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontWeight: 'normal',
                            marginTop: 5,
                            marginBottom: 5,
                            opacity: 0.8,
                          }}
                        >
                          {t('indicative_traits')}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          opacity: 0.8,
                        }}
                      >
                        {getScore('Extraversion') < 50 ? (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('quite')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('reserved')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('withdrawn')}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('outgoing')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('warm')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('seeks_adventure')}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingLeft: 10,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {t('neuroticism')}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: '#ED2C94',
                            marginLeft: 10,
                          }}
                        >
                          ({getScore('Neuroticism')}%)
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontWeight: 'normal',
                            marginTop: 5,
                            marginBottom: 5,
                            opacity: 0.8,
                          }}
                        >
                          {t('indicative_traits')}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          opacity: 0.8,
                        }}
                      >
                        {getScore('Neuroticism') < 50 ? (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('calm')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('even-tempered')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('secure')}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('anxious')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('unhappy')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('prone_to_negative_emotions')}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingRight: 10,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <Text style={{ fontWeight: 'bold' }}>
                          {t('openness')}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: '#ED2C94',
                            marginLeft: 10,
                          }}
                        >
                          ({getScore('Openness')}%)
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontWeight: 'normal',
                            marginTop: 5,
                            marginBottom: 5,
                            opacity: 0.8,
                          }}
                        >
                          {t('indicative_traits')}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          opacity: 0.8,
                        }}
                      >
                        {getScore('Openness') < 50 ? (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('practical')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('conventional')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('prefers_routine')}
                              </Text>
                            </View>
                          </>
                        ) : (
                          <>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('curious')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('multiple_interests')}
                              </Text>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                fontSize: 10,
                                marginTop: 3,
                                marginBottom: 3,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  width: 4,
                                  height: 4,
                                  backgroundColor: '#000000',
                                  borderRadius: '50%',
                                  marginRight: 4,
                                  marginLeft: 4,
                                }}
                              ></View>
                              <Text style={{ display: 'flex' }}>
                                {t('independent')}
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        paddingLeft: 10,
                        marginBottom: 20,
                      }}
                    ></View>
                  </View>
                </View>
              </View>
            </>

            <>
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {t('agreeableness')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 10,
                    color: '#515151',
                    padding: 10,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {t(
                      'this_personality_dimension_includes_attributes_such_as_trust'
                    )}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderTop: 1,
                        width: '100%',
                        borderStyle: 'dashed',
                        borderColor: '#6600C9',
                      }}
                    ></View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        right: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('high')}</Text>
                    </View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        left: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('low')}</Text>
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: -14,
                        left: -10,
                      }}
                    >
                      <View
                        style={{ paddingLeft: `${getScore('Agreeable')}%` }}
                      >
                        <Image
                          source={personIcon}
                          style={{ width: 26, height: 26, margin: 2 }}
                        ></Image>
                        <Text style={{ color: '#6600C9', marginLeft: 6 }}>
                          {getScore('Agreeable')}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      marginTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('takes_little_interest_in_others')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t("doesn't_care_about_how_other_people_feel")}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t("has_little_interest_in_other_people's_problems")}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('insults_and_belittles_others')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('manipulates_others_to_get_what_they_want')}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'right',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('has_great_deal_of_interest_in_other_people')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('cares_about_others')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('feels_empathy_and_concern_for_other_people')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t(
                          'enjoys_helping_and_contributing_to_the_happiness_of_others'
                        )}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('assists_others_who_are_in_need_of_help')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {t('conscientiousness')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 10,
                    color: '#515151',
                    padding: 10,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {t(
                      'standard_features_of_this_dimension_include_high_levels_of_thoughtfulness'
                    )}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderTop: 1,
                        width: '100%',
                        borderStyle: 'dashed',
                        borderColor: '#6600C9',
                      }}
                    ></View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        right: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('high')}</Text>
                    </View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        left: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('low')}</Text>
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: -14,
                        left: -10,
                      }}
                    >
                      <View
                        style={{
                          paddingLeft: `${getScore('Conscientiousness')}%`,
                        }}
                      >
                        <Image
                          source={personIcon}
                          style={{ width: 26, height: 26, margin: 2 }}
                        ></Image>
                        <Text style={{ color: '#6600C9', marginLeft: 6 }}>
                          {getScore('Conscientiousness')}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      marginTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('dislike_structure_and_schedules')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t("makes_messes_and_doesn't_take_care_of_things")}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t(
                          'fails_to_return_the_things_or_put_them_back_where_they_belongs'
                        )}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('procastinates_important_tasks')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('fails_to_complete_necessery_or_assigned_tasks')}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'right',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('spends_time_preparing')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('finishes_important_tasks_right_away')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('pays_attention_to_detail')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('enjoys_having_a_set_schedule')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {t('extraversion')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 10,
                    color: '#515151',
                    padding: 10,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {t(
                      'extraversion_(or_extroversion)_is_characterized_by_excitability'
                    )}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderTop: 1,
                        width: '100%',
                        borderStyle: 'dashed',
                        borderColor: '#6600C9',
                      }}
                    ></View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        right: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('high')}</Text>
                    </View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        left: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text>{t('low')}</Text>
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: -14,
                        left: -10,
                      }}
                    >
                      <View
                        style={{
                          paddingLeft: `${getScore('Extraversion')}%`,
                        }}
                      >
                        <Image
                          source={personIcon}
                          style={{ width: 26, height: 26, margin: 2 }}
                        ></Image>
                        <Text style={{ color: '#6600C9', marginLeft: 6 }}>
                          {getScore('Extraversion')}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      marginTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('prefers_solitude')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('feels_exhausted_when_having_to_socialize_a_lot')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('finds_it_difficult_to_start_conversations')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('dislike_making_small_talks')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('carefully_thinks_things_before_speak')}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'right',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('enjoys_being_the_center_of_attention')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('likes_to_start_conversations')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('finds_it_easy_to_make_new_friends')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('enjoys_meeting_new_people')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('say_things_before_thinking_about_them')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {t('neuroticism')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 10,
                    color: '#515151',
                    padding: 10,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {t('neuroticism_is_a_trait_characterized_by_sadness')}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderTop: 1,
                        width: '100%',
                        borderStyle: 'dashed',
                        borderColor: '#6600C9',
                      }}
                    ></View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        right: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text> {t('high')}</Text>
                    </View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        left: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text> {t('low')}</Text>
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: -14,
                        left: -10,
                      }}
                    >
                      <View
                        style={{
                          paddingLeft: `${getScore('Neuroticism')}%`,
                        }}
                      >
                        <Image
                          source={personIcon}
                          style={{ width: 26, height: 26, margin: 2 }}
                        ></Image>
                        <Text style={{ color: '#6600C9', marginLeft: 6 }}>
                          {getScore('Neuroticism')}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      marginTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('emotionally_stable')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('deals_well_with_stress')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t("doesn't_worry_much")}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('rarely_feels_sad_or_depressed')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('is_very_relaxed')}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'right',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('experience_a_lot_of_stress')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('gets_upset_easily')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('feels_anxious')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('worries_about_many_different_things')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('experience_dramatic_shifts_in_mood')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {t('openness')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 10,
                    color: '#515151',
                    padding: 10,
                  }}
                >
                  <Text style={{ marginBottom: 10 }}>
                    {t(
                      'this_trait_features_characteristics_such_as_imagination_and_insight'
                    )}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderTop: 1,
                        width: '100%',
                        borderStyle: 'dashed',
                        borderColor: '#6600C9',
                      }}
                    ></View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        right: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text> {t('high')}</Text>
                    </View>
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#6600C9',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        top: -5,
                      }}
                    ></View>
                    <View
                      style={{
                        color: '#6600C9',
                        position: 'absolute',
                        left: 0,
                        top: 8,
                        fontSize: 10,
                      }}
                    >
                      <Text> {t('low')}</Text>
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: -14,
                        left: -10,
                      }}
                    >
                      <View
                        style={{
                          paddingLeft: `${getScore('Openness')}%`,
                        }}
                      >
                        <Image
                          source={personIcon}
                          style={{ width: 26, height: 26, margin: 2 }}
                        ></Image>
                        <Text style={{ color: '#6600C9', marginLeft: 6 }}>
                          {getScore('Openness')}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      marginTop: 15,
                      paddingBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'left',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('dislikes_change')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('does_not_enjoy_new_things')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('resists_new_ideas')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('not_very_imaginative')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('dislike_abstract_or_theoritical_concepts')}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                        textAlign: 'right',
                      }}
                    >
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('very_creative')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('open_to_trying_new_things')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('focused_on_tackling_new_chanlleges')}
                      </Text>
                      <Text style={{ marginTop: 3, marginBottom: 3 }}>
                        {t('happy_to_think_about_abstract_concepts')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </>
          </View>
          {/* Big 5 traits end */}

          {/* 16 Personality Factors Scores Start */}
          <View style={styles.bodyContainer} break>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: '#ED2C94',
                    fontFamily: 'Tajawal',
                  }}
                >
                  16 {t('16_personality_factors_scores').replace('16', '')}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  paddingLeft: 3,
                  paddingRight: 3,
                  paddingTop: 12,
                  paddingBottom: 12,
                  backgroundColor: '#FFF6F3',
                  borderRadius: 4,
                }}
                wrap={false}
              >
                <View
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View style={[styles.traitsHeader, { width: '16%' }]}>
                    <Text>{t('scale')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '20%',
                        justifyContent: 'flex-end',
                        textAlign: 'right',
                      },
                    ]}
                  >
                    <Text> {t('left_description')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '36%',
                        justifyContent: 'space-evenly',
                      },
                    ]}
                  >
                    <Text style={styles.traitsNumber}>1</Text>
                    <Text style={styles.traitsNumber}>2</Text>
                    <Text style={styles.traitsNumber}>3</Text>
                    <Text style={styles.traitsNumber}>4</Text>
                    <Text style={styles.traitsNumber}>5</Text>
                    <Text style={styles.traitsNumber}>6</Text>
                    <Text style={styles.traitsNumber}>7</Text>
                    <Text style={styles.traitsNumber}>8</Text>
                    <Text style={styles.traitsNumber}>9</Text>
                    <Text style={styles.traitsNumber}>10</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '20%',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                      },
                    ]}
                  >
                    <Text>{t('right_description')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '8%',
                      },
                    ]}
                  >
                    <Text>%</Text>
                  </View>
                </View>
                {bigSixteenData.map((item, index) => {
                  return index < 11 ? (
                    <View
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                      }}
                      key={item.scale}
                    >
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '16%',
                            backgroundColor: '#ED2C94',
                            color: '#ffffff',
                          },
                        ]}
                      >
                        <Text>
                          {t(convertLowerAndUnderScore(item.scaleName))}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '20%',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                          },
                        ]}
                      >
                        <Text style={{ textAlign: 'right', marginBottom: 0 }}>
                          {item.leftDescription.heading}
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                          }}
                        >
                          {item.leftDescription.traits}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '36%',
                            justifyContent: 'space-evenly',
                            height: '20px',
                          },
                        ]}
                      >
                        {staticScale.map((staticItem) => (
                          <Text
                            key={staticItem.id}
                            style={[
                              styles.traitsNumber,
                              {
                                backgroundColor: `${
                                  staticItem.value === item.scalevalue
                                    ? '#ED2C94'
                                    : '#eff6ff'
                                }`,
                                color: `${
                                  staticItem.value === item.scalevalue
                                    ? '#ffffff'
                                    : '#6b7280'
                                }`,
                              },
                            ]}
                          >
                            {staticItem.value}
                          </Text>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '20%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                          },
                        ]}
                      >
                        <Text style={{ textAlign: 'left', marginBottom: 3 }}>
                          {item.rightDescription.heading}
                        </Text>
                        <Text style={{ fontSize: 8 }}>
                          {item.rightDescription.traits}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '8%',
                          },
                        ]}
                      >
                        <Text>{item.percentile}</Text>
                      </View>
                    </View>
                  ) : null;
                })}
              </View>
              <View
                style={{
                  width: '100%',
                  paddingLeft: 3,
                  paddingRight: 3,
                  paddingTop: 12,
                  paddingBottom: 12,
                  marginTop: 10,
                  backgroundColor: '#FFF6F3',
                  borderRadius: 4,
                }}
                wrap={false}
              >
                <View
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View style={[styles.traitsHeader, { width: '16%' }]}>
                    <Text>{t('scale')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '20%',
                        justifyContent: 'flex-end',
                        textAlign: 'right',
                      },
                    ]}
                  >
                    <Text> {t('left_description')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '36%',
                        justifyContent: 'space-evenly',
                      },
                    ]}
                  >
                    <Text style={styles.traitsNumber}>1</Text>
                    <Text style={styles.traitsNumber}>2</Text>
                    <Text style={styles.traitsNumber}>3</Text>
                    <Text style={styles.traitsNumber}>4</Text>
                    <Text style={styles.traitsNumber}>5</Text>
                    <Text style={styles.traitsNumber}>6</Text>
                    <Text style={styles.traitsNumber}>7</Text>
                    <Text style={styles.traitsNumber}>8</Text>
                    <Text style={styles.traitsNumber}>9</Text>
                    <Text style={styles.traitsNumber}>10</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '20%',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                      },
                    ]}
                  >
                    <Text>{t('right_description')}</Text>
                  </View>
                  <View
                    style={[
                      styles.traitsHeader,
                      {
                        width: '8%',
                      },
                    ]}
                  >
                    <Text>%</Text>
                  </View>
                </View>
                {bigSixteenData.map((item, index) => {
                  return index >= 11 ? (
                    <View
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                      }}
                      key={item.scale}
                    >
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '16%',
                            backgroundColor: '#ED2C94',
                            color: '#ffffff',
                          },
                        ]}
                      >
                        <Text>
                          {' '}
                          {t(convertLowerAndUnderScore(item.scaleName))}{' '}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '20%',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                          },
                        ]}
                      >
                        <Text style={{ textAlign: 'right', marginBottom: 3 }}>
                          {item.leftDescription.heading}
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                          }}
                        >
                          {item.leftDescription.traits}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '36%',
                            justifyContent: 'space-evenly',
                          },
                        ]}
                      >
                        {staticScale.map((staticItem) => (
                          <Text
                            key={staticItem.id}
                            style={[
                              styles.traitsNumber,
                              {
                                backgroundColor: `${
                                  staticItem.value === item.scalevalue
                                    ? '#ED2C94'
                                    : '#eff6ff'
                                }`,
                                color: `${
                                  staticItem.value === item.scalevalue
                                    ? '#ffffff'
                                    : '#6b7280'
                                }`,
                              },
                            ]}
                          >
                            {staticItem.value}
                          </Text>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '20%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                          },
                        ]}
                      >
                        <Text style={{ textAlign: 'left', marginBottom: 3 }}>
                          {item.rightDescription.heading}
                        </Text>
                        <Text style={{ fontSize: 8 }}>
                          {item.rightDescription.traits}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.traitsBody,
                          {
                            width: '8%',
                          },
                        ]}
                      >
                        <Text>{item.percentile}</Text>
                      </View>
                    </View>
                  ) : null;
                })}
              </View>
            </View>
          </View>

          {/* 16 Personality Factors Scores end */}

          {/* footer start */}
          <View style={styles.footerSection} fixed>
            <View
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontSize: 9,
                color: 'grey',
                paddingTop: 10,
                borderTop: 1,
                borderColor: 'grey',
              }}
            >
              <Text style={{ flex: '1 1 0%' }}>
                &copy; {dayjs().year()}. All rights reserved.
              </Text>
              <Text style={{ flex: '1 1 0%' }}></Text>
              <Text style={{ flex: '1 1 0%' }}>Powered by Smart hire</Text>
            </View>
          </View>
          {/* <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          /> */}
        </Page>
      </Document>
    </>
  );
}
