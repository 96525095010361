import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import Editor from 'pages/common/Jobs/components/editor';

import { AuthContext } from 'contexts/authContext';

import { ErrorToast, SuccessToast } from 'services/toasterService';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import PrimaryButton from 'components/Button/primaryButton';

import { ReactComponent as SendIlls } from 'assets/images/applicants/sendIlls.svg';
import { ReactComponent as Attachlls } from 'assets/images/applicants/attach.svg';
import { ReactComponent as Removells } from 'assets/images/applicants/removeIlls.svg';
import pdflls from 'assets/images/applicants/pdf.png';
import docslls from 'assets/images/applicants/docs.png';

export default function ComposeEmail({
  setComposeEmail,
  composeEmail,
  applicantEmail,
  setShowEmailModal,
}) {
  const { t } = useTranslation(['jobsAndApplicants']);
  const currentLanguageCode = cookies.get('i18next');
  const queryClient = useQueryClient();

  const { authState } = useContext(AuthContext);

  const [descriptionData, setDescriptionData] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [descriptionError, setDescriptionError] = useState('');
  const [handleValidation, setHandleValidation] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    if (handleValidation) {
      handelDescriptionValidation(descriptionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleValidation, descriptionData]);

  const defaultValues = {
    to: '',
    cc: null,
    subject: '',
    description: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    reset({
      to: applicantEmail,
    });
  }, [applicantEmail, reset]);

  const header = (
    <div>
      <div className='flex justify-center'>
        <p className='text-center flex-1 text-primary-rose text-2xl mt-1'>
          {t('jobsAndApplicants:mail')}
        </p>
        <div className='z-50 flex justify-end'>
          <svg
            width='40'
            height='40'
            viewBox='0 0 67 65'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
            className='cursor-pointer'
            onClick={() => handleCloseModal()}
          >
            <path
              d='M41.3962 24.3962L24.9824 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M24.9824 24.3962L41.3962 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
    </div>
  );

  const getFormErrorMessage = (name) => {
    return (
      <div className='w-10/12 mx-auto'>
        {errors[name] && (
          <small className='p-error'>{errors[name].message}</small>
        )}
      </div>
    );
  };

  const handleCloseModal = () => {
    setComposeEmail(false);
    setDescriptionData('');
    setDescriptionError('');
    setHandleValidation(false);
    setShowEmailModal(true);
    reset();
  };

  const sendEmailMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANTS_EMAILS
          : apiEndPoints.APPLICANTS_EMAILS,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        queryClient.invalidateQueries(['get-applicant-mails']);
        handleCloseModal();
        setShowEmailModal(true);
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
        handleCloseModal();
        setShowEmailModal(true);
      },
    }
  );

  const handelDescriptionValidation = (jobDescription) => {
    if (jobDescription === '') {
      setDescriptionError(t('common:this_is_a_required_field'));
      return false;
    }
    if (jobDescription === '<p><br></p>') {
      setDescriptionError(t('common:this_is_a_required_field'));
      return false;
    }
    if (jobDescription !== '') {
      setDescriptionError('');
      return true;
    }
  };

  const handleSubmitValidation = () => {
    setHandleValidation(true);
  };

  const onSubmit = (data) => {
    const newData = {
      ...data,
      description: descriptionData,
      cc: data.cc === undefined ? 0 : data.cc.toString(),
    };
    const formData = new FormData();
    formData.append('to', newData.to);
    formData.append('cc', newData.cc);
    formData.append('subject', newData.subject);
    formData.append('description', newData.description);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append(`aa${i}`, selectedFile[i].name);
    }

    if (authState.is_client_admin || authState.is_client_team) {
      formData.append('token', authState.token_client);
    }
    sendEmailMutation.mutate(formData);
  };

  // Attachments code
  const changeHandler = (event) => {
    setFileError(false);
    const urlArr = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (
        event.target.files[i].type === 'image/png' ||
        event.target.files[i].type === 'image/jpeg' ||
        event.target.files[i].type === '.doc' ||
        event.target.files[i].type === '.docx' ||
        event.target.files[i].type === 'application/pdf' ||
        event.target.files[i].type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        event.target.files[i].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        urlArr.push({
          name: event.target.files[i],
          url: URL.createObjectURL(event.target.files[i]),
          type: event.target.files[i].type,
        });
        setSelectedFile(urlArr);
      } else {
        setSelectedFile([]);
        setFileError(true);
      }
    }
  };

  // remove attachments
  const handleRemove = (data) => {
    const newArr = selectedFile.filter((item) => item.url !== data.url);
    setSelectedFile(newArr);
  };

  return (
    <div className='modal-padding'>
      <Dialog
        header={header}
        visible={composeEmail}
        onHide={() => handleCloseModal()}
        style={{
          borderRadius: '1rem',
        }}
        className='w-4/6 max-w-2xl lg:w-8/12 lg:max-w-3xl rounded-lg  modal-with-footer'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
        appendTo='self'
      >
        <form onSubmit={handleSubmit(onSubmit)} className='relative'>
          <div>
            <p className={`w-10/12 mx-auto text-sm pb-0.5 text-black ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
              {t('jobsAndApplicants:to')}
            </p>
            <span className='p-float-label w-10/12 mx-auto'>
              <Controller
                name='to'
                control={control}
                rules={{
                  required: 'This is a required field',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t('authentication:invalid_email_address'),
                  },
                }}
                render={({ field }) => <InputText id={field.name} {...field} />}
              />
            </span>
            {getFormErrorMessage('to')}
          </div>
          <div className='mt-3'>
            <p className={`w-10/12 mx-auto text-sm -mb-1 text-black ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
              {' '}
              {t('jobsAndApplicants:cc')}
            </p>
            <span className='p-float-label w-10/12 mx-auto invite_applicants_overlay_container'>
              <Controller
                name='cc'
                control={control}
                render={({ field }) => (
                  <Chips
                    id={field.name}
                    {...field}
                    style={{ width: '100%' }}
                    allowDuplicate={false}
                    separator=','
                  />
                )}
              />
            </span>
          </div>
          <div className='mt-3'>
            <p className={`w-10/12 mx-auto text-sm pb-0.5 text-black ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
              {t('jobsAndApplicants:subject')}
            </p>
            <span className='p-float-label w-10/12 mx-auto'>
              <Controller
                name='subject'
                control={control}
                rules={{
                  required: 'This is a required field',
                }}
                render={({ field }) => <InputText id={field.name} {...field} />}
              />
            </span>
            {getFormErrorMessage('subject')}
          </div>
          <div className='editor2 mt-3 w-10/12 mx-auto'>
            <p className={`text-sm pb-0.5 text-black ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
              {' '}
              {t('jobsAndApplicants:description')}
            </p>
            <Editor onChange={setDescriptionData} text={descriptionData} />
          </div>
          {/*    <div className='w-10/12 mx-auto'>
            {descriptionError && (
              <small className='p-error'>{descriptionError}</small>
            )}
          </div> */}
          <div className='flex w-10/12 mx-auto mt-3 mb-4'>
            <p className={`text-sm pb-0.5 text-black ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
              {' '}
              {t('jobsAndApplicants:attachments')}
            </p>
            <div
              className={`flex w-full justify-end ${
                currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
              }`}
            >
              <label htmlFor='image'>
                <Attachlls className='cursor-pointer' />
              </label>
              <input
                type='file'
                id='image'
                name='image'
                accept='image/png, image/jpeg, .doc,.docx, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                className='hidden'
                onChange={changeHandler}
                multiple
              ></input>
            </div>
          </div>
          {fileError ? (
            <p
              className={`p-error text-xs -mt-4 ${
                currentLanguageCode === 'ar'
                  ? 'mr-12 lg:mr-16'
                  : 'ml-12 lg:ml-16'
              }`}
            >
              {t('jobsAndApplicants:invalid_file_format')}
            </p>
          ) : null}
          <div className='flex flex-wrap mx-12'>
            {selectedFile &&
              selectedFile.map((item) => {
                return (
                  <div key={item.url} className='w-12 h-12 m-4 relative'>
                    {item.type === 'image/png' || item.type === 'image/jpeg' ? (
                      <>
                        <img
                          src={item.url}
                          alt='preview'
                          className='w-12 h-12'
                        />
                        <div
                          className='text-white absolute top-0 right-0 cursor-pointer transform translate-x-2 -translate-y-1.5'
                          onClick={() => handleRemove(item)}
                        >
                          <Removells className='w-4 h-4' />
                        </div>
                      </>
                    ) : item.type === 'application/pdf' ? (
                      <>
                        <img src={pdflls} alt='preview' className='w-12 h-12' />
                        <div
                          className='text-white absolute top-0 right-0 transform translate-x-2 -translate-y-1.5 cursor-pointer'
                          onClick={() => handleRemove(item)}
                        >
                          <Removells className='w-4 h-4' />
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={docslls}
                          alt='preview'
                          className='w-12 h-12'
                        />
                        <div
                          className='text-white absolute top-0 right-0 transform translate-x-2 -translate-y-1.5 cursor-pointer'
                          onClick={() => handleRemove(item)}
                        >
                          <Removells className='w-4 h-4' />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <div className='w-full pt-0.5 h-16 sticky bottom-0 bg-white'>
            <div
              className='w-32 mt-3 mx-auto relative'
              onClick={() => handleSubmitValidation()}
            >
              <button className='w-28'>
                <PrimaryButton isLoading={sendEmailMutation.isLoading}>
                  <SendIlls
                    className={`w-3.5 h-3.5 mt-1 absolute ${
                      currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                    }`}
                  />
                  {t('jobsAndApplicants:send')}
                </PrimaryButton>
              </button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
