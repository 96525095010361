import {
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef,
  useCallback,
} from 'react';
import { atom, useAtom } from 'jotai';
import { useHistory, useLocation } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Skeleton } from 'primereact/skeleton';

import UseSpecificApplicant from 'hooks/useSpecificApplicant';
import UseGetSpecificJobStageList from 'hooks/useGetSpecificJobStageList';

import queryKeys from 'reactQuery/constant';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';

import Loader from 'components/Loader';

import { JobContext } from 'contexts/jobContext';
import { ApplicantsContext } from 'contexts/applicantsContext';
import { AuthContext } from 'contexts/authContext';

import dateFormatter from 'helper/dateFormatter';
import getUserInitialsFromname from 'helper/getUserInitialsFromName';
import getDisplaynameForJobStages from 'helper/getDisplaynameForJobStages';
import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';
import getModifiedStagename from 'helper/getModifiedStagename';

import SomethingWentWrongComponent from 'pages/common/error/components/somethingWentWrongComponent';

import CircularProgressbar from '../../components/circularProgressbar';
import ConfirmMoveApplicantModal from '../../components/modal/ConfirmMoveApplicantModal';
import ApplicantMovedToNextStageModal from '../../components/modal/ApplicantMovedToNextStage';
import ScheduleAssessmentModal from '../../components/modal/ScheduleAssessmentModal';
import NotesModal from '../../components/modal/NotesModal';
import ApplicantEmailModal from '../../components/modal/applicantMails';
import ConfirmRejectApplicantModal from '../../components/modal/ConfirmRejectApplicantModal';
import RescheduledInterviewModal from '../../components/modal/RescheduledSuccessModal';
import ScheduleInterviewModal from '../../components/modal/ScheduleInterviewModal';
import ConfirmMoveApplicantToClientModal from '../../components/modal/confirmMoveApplicantToClientModal';

import EditApplicantsDetailsModal from 'pages/common/Jobs/components/modals/editApplicantsDetailsModal';

import TestAssessmentCredentialModal from 'pages/HR/Applicants/InterviewStages/components/modals/testAssessmentCredentialsModal';

import whatapp_icon from 'assets/icons/whatsapp-96.png';
import { ReactComponent as OptionsIcon } from 'assets/images/team/optionsIcon.svg';
import { ReactComponent as RestoreIlls } from 'assets/images/team/restoreIlls.svg';

import {
  AcceptIcon,
  RejectIcon,
  AnotherOfferIcon,
  PhoneIcon,
  EmailIcon,
  NotesIcon,
  // ShareIcon,
  LocationIcon,
  TimePostedIcon,
  ExperienceIcon,
  ApplicantEditIcon,
} from '../components/Icons';

import PrimaryButton from 'components/Button/primaryButton';
import Tip from 'components/Tooltip';
import Avatar from 'components/Avatar';
import InterviewSuccessfullModal from '../components/modals/InterviewSuccessfullModal';
import ViewResumeModal from 'pages/HR/Applicants/components/modal/viewResumeModal';
import SkillAssessmentReport from 'pages/HR/Applicants/InterviewStages/components/skillAssessmentReport';
import UseApplicantsAssessmentInfo from 'hooks/useApplicantsAssessmentInfo';
import PsychometricReport from 'pages/HR/Applicants/InterviewStages/components/psychometricReport';

export const isjobDeactivatedAtomREC = atom(false);

const InterviewStagesContainer = ({ children }) => {
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const { jobState } = useContext(JobContext);
  const { authState } = useContext(AuthContext);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const [skillReport, setSkillReport] = useState(null);
  const [psyReport, setPSYReport] = useState(null);

  const [clientViewMode, setClientViewMode] = useState(false);

  // console.log(skillReport, 'skillReport file');
  // console.log(psyReport, 'psyReport file');

  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const pathNameArray = location.pathname.split('/');

  const [currentTab, setCurrentTab] = useState('');

  const [nextStage, setNextStage] = useState('');
  const [prevStage, setPrevStage] = useState('');
  const [radarChartData, setRadarChartData] = useState();

  const [inProgressStage, setInprogressStage] = useState([]);

  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [viewResume, setViewResume] = useState(false);

  const [mandatoryFiledError, setMandatoryFiledError] = useState('');

  const [emailCount, setEmailCount] = useState(0);

  const [showApplicantsEditModal, setShowApplicantsEditModal] = useState(false);

  const afterHirePopoverRef = useRef(null);
  const [isJobDeactivated, setIsJobDeactivated] = useAtom(
    isjobDeactivatedAtomREC
  );

  const handelTabChange = (tabName) => {
    /**
     *  hacky way to check the url params, because we don't
     * have access useParams() here, because of container element
     */
    setCurrentTab(tabName);

    if (authState.is_client_admin || authState.is_client_team) {
      if (history.location?.state?.from === 'job-listing-page') {
        history.push(
          `/client/applicants/${pathNameArray[3]}/${tabName}/${pathNameArray[5]}/${pathNameArray[6]}`,
          {
            from: 'job-listing-page',
          }
        );
      } else {
        history.push(
          `/client/applicants/${pathNameArray[3]}/${tabName}/${pathNameArray[5]}/${pathNameArray[6]}`
        );
      }
    } else {
      if (history.location?.state?.from === 'job-listing-page') {
        history.push(
          `/recruiter/applicants/${pathNameArray[3]}/${tabName}/${pathNameArray[5]}/${pathNameArray[6]}`,
          {
            from: 'job-listing-page',
          }
        );
      } else {
        history.push(
          `/recruiter/applicants/${pathNameArray[3]}/${tabName}/${pathNameArray[5]}/${pathNameArray[6]}`
        );
      }
    }
  };

  const applicantDetails = UseSpecificApplicant({
    applicantID: pathNameArray[6],
    setJobStatus: setIsJobDeactivated,
  });

  const jobStagelist = UseGetSpecificJobStageList({
    jobId: pathNameArray[3],
  });

  const moveCandidatetoNextStage = (tabname) => {
    handelTabChange(tabname);
  };

  const checkToActivateStageTabTechnical = () => {
    let visible = false;

    if (inProgressStage.includes('Technical Interview')) {
      if (
        applicantDetails?.data?.data?.[0]?.Technical_Interview ||
        applicantDetails?.data?.data?.[0]?.Technical_Interview_status ===
          'Completed'
      ) {
        visible = true;
      }
    }
    return visible;
  };

  const checkToActivateStageTabHR = () => {
    let visible = false;

    if (inProgressStage.includes('HR Interview')) {
      if (
        applicantDetails?.data.data[0].HR_Interview ||
        applicantDetails?.data.data[0].HR_Interview_status === 'Completed'
      ) {
        visible = true;
      }
    }
    return visible;
  };

  const checkToActivateStageTabPanel = () => {
    let visible = false;

    if (inProgressStage.includes('Panel Interview')) {
      if (
        applicantDetails?.data.data[0].Panel_Interview ||
        applicantDetails?.data.data[0].Panel_Interview_status === 'Completed'
      ) {
        visible = true;
      }
    }
    return visible;
  };
  const renderJobStagesList = (item, index) => {
    if (!applicantDetails.isLoading) {
      switch (item) {
        case 'CV Screening':
          return (
            <li
              key={`specific-applicant-${index}`}
              className={`text-gray-500 hover:text-gray-700 transition-colors px-2 pb-1 cursor-pointer text-sm lg:text-base ${
                currentTab === 'CV_Screening'
                  ? 'border-gradient border-b-3 text-gray-900 font-bold'
                  : 'text-gray-500'
              } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
              onClick={() => handelTabChange('CV_Screening')}
            >
              {t('common:cv_screening')}
            </li>
          );

        case 'Holistic Assessment':
          if (
            inProgressStage.includes('Holistic Assessment') &&
            applicantDetails?.data.data[0].Holistic_Assessment_status !==
              'Not Scheduled'
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'Holistic_Assessment'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => handelTabChange('Holistic_Assessment')}
              >
                {t('common:pre_hire_assessment')}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {t('common:pre_hire_assessment')}
              </li>
            );
          }

        case 'Skill Assessment':
          if (
            inProgressStage.includes('Skill Assessment') &&
            applicantDetails?.data.data[0].Skill_Assessment_status !==
              'Not Scheduled'
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'Skill_Assessment'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => moveCandidatetoNextStage('Skill_Assessment')}
              >
                {t('common:skill_assessment')}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {t('common:skill_assessment')}
              </li>
            );
          }

        case 'Psychometric Assessment':
          if (
            inProgressStage.includes('Psychometric Assessment') &&
            applicantDetails?.data.data[0].Psychometric_Assessment_status !==
              'Not Scheduled'
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'Psychometric_Assessment'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => handelTabChange('Psychometric_Assessment')}
              >
                {JSON.parse(localStorage.getItem('psychometric_stage_name'))}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {JSON.parse(localStorage.getItem('psychometric_stage_name'))}
              </li>
            );
          }

        case 'Technical Interview':
          if (
            checkToActivateStageTabTechnical() &&
            !applicantDetails?.data?.data[0]?.client_only_view
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'Technical_Interview'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => handelTabChange('Technical_Interview')}
              >
                {t('common:interview')}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {t('common:interview')}
              </li>
            );
          }

        case 'HR Interview':
          if (
            checkToActivateStageTabHR() &&
            !applicantDetails?.data?.data[0]?.client_only_view
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'HR_Interview'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => handelTabChange('HR_Interview')}
              >
                {t('common:hr')}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {t('common:hr')}
              </li>
            );
          }

        case 'Panel Interview':
          if (
            checkToActivateStageTabPanel() &&
            !applicantDetails?.data?.data[0]?.client_only_view
          ) {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1 hover:text-gray-700 transition-colors cursor-pointer text-sm lg:text-base ${
                  currentTab === 'Panel_Interview'
                    ? 'border-gradient border-b-3 text-gray-900 font-bold'
                    : 'text-gray-500'
                } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => handelTabChange('Panel_Interview')}
              >
                {t('common:panel_interview')}
              </li>
            );
          } else {
            return (
              <li
                key={`specific-applicant-${index}`}
                className={`px-2 pb-1  opacity-50 cursor-not-allowed  text-gray-500 ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                {t('common:panel_interview')}
              </li>
            );
          }

        default:
          return null;
      }
    }
  };

  const handelgobackToApplicantsOverviewpage = () => {
    if (history.location?.state?.from === 'job-listing-page') {
      history.push(
        `${jobState.Job_listing_pathname}${jobState.Job_listing_searchparam}`
      );
    } else {
      history.push(
        `${jobState.Job_applicant_overview_pathname}${jobState.Job_applicant_overview_searchparam}`
      );
    }
  };

  const findOutNextJobStage = () => {
    let nextstageName;
    if (!jobStagelist.isLoading) {
      const currentIndex = jobStagelist?.data?.indexOf(
        pathNameArray[4]?.replace('_', ' ')
      );
      const nextIndex = (currentIndex + 1) % jobStagelist?.data?.length;
      nextstageName = jobStagelist?.data[nextIndex];
    }
    return nextstageName;
  };

  const handelOpenCandidateMoveToNextStage = () => {
    const nextjobStage = findOutNextJobStage();

    if (
      nextjobStage.includes('Technical') ||
      nextjobStage.includes('HR') ||
      nextjobStage.includes('Panel')
    ) {
      if (authState.is_client_admin || authState.is_client_team) {
        applicantsDispatch({
          type: 'SETCONFIRMMOVEAPPLICANTMODALVISIBLESTATE',
          payload: true,
        });
      } else {
        applicantsDispatch({
          type: 'SETCONFIRMMOVEAPPLICANTTOCLIENTMODALVISIBLESTATE',
          payload: true,
        });
      }
    } else {
      applicantsDispatch({
        type: 'SETSCHEDULEASSESSMENTMODALVISIBLESTATE',
        payload: true,
      });
    }
  };

  const handelOpenRejectCandidateModal = () => {
    applicantsDispatch({
      type: 'SETREJECTAPPLICANTMODALVISIBLESTATE',
      payload: true,
    });
  };

  const getStateStatusKeyName = (stageName) => {
    const stageStatus = stageName.concat('_status');
    return stageStatus;
  };

  const checkTeamMemberPermission = (applicantDetail) => {
    if (applicantDetail.can_move) {
      return true;
    } else {
      return false;
    }
  };

  const handelCanMoveToNextStage = (stageName, applicantDetail) => {
    const stageStatus = getStateStatusKeyName(stageName);

    if (applicantDetail.can_move) {
      if (
        applicantDetail[stageStatus] === 'Viewed' ||
        applicantDetail[stageStatus] === 'Completed'
      ) {
        if (authState.is_recruiter || authState.is_recruiter_team) {
          if (
            pathNameArray[4]?.includes('Interview') &&
            !applicantDetail?.client_only_view
          ) {
            return false;
          }
          return true;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkIfRoleIsClient = () => {
    let isClient;

    if (authState.is_recruiter || authState.is_recruiter_team) {
      isClient = false;
    } else {
      isClient = true;
    }

    return isClient;
  };

  const canRecruiterMove = (applicantDetail) => {
    let canMove;

    if (authState.is_recruiter || authState.is_recruiter_team) {
      if (pathNameArray[4]?.includes('Interview')) {
        canMove = false;
      } else if (
        nextStage?.includes('Interview') &&
        prevStage?.includes('Interview')
      ) {
        canMove = false;
      } else if (nextStage?.includes('CV')) {
        canMove = false;
      } else {
        canMove = true;
      }
    } else if (applicantDetail?.client_only_view) {
      canMove = true;
    } else {
      canMove = true;
    }

    return canMove;
  };

  const canClientMove = (applicantDetail) => {
    let canMove;

    if (authState.is_client_admin || authState.is_client_team) {
      if (
        applicantDetail?.client_only_view ||
        applicantDetail?.assigned_to_recruiter
      ) {
        canMove = false;
      } else {
        canMove = true;
      }
    } else {
      canMove = true;
    }

    return canMove;
  };

  const hireApplicantsMutation = useMutation(
    async () => {
      let queryData;
      let url =
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANT_CHANGE_STATUS
          : apiEndPoints.APPLICANT_CHANGE_STATUS;

      if (authState.is_client_admin || authState.is_client_team) {
        queryData = {
          status: 'Hired',
          applicants: [pathNameArray[6]],
          token: authState.token_client,
        };
      } else {
        queryData = {
          status: 'Hired',
          applicants: [pathNameArray[6]],
        };
      }
      const res = await makeAPIRequest.post(url, queryData);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] ===
              getJobStageNameForBackendQuery(pathNameArray[4]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[2] === pathNameArray[6],
        });
        afterHirePopoverRef?.current?.click();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );

  const restoreprevStateMutation = useMutation(
    async (data) => {
      let queryData;
      let url =
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANT_CHANGE_STATUS
          : apiEndPoints.APPLICANT_CHANGE_STATUS;

      if (authState.is_client_admin || authState.is_client_team) {
        queryData = {
          ...data,
          token: authState.token_client,
        };
      } else {
        queryData = data;
      }
      const res = await makeAPIRequest.post(url, queryData);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] ===
              getJobStageNameForBackendQuery(pathNameArray[4]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[2] === pathNameArray[6],
        });
        afterHirePopoverRef?.current?.click();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );

  const acceptAnotherOfferApplicantMutation = useMutation(
    async () => {
      let queryData;
      let url =
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANT_CHANGE_STATUS
          : apiEndPoints.APPLICANT_CHANGE_STATUS;

      if (authState.is_client_admin || authState.is_client_team) {
        queryData = {
          status: 'Accepted_Another_Offer',
          applicants: [pathNameArray[6]],
          token: authState.token_client,
        };
      } else {
        queryData = {
          status: 'Accepted_Another_Offer',
          applicants: [pathNameArray[6]],
        };
      }
      const res = await makeAPIRequest.post(url, queryData);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] ===
              getJobStageNameForBackendQuery(pathNameArray[4]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[2] === pathNameArray[6],
        });
        afterHirePopoverRef?.current?.click();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );

  const handelRestorePrevState = () => {
    const data = {
      status: 'Accepted',
      applicants: [pathNameArray[6]],
    };
    restoreprevStateMutation.mutate(data);
  };

  const checkIfmandatoryFiledsArePresentInApplicantsData = useCallback(
    (data) => {
      // if (!data.mobile_number) {
      //   setMandatoryFiledError('Please add mobile number');
      // } else if (data.mobile_number) {
      //   setMandatoryFiledError(
      //     data.mobile_number.match(/^[0-9]+$/) != null
      //       ? ''
      //       : 'Phone number is not valid'
      //   );
      // } else
      // if (!data.your_current_location) {
      //   setMandatoryFiledError('Please add location');
      // }
      //  else if (!data.country) {
      //   setMandatoryFiledError('Please add country');
      // }
      // else
      if (!data.email) {
        setMandatoryFiledError('Please add valid email');
      } else if (data.email) {
        const re =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let isvalid = re.test(data.email);
        if (!isvalid) {
          setMandatoryFiledError('Please add valid email');
        } else {
          setMandatoryFiledError('');
        }
      } else {
        setMandatoryFiledError('');
      }
    },
    []
  );

  useEffect(() => {
    setCurrentTab(location.pathname.split('/')[4]);
  }, [location.pathname]);

  useEffect(() => {
    if (!applicantDetails.isLoading) {
      checkIfmandatoryFiledsArePresentInApplicantsData(
        applicantDetails?.data.data[0]
      );
    }
  }, [
    applicantDetails.data,
    applicantDetails.isLoading,
    checkIfmandatoryFiledsArePresentInApplicantsData,
  ]);

  useEffect(() => {
    if (applicantDetails?.data) {
      const newStageSet = applicantDetails?.data?.data[0].stages.filter(
        (value) =>
          !applicantDetails?.data?.data[0].completed_stages.includes(value)
      );

      setInprogressStage([
        ...applicantDetails?.data?.data[0].completed_stages,
        newStageSet[0],
      ]);
    }
  }, [applicantDetails?.data]);

  useEffect(() => {
    let prevstageName;
    let nextstageName;
    if (!jobStagelist.isLoading) {
      const currentIndex = jobStagelist?.data?.indexOf(
        pathNameArray[4]?.replace('_', ' ')
      );
      const prevIndex = (currentIndex - 1) % jobStagelist?.data?.length;
      prevstageName = jobStagelist?.data[prevIndex];
      const nextIndex = (currentIndex + 1) % jobStagelist?.data?.length;
      nextstageName = jobStagelist?.data[nextIndex];
    }

    setPrevStage(getJobStageNameForBackendQuery(prevstageName));
    setNextStage(getJobStageNameForBackendQuery(nextstageName));
  }, [pathNameArray, jobStagelist.isLoading, jobStagelist.data]);

  useEffect(() => {
    if (!applicantDetails.isLoading) {
      applicantsDispatch({
        type: 'SETAPPLICANTSTATUS',
        payload: applicantDetails?.data.data[0]?.status,
      });
    }
  }, [applicantDetails.data, applicantDetails.isLoading, applicantsDispatch]);

  useEffect(() => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'all-applicants' &&
        query.queryKey[2] === 'CV_Screening',
    });
  }, [queryClient]);

  if (applicantDetails.isError) {
    return (
      <div className='relative max-w-main-page w-full mx-auto min-h-80vh xl:px-10 px-6 pb-8'>
        <SomethingWentWrongComponent />
      </div>
    );
  }

  const Whatsapp = (number) => {
    return (
      <a href={`https://wa.me/${number}`} target='_blank' rel='noreferrer'>
        <img src={whatapp_icon} alt='Whatapp' />
      </a>
    );
  };

  const handelCloseReportsModal = (value) => {
    applicantsDispatch({
      type: 'SET_REPORTS_MODAL_VISIBLE',
      payload: value,
    });
  };
  const handelClosePSYReportsModal = (value) => {
    applicantsDispatch({
      type: 'SET_PSY_REPORTS_MODAL_VISIBLE',
      payload: value,
    });
  };

  const assesmentInfo = UseApplicantsAssessmentInfo({
    applicantID: pathNameArray[6],
    jobId: pathNameArray[3],
    jobStage: 'Skill_Assessment',
  });

  const assesmentInfoPSY = UseApplicantsAssessmentInfo({
    applicantID: pathNameArray[6],
    jobId: pathNameArray[3],
    jobStage: 'Psychometric_Assessment',
  });

  const getApplicantData = useQuery(
    ['applicant-data-for-skill-assessment-report'],
    async () => {
      const res = await makeAPIRequest.get(
        `${
          authState.is_client_admin || authState.is_client_team
            ? apiEndPoints.CLIENT_SKILL_ASSESSMENT_REPORT
            : apiEndPoints.SKILL_ASSESSMENT_REPORT
        }?applicant_id=${pathNameArray[6]}${
          authState.is_client_admin || authState.is_client_team
            ? `&token=${authState.token_client}`
            : ''
        }`
      );
      return res.data;
    },
    {
      onSuccess: () => {
        // setViewReport(true);
      },
      onError: () => {
        // setViewReport(false);
      },
    }
  );

  // applicant info
  const getApplicantDataPSY = useQuery(
    ['applicant-data-for-psychometric-report'],
    async () => {
      const res = await makeAPIRequest.get(
        `${
          authState.is_client_admin || authState.is_client_team
            ? apiEndPoints.CLIENT_JOB_APPLICANTS
            : apiEndPoints.JOB_APPLICANTS
        }?_id=${pathNameArray[6]}${
          authState.is_client_admin || authState.is_client_team
            ? `&token=${authState.token_client}`
            : ''
        }`
      );
      return res.data;
    }
  );

  useEffect(() => {
    if (
      typeof assesmentInfoPSY?.data?.data?.assessment_results?.psy_report ===
      'object'
    ) {
      let localArr = [];
      Object.keys(
        assesmentInfoPSY?.data?.data?.assessment_results?.psy_report
      ).forEach((el) => {
        localArr.push({
          traits:
            el === 'Extroversion'
              ? 'Extraversion'
              : el === 'Neurotism'
              ? 'Neuroticism'
              : el,
          score: Math.ceil(
            assesmentInfoPSY?.data?.data?.assessment_results?.psy_report[el]
          ),
        });
      });

      setRadarChartData(localArr);
    }
  }, [assesmentInfoPSY?.data]);

  return (
    <>
      <div className={`max-w-main-page my-6 mb-8 sm:mx-2 lg:mx-auto `}>
        <div className='w-95% mx-auto mb-6 px-1'>
          <svg
            width='22'
            height='23'
            viewBox='0 0 22 23'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
            className='cursor-pointer'
            onClick={handelgobackToApplicantsOverviewpage}
          >
            <path
              d='M12.1518 21.5571C12.3198 21.3905 12.4531 21.1922 12.5441 20.9738C12.6351 20.7554 12.682 20.5212 12.682 20.2846C12.682 20.048 12.6351 19.8137 12.5441 19.5953C12.4531 19.3769 12.3198 19.1787 12.1518 19.0121L6.23739 13.1156L19.8406 13.1156C20.3159 13.1156 20.7718 12.9268 21.1079 12.5906C21.444 12.2545 21.6328 11.7987 21.6328 11.3233C21.6328 10.848 21.444 10.3921 21.1079 10.056C20.7718 9.71991 20.3159 9.53108 19.8406 9.53108L6.23739 9.53108L12.1518 3.63458C12.4893 3.29709 12.6789 2.83936 12.6789 2.36208C12.6789 1.8848 12.4893 1.42707 12.1518 1.08959C11.8143 0.752098 11.3566 0.5625 10.8793 0.5625C10.402 0.5625 9.94431 0.752098 9.60682 1.08959L0.645572 10.0508C0.482406 10.2213 0.354499 10.4223 0.269199 10.6423C0.174379 10.8568 0.125402 11.0888 0.125402 11.3233C0.125402 11.5579 0.174379 11.7899 0.269199 12.0044C0.354499 12.2244 0.482406 12.4254 0.645572 12.5958L9.60682 21.5571C9.77343 21.7251 9.97165 21.8584 10.1901 21.9494C10.4085 22.0404 10.6427 22.0872 10.8793 22.0872C11.1159 22.0872 11.3502 22.0404 11.5686 21.9494C11.787 21.8584 11.9852 21.7251 12.1518 21.5571Z'
              fill='black'
            />
          </svg>
        </div>
        <div className='w-95% rounded-lg mt-4 px-4 md:px-6 py-4 mx-auto bg-white border shadow-2xl mb-8 relative'>
          <div className=' flex justify-between items-center'>
            {applicantDetails.isLoading ? (
              <Skeleton width='10rem' height='1.5rem' className='mx-4' />
            ) : (
              <p
                className='text-xl cursor-pointer md:px-4'
                onClick={() =>
                  history.push(
                    `/jobs/overview/${applicantDetails?.data?.data[0]?.job_id}`
                  )
                }
              >
                {applicantDetails?.data?.data[0]?.job_name} (
                {applicantDetails?.data?.data[0]?.job_id_display})
              </p>
            )}

            <div className='flex items-end md:items-center'>
              {/* <button className='w-24 group hidden md:flex items-center space-x-3 opacity-60 cursor-not-allowed'>
                <p className=''>{ShareIcon}</p>
                <p className='text-gradient'>{t('share')}</p>
              </button> */}

              {applicantDetails.isLoading ? (
                <button className='w-28 cursor-not-allowed opacity-70 mx-2'>
                  <PrimaryButton>
                    <p className='text-sm'>{t('jobsAndApplicants:view_cv')}</p>
                  </PrimaryButton>
                </button>
              ) : (
                <button
                  className='w-28 text-center mx-2'
                  onClick={() => setViewResume(true)}
                >
                  <PrimaryButton>
                    {t('jobsAndApplicants:view_cv')}
                  </PrimaryButton>
                </button>
              )}

              {applicantDetails.isLoading ? (
                <button
                  className='hidden md:block w-28 mx-2 relative cursor-not-allowed'
                  disabled
                >
                  <PrimaryButton>
                    {' '}
                    <p className='text-sm'>{t('jobsAndApplicants:mail')}</p>
                  </PrimaryButton>
                  <p className='absolute bg-white h-5 w-5 rounded-full -top-1 -right-2 font-bold shadow-3xl text-xs flex justify-center items-center border'>
                    {emailCount}
                  </p>
                </button>
              ) : (
                <button
                  className='hidden md:block w-28 relative mx-2'
                  onClick={() => setShowEmailModal(true)}
                >
                  <PrimaryButton>
                    {' '}
                    <p className='text-sm'>{t('jobsAndApplicants:mail')}</p>
                  </PrimaryButton>
                  <p className='absolute bg-white h-5 w-5 rounded-full -top-1 -right-2 font-bold shadow-3xl text-xs flex justify-center items-center border'>
                    {emailCount}
                  </p>
                </button>
              )}
              <button
                className='hidden md:block w-8 invisible'
                disabled
              ></button>
            </div>
          </div>
          <Tip
            content={`${
              authState.is_client_admin || authState.is_client_team
                ? 'Notes'
                : 'Add notes'
            }`}
          >
            <div
              className='w-12 h-12 rounded-full bg-white hover:bg-gray-50 transition-colors shadow-2xl hidden md:flex justify-center items-center border cursor-pointer absolute -right-6 top-2'
              onClick={() => setShowNotesModal(true)}
            >
              <button>
                <div className='flex justify-center items-center'>
                  {NotesIcon}
                </div>
              </button>
            </div>
          </Tip>
          <div
            className='my-4 mt-6 px-3 md:px-4 py-3 rounded-md'
            style={{ background: '#F9F9F9' }}
          >
            <div className=' lg:flex lg:justify-between'>
              {applicantDetails.isLoading || jobStagelist.isLoading ? (
                <>
                  {/* skeleton for mobile */}
                  <div className='flex md:hidden justify-between w-full'>
                    <div className='flex items-center'>
                      <div
                        className={`flex items-center ${
                          currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                        }`}
                      >
                        <Skeleton
                          borderRadius='999px'
                          height='4rem'
                          width='4rem'
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                      </div>
                    </div>

                    <div className='flex items-center mr-0'>
                      <Skeleton
                        borderRadius='999px'
                        height='3rem'
                        width='3rem'
                        animation='none'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      />
                      <Skeleton
                        borderRadius='999px'
                        height='3rem'
                        width='3rem'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      />
                      <Skeleton
                        borderRadius='999px'
                        height='3rem'
                        animation='none'
                        width='3rem'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      />
                    </div>
                  </div>

                  {/* skeleton for tablet */}
                  <div className='hidden md:flex justify-between w-full'>
                    <div className='flex items-center'>
                      <div
                        className={`flex items-center ${
                          currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                        }`}
                      >
                        <Skeleton
                          borderRadius='999px'
                          height='4rem'
                          width='4rem'
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />

                        <div
                          className={`'flex flex-col ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <Skeleton
                            height='1.4rem'
                            width='15rem'
                            animation='none'
                            className={`${
                              currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                            }`}
                          />
                          <Skeleton
                            height='1rem'
                            width='8rem'
                            animation='none'
                            className={`${
                              currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex items-center ${
                        currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                      }`}
                    >
                      <Skeleton
                        borderRadius='999px'
                        height='3.5rem'
                        width='3.5rem'
                        animation='none'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                      />
                      <Skeleton
                        borderRadius='999px'
                        height='3.5rem'
                        width='3.5rem'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                      />
                      <Skeleton
                        borderRadius='999px'
                        height='3.5rem'
                        animation='none'
                        width='3.5rem'
                        className={`${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`flex lg:hidden items-center justify-end ${
                      currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                    }`}
                  >
                    <div
                      className={`w-14 h-14 hidden md:block ${
                        currentLanguageCode === 'ar'
                          ? 'ml-2 md:ml-4'
                          : 'mr-2 md:mr-4'
                      }`}
                    >
                      {applicantDetails.isFetching ? (
                        <Skeleton
                          borderRadius='999px'
                          height='3.5rem'
                          animation='none'
                          width='3.5rem'
                        />
                      ) : (
                        <CircularProgressbar
                          value={applicantDetails?.data?.data[0]?.skill_score}
                          isAnimated
                        />
                      )}
                    </div>
                    {applicantDetails?.data?.data[0]?.status === 'Accepted' ? (
                      <>
                        {(applicantDetails?.data?.data[0][pathNameArray[4]] &&
                          handelCanMoveToNextStage(
                            pathNameArray[4],
                            applicantDetails?.data?.data[0]
                          ) &&
                          canRecruiterMove(applicantDetails?.data?.data[0]) &&
                          !mandatoryFiledError &&
                          !isJobDeactivated &&
                          jobStagelist?.data[0] ===
                            getDisplaynameForJobStages(nextStage)) ||
                        (handelCanMoveToNextStage(
                          pathNameArray[4],
                          applicantDetails?.data?.data[0]
                        ) &&
                          canRecruiterMove(applicantDetails?.data?.data[0]) &&
                          !mandatoryFiledError &&
                          !isJobDeactivated &&
                          canClientMove(applicantDetails?.data?.data[0]) &&
                          applicantDetails?.data?.data[0]?.client_only_view) ? (
                          <>
                            {' '}
                            <Tip content='Hire applicant'>
                              <button
                                onClick={() => hireApplicantsMutation.mutate()}
                                className={`bg-white hover:bg-gray-50 border transition-colors rounded-full cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                  currentLanguageCode === 'ar'
                                    ? 'ml-2 md:ml-4'
                                    : 'mr-2 md:mr-4'
                                }`}
                              >
                                <p className='-mt-1'>{AcceptIcon}</p>
                              </button>
                            </Tip>
                          </>
                        ) : (
                          <>
                            {applicantDetails?.data?.data[0][
                              pathNameArray[4]
                            ] &&
                            jobStagelist?.data[0] !==
                              getDisplaynameForJobStages(nextStage) &&
                            handelCanMoveToNextStage(
                              pathNameArray[4],
                              applicantDetails?.data?.data[0]
                            ) &&
                            !authState.subscription_ended &&
                            !mandatoryFiledError &&
                            !isJobDeactivated ? (
                              <Tip
                                content={`Move to ${getModifiedStagename(
                                  nextStage,
                                  t
                                )}`}
                              >
                                <button
                                  onClick={handelOpenCandidateMoveToNextStage}
                                  className={`bg-white hover:bg-gray-50 border transition-colors rounded-full cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-2 md:ml-4'
                                      : 'mr-2 md:mr-4'
                                  }`}
                                >
                                  <p className='-mt-1'>{AcceptIcon}</p>
                                </button>
                              </Tip>
                            ) : (
                              <>
                                {!mandatoryFiledError ? (
                                  <button
                                    className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2 md:ml-4'
                                        : 'mr-2 md:mr-4'
                                    }`}
                                  >
                                    <p className='-mt-1'>{AcceptIcon}</p>
                                  </button>
                                ) : (
                                  <Tip content={mandatoryFiledError}>
                                    <button
                                      className={`bg-white border rounded-full cursor-default shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                                        currentLanguageCode === 'ar'
                                          ? 'ml-2 md:ml-4'
                                          : 'mr-2 md:mr-4'
                                      }`}
                                    >
                                      <p className='-mt-1'>{AcceptIcon}</p>
                                    </button>
                                  </Tip>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {checkIfRoleIsClient() ||
                        canRecruiterMove() ||
                        !isJobDeactivated ? (
                          <>
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                            applicantDetails?.data?.data[0][
                              `${pathNameArray[4]}_status`
                            ] !== 'Scheduled' &&
                            canClientMove(applicantDetails?.data?.data[0]) &&
                            !isJobDeactivated ? (
                              <Tip content='Reject candidate'>
                                <button
                                  className='bg-white rounded-full hover:bg-gray-50 border transition-colors cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center'
                                  onClick={handelOpenRejectCandidateModal}
                                >
                                  {RejectIcon}
                                </button>
                              </Tip>
                            ) : (
                              <button className='bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60'>
                                <p className='-mt-1'>{RejectIcon}</p>
                              </button>
                            )}
                          </>
                        ) : (
                          <button className='bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60'>
                            <p className='-mt-1'>{RejectIcon}</p>
                          </button>
                        )}

                        {checkIfRoleIsClient() ||
                        canRecruiterMove() ||
                        !isJobDeactivated ? (
                          <>
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                            canClientMove(applicantDetails?.data?.data[0]) &&
                            !isJobDeactivated ? (
                              <Tip content='Accepted another offer'>
                                <button
                                  onClick={() =>
                                    acceptAnotherOfferApplicantMutation.mutate()
                                  }
                                  className='bg-white rounded-full hover:bg-gray-50 border transition-colors  cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center'
                                >
                                  {AnotherOfferIcon}
                                </button>
                              </Tip>
                            ) : (
                              <button className='bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60'>
                                <p className='-mt-1'>{AnotherOfferIcon}</p>
                              </button>
                            )}
                          </>
                        ) : (
                          <button className='bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60'>
                            <p className='-mt-1'>{AnotherOfferIcon}</p>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className={` text-white px-2 leading-5 py-2 rounded-md flex items-center justify-center ${
                            applicantDetails?.data?.data[0]?.status === 'Hired'
                              ? 'bg-tag-taken w-28 bg-opacity-80'
                              : 'bg-tag-rejected bg-opacity-80 w-32 text-xs whitespace-nowrap'
                          }`}
                        >
                          {applicantDetails?.data?.data[0]?.status === 'Hired'
                            ? t('common:hired')
                            : applicantDetails?.data?.data[0]?.status ===
                              'Rejected'
                            ? t('common:rejected')
                            : t('common:accepted_another_offer')}
                        </div>

                        {checkIfRoleIsClient() ? null : (
                          <>
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                              !isJobDeactivated && (
                                <div>
                                  <Popover className='relative'>
                                    {({ open }) => (
                                      <>
                                        <Popover.Button
                                          ref={afterHirePopoverRef}
                                        >
                                          <div className='px-2'>
                                            <OptionsIcon className='mt-2' />
                                          </div>
                                        </Popover.Button>
                                        <Transition
                                          as={Fragment}
                                          enter='transition ease-out duration-200'
                                          enterFrom='opacity-0 translate-y-1'
                                          enterTo='opacity-100 translate-y-0'
                                          leave='transition ease-in duration-150'
                                          leaveFrom='opacity-100 translate-y-0'
                                          leaveTo='opacity-0 translate-y-1'
                                        >
                                          <Popover.Panel className='absolute z-10 w-52 p-2 transform -translate-x-1/2 translate-y-1 -left-20 -ml-3 sm:px-0'>
                                            <div className='overflow-hidden rounded-md shadow-lg'>
                                              <div className='relative p-2 bg-white border-t text-sm flex flex-col justify-center cursor-pointer py-3'>
                                                {applicantDetails?.data?.data[0]
                                                  ?.status === 'Hired' ? (
                                                  <>
                                                    <div
                                                      className=' flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={
                                                        handelOpenRejectCandidateModal
                                                      }
                                                    >
                                                      {RejectIcon}{' '}
                                                      <p
                                                        className={`-mt-2 ${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'pr-2'
                                                            : 'pl-2'
                                                        }`}
                                                      >
                                                        {t('common:reject')}
                                                      </p>
                                                    </div>
                                                    <div
                                                      className=' flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={() =>
                                                        acceptAnotherOfferApplicantMutation.isLoading
                                                          ? null
                                                          : acceptAnotherOfferApplicantMutation.mutate()
                                                      }
                                                    >
                                                      {AnotherOfferIcon}
                                                      <p
                                                        className={`${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'pr-2'
                                                            : 'pl-2'
                                                        }`}
                                                      >
                                                        {t(
                                                          'common:accepted_another_offer'
                                                        )}
                                                      </p>
                                                      {acceptAnotherOfferApplicantMutation.isLoading ? (
                                                        <Loader
                                                          width={18}
                                                          height={18}
                                                          color='#6600C9'
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      className='flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={
                                                        restoreprevStateMutation.isLoading
                                                          ? null
                                                          : handelRestorePrevState
                                                      }
                                                    >
                                                      <RestoreIlls />
                                                      <p
                                                        className={`-mt-2 ${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'ml-1 pr-2'
                                                            : 'mr-1 pl-2'
                                                        }`}
                                                      >
                                                        {t(
                                                          'jobsAndApplicants:restore_to_last_stage'
                                                        )}
                                                      </p>
                                                      {restoreprevStateMutation.isLoading ? (
                                                        <Loader
                                                          width={18}
                                                          height={18}
                                                          color='#6600C9'
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </Popover.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Popover>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className='md:flex items-center -mt-12 md:-mt-0'>
                    <Avatar
                      className='h-14 w-14 border-1 border-primary-rose md:mx-3'
                      imgSrc={applicantDetails?.data?.data[0]?.upload_image}
                      userInitials={getUserInitialsFromname(
                        applicantDetails?.data?.data[0]?.name
                      )}
                    />

                    <div>
                      <div className='flex items-start pt-2 md:pt-0'>
                        <p
                          className={`text-xl mb-1 text-black ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          {applicantDetails?.data?.data[0]?.name}
                        </p>
                        {authState.is_recruiter ||
                        authState.is_hiring_manager ? (
                          <button
                            onClick={() =>
                              setShowApplicantsEditModal(
                                !showApplicantsEditModal
                              )
                            }
                            className={` ${
                              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                            }`}
                          >
                            {ApplicantEditIcon}
                          </button>
                        ) : null}
                        {applicantDetails?.data?.data[0]?.mobile_number ? (
                          <Tip content='whatsapp'>
                            <span
                              className={`w-6 h-6 ${
                                currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                              }`}
                            >
                              {Whatsapp(
                                applicantDetails?.data?.data[0]?.mobile_number
                              )}
                            </span>
                          </Tip>
                        ) : null}
                      </div>
                      <div className='md:flex items-center'>
                        <div className='flex items-center md:mx-2'>
                          {PhoneIcon}
                          {applicantDetails?.data?.data[0]?.mobile_number ? (
                            <p className='text-gray-500 text-base px-1'>
                              {
                                applicantDetails?.data?.data[0]?.country?.split(
                                  '/'
                                )[1]
                              }{' '}
                              {applicantDetails?.data?.data[0]?.mobile_number}
                            </p>
                          ) : (
                            <p className='text-gray-500 text-base'>N/A</p>
                          )}
                        </div>
                        <div className='flex items-center mt-2 md:mt-0 md:mx-2'>
                          {EmailIcon}
                          {applicantDetails?.data?.data[0]?.email ? (
                            <p className='text-gray-500 text-base px-1'>
                              {applicantDetails?.data?.data[0]?.email}
                            </p>
                          ) : (
                            <p className='text-gray-500 text-base'>N/A</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`hidden lg:flex items-center ${
                      currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                    }`}
                  >
                    <div
                      className={`w-14 h-14 ${
                        currentLanguageCode === 'ar' ? 'ml-4 mr-4' : 'mr-4 ml-4'
                      }`}
                    >
                      {applicantDetails.isFetching ? (
                        <Skeleton
                          borderRadius='999px'
                          height='3.5rem'
                          animation='none'
                          width='3.5rem'
                        />
                      ) : (
                        <CircularProgressbar
                          value={applicantDetails?.data?.data[0]?.skill_score}
                          isAnimated
                        />
                      )}
                    </div>

                    {applicantDetails?.data?.data[0]?.status === 'Accepted' ? (
                      <>
                        {(applicantDetails?.data?.data[0][pathNameArray[4]] &&
                          handelCanMoveToNextStage(
                            pathNameArray[4],
                            applicantDetails?.data?.data[0]
                          ) &&
                          canRecruiterMove(applicantDetails?.data?.data[0]) &&
                          !mandatoryFiledError &&
                          !isJobDeactivated &&
                          jobStagelist?.data[0] ===
                            getDisplaynameForJobStages(nextStage)) ||
                        (handelCanMoveToNextStage(
                          pathNameArray[4],
                          applicantDetails?.data?.data[0]
                        ) &&
                          canRecruiterMove(applicantDetails?.data?.data[0]) &&
                          !mandatoryFiledError &&
                          !isJobDeactivated &&
                          canClientMove(applicantDetails?.data?.data[0]) &&
                          applicantDetails?.data?.data[0]?.client_only_view) ? (
                          <>
                            {' '}
                            <Tip content='Hire applicant'>
                              <button
                                onClick={() => hireApplicantsMutation.mutate()}
                                className={`bg-white hover:bg-gray-50 border transition-colors rounded-full cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              >
                                <p className='-mt-1'>{AcceptIcon}</p>
                              </button>
                            </Tip>
                          </>
                        ) : (
                          <>
                            {applicantDetails?.data?.data[0][
                              pathNameArray[4]
                            ] &&
                            jobStagelist?.data[0] !==
                              getDisplaynameForJobStages(nextStage) &&
                            handelCanMoveToNextStage(
                              pathNameArray[4],
                              applicantDetails?.data?.data[0]
                            ) &&
                            !mandatoryFiledError &&
                            !isJobDeactivated ? (
                              <Tip
                                content={`Move to ${getModifiedStagename(
                                  nextStage,
                                  t
                                )}`}
                              >
                                <button
                                  onClick={handelOpenCandidateMoveToNextStage}
                                  className={`bg-white hover:bg-gray-50 border transition-colors rounded-full cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-4'
                                      : 'mr-4'
                                  }`}
                                >
                                  <p className='-mt-1'>{AcceptIcon}</p>
                                </button>
                              </Tip>
                            ) : (
                              <>
                                {!mandatoryFiledError ? (
                                  <button
                                    className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-4'
                                        : 'mr-4'
                                    }`}
                                  >
                                    <p className='-mt-1'>{AcceptIcon}</p>
                                  </button>
                                ) : (
                                  <Tip content={mandatoryFiledError}>
                                    <button
                                      className={`bg-white border rounded-full cursor-default shadow-xl w-12 h-12 flex justify-center items-center opacity-80 ${
                                        currentLanguageCode === 'ar'
                                          ? 'ml-4'
                                          : 'mr-4'
                                      }`}
                                    >
                                      <p className='-mt-1'>{AcceptIcon}</p>
                                    </button>
                                  </Tip>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {checkIfRoleIsClient() ||
                        canRecruiterMove() ||
                        !isJobDeactivated ? (
                          <>
                            {' '}
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                            applicantDetails?.data?.data[0][
                              `${pathNameArray[4]}_status`
                            ] !== 'Scheduled' &&
                            canClientMove(applicantDetails?.data?.data[0]) &&
                            !isJobDeactivated ? (
                              <Tip content='Reject candidate'>
                                <button
                                  className={`bg-white rounded-full hover:bg-gray-50 border transition-colors cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-4'
                                      : 'mr-4'
                                  }`}
                                  onClick={handelOpenRejectCandidateModal}
                                >
                                  {RejectIcon}
                                </button>
                              </Tip>
                            ) : (
                              <button
                                className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              >
                                <p className='-mt-1'>{RejectIcon}</p>
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                            }`}
                          >
                            <p className='-mt-1'>{RejectIcon}</p>
                          </button>
                        )}

                        {checkIfRoleIsClient() ||
                        canRecruiterMove() ||
                        !isJobDeactivated ? (
                          <>
                            {' '}
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                            canClientMove(applicantDetails?.data?.data[0]) &&
                            !isJobDeactivated ? (
                              <Tip content='Accepted another offer'>
                                <button
                                  onClick={() =>
                                    acceptAnotherOfferApplicantMutation.mutate()
                                  }
                                  className={`bg-white rounded-full hover:bg-gray-50 border transition-colors  cursor-pointer shadow-xl w-12 h-12 flex justify-center items-center ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-4'
                                      : 'mr-4'
                                  }`}
                                >
                                  {AnotherOfferIcon}
                                </button>
                              </Tip>
                            ) : (
                              <button
                                className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              >
                                <p className='-mt-1'>{AnotherOfferIcon}</p>
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            className={`bg-white border rounded-full cursor-not-allowed shadow-xl w-12 h-12 flex justify-center items-center opacity-60 ${
                              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                            }`}
                          >
                            <p className='-mt-1'>{AnotherOfferIcon}</p>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className={` text-white px-2 leading-5 py-2 rounded-md flex items-center justify-center ${
                            applicantDetails?.data?.data[0]?.status === 'Hired'
                              ? 'bg-tag-taken bg-opacity-80 w-28'
                              : 'bg-tag-rejected bg-opacity-80 w-32 text-xs whitespace-nowrap'
                          } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                        >
                          {applicantDetails?.data?.data[0]?.status === 'Hired'
                            ? t('common:hired')
                            : applicantDetails?.data?.data[0]?.status ===
                              'Rejected'
                            ? t('common:rejected')
                            : t('common:accepted_another_offer')}
                        </div>

                        {checkIfRoleIsClient() ? null : (
                          <>
                            {checkTeamMemberPermission(
                              applicantDetails?.data?.data[0]
                            ) &&
                              !isJobDeactivated && (
                                <div>
                                  <Popover className='relative'>
                                    {({ open }) => (
                                      <>
                                        <Popover.Button
                                          ref={afterHirePopoverRef}
                                        >
                                          <div className='px-2'>
                                            <OptionsIcon className='mt-2' />
                                          </div>
                                        </Popover.Button>
                                        <Transition
                                          as={Fragment}
                                          enter='transition ease-out duration-200'
                                          enterFrom='opacity-0 translate-y-1'
                                          enterTo='opacity-100 translate-y-0'
                                          leave='transition ease-in duration-150'
                                          leaveFrom='opacity-100 translate-y-0'
                                          leaveTo='opacity-0 translate-y-1'
                                        >
                                          <Popover.Panel className='absolute z-10 w-52 p-2 transform -translate-x-1/2 translate-y-1 -left-20 -ml-3 sm:px-0'>
                                            <div className='overflow-hidden rounded-md shadow-lg'>
                                              <div className='relative p-2 bg-white border-t text-sm flex flex-col justify-center cursor-pointer py-3'>
                                                {applicantDetails?.data?.data[0]
                                                  ?.status === 'Hired' ? (
                                                  <>
                                                    <div
                                                      className=' flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={
                                                        handelOpenRejectCandidateModal
                                                      }
                                                    >
                                                      {RejectIcon}{' '}
                                                      <p
                                                        className={`-mt-2 ${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'pr-2'
                                                            : 'pl-2'
                                                        }`}
                                                      >
                                                        {t('common:reject')}
                                                      </p>
                                                    </div>
                                                    <div
                                                      className=' flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={() =>
                                                        acceptAnotherOfferApplicantMutation.isLoading
                                                          ? null
                                                          : acceptAnotherOfferApplicantMutation.mutate()
                                                      }
                                                    >
                                                      {AnotherOfferIcon}
                                                      <p
                                                        className={`${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'pr-2'
                                                            : 'pl-2'
                                                        }`}
                                                      >
                                                        {t(
                                                          'common:accepted_another_offer'
                                                        )}
                                                      </p>
                                                      {acceptAnotherOfferApplicantMutation.isLoading ? (
                                                        <Loader
                                                          width={18}
                                                          height={18}
                                                          color='#6600C9'
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      className='flex p-1.5 whitespace-nowrap justify-start items-center transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 py-3'
                                                      onClick={
                                                        restoreprevStateMutation.isLoading
                                                          ? null
                                                          : handelRestorePrevState
                                                      }
                                                    >
                                                      <RestoreIlls />
                                                      <p
                                                        className={`-mt-2 ${
                                                          currentLanguageCode ===
                                                          'ar'
                                                            ? 'ml-1 pr-2'
                                                            : 'mr-1 pl-2'
                                                        }`}
                                                      >
                                                        {t(
                                                          'jobsAndApplicants:restore_to_last_stage'
                                                        )}
                                                      </p>
                                                      {restoreprevStateMutation.isLoading ? (
                                                        <Loader
                                                          width={18}
                                                          height={18}
                                                          color='#6600C9'
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </Popover.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Popover>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            {applicantDetails.isLoading ? (
              <ul className='md:flex text-base text-gray-500 space-y-4 md:space-y-0 items-center my-2 mt-6'>
                <Skeleton
                  width='8rem'
                  height='1.3rem'
                  animation='none'
                  className={`${
                    currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                  }`}
                />
                <Skeleton
                  width='7rem'
                  height='1.3rem'
                  className={`${
                    currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                  }`}
                />
                <Skeleton
                  width='9rem'
                  height='1.3rem'
                  animation='none'
                  className={`${
                    currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                  }`}
                />
                <Skeleton
                  width='10rem'
                  height='1.3rem'
                  animation='none'
                  className={`${
                    currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                  }`}
                />
                <Skeleton
                  width='8rem'
                  height='1.3rem'
                  animation='none'
                  className={`${
                    currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
                  }`}
                />
              </ul>
            ) : (
              <>
                <ul className='flex text-base text-gray-500  items-center my-2 mt-2 flex-wrap'>
                  <li
                    className={`mt-1 md:mt-4 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                  >
                    <div className='flex items-center'>
                      {LocationIcon}
                      {applicantDetails?.data?.data[0]
                        ?.your_current_location ? (
                        <p className='mx-1'>
                          {
                            applicantDetails?.data?.data[0]
                              ?.your_current_location
                          }
                        </p>
                      ) : (
                        <p className='mx-1'>N/A</p>
                      )}
                    </div>
                  </li>
                  <li
                    className={`mt-1 md:mt-4 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                  >
                    <div className='flex items-center'>
                      {TimePostedIcon}
                      {applicantDetails?.data?.data[0]?.applied_date ? (
                        <p className='mx-1'>
                          {dateFormatter(
                            applicantDetails?.data?.data[0]?.applied_date,
                            'll'
                          )}
                        </p>
                      ) : (
                        <p className='mx-1'>N/A</p>
                      )}
                    </div>
                  </li>
                  <li
                    className={`mt-4 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                  >
                    <div className='flex items-center'>
                      {ExperienceIcon}
                      {applicantDetails?.data?.data[0]
                        ?.your_current_position ? (
                        <p className='mx-1'>
                          {
                            applicantDetails?.data?.data[0]
                              ?.your_current_position
                          }
                        </p>
                      ) : (
                        <p className='mx-1'>N/A</p>
                      )}
                    </div>
                  </li>
                  <li
                    className={`mt-4 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                  >
                    <div className='flex items-center'>
                      <div
                        className={`'flex ${
                          currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                        }`}
                      >
                        <p className='px-1'>
                          {t('jobsAndApplicants:relevant_experience')}
                        </p>{' '}
                        :{' '}
                        <p className='px-1' dir='ltr'>
                          {applicantDetails?.data?.data[0]
                            ?.relevant_experience === null ||
                          applicantDetails?.data?.data[0]
                            ?.relevant_experience === 'null' ||
                          applicantDetails?.data?.data[0]
                            ?.relevant_experience === undefined ||
                          !applicantDetails?.data?.data[0]
                            ?.relevant_experience ? (
                            <span>N/A</span>
                          ) : (
                            <span
                              className={`text-primary-rose ${
                                currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                              }`}
                            >
                              {
                                applicantDetails?.data?.data[0]
                                  ?.relevant_experience
                              }{' '}
                              years
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`mt-4 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                  >
                    <div className='flex items-center'>
                      <div
                        className={`flex ${
                          currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                        }`}
                      >
                        <p className='px-1'>
                          {t('jobsAndApplicants:notice_period')} :{' '}
                        </p>
                        <p className='px-1' dir='ltr'>
                          {applicantDetails?.data?.data[0]
                            ?.notice_period_days === null ||
                          applicantDetails?.data?.data[0]
                            ?.notice_period_days === 'null' ||
                          applicantDetails?.data?.data[0]
                            ?.notice_period_days === undefined ||
                          !applicantDetails?.data?.data[0]
                            ?.notice_period_days ? (
                            <span>N/A</span>
                          ) : (
                            <span
                              className={`text-primary-rose ${
                                currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                              }`}
                            >
                              {
                                applicantDetails?.data?.data[0]
                                  ?.notice_period_days
                              }{' '}
                              days
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  {applicantDetails?.data &&
                  applicantDetails?.data?.data[0]?.dynamic_fields?.length ? (
                    <li
                      className={`mt-4 ${
                        currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                      }`}
                    >
                      <div className='flex items-center'>
                        {!showViewMore ? (
                          <small
                            className={`text-primary-rose cursor-pointer select-none ${
                              currentLanguageCode === 'ar'
                                ? 'ml-2'
                                : 'ml-1 mr-1'
                            }`}
                            onClick={() => setShowViewMore(true)}
                          >
                            {t('jobsAndApplicants:view_more')}
                          </small>
                        ) : (
                          <small
                            className={`text-primary-rose cursor-pointer select-none ${
                              currentLanguageCode === 'ar'
                                ? 'ml-2'
                                : 'ml-1 mr-1'
                            }`}
                            onClick={() => setShowViewMore(false)}
                          >
                            {t('jobsAndApplicants:view_less')}
                          </small>
                        )}
                      </div>
                    </li>
                  ) : null}
                </ul>
                <Transition
                  show={showViewMore}
                  enter='transition duration-100 ease-linear'
                  enterFrom='transform scale-95 opacity-0'
                  enterTo='transform scale-100 opacity-100'
                  leave='transition duration-75 ease-linear'
                  leaveFrom='transform scale-100 opacity-100'
                  leaveTo='transform scale-95 opacity-0'
                >
                  <ul className='flex text-base text-gray-500  items-center my-2 mt-2 flex-wrap'>
                    {applicantDetails?.data &&
                      applicantDetails?.data?.data[0]?.dynamic_fields?.map(
                        (item) => {
                          return (
                            <li
                              className={`mt-4 ${
                                currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                              }`}
                              key={item._id}
                            >
                              <div className='flex items-center'>
                                <p
                                  className={`${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-1'
                                      : 'mr-1'
                                  }`}
                                >
                                  {item.name} :{' '}
                                  <span
                                    className={`text-primary-rose ${
                                      currentLanguageCode === 'ar'
                                        ? 'mr-1'
                                        : 'ml-1'
                                    }`}
                                  >
                                    {applicantDetails?.data?.data[0][
                                      item.be_name
                                    ] === 'null' ||
                                    applicantDetails?.data?.data[0][
                                      item.be_name
                                    ] === undefined ||
                                    applicantDetails?.data?.data[0][
                                      item.be_name
                                    ] === '' ? (
                                      'N/A'
                                    ) : (
                                      <span>
                                        {item.field_type === 'file' ? (
                                          <a
                                            href={
                                              applicantDetails?.data?.data[0][
                                                `${item.be_name}`
                                              ]
                                            }
                                            target='_blank'
                                            rel='noreferrer'
                                          >
                                            {t('jobsAndApplicants:link')}
                                          </a>
                                        ) : item.field_type === 'date' ? (
                                          <span>
                                            {dateFormatter(
                                              applicantDetails?.data?.data[0][
                                                `${item.be_name}`
                                              ],
                                              'll'
                                            )}
                                          </span>
                                        ) : (
                                          applicantDetails?.data?.data[0][
                                            `${item.be_name}`
                                          ]
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </Transition>
              </>
            )}
          </div>

          <div className='my-4 mt-10'>
            <div className='overflow-x-scroll md:overflow-x-hidden pb-4 md:pb-0'>
              <ul className='flex text-center'>
                {jobStagelist.isLoading || applicantDetails.isLoading ? (
                  <li
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                    }`}
                  >
                    <Skeleton width='30rem' height='2.5rem' />
                  </li>
                ) : (
                  <>
                    {jobStagelist?.data?.map((item, index) => {
                      return renderJobStagesList(item, index);
                    })}
                  </>
                )}
              </ul>
            </div>

            <div className='py-2 mt-4 min-h-40vh'>{children}</div>
          </div>
        </div>
      </div>

      <SkillAssessmentReport
        showSkillAssessmentReport={applicantsState.is_reports_modal_visible}
        setShowSkillAssessmentReport={handelCloseReportsModal}
        getApplicantData={getApplicantData}
        assesmentInfo={assesmentInfo}
        totalPercentage={
          assesmentInfo?.data?.data?.assessment_results?.percentage
        }
        skillReport={skillReport}
        setSkillReport={setSkillReport}
        isSendToClient={true}
      />

      <PsychometricReport
        showPsychometricReport={applicantsState.is_psy_report_modal_visible}
        setShowPsychometricReport={handelClosePSYReportsModal}
        radarChartData={radarChartData}
        getApplicantData={getApplicantDataPSY?.data?.data[0]}
        psyReport={psyReport}
        setPSYReport={setPSYReport}
        isSendToClient={true}
      />

      <ConfirmMoveApplicantModal
        currentStage={pathNameArray[4]}
        nextStage={nextStage}
        applicantID={pathNameArray[6]}
        jobId={pathNameArray[3]}
      />
      <ConfirmMoveApplicantToClientModal
        currentStage={pathNameArray[4]}
        nextStage={nextStage}
        applicantsList={[{ _id: pathNameArray[6] }]}
        jobId={pathNameArray[3]}
        skillReport={skillReport}
        psyReport={psyReport}
        assesmentInfo={assesmentInfo}
        assesmentInfoPSY={assesmentInfoPSY}
        disableThird={
          (assesmentInfo?.data?.data?.assessment_results &&
            !assesmentInfo?.data?.data?.all_rated) ||
          (typeof assesmentInfoPSY?.data?.data?.assessment_results
            ?.psy_report !== 'object' &&
            !assesmentInfo?.data?.data?.assessment_results)
        }
        setClientViewMode={setClientViewMode}
      />
      <ApplicantMovedToNextStageModal
        headerText={
          applicantsState.is_re_schedule_assessment_modal_visible
            ? pathNameArray[4]
            : nextStage
        }
        nextStage={nextStage}
        clientViewMode={clientViewMode}
      />
      <ScheduleAssessmentModal
        prevStage={prevStage}
        nextStage={nextStage}
        currentStage={pathNameArray[4]}
        selectedApplicantsList={[{ _id: pathNameArray[6] }]}
        jobId={pathNameArray[3]}
        fromApplicantsDetailsPage
      />
      <ScheduleInterviewModal
        prevStage={prevStage}
        nextStage={nextStage}
        currentStage={pathNameArray[4]}
        selectedApplicantsList={[{ _id: pathNameArray[6] }]}
        jobId={pathNameArray[3]}
        fromApplicantsDetailsPage
      />
      <RescheduledInterviewModal
        prevStage={prevStage}
        nextStage={nextStage}
        currentStage={pathNameArray[4]}
      />
      <NotesModal
        showNotesModal={showNotesModal}
        setShowNotesModal={setShowNotesModal}
        applicantID={pathNameArray[6]}
        assignedTeamMembers={
          !applicantDetails.isLoading && applicantDetails?.data?.assigned_users
        }
        isJobDeactivated={isJobDeactivated}
      />
      <ConfirmRejectApplicantModal
        currentStage={pathNameArray[4].replace('_', ' ')}
        applicantsList={[{ _id: pathNameArray[6] }]}
      />
      <ApplicantEmailModal
        setShowEmailModal={setShowEmailModal}
        showEmailModal={showEmailModal}
        applicantEmail={
          !applicantDetails.isLoading && applicantDetails?.data?.data[0]?.email
        }
        enableQuery={!applicantDetails.isLoading}
        setEmailCount={setEmailCount}
      />
      <InterviewSuccessfullModal />
      <ViewResumeModal
        url={applicantDetails?.data && applicantDetails?.data?.data[0]?.resume}
        setViewResume={setViewResume}
        viewResume={viewResume}
      />
      <EditApplicantsDetailsModal
        showApplicantsEditModal={showApplicantsEditModal}
        setShowApplicantsEditModal={setShowApplicantsEditModal}
        applicantsData={
          !applicantDetails.isLoading && applicantDetails?.data?.data[0]
        }
      />
      <TestAssessmentCredentialModal
        applicantsData={
          !applicantDetails.isLoading && applicantDetails?.data?.data[0]
        }
      />
    </>
  );
};

export default InterviewStagesContainer;
