import { Loader, Select, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as UploadCv } from 'assets/images/resumebank/UploadCv.svg';
import { ReactComponent as ResumeIlls } from 'assets/images/resumebank/resumeFormatIlls.svg';
import { ReactComponent as Checkbox } from 'assets/images/resumebank/acceptCheckboxIlls.svg';
import PrimaryButton from 'components/Button/primaryButton';

import useUpdateDetails from 'hooks/useUploadApplicantDetails';

import SuccessModal from 'pages/common/Resumebank/UploadResume/components/successModal';
import { useLocation } from 'react-router-dom';

export default function UploadResume() {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({ mode: 'onChange' });
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState(false);

  const addResume = useUpdateDetails(setSuccess);

  let searchParams = useLocation();
  const localUrl = searchParams?.search.split('=')[1];
  const logoUrl = searchParams?.search.split('=')[2];
  // const comapanyName = searchParams?.search.split('=')[3].split('&')[0];
  const companyUuid = searchParams?.search.split('=')[4];

  /*  const comName = comapanyName.includes('%20');
  const splitWithPipe = comapanyName.includes('|');
  const splitwith7C = comapanyName.includes('%7C'); */

  /* console.log(
    searchParams?.search?.split('company_name=')[1]?.split('&company_uuid')[0]
  ); */

  const decoded = decodeURI(searchParams?.search);
  const char = decoded?.split('company_name=')[1]?.split('&company_uuid')[0];

  const onSaveData = (data) => {
    delete data?.checkbox;
    data['root_folder_id'] = localUrl.split('&')[0];
    data['company_uuid'] = companyUuid;
    const newData = new FormData();
    newData.append('root_folder_id', data.root_folder_id);
    newData.append('company_uuid', data.company_uuid);
    newData.append('full_name', data.full_name);
    newData.append('phone', data.phone);
    newData.append('email', data.email);
    newData.append('years_of_experience', data.years_of_experience);
    newData.append('field_of_experience', data.field_of_experience);
    newData.append('degree', data.degree);
    newData.append('specialisation', data.specialisation);
    newData.append('resume', file);

    addResume?.mutate(newData);
    // console.log('formData', data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className='text-red-500 text-sm ml-1'>{errors[name].message}</p>
      )
    );
  };

  useEffect(() => {
    if (addResume?.isSuccess) {
      reset({
        full_name: '',
        phone: '',
        email: '',
        years_of_experience: '',
        field_of_experience: '',
        degree: '',
        specialisation: '',
        resume: '',
        checkbox: false,
      });
      setValue('checkbox', false);
    }

    //eslint-disable-next-line
  }, [addResume?.isSuccess]);

  return (
    <div className='w-screen h-screen'>
      <div className='bg-white w-full  pt-1 p-3 shadow-2xl flex fixed top-0 z-50'>
        <img
          src={logoUrl.split('&')[0]}
          alt='logo'
          className='w-8 h-8 rounded-full mr-2'
        />
        <p className='text-primary-rose text-md mobile:text-xs mt-1'>
          {/* {splitwith7C && comapanyName?.split('%7C')[0]
            ? comapanyName?.split('%7C')[0].split('%20').join(' ')
            : splitWithPipe && comapanyName?.split('|')[0]
            ? comapanyName?.split('|')[0].split('%20').join(' ')
            : comName
            ? comapanyName.split('%20').join(' ')
            : comapanyName} */}
          {char}
        </p>
      </div>
      <form onSubmit={handleSubmit(onSaveData)}>
        <div className='bg-white max-w-full	 shadow-2xl mt-24	 mobile:m-5 m-8 mobile:mt-20 rounded-lg'>
          <h2 className='text-primary-rose text-lg mobile:text-sm mb-2 text-center mt-8 '>
            Add Details
          </h2>
          <div className='flex flex-wrap relative p-5 ml-10 mobile:ml-0'>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='full_name'
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id='name'
                    placeholder='Full Name *'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ input: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('full_name')}
            </div>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='phone'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    {...register('phone', {
                      pattern: {
                        value: /^[0-9]+[0-9]*$/,
                        message: 'Only numbers are allowed',
                      },
                      minLength: {
                        value: 10,
                        message: 'Mobile number must contain 10 characters',
                      },
                      maxLength: {
                        value: 10,
                        message:
                          'Mobile number must not contain more than 10 characters',
                      },
                    })}
                    id='Phone Number '
                    placeholder='Phone Number *'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('phone')}
            </div>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='email'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id='email'
                    placeholder='Email *'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='years_of_experience'
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id='yearsOfExperience'
                    placeholder='Years Of Experience'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('years_of_experience')}
            </div>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='field_of_experience'
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id='fieldOfExperience'
                    placeholder='Field Of Experience'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('field_of_experience')}
            </div>
            <div className='mb-5 mobile:w-full'>
              <Controller
                control={control}
                name='degree'
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id='Degree'
                    placeholder='Degree'
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={[
                      'High School',
                      'Associates',
                      'Bachelor',
                      'High School Diploma',
                      'Masters',
                      'PhD',
                      'Others',
                    ]}
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('degree')}
            </div>
            <div className='mb-5 mobile:w-full w-60'>
              <Controller
                control={control}
                name='specialisation'
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id='Specialization'
                    placeholder='Specialization *'
                    withAsterisk
                    className=' mr-10 mobile:mr-0'
                    // classNames={{ root: 'mobile:w-0 w-60' }}
                  />
                )}
              />
              {getFormErrorMessage('specialisation')}
            </div>
          </div>
        </div>
        <div className='bg-white max-w-full mobile:m-5 m-8 shadow-2xl rounded-lg'>
          <div className='grid justify-center'>
            {' '}
            <h3 className='text-primary-rose text-lg mobile:text-sm text-center mt-5'>
              Recommended <br /> CV Format
            </h3>
            <ResumeIlls className='mobile:w-16 mobile:h-16 w-40 h-40' />
          </div>
          <div className='mb-8 p-3'>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Use a chronological CV format
              </p>
            </div>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Remove graphics or tables
              </p>
            </div>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Correctly label your sections
              </p>
            </div>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Include job-relevant keywords
              </p>
            </div>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Type your CV in a common font
              </p>
            </div>
            <div className='flex mobile:ml-2 ml-10 mb-1'>
              <Checkbox className='mobile:w-3 mobile:h-3 mobile:mr-2 h-7 w-7 mr-3' />
              <p className='mobile:text-xs text-[16px]'>
                Save your CV in a proper file type (.docx or pdf)
              </p>
            </div>
          </div>
          <div>
            <div className='grid justify-items-center p-3'>
              <p className='mobile:text-xs text-center'>
                (My CV is in the recommended file format (.docx/pdf) and does
                not contain any graphics)
              </p>
            </div>
            <div className='flex justify-center mb-8 mt-5 border-b-2'>
              <Controller
                control={control}
                name='checkbox'
                render={({ field }) => (
                  <input {...field} type='checkbox' className='mr-2' />
                )}
              />

              <p className='text-purple'>I accept it</p>
            </div>

            <div className='grid justify-items-center'>
              <h3 className='text-primary-rose'>Upload your CV</h3>
              <UploadCv className='mt-3 mb-3' />
              <p className='text-sm mobile:text-xs'>
                {file ? file?.name : 'No File Choosen *'}
              </p>
              <Controller
                control={control}
                name='resume'
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <div {...field}>
                    <label
                      className={`flex justify-center items-center file-upload text-white pl-3 pr-3 ${
                        watch('checkbox') && !addResume?.isLoading
                          ? 'cursor-pointer w-24 text-center'
                          : 'opacity-30 cursor-not-allowed'
                      }`}
                    >
                      <input
                        disabled={
                          watch('checkbox') === true && !addResume?.isLoading
                            ? false
                            : true
                        }
                        type='file'
                        accept='.pdf,.docx'
                        onChange={(e) =>
                          setFile(
                            !e.target.files || e.target.files.length === 0
                              ? null
                              : e?.target?.files[0]
                          )
                        }
                      />
                      {/*  {addResume?.isLoading ? (
                        <Loader size='sm' />
                      ) : (  */}
                      Upload file
                      {/*  )}*/}
                    </label>
                  </div>
                )}
              />
              {getFormErrorMessage('resume')}
              <p className='mobile:text-xs text-sm mb-10 text-center mt-1'>
                * Only files from internal storage can be uploaded
              </p>
            </div>
          </div>
        </div>
        <div className='grid justify-end mb-60'>
          <button
            className='w-28 cursor-pointer mobile:mr-5 mr-8 mb-36'
            disabled={addResume?.isLoading}
          >
            <PrimaryButton isLoading={addResume?.isLoading}>
              Apply
            </PrimaryButton>
          </button>
        </div>
      </form>
      <div className='bg-white flex justify-between shadow-inner w-full fixed bottom-0'>
        <Logo className='w-16 h-16 ml-5' />
        <p className='mobile:text-xs text-lg p-5'>Powered by Smart Hire</p>
      </div>
      <div>
        {success && (
          <SuccessModal
            success={success}
            setSuccess={setSuccess}
            addResume={addResume}
          />
        )}
      </div>
    </div>
  );
}
