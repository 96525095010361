import { useQuery } from 'react-query';
import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';
import queryKeys from '../reactQuery/constant';

export default function UseTeammembersForMentions(enable = true) {
  return useQuery(
    [queryKeys.teamMemberFormention, enable],
    async () => {
      let url = `${apiEndPoints.TEAM_MEMBER_FOR_MENTIONS}?name=`;

      const { data } = await makeAPIRequest.get(url);
      return data.data;
    },
    { enabled: enable }
  );
}
