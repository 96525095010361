import React from 'react';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as Date } from 'assets/images/dashboard/date.svg';
import { ReactComponent as Location } from 'assets/images/dashboard/location.svg';
import { ReactComponent as JobCard } from 'assets/images/dashboard/jobcard.svg';
import { ReactComponent as CvScreeningIcon } from 'assets/images/settings/cv_screening.svg';
import { ReactComponent as PerSonalityIcon } from 'assets/images/settings/personality_profiling.svg';
import { ReactComponent as SkillAssesmentIcon } from 'assets/images/settings/skill_assesment.svg';
import { ReactComponent as HolisticInterviewIcon } from 'assets/images/settings/holistic_interview.svg';
import { ReactComponent as PsychometricAssesmentIcon } from 'assets/images/settings/pshychometric.svg';
import { ReactComponent as TechnicalInterviewIcon } from 'assets/images/settings/techinacal.svg';
import { ReactComponent as HRInterviewIcon } from 'assets/images/settings/hr_interview.svg';
import { ReactComponent as PanelInterviewIcon } from 'assets/images/settings/panel_interview.svg';
import { ReactComponent as Accept } from 'assets/images/dashboard/accept.svg';
// import { ReactComponent as Reject } from 'assets/images/dashboard/reject.svg';

import Tip from 'components/Tooltip';
const JobAnalyticsData = ({ jobData }) => {
  const { t } = useTranslation(['dashboard', 'common']);
  const currentLanguageCode = cookies.get('i18next');

  return (
    <div
      key={jobData?.job_details?.job_id}
      className={`flex flex-col md:mt-5 ${
        currentLanguageCode === 'ar' ? 'md:mr-5' : 'md:ml-5'
      }`}
    >
      <div className='md:flex  py-3 justify-center'>
        <div
          className={`flex justify-between ${
            currentLanguageCode === 'ar' ? 'ml-10' : 'mr-10'
          }`}
        >
          <div
            className={`flex ${
              currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
            }`}
          >
            <Location className='pt-1 w-5 h-5 ' />
            <p className='font-normal text-sm text-Neutrals-gray-1 mx-2'>
              {jobData?.job_details?.location}
            </p>
          </div>
          <div
            className={`flex ${
              currentLanguageCode === 'ar' ? 'md:ml-8' : 'md:mr-8'
            }`}
          >
            <Date className='pt-1 w-4 h-4 ' />
            <p className='font-normal text-sm text-Neutrals-gray-1 mx-2'>
              {jobData?.job_details?.posted_date
                ? dayjs(jobData?.job_details?.posted_date).format('MMM DD YYYY')
                : '-'}
            </p>
          </div>
        </div>

        <div
          className={`flex items-center justify-center pt-5 md:pt-0 md:flex-row ${
            currentLanguageCode === 'ar' ? 'ml-10 pl-10 md:pl-0' : 'mr-10 pr-10 md:pr-0'
          }`}
        >
          <JobCard className='pb-1 w-5 h-5  ' />
          <p className='font-normal text-sm text-Neutrals-gray-1 mx-2'>
            {jobData?.job_details?.job_id}
          </p>
        </div>
      </div>
      <div className='flex justify-center py-5'>
        <div
          className={`rounded-lg shadow-lg border bg-white w-24 h-20 md:w-36 md:h-20 pt-3 text-center ${
            currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
          }`}
        >
          <p className='text-base text-primary-green font-bold'>
            {jobData?.applicants_hired_avg === null
              ? 0 + ' day'
              : jobData?.applicants_hired_avg?.hired_avg_in_days + ' days'}{' '}
          </p>
          <p className='text-sm  font-normal'>
            {t('dashboard:mean_time_for_hire')}
          </p>
        </div>
        <div
          className={`rounded-lg shadow-lg border bg-white  w-24 h-20 md:w-36 md:h-20 pt-3 text-center ${
            currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
          }`}
        >
          <p className='text-base text-primary-green font-bold'>
            {jobData?.hired_count === null ? 0 : jobData?.hired_count}{' '}
          </p>
          <p className='text-sm  font-normal'>{t('common:hired')}</p>
        </div>
        <div
          className={`rounded-lg shadow-lg border bg-white  w-24 h-20 md:w-36 md:h-20 pt-3 text-center ${
            currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
          }`}
        >
          <p className='text-base text-red-500 font-bold'>
            {jobData?.rejected_count === null ? 0 : jobData?.rejected_count}{' '}
          </p>
          <p className='text-sm  font-normal'>{t('common:rejected')}</p>
        </div>
      </div>
      <div className='flex text-center justify-center text-base text-black font-semibold'>
        {t('dashboard:applicants_in_each_stage')}
      </div>

      <div className='flex justify-center py-5'>
        {jobData?.stages.map((stage, i) => (
          <>
            {stage === 'CV Screening' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose '
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.CV_Screening_count == null
                      ? 0
                      : jobData?.stages_count?.CV_Screening_count}
                  </p>
                  <p
                    className={`w-10 h-10 mb-2 ${
                      currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                    }`}
                  >
                    <CvScreeningIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Personality Profiling' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.Personality_Profiling_count == null
                      ? 0
                      : jobData?.stages_count?.Personality_Profiling_count}
                  </p>
                  <p className='w-11 h-11  mb-2'>
                    <PerSonalityIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Holistic Assessment' ? (
              <Tip
                content='Pre-hire Assessment'
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.Holistic_Assessment_count == null
                      ? 0
                      : jobData?.stages_count?.Holistic_Assessment_count}
                  </p>
                  <p className='w-32 h-10'>
                    <HolisticInterviewIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Skill Assessment' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.Skill_Assessment_count == null
                      ? 0
                      : jobData?.stages_count?.Skill_Assessment_count}
                  </p>
                  <p className='w-11 h-11  mb-2'>
                    <SkillAssesmentIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Psychometric Assessment' ? (
              <Tip
                content={JSON.parse(
                  localStorage.getItem('psychometric_stage_name')
                )}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.Psychometric_Assessment_count ==
                    null
                      ? 0
                      : jobData?.stages_count?.Psychometric_Assessment_count}
                  </p>
                  <p className='w-12 h-10 mb-2'>
                    <PsychometricAssesmentIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Technical Interview' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.Technical_Interview_count == null
                      ? 0
                      : jobData?.stages_count?.Technical_Interview_count}
                  </p>
                  <p className='w-10 h-11 mb-2'>
                    <TechnicalInterviewIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'HR Interview' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.HR_Interview_count == null
                      ? 0
                      : jobData?.stages_count?.HR_Interview_count}
                  </p>
                  <p className='w-10 h-11  mb-2'>
                    <HRInterviewIcon />
                  </p>
                </div>
              </Tip>
            ) : stage === 'Panel Interview' ? (
              <Tip
                content={stage}
                className={`${
                  currentLanguageCode === 'ar'
                    ? 'md:ml-7 lg:ml-6 ml-3'
                    : 'md:mr-7 lg:mr-6 mr-3'
                }`}
              >
                <div
                  className='flex flex-col space-y-4 justify-center items-center text-primary-rose'
                  key={`${stage}${i}`}
                >
                  <p>
                    {jobData?.stages_count?.HR_Interview_count == null
                      ? 0
                      : jobData?.stages_count?.Panel_Interview_count}
                  </p>
                  <p className='w-12 h-11  mb-2'>
                    <PanelInterviewIcon />
                  </p>
                </div>
              </Tip>
            ) : null}
          </>
        ))}
        <Tip
          content='Accepted another offer'
          className={`${
            currentLanguageCode === 'ar'
              ? 'md:ml-7 lg:ml-6 ml-3'
              : 'md:mr-7 lg:mr-6 mr-3'
          }`}
        >
          <div className='flex flex-col space-y-4 justify-center items-center text-primary-rose'>
            <p className={`${currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'}`}>
              {jobData?.accepted_count === null ? 0 : jobData?.accepted_count}{' '}
            </p>
            <p className='w-12 h-11  mb-2'>
              <Accept />
            </p>
          </div>
        </Tip>
      </div>
      {/* <div className='flex justify-center py-5 xl:space-x-12 md:space-x-8 space-x-3 '>
        <Tip content='Accepted to another offer'>
          <div className='flex flex-col space-y-4 justify-center items-center text-primary-rose'>
            <p className='mr-3'>
              {jobData?.accepted_count === null ? 0 : jobData?.accepted_count}{' '}
            </p>
            <p className='w-12 h-11  mb-2'>
              <Accept />
            </p>
          </div>
        </Tip> */}
      {/* <Tip content='Rejected'>
          <div className='flex flex-col space-y-4 justify-center items-center text-primary-rose'>
            <p className='mr-3'>
              {jobData?.rejected_count === null ? 0 : jobData?.rejected_count}{' '}
            </p>
            <p className='w-12 h-11  mb-2'>
              <Reject />
            </p>
          </div>
        </Tip> */}
      {/* </div> */}
    </div>
  );
};

export default JobAnalyticsData;
