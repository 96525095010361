import React, { createContext, useEffect, useReducer } from 'react';
export const AuthContext = createContext();

let initialState = {
  isAuthenticated:
    JSON.parse(localStorage.getItem('token')) ||
    JSON.parse(localStorage.getItem('token_client'))
      ? true
      : false,
  token: JSON.parse(localStorage.getItem('token')) || null,
  default_mail: JSON.parse(localStorage.getItem('default_mail')) || null,
  token_client: JSON.parse(localStorage.getItem('token_client')) || null,
  is_hiring_manager:
    JSON.parse(localStorage.getItem('is_hiring_manager')) || null,
  is_recruiter: JSON.parse(localStorage.getItem('is_recruiter')) || null,
  is_recruiter_team:
    JSON.parse(localStorage.getItem('is_recruiter_team')) || null,
  is_client_admin: JSON.parse(localStorage.getItem('is_client_admin')) || null,
  is_client_team: JSON.parse(localStorage.getItem('is_client_team')) || null,
  is_hiring_manager_team:
    JSON.parse(localStorage.getItem('is_hiring_manager_team')) || null,
  subscription_ended:
    JSON.parse(localStorage.getItem('subscription_ended')) || null,
  Username: JSON.parse(localStorage.getItem('Username')) || null,
  User_initials: JSON.parse(localStorage.getItem('User_initials')) || null,
  profilePicture: JSON.parse(localStorage.getItem('profilePicture')) || null,
  user_id: JSON.parse(localStorage.getItem('user_id')) || null,
  company_name: JSON.parse(localStorage.getItem('company_name')) || null,
  company_uuid: JSON.parse(localStorage.getItem('company_uuid')) || null,
  user_permissions: JSON.parse(localStorage.getItem('user_permissions')) || {},
  job_ask_every_time:
    JSON.parse(localStorage.getItem('job_ask_every_time')) || false,
  first_login: JSON.parse(localStorage.getItem('first_login')) || null,
  first_login_email:
    JSON.parse(localStorage.getItem('first_login_email')) || null,
  push_notification_token:
    JSON.parse(localStorage.getItem('push_notification_token')) || null,
  can_add_custom_flow:
    JSON.parse(localStorage.getItem('can_add_custom_flow')) || false,
  psychometric_stage_name:
    JSON.parse(localStorage.getItem('psychometric_stage_name')) || '',
};

const reducer = (state, action) => {
  switch (action.type) {
    /**
     * LOGIN
     *
     * as name suggest we will set the user information and preference to the local storage
     * Beacuse local storage only store String, we have to convert everything to string using JSON.stringify() method
     *
     * read more https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
     */

    case 'LOGIN':
      localStorage.setItem(
        'token',
        JSON.stringify(action.payload.token ?? false)
      );
      localStorage.setItem(
        'default_mail',
        JSON.stringify(action.payload.default_mail ?? false)
      );
      localStorage.setItem(
        'token_client',
        JSON.stringify(action.payload.token_client ?? false)
      );
      localStorage.setItem(
        'is_hiring_manager',
        JSON.stringify(action.payload.is_hiring_manager ?? false)
      );
      localStorage.setItem(
        'is_recruiter_team',
        JSON.stringify(action.payload.is_recruiter_team ?? false)
      );
      localStorage.setItem(
        'is_hiring_manager_team',
        JSON.stringify(action.payload.is_hiring_team ?? false)
      );
      localStorage.setItem(
        'is_recruiter',
        JSON.stringify(action.payload.is_recruiter ?? false)
      );
      localStorage.setItem(
        'is_client_team',
        JSON.stringify(action.payload.is_client_team ?? false)
      );
      localStorage.setItem(
        'is_client_admin',
        JSON.stringify(action.payload.is_client_admin ?? false)
      );
      localStorage.setItem(
        'subscription_ended',
        JSON.stringify(action.payload.subscription_ended ?? false)
      );
      localStorage.setItem(
        'user_id',
        JSON.stringify(action.payload.user_id ?? false)
      );
      localStorage.setItem(
        'company_name',
        JSON.stringify(action.payload.company_name ?? false)
      );
      localStorage.setItem(
        'company_uuid',
        JSON.stringify(action.payload.company_uuid ?? false)
      );
      localStorage.setItem(
        'user_permissions',
        JSON.stringify(action.payload.user_permissions ?? false)
      );
      localStorage.setItem(
        'Username',
        JSON.stringify(action.payload.Username ?? false)
      );
      localStorage.setItem(
        'profilePicture',
        JSON.stringify(action.payload.profilePicture ?? '')
      );
      localStorage.setItem(
        'job_ask_every_time',
        JSON.stringify(action.payload.job_ask_every_time ?? false)
      );
      localStorage.setItem(
        'first_login',
        JSON.stringify(action.payload.first_login ?? false)
      );
      localStorage.setItem(
        'first_login_email',
        JSON.stringify(action.payload.first_login ?? false)
      );
      localStorage.setItem(
        'User_initials',
        JSON.stringify(action.payload.user_initials || '')
      );
      localStorage.setItem(
        'can_add_custom_flow',
        JSON.stringify(action.payload.can_add_custom_flow ?? false)
      );
      localStorage.setItem(
        'psychometric_stage_name',
        JSON.stringify(
          action.payload.is_client_admin || action.payload.is_client_team
            ? ''
            : action.payload?.user_label_names[0]?.psychometric_assessment || ''
        )
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token ?? null,
        default_mail: action.payload.default_mail ?? false,
        token_client: action.payload.token_client ?? null,
        is_hiring_manager: action.payload.is_hiring_manager ?? false,
        is_recruiter: action.payload.is_recruiter ?? false,
        is_client_admin: action.payload.is_client_admin ?? false,
        is_client_team: action.payload.is_client_team ?? false,
        Username: action.payload.Username ?? null,
        profilePicture: action.payload.profilePicture ?? null,
        user_id: action.payload.user_id ?? null,
        company_name: action.payload.company_name ?? null,
        company_uuid: action.payload.company_uuid ?? null,
        user_permissions: action.payload.user_permissions ?? false,
        subscription_ended: action.payload.subscription_ended ?? false,
        job_ask_every_time: action.payload.job_ask_every_time ?? false,
        first_login: action.payload.first_login ?? false,
        first_login_email: action.payload.first_login ?? false,
        push_notification_token:
          action.payload.push_notification_token ?? false,
        is_recruiter_team: action.payload.is_recruiter_team ?? false,
        is_hiring_manager_team: action.payload.is_hiring_team ?? false,
        can_add_custom_flow: action.payload.can_add_custom_flow ?? false,
        psychometric_stage_name:
          action.payload.is_client_admin || action.payload.is_client_team
            ? ''
            : action.payload?.user_label_names[0]?.psychometric_assessment ||
              '',
        User_initials:
          action.payload.user_initials === undefined
            ? ''
            : action.payload.user_initials,
      };

    /**
     * UPDATEPROFILEIMAGE
     *
     * This reducer is responsible for updating the profile picture
     * this is usefull when user update his/her profile,
     * after updating the profile info we will get back the new profile picture URL,
     * then we will update our local version with the updated url from server
     *
     */

    case 'UPDATEPROFILEIMAGE':
      localStorage.setItem('profilePicture', JSON.stringify(action.payload));
      return {
        ...state,
        profilePicture: action.payload,
      };

    case 'UPDATEJOBFLOWPREFERENCE':
      localStorage.setItem(
        'job_ask_every_time',
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        job_ask_every_time: action.payload,
      };

    /**
     * UPDATETOURSTATUS
     *
     * This reducer will allow us to set first_login(boolean) value
     * first_login value will act as main source for React Tour
     * setting first_login to true will enable React Tour
     */

    case 'UPDATETOURSTATUS':
      localStorage.setItem('first_login', JSON.stringify(action.payload));
      return {
        ...state,
        first_login: action.payload,
      };

    /**
     * UPDATEPUSHNOTIFICATION
     *
     * This reducer is responsible for knowing push notification availability
     */

    case 'UPDATEPUSHNOTIFICATION':
      localStorage.setItem(
        'push_notification_token',
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        push_notification_token: action.payload,
      };

    case 'UPDATEEMAILDEFAULT':
      return {
        ...state,
        default_mail: true,
      };

    /**
     * LOGOUT
     *
     * This reducer is responsible for logging the user out
     * and clearing the browser localstorage after that
     *
     */

    case 'LOGOUT':
      localStorage.clear();

      return {
        ...state,
        isAuthenticated: false,
        token: null,
        default_mail: null,
        token_client: null,
        is_recruiter: null,
        is_client_admin: null,
        is_client_team: null,
        is_hiring_manager: null,
        is_recruiter_team: null,
        is_hiring_manager_team: null,
        Username: null,
        profilePicture: null,
        user_id: null,
        company_name: null,
        company_uuid: null,
        user_permissions: {},
        subscription_ended: null,
        first_login: false,
        first_login_email: false,
        push_notification_token: null,
        job_ask_every_time: false,
        User_initials: null,
        can_add_custom_flow: false,
        psychometric_stage_name: '',
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
