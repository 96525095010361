/**
 * DO NOT Forgot to include / at the end of the url
 * without ending "/" it will through error in safari
 */

const URLS = {
  // Authentication urls

  REGISTER: '/Profile/UserProfile/',
  LOGIN: '/Profile/login/',
  CLIENT_LOGIN: '/Profile/ClientLoginAPI/',
  LOGOUT: '/Profile/logout/',
  VERIFY_OTP: '/Profile/VerifyOtp/',
  RESEND_OTP: '/Profile/ResendOtp/',
  CHECK_EXISTING_USER_DATA: '/Profile/VerifyExistence/',
  COUNTRY_LIST: '/Profile/GetCountriesList/',
  INDUSTRY_LIST: '/Profile/ClientIndustries/',
  COUNTRY_CODE: '/Profile/GetCountryCodes/',
  CURRENCY_LIST: '/Profile/GetCurrencies/',
  FORGOT_PASSWORD: '/Profile/ForgetPassword/',
  COMPANY_EMAIL_PARSING: '/Profile/CompanyEmailForParsingAPI/',
  CHECK_USER_PERMISSION: '/Profile/CheckUserPermissions/',
  CLIENT_CHECK_USER_PERMISSION: '/Clients/ClientCheckUserPermissions/',

  // Profile urls

  CHANGE_PASSWORD: '/Profile/ChangePassword/',
  CLIENT_CHANGE_PASSWORD: '/Clients/ClientChangePassword/',
  UPDATE_PROFILE_DATA: '/Profile/updateuserprofile/',
  UPDATE_CLIENT_PROFILE_DATA: '/Clients/ClientProfile/',
  COMPANY_DETAILS: '/Profile/Company_logo/',
  EMAIL_ACCOUNT_SETUP: '/Profile/EmailAccountsetup/',
  RESET_EMAIL_ACCOUNT: '/Profile/ResetEmailAccountAPI/',
  GET_ALL_PLANS: '/Profile/Plans/',
  CURRENT_PLAN_DETAILS: '/Profile/GetPlanInfo/',
  NOTIFICATIONS: '/Profile/NotificationsAPI/',
  RAZOR_PAY_PAYMENT_API: '/Profile/RazorPayRouteAPI/',
  PAYPAL_PAYMENT_API: '/Profile/PayPalPaymentAPI/',
  STRIPE_PAYMENT_API: '/Profile/StripePaymentSessionAPI/',
  CHANGE_JOB_STAGE_LABEL_NAME: '/Profile/LabelNamesAPI/',
  APPLICANT_REJECTION_CONTROL: '/Profile/SetApplicantRejectDaysAPI/',
  CLIENT_CHANGE_JOB_STAGE_LABEL_NAME: '/Clients/ClientLabelNamesAPI/',
  PURCHASED_ADD_ONS_API: '/Profile/PurchasedAddOnsAPI/',
  ADD_ONS_PLANS_API: '/Profile/AddOnPlansAPI/',
  REFERRAL_AND_WALLET: '/Profile/WalletAndReferralAPI/',
  SHARE_REFERRAL_CODE: '/Profile/ShareReferralCodeAPI/',
  UPDATE_EMAIL_CONTENT: '/Profile/UpdateEmailContentAPI/',

  GENARATE_PSY_REPORT: '/Jobs/UnRestrictReportAPI/',

  // Database api

  DATABASE_SETUP_API: '/Profile/DatabaseSetup/',

  // JObs APIS

  JOB_POSTING: '/Jobs/JobPosting/',
  CLIENT_JOB_POSTING: '/Clients/ClientJobPosting/',
  CLIENT_JOB_POSTINGTAGEASSIGN: '/Clients/ClientSideJobStagesAssign/',
  SPECIFICCLIENT_JOB_POSTINGTAGE: '/Clients/ClientJobStagesAPI/',
  DRAFT_JOB_COUNT: '/Jobs/JobDraftCountAPI/',

  JOB_SAVE_AS_DRAFT: '/Jobs/JobSaveAsDraftAPI/',

  JOB_EVALUATION: '/Jobs/JobEvaluationAPI/',
  JOB_LOCATIONS: '/Jobs/JobLocations/',
  INVITE_APPLICANTS_FOR_JOB: '/Jobs/InviteApplicants/',
  JOB_APPLICANTS: '/Jobs/JobApplicants/',
  COMPARE_APPLICANTS: '/Jobs/CompareApplicants/',
  CLIENTS_COMPARE_APPLICANTS: '/Clients/ClientCompareApplicants/',
  SPECIFIC_JOBSTAGE: '/Jobs/GetJobStages/',
  JOB_STAGE_COMPLETED_DAYS: '/Jobs/GetBufferDays/',
  JOB_SHOW_EMAIL_TEMPLATE: '/Jobs/ShowEmailTemplate/',

  JOB_VIDEO_INTERVIEW_EMAIL_TEMPLATE: '/Jobs/ShowVideoEmailTemplate/',
  JOB_CLIENT_VIDEO_INTERVIEW_TAMPLATE:
    '/Clients/ClientShowVideoInterviewEmailTemplate/',
  JOB_SHOW_REJECT_TEMPLATE: '/Jobs/ShowRejectionTemplate/',
  JOB_APPLICANT_SEARCH: '/Jobs/JobApplicantSearch/',
  JOB_DESCRIPTION_PARSER: '/Jobs/JobParserAPI/',

  JOB_DYNAMIC_FILTER: '/Jobs/DynamicFiltersDataAPI/',
  CLIENT_JOB_DYNAMIC_FILTER: '/Clients/ClientDynamicFiltersDataAPI/',

  // Applicants APIS
  JOB_APPLICANTS_NOTES: '/Jobs/ApplicantNotes/',
  CLIENT_JOB_APPLICANTS_NOTES: '/Clients/ClientApplicantNotesAPI/',
  CLIENT_JOB_APPLICANTS: '/Clients/ClientJobApplicants/',
  APPLICANT_CHANGE_STATUS: '/Jobs/ChangeStatusApplicant/',
  CLIENT_APPLICANT_CHANGE_STATUS: '/Clients/ClientChangeStatusApplicant/',
  APPLICANTS_CHEDULE_STAGE: '/Jobs/ScheduleStage/',
  APPLICANTS_EMAILS: '/Jobs/SendMailToApplicant/',
  CLIENT_APPLICANTS_EMAILS: '/Clients/ClientSendMailToApplicantAPI/',
  APPLICANTS_ASSESSMENT_INFO: '/Jobs/GetApplicantAssessmentInfo/',
  APPLICANTS_MOVE_TO_CV_BANK: '/FindResumes/ManualAddingCandidatesAPI/',
  APPLICANTS_MOVE_ALL_TO_CV_BANK: '/FindResumes/AddingClosedCandidatesAPI/',
  APPLICANTS_MAIL_REPORTS_TO_CLIENT: '/Jobs/TriggerReportMailAPI/',

  CLIENT_APPLICANT_ASSESSMENT_INFO:
    '/Clients/ClientGetApplicantAssessmentInfo/',
  APPLICANTS_SCHEDULE_INTERVIEW: '/Jobs/ScheduleVideoStages/',
  CLIENT_APPLICANTS_SCHEDULE_INTERVIEW: '/Clients/ClientScheduleVideoStages/',
  APPLICANTS_VIDEO_INTERVIEW_JITSI: '/Jobs/VideoInterview/',
  CLIENT_APPLICANTS_VIDEOINTERVIEW_JITSI: '/Clients/ClientVideoInterviewAPI/',
  APPLICANTS_ADD_FEEDBACK_AFTER_INTERVIEW: '/Jobs/EndVideoInterview/',
  CLIENT_APPLICANTS_ADD_FEEDBACK_AFTER_INTERVIEW:
    '/Clients/ClientEndVideoInterview/',
  JOB_APPLICANTS_CSV: '/Jobs/GenerateApplicantsCSV/',
  JOB_APPLICANTS_DETAILS_UPDATE: '/Applicants/EditApplicantInfoAPI/',

  RESUME_FOLDERS: '/FindResumes/RootFolderAPI/',
  CANDIDATE_RESUME: '/FindResumes/ResumeCandidatesAPI/',
  CANDIDATE_RESUME_JOB: '/FindResumes/ResumeCandidatesViaJobsAPI/',

  // Team urls

  TEAM_MEMBER: '/team/Teammember/',
  CLIENT_TEAM_MEMBER: '/Clients/ClientTeamMember/',
  TEAM_MEMBER_FOR_MENTIONS: '/team/GetTeamMemberMention/',

  // Recruiter URL

  MOVE_APPLICANTS_TO_CLIENT: '/Jobs/MoveToClientAPI/',
  MOVE_APPLICANTS_TO_CLIENT_MAIL_REPORTS: '/Jobs/TriggerReportMailAPI/',

  // Client urls

  CREATE_CLIENT: '/Profile/CreateClient/',
  CLIENT_JOB_LOCATION: 'Clients/ClientJobLocation/',
  CLIENT_APPLICANT_REJECTION_EMAIL: '/Clients/ClientShowRejectionTemplate/',

  // Assessments

  PSYCHOMETRY_QUESTIONS: '/Assessment/PsychometryQuestionsAPI/',
  DISC_ASSESSMENT: '/Profile/DISCAssessmentQuestionsAPI/',
  BULK_UPLOAD: '/Assessment/BulkUpload/',

  // Assessment Database URL'S
  DATABASE_DOMAINS: '/Profile/CreateDomain/',
  DATABASE_DOMAIN_FOLDERS: '/Profile/CreateFolder/',
  DATABASE_DOMAIN_FOLDER_QUESTIONS: '/Profile/CreateQuestion/',

  // Assessment urls

  CREATE_ASSESSMENT_FOLDER: '/Assessment/CreateAssessmentfolder/',
  CREATE_SKILL_ASSESSMENT_QUESTIONS: '/Assessment/QuestionsAPI/',

  SKILL_ASSESSMENT_REPORT: '/Assessment/SkillReportAPI/',
  CLIENT_SKILL_ASSESSMENT_REPORT: '/Clients/ClientSkillReportAPI',

  // Job application api
  JOB_APPLICATION_API: '/Jobs/JobApplicationAPI/',

  JOB_APPLICANTS_TEST_CRED: '/Jobs/TestApplicantCredsAPI/',

  // Dashboard
  DASHBOARD_ANALYTICS: '/Dashboard/ActiveJobs/',
  DASHBOARD_CLIENT_ACTIVE_JOBS: '/Clients/ClientActiveJobs/',
  DASHBOARD_CLIENT_PENDING_JOBS: '/Clients/ClientDashBoard/',
  DASHBOARD_ACTIVITY_TRACKER: '/Dashboard/ActivityTracker/',
  DASHBOARD_MEETING_SCHEDULE: '/Dashboard/MeetingSchedule/',
  DASHBOARD_MEETING_SCHEDULE_DATES_API: '/Dashboard/MeetingScheduleDatesAPI/',
  CLIENT_MEETING_SCHEDULE_DATES_API: '/Clients/ClientMeetingScheduleDatesAPI/',
  DASHBOARD_CLIENT_MEETING_SCHEDULE: '/Clients/ClientMeetingSchedule/',
  DASHBOARD_RECRUITER_TOTAL_CLIENTS: '/Dashboard/RecruiterTotalClients/',
  CLIENT_PENDING_JOBS: '/Clients/ClientPendingJobs/',
  JOB_ANALYTICS_DATA: 'Dashboard/JobAnalyticsAPI/',

  // Push Notification
  PUSH_NOTIFICATION_TOKEN_REGISTRATION: '/Profile/PushNotiRegTokenAPI/',

  // Find Cv
  JOB_SCANNER_URL: '/FindResumes/CvBankBarcodeURL/',
  APPLICANT_DETAILS: '/FindResumes/ResumeCandidatesSub/',
};

export default URLS;
