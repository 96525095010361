const queryKeys = {
  allApplicants: 'all-applicants',
  applicantsAssessmentInfo: 'applicant-assesment-info',
  allJobs: 'all-jobs',
  jobStageCompletedDays: 'job-stage-completed-days',
  allJobsLocation: 'all-jobs-location',
  specificApplicant: 'specific-applicant',
  compareApplicants: 'compare-applicants',
  applicantsNotes: 'applicant-notes',
  teamMemberFormention: 'all-team-member-mention',
  specificJobStage: 'specific-job-stage',
  allClients: 'all-clients',
};

export default queryKeys;
