import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as NoMeetingsIllus } from 'assets/images/dashboard/NoMeetingsIllus.svg';
import { ReactComponent as StatstopIllus } from 'assets/images/dashboard/StatstopIllus.svg';
import { ReactComponent as TotalHired } from 'assets/images/dashboard/TotalHired.svg';
import { ReactComponent as ApplicantProcessed } from 'assets/images/dashboard/ApplicantProcessed.svg';
import { ReactComponent as StatsbottomIllus } from 'assets/images/dashboard/StatsbottomIllus.svg';

import MeetingTile from './meetingTiles/MeetingTile';
import MeetingTilePsychometric from './meetingTiles/MeetingTilePsychometric';
import MeetingTileTechInter from './meetingTiles/MeetingTileTechInter';
import MeetingTileHRInter from './meetingTiles/MeetingTileHRInter';
import MeetingTilePanel from './meetingTiles/MeetingTilePanel';
import MeetingTilePreHire from './meetingTiles/MeetingTilePreHire';
import SkeletonMeeting from './meetingTiles/SkeletonMeeting';

function MeetingsAnalytics({
  is_recruiter,
  is_hiring_manager,
  is_client_admin,
  dashboardPendingAssignments,
  token,
}) {
  const [displayDateValue, setDisplayDateValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  const MeetingScheduleDates = useQuery(['MeetingScheduleDates'], async () => {
    if (is_client_admin) {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.CLIENT_MEETING_SCHEDULE_DATES_API}?token=${token}`
      );
      return resData;
    } else {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.DASHBOARD_MEETING_SCHEDULE_DATES_API}`
      );
      return resData;
    }
  });

  const dashboardMeetings = useQuery(
    ['dashboardMeetings', dateValue],
    async () => {
      let resData;
      let event = new Date();
      var newDate = new Date(
        event.getTime() - event.getTimezoneOffset() * 60 * 1000
      );
      if (is_client_admin) {
        if (dateValue) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_CLIENT_MEETING_SCHEDULE}?token=${token}&date=${dateValue}`
          );
          return resData;
        } else if (!dateValue) {
          let date = JSON.stringify(newDate);
          date = date.slice(1, 11);
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_CLIENT_MEETING_SCHEDULE}?token=${token}&date=${date}`
          );
          return resData;
        }
      } else {
        if (dateValue) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_MEETING_SCHEDULE}?date=${dateValue}`
          );
          return resData;
        } else if (!dateValue) {
          let date = JSON.stringify(newDate);
          date = date.slice(1, 11);
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_MEETING_SCHEDULE}?date=${date}`
          );
          return resData;
        }
      }
    }
  );

  const isDateHasMeetings = (date) => {
    if (
      MeetingScheduleDates?.data?.data?.data?.includes(
        `${date.day}${date.month + 1}${date.year}`
      )
    )
      return true;
    return false;
  };

  const dateTemplate = (date) => {
    if (isDateHasMeetings(date)) {
      return (
        <div>
          {date.day}{' '}
          <div className='w-1 h-1 rounded-full bg-red-600 mx-auto'></div>
        </div>
      );
    } else {
      return (
        <div>
          {date.day} <div className='w-1 h-1 rounded-full '></div>
        </div>
      );
    }
  };

  return (
    <>
      <div className='md:hidden text-lg 2xl:text-xl font-bold mb-2 text-midnightBlue'>
        {t('meeting_schedule')}
      </div>
      <div
        className={`flex flex-wrap  w-full lg:w-8/12 bg-white rounded-lg border-t shadow-lg hover:shadow-xl mb-6 lg:mb-0 py-4 px-6  ${
          currentLanguageCode === 'ar' ? 'lg:ml-6' : 'lg:mr-6'
        }`}
      >
        <div className='flex flex-col w-full md:w-1/2 lg:w-4/12'>
          <div className='hidden md:block text-lg 2xl:text-xl font-bold text-midnightBlue'>
            {t('meeting_schedule')}
          </div>
          <div
            className={`text-sm 2xl:text-base text-center  text-Neutrals-gray mt-3 ${
              currentLanguageCode === 'ar' ? 'md:text-right' : 'md:text-left'
            }`}
          >
            {t('select_a_date_to_view')}
          </div>
          <div className='dashboard-date-picker h-72 md:h-80 max-h-80 mt-2 border-opacity-50 flex justify-center md:justify-start'>
            <Calendar
              value={displayDateValue}
              onChange={(e) => {
                let event = new Date(e.value);
                var newDate = new Date(
                  event.getTime() - event.getTimezoneOffset() * 60 * 1000
                );
                let date = JSON.stringify(newDate);
                date = date.slice(1, 11);
                setDateValue(date);
                setDisplayDateValue(e.value);
              }}
              dateTemplate={dateTemplate}
              inline
            />
            {/* showWeek */}
          </div>
        </div>
        {/* <div className='flex flex-col justify-center items-center w-8/12'>
            <div className='flex flex-1 justify-center items-center flex-col'>
              <NoMeetingsIllus />
              <span className='text-Neutrals-gray-1 text-sm my-2'>
                No Meetings to show
              </span>
            </div>
          </div> */}
        <div className='flex flex-col justify-center items-center w-full md:w-1/2 lg:w-8/12'>
          <div className='flex flex-col lg:flex-row w-full flex-1'>
            <div
              className={`flex flex-col ${
                is_client_admin ? 'w-full' : ' w-full lg:w-1/2'
              }`}
            >
              <div
                className={`text-black font-bold flex justify-center py-4  border-b-1 border-primary-rose border-opacity-40  ${
                  currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                }`}
              >
                {t('scheduled_interviews')}
              </div>
              <div
                className={`flex flex-col h-80 max-h-80 scroller overflow-y-auto my-3 ${
                  is_client_admin
                    ? ''
                    : currentLanguageCode === 'ar'
                    ? 'lg:border-l border-primary-rose border-opacity-40'
                    : 'lg:border-r border-primary-rose border-opacity-40'
                } `}
              >
                {dashboardMeetings.isLoading ? (
                  <>
                    <SkeletonMeeting />
                    <SkeletonMeeting />
                    <SkeletonMeeting />
                    <SkeletonMeeting />
                  </>
                ) : dashboardMeetings?.data?.data?.data?.interviews?.length ? (
                  dashboardMeetings?.data?.data?.data?.interviews?.map(
                    (meeting, index) =>
                      meeting?.stage === 'Technical_Interview' ? (
                        <MeetingTileTechInter
                          key={meeting.applicant_id + index}
                          meeting={meeting}
                          stage={'interview'}
                          is_recruiter={is_recruiter}
                        />
                      ) : meeting?.stage === 'HR_Interview' ? (
                        <MeetingTileHRInter
                          key={meeting.applicant_id + index}
                          meeting={meeting}
                          stage={'interview'}
                          is_recruiter={is_recruiter}
                        />
                      ) : meeting?.stage === 'Panel_Interview' ? (
                        <MeetingTilePanel
                          key={meeting.applicant_id + index}
                          meeting={meeting}
                          stage={'interview'}
                          is_recruiter={is_recruiter}
                        />
                      ) : (
                        <></>
                      )
                  )
                ) : (
                  <div className='flex flex-col items-center justify-center h-full'>
                    <NoMeetingsIllus className='w-20 h-20' />
                    <span className='text-Neutrals-gray-1 text-sm my-4'>
                      {t('no_meetings_to_show')}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {is_hiring_manager || is_recruiter ? (
              <div className={`flex flex-col w-full lg:w-1/2`}>
                <div
                  className={`text-black font-bold flex justify-center py-4  border-b-1 border-primary-rose border-opacity-40  ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  {t('scheduled_assessments')}
                </div>
                <div className='flex flex-col h-80 max-h-80 scroller overflow-y-auto my-3'>
                  {dashboardMeetings.isLoading ? (
                    <>
                      <SkeletonMeeting />
                      <SkeletonMeeting />
                      <SkeletonMeeting />
                      <SkeletonMeeting />
                    </>
                  ) : dashboardMeetings?.data?.data?.data?.assessments
                      ?.length ? (
                    dashboardMeetings?.data?.data?.data?.assessments?.map(
                      (meeting, index) =>
                        meeting.stage === 'Skill_Assessment' ? (
                          <MeetingTile
                            key={meeting.applicant_id + index}
                            meeting={meeting}
                            stage={'assessment'}
                          />
                        ) : meeting?.stage === 'Psychometric_Assessment' ? (
                          <MeetingTilePsychometric
                            key={meeting.applicant_id + index}
                            meeting={meeting}
                            stage={'assessment'}
                          />
                        ) : (
                          <MeetingTilePreHire
                            key={meeting.applicant_id + index}
                            meeting={meeting}
                            stage={'assessment'}
                          />
                        )
                    )
                  ) : (
                    <div className='flex flex-col items-center justify-center h-full'>
                      <NoMeetingsIllus className='w-20 h-20' />
                      <span className='text-Neutrals-gray-1 text-sm my-4'>
                        {t('no_assessments_to_show')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className='w-full lg:w-4/12 bg-white rounded-lg border-t shadow-lg hover:shadow-xl px-6 py-4'>
        {is_client_admin ? (
          <div className='flex flex-col items-center h-96 min-h-80 lg:h-full justify-evenly'>
            <div className='flex w-full'>
              <div className='flex justify-center w-1/2'>
                <ApplicantProcessed />
              </div>
              <div className='flex w-1/2 flex-col items-center justify-center'>
                <div className='flex text-center text-primary-rose font-bold text-2xl mb-2'>
                  {dashboardPendingAssignments?.data?.data?.data
                    ?.applicants_progressed_count || 0}
                </div>
                <div className='flex flex-col font-bold text-center'>
                  {t('applicants_progressed_from_recruiter')}
                </div>
              </div>
            </div>
            <div className='border-b border-primary-rose border-opacity-50 w-full'></div>
            <div className='flex w-full'>
              <div className='flex w-1/2 flex-col items-center justify-center'>
                <div className='flex w-full flex-col items-center'>
                  <div className='w-full text-center text-primary-rose font-bold text-2xl my-2'>
                    {dashboardPendingAssignments?.data?.data?.data
                      ?.total_hired_count || 0}
                  </div>
                  <div className='w-full flex-col font-bold text-center'>
                    {t('total_hires')}
                  </div>
                </div>
              </div>
              <div className='flex justify-center w-1/2'>
                <TotalHired />
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center h-96 min-h-80 lg:h-full justify-evenly'>
            <div className='flex w-full'>
              <div className='flex w-1/2'>
                <StatstopIllus />
              </div>
              <div className='flex w-1/2 flex-col items-center justify-center'>
                <div className='flex text-center text-gradient font-bold text-xl mb-2'>
                  {dashboardMeetings?.data?.data?.cv_count || 0}
                </div>
                <div className='flex flex-col text-center'>
                  {t('cvs_screened_with_our')}{' '}
                  <span className='font-bold'>
                    {' '}
                    {t('ai_powered_algorithm')}
                  </span>
                </div>
              </div>
            </div>
            <div className='border-b border-primary-rose border-opacity-50 w-full'></div>
            <div className='flex w-full'>
              <div className='flex w-1/2 flex-col items-center justify-center'>
                <div className='flex w-full flex-col items-center'>
                  <div className='w-full text-center text-gradient font-bold text-xl my-2'>
                    {dashboardMeetings?.data?.data?.pers_count || 0}
                  </div>
                  <div className='w-full flex-col text-center'>
                    {t('job_applicants_screened_with_our')}{' '}
                    <span className='font-bold block'>
                      {' '}
                      {t('ai_powered_psychometry')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex w-1/2'>
                <StatsbottomIllus />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MeetingsAnalytics;
