import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { ErrorToast } from 'services/toasterService';
import cookies from 'js-cookie';
// import { Popover, Transition } from '@headlessui/react';
// import { ReactComponent as EditIcon } from 'assets/images/dashboard/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/dashboard/DeleteIcon.svg';
// import { ReactComponent as OptionsIcon } from 'assets/images/dashboard/OptionsIcon.svg';

function ActivitySingle({ activity, updateActivity, deleteActivity }) {
  const [checkboxValue, setCheckboxValue] = useState(activity.completed);
  const currentLanguageCode = cookies.get('i18next');

  const completed = (data) => {
    updateActivity.mutate(data, {
      // onSuccess: (resdata) => {},
      onError: (err) => {
        setCheckboxValue(!data.completed);
        ErrorToast({ text: err.response.data.message });
      },
    });
  };
  const deleteActivityFn = (data) => {
    deleteActivity.mutate(data, {
      // onSuccess: (resdata) => {},
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    });
  };

  useEffect(() => {
    setCheckboxValue(activity.completed);
  }, [activity.completed]);

  return (
    <div className='flex w-full my-4'>
      <div
        className={`'p-field-checkbox flex items-start ${
          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
        }`}
      >
        <Checkbox
          checked={checkboxValue}
          onChange={(event) => {
            setCheckboxValue(event.checked);
            completed({ completed: event.checked, id: activity.id });
          }}
        />
      </div>
      <div
        className={`text-sm flex-1 text-9A9A9A mx-2 ${
          activity.completed ? 'line-through' : ''
        }`}
      >
        {activity?.activity}
      </div>
      <div>
        {/* <Popover className='relative'>
          {({ open }) => (
            <>
              <Popover.Button className='px-1'>
                <OptionsIcon />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
              >
                <Popover.Panel className='absolute z-10  w-32 p-2 transform -translate-x-1/2 -left-16 -bottom-6 sm:px-0 '>
                  <div className='overflow-hidden rounded-md shadow-lg'>
                    <div
                      onClick={() => {
                        deleteActivityFn({ activity_ids: [activity.id] });
                      }}
                      className='relative p-2 px-4 bg-white text-sm flex justify-start cursor-pointer'
                    >
                      <div className='flex items-center justify-start'>
                        <span className='mr-2'>
                          <DeleteIcon />
                        </span>{' '}
                        Delete
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover> */}
        <div
          onClick={() => {
            deleteActivityFn({ activity_ids: [activity.id] });
          }}
          className='relative bg-white text-sm flex justify-start cursor-pointer'
        >
          <div className='flex items-center justify-end'>
            <span
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
            >
              <DeleteIcon className='w-4 h-5' />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitySingle;
