// This file (firebase.js) helps us in generating token and initializes firebase in our application
// token generated will be unique for browser specific and will be same on each login
// along with this file we aslo do have a firebase-messaging-sw.js file in public folder which acts as a service worker

// References for firebase integration
// https://firebase.google.com/docs/web/setup
// https://firebase.google.com/docs/cloud-messaging/js/client
// https://firebase.google.com/docs/cloud-messaging/js/receive#web-version-8

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// firebaseConfig has all the information required to link our application with firebase cloud messaging service
// Don't alter any of the firebaseConfig
const firebaseConfig = {
  apiKey: 'AIzaSyCLSjQNDmKlNxmfzp1r9rgI3acNcNZ7YPA',
  authDomain: 'aptahr-push-notifications.firebaseapp.com',
  projectId: 'aptahr-push-notifications',
  storageBucket: 'aptahr-push-notifications.appspot.com',
  messagingSenderId: '373785187068',
  appId: '1:373785187068:web:628606b9122b2699557d79',
  measurementId: 'G-FXB7WLKQ9D',
};

initializeApp(firebaseConfig); // initializing firebase

// VAPID_KEY is a key generated in firebase console which helps in generating token in client side
const publicKey = process.env.REACT_APP_FIREBASE_VAPID_KEY_TEST;

/**
 * getTokenFn helps in generating token for browser-user specific
 *
 * @param {Function} setFirebaseToken - using setFirebaseToken we are setting token returned by the getTokenFn
 * @returns {Token<any>} - a unique token is generated on user grants permission successfully
 */

export const getTokenFn = async (setFirebaseToken) => {
  let currentToken = ''; // defining default value
  const messagingAPI = await getMessaging(); // initializing messasing service form firebase

  try {
    currentToken = await getToken(messagingAPI, { vapidKey: publicKey });
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

// the code below helps in showing notification from firebase when our application is in acitve state(while using the application)

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messagingAPI.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
