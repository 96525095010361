import { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { ApplicantsContext } from 'contexts/applicantsContext';

import PrimaryButton from 'components/Button/primaryButton';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { SuccessToast, ErrorToast } from 'services/toasterService';

function TestAssessmentCredentialModal({ applicantsData }) {
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const defaultValues = {
    eamil: '',
    password: '',
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const testCredentialsMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.JOB_APPLICANTS_TEST_CRED,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        reset({});
        applicantsDispatch({
          type: 'SET_TEST_CREDENTIAL_MODAL_VISIBLE_STATE',
          payload: false,
        });
      },
      onError: (error) => {
        if (error.response.data.message === 'Something Went Wrong') {
          ErrorToast({ text: error.response.data.data });
        } else {
          ErrorToast({ text: error.response.data.message });
        }
      },
    }
  );

  const onSubmit = (data) => {
    testCredentialsMutation.mutate({ ...data });
  };

  const habndelCloseModal = () => {
    applicantsDispatch({
      type: 'SET_TEST_CREDENTIAL_MODAL_VISIBLE_STATE',
      payload: false,
    });
    reset({});
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  useEffect(() => {
    reset({
      email: applicantsData?.email,
      password: applicantsData?.password,
    });
  }, [applicantsData, reset]);

  const header = (
    <div className='z-50 flex justify-between items-center'>
      <p className='invisible'>Add</p>

      <p>{t('jobsAndApplicants:test_candidate_credentials')}</p>

      <svg
        width='40'
        height='40'
        viewBox='0 0 67 65'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
        className='cursor-pointer'
        onClick={habndelCloseModal}
      >
        <path
          d='M41.3962 24.3962L24.9824 40.2906'
          stroke='#FF009C'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.9824 24.3962L41.3962 40.2906'
          stroke='#FF009C'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        visible={applicantsState.is_test_credentidal_modal_visible}
        closable={false}
        onHide={habndelCloseModal}
        className='w-full max-w-xl rounded-lg mx-4 md:mx-0'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col w-full items-center justify-center'>
          <div className='w-9/12 mb-2'>
            <form onSubmit={handleSubmit(onSubmit)} id='add-skill-word'>
              <div className='w-full my-8 mb-10'>
                <span className='p-float-label'>
                  <Controller
                    name='email'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        readOnly
                        className='opacity-60 cursor-default'
                      />
                    )}
                  />
                  <label htmlFor='email'>{t('common:email')}</label>
                </span>
                {getFormErrorMessage('email')}
              </div>
              <div className='w-full my-8'>
                <span className='p-float-label'>
                  <Controller
                    name='password'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        readOnly
                        className='opacity-60 cursor-default'
                      />
                    )}
                  />
                  <label htmlFor='password'>{t('common:password')}</label>
                </span>
                {getFormErrorMessage('password')}
              </div>
            </form>
          </div>

          <div className='w-full flex justify-center mt-4'>
            <button type='submit' form='add-skill-word' className='w-32'>
              <PrimaryButton isLoading={testCredentialsMutation.isLoading}>
                <p className='text-sm leading-5'>
                  {t('jobsAndApplicants:test')}
                </p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default TestAssessmentCredentialModal;
