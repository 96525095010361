import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ScrollToTop from './scroolToTop';

import ProtectedRoute from './ProtectedRoute';
import AuthRoutes from 'pages/common/Authentication';
import ProfileRoutes from 'pages/common/profile';
import CheckAuthPage from 'pages/checkAuthPage';
import TeamsPage from 'pages/common/Team';
import AssessmentBank from 'pages/common/AssessmentBank';
import Dashboard from 'pages/common/Dashboard';
import Plans from 'pages/common/plans';
import HelpSupport from 'pages/common/HelpSupport';

import Demopage from 'pages/common/demoPage';

import HRJobsPage from 'pages/HR/Jobs';
import HRApplicantsInterviewStages from 'pages/HR/Applicants';

import RecruiterJobsPage from 'pages/Recruiter/Jobs';
import RecruiterApplicantsInterviewStages from 'pages/Recruiter/Applicants';
import RecruterClientsPage from 'pages/Recruiter/Clients';
import ResumeBank from 'pages/common/Resumebank';
// import Sandbox from 'Sandbox';

import UploadResume from 'pages/common/Resumebank/UploadResume';

import NoPageFound from 'pages/common/error/noPageFound';

const AppRoutes = () => {
  const routeLink = localStorage.getItem('scannerURL');
  const qrUrl = routeLink?.split('/')?.slice(3).join('/');
  console.log(qrUrl);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/'>
            <CheckAuthPage />
          </Route>
          <Route path='/auth'>
            <AuthRoutes />
          </Route>
          <Route path='/plans'>
            <Plans />
          </Route>
          {/* <Route path='/sandbox'>
            <Sandbox />
          </Route> */}

          <Route path='/schedule-demo'>
            <Demopage />
          </Route>
          <ProtectedRoute path='/profile' component={ProfileRoutes} />
          <ProtectedRoute path='/helpSupport' component={HelpSupport} />
          <ProtectedRoute path='/teams' component={TeamsPage} />
          <ProtectedRoute path='/resume-bank' component={ResumeBank} />
          <ProtectedRoute path='/assessmentBank' component={AssessmentBank} />
          <ProtectedRoute path='/dashboard' component={Dashboard} />
          <ProtectedRoute path='/jobs' component={HRJobsPage} />
          <ProtectedRoute
            path='/applicants'
            component={HRApplicantsInterviewStages}
          />
          <ProtectedRoute
            path='/recruiter/jobs'
            component={RecruiterJobsPage}
          />
          <ProtectedRoute path='/client/jobs' component={RecruiterJobsPage} />
          <ProtectedRoute
            path='/recruiter/applicants'
            component={RecruiterApplicantsInterviewStages}
          />
          <ProtectedRoute
            path='/client/applicants'
            component={RecruiterApplicantsInterviewStages}
          />
          <ProtectedRoute
            path='/recruiter/clients'
            component={RecruterClientsPage}
          />
          <Route path='/ResumesRootFolder' component={UploadResume} />

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
