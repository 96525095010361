import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { AuthContext } from 'contexts/authContext';

import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as PlanIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
// import { ReactComponent as SupportIcon } from 'assets/icons/support.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
// import { ReactComponent as ProfileIlls } from 'assets/images/profileSidebar.svg';

const ProfileContainer = ({ children }) => {
  const { t } = useTranslation(['profileSetting']);
  const currentLanguageCode = cookies.get('i18next');
  const { authState } = useContext(AuthContext);
  const { path } = useRouteMatch();
  const { pathname, search } = useLocation();

  const removeToast = () => {
    toast.remove();
  };

  return (
    <div
      id='profile-con'
      className='lg:px-10 px-4 py-8 min-h-86vh h-86vh w-full flex items-center justify-center relative'
    >
      {authState?.is_client_admin || authState?.is_client_team ? null : (
        <div
          className={`hidden md:block rounded-lg border bg-white shadow-xl h-full w-1/5 sm:w-1/3 xl:w-1/5 max-w-14rem lg:max-w-profile-menu-card relative overflow-y-auto scroller ${
            currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
          }`}
        >
          <ul className='px-3 py-6'>
            <li className='mb-4 xl:mb-6' onClick={removeToast}>
              <Link to={`${path}/myprofile`} className='flex'>
                <div
                  className={`w-6 h-6 ${
                    currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                  }`}
                >
                  <ProfileIcon />
                </div>
                <p
                  className={`px-1 ${
                    pathname.includes('/myprofile')
                      ? 'border-b-2 border-gradient pb-0.5'
                      : ''
                  }`}
                >
                  {t('profileSetting:profile')}
                </p>
              </Link>
            </li>
            {authState.is_hiring_manager || authState.is_recruiter ? (
              <li className='flex mb-4 xl:mb-6' onClick={removeToast}>
                <Link to={`${path}/companytheme`} className='flex'>
                  <div
                    className={`w-6 h-6 ${
                      currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                    }`}
                  >
                    <CompanyIcon />
                  </div>
                  <div>
                    <p
                      className={`px-1 ${
                        pathname.includes('/companytheme')
                          ? 'border-b-2 border-gradient pb-0.5'
                          : ''
                      }`}
                    >
                      {t('profileSetting:company')}
                    </p>
                  </div>
                </Link>
              </li>
            ) : (
              <li className='flex mb-6 opacity-60'>
                <div
                  className={`w-6 h-6 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  <CompanyIcon />
                </div>
                <p> {t('profileSetting:company')}</p>
              </li>
            )}

            {authState.is_hiring_manager || authState.is_recruiter ? (
              <li className='flex mb-6' onClick={removeToast}>
                <Link to={`${path}/billing?tab=mySub`} className='flex'>
                  <div
                    className={`w-6 h-6 ${
                      currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                    }`}
                  >
                    <PlanIcon />
                  </div>
                  <div>
                    <p
                      className={`px-1 ${
                        pathname.includes('/billing')
                          ? 'border-b-2 border-gradient pb-0.5'
                          : ''
                      }`}
                    >
                      {t('profileSetting:plans_and_billing')}
                    </p>
                  </div>
                </Link>
              </li>
            ) : (
              <li className='flex mb-6 opacity-60'>
                <div
                  className={`w-6 h-6 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  <PlanIcon />
                </div>
                <p>{t('profileSetting:plans_and_billing')}</p>
              </li>
            )}

            {authState.is_hiring_manager || authState.is_recruiter ? (
              <li className='flex mb-6'>
                <Link
                  to={`${path}/settings?selected=email-account`}
                  className='flex'
                >
                  <div
                    className={`w-6 h-6 ${
                      currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                    }`}
                  >
                    <SettingsIcon />
                  </div>
                  <div>
                    <p
                      className={`px-1 ${
                        pathname.includes('/settings')
                          ? 'border-b-2 border-gradient pb-0.5'
                          : ''
                      }`}
                    >
                      {t('profileSetting:settings')}
                    </p>
                  </div>
                </Link>
              </li>
            ) : (
              <li className='flex mb-6 opacity-60'>
                <div
                  className={`w-6 h-6 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  <SettingsIcon />
                </div>
                <p>{t('profileSetting:settings')}</p>
              </li>
            )}

            {pathname.includes('/settings') ? (
              <div
                className={`${
                  currentLanguageCode === 'ar' ? 'pr-10' : 'pl-10'
                }`}
              >
                <ul>
                  <li
                    className={`flex mb-3  ${
                      search.includes('selected=email-account')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=email-account`}>
                      <p>{t('profileSetting:email_account')}</p>
                    </Link>
                  </li>
                  {/* <li
                    className={`flex mb-3  ${
                      search.includes('selected=email-parsing')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=email-parsing`}>
                      <p>{t('profileSetting:email_parsing')}</p>
                    </Link>
                  </li> */}
                  <li
                    className={`flex mb-3  ${
                      search.includes('selected=email-templates')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=email-templates`}>
                      <p>{t('profileSetting:email_templates')}</p>
                    </Link>
                  </li>
                  <li
                    className={`flex mb-3  ${
                      search.includes('selected=job-evaluation')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=job-evaluation`}>
                      <p>{t('profileSetting:job_evaluation')}</p>
                    </Link>
                  </li>
                  <li
                    className={`flex mb-3 ${
                      search.includes('selected=job-application')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=job-application`}>
                      <p>{t('profileSetting:job_application_page')}</p>
                    </Link>
                  </li>
                  <li
                    className={`flex mb-3 ${
                      search.includes('selected=job-screening')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=job-screening`}>
                      <p>{t('profileSetting:job_screening')}</p>
                    </Link>
                  </li>
                  {/*   <li
                    className={`flex space-x-4 mb-3 pl-3 border-l-2  ${
                      search.includes('selected=controlling-applicants')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    }`}
                  >
                    <Link
                      to={`${path}/settings?selected=controlling-applicants`}
                    >
                      <p>Applicant control</p>
                    </Link>
                  </li> */}
                  <li
                    className={`flex mb-3 ${
                      search.includes('selected=job-board')
                        ? 'border-gradient text-primary-rose'
                        : 'border-transparent'
                    } ${
                      currentLanguageCode === 'ar'
                        ? 'pr-3 border-r-2'
                        : 'pl-3 border-l-2'
                    }`}
                  >
                    <Link to={`${path}/settings?selected=job-board`}>
                      <p>{t('profileSetting:job_board_integration')}</p>
                    </Link>
                  </li>
                </ul>
              </div>
            ) : null}

            {/* <li className='flex space-x-4 mb-6 opacity-60 cursor-default'>
              <div className='w-6 h-6'>
                <SupportIcon />
              </div>
              <p>Help & support</p>
            </li> */}

            {authState.is_hiring_manager || authState.is_recruiter ? (
              <li className='flex mb-6'>
                <Link to={`${path}/referral`} className='flex'>
                  <div
                    className={`w-6 h-6 flex items-center justify-center ${
                      currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                    }`}
                  >
                    <WalletIcon />
                  </div>
                  <div>
                    <p
                      className={`px-1 ${
                        pathname.includes('/referral')
                          ? 'border-b-2 border-gradient pb-0.5'
                          : ''
                      }`}
                    >
                      {t('profileSetting:referral_and_wallet')}
                    </p>
                  </div>
                </Link>
              </li>
            ) : (
              <li className='flex mb-6 opacity-60'>
                <div
                  className={`w-6 h-6 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  <WalletIcon />
                </div>
                <p>{t('profileSetting:referral_and_wallet')}</p>
              </li>
            )}
          </ul>
          {/* <div className='2xl:w-1/3 w-1/4 h-auto absolute left-1/2 transform -translate-x-1/2 bottom-4'>
            <ProfileIlls />
          </div> */}
        </div>
      )}

      <div
        className={`rounded-lg border bg-white shadow-xl h-full ${
          authState?.is_client_admin || authState?.is_client_team
            ? 'w-full scroller max-h-full  overflow-x-auto overflow-y-auto pt-4'
            : 'md:w-9/12 lg:w-9/12 scroller max-h-full  overflow-x-auto overflow-y-auto  py-4'
        } w-full md:max-w-tab-profile-main-card lg:max-w-profile-main-card md:px-6 `}
      >
        {children}
      </div>
    </div>
  );
};

export default ProfileContainer;
