import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export default function NotificationProvider({ children }) {
  const [notificationCount, setNotificationCount] = useState(0);
  return (
    <NotificationContext.Provider
      value={{ notificationCount, setNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
