import React from 'react';
// import Loader from 'components/Loader';
import loadable from '@loadable/component';
import { Skeleton } from 'primereact/skeleton';
import { useQuery } from 'react-query';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import { ReactComponent as NoClientsIllus } from 'assets/images/dashboard/NoClientsIllus.svg';
import { t } from 'i18next';

const ClientsDonutPieChart = loadable(
  () => import('../Components/Charts/ClientsDonutPieChart'),
  {
    fallback: (
      <div className=' flex flex-1 flex-col'>
        <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
          <div className='flex my-4 justify-center'>
            <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
          </div>
        </div>
        <div className='flex px-6'>
          <Skeleton height='1.5rem' width='100%' />
        </div>
      </div>
    ),
  }
);

function ClientAnalytics() {
  const getClientAnalytics = useQuery(['getClientAnalytics'], async () => {
    const resData = await makeAPIRequest.get(
      `${apiEndPoints.DASHBOARD_RECRUITER_TOTAL_CLIENTS}`
    );
    return resData?.data;
  });

  return (
    <>
      <div className='w-full flex flex-col lg:w-4/12 bg-white rounded-lg border-t shadow-lg hover:shadow-xl px-6 py-4 mt-6 lg:mt-0'>
        <div className='flex justify-between items-center  w-full'>
          <div className='text-lg 2xl:text-xl font-bold pb-2'>
            {t('total_clients')}
          </div>
        </div>
        {getClientAnalytics.isLoading || getClientAnalytics.isFetching ? (
          <div className=' flex flex-1 flex-col'>
            <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
              <div className='flex my-4 justify-center'>
                <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
              </div>
            </div>
            <div className='flex px-6'>
              <Skeleton height='1.5rem' width='100%' />
            </div>
          </div>
        ) : getClientAnalytics?.data?.data?.total_clients > 0 ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <ClientsDonutPieChart chartData={getClientAnalytics?.data.data} />
          </div>
        ) : (
          <div className=' flex justify-center items-center'>
            <div className='flex h-80 mt-6 justify-center items-center flex-col'>
              <NoClientsIllus />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('no_clients_to_show')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ClientAnalytics;
