import React, { useState, useContext } from 'react';
import { useWindowSize } from 'react-use';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import queryKeys from 'reactQuery/constant';

import { Dialog } from 'primereact/dialog';
import { Slider } from 'primereact/slider';
import { InputTextarea } from 'primereact/inputtextarea';

import { ApplicantsContext } from 'contexts/applicantsContext';

import { ErrorToast } from 'services/toasterService';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

export default function InterviewSuccessfullModal({
  showSuccessInterviewModal,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['jobsAndApplicants']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const queryClient = useQueryClient();

  const location = useLocation();
  const locationPathNameArray = location.pathname.split('/');

  const [sliderValue, setSliderValue] = useState(0);
  const [feedback, setFeedback] = useState('');
  const { width, height } = useWindowSize();
  const { jobId, interviewType, applicantName, applicantId } = useParams();
  const history = useHistory();

  const header = (
    <div className='flex justify-center'>
      <p className='text-center flex-1 text-primary-rose text-2xl mt-4'>
        {t('jobsAndApplicants:interview_feedback')}
      </p>
    </div>
  );

  const closeFeedBackModal = () => {
    applicantsDispatch({
      type: 'SETINTERVIEWFEEDBACKMODALVISIBLESTATE',
      payload: false,
    });
  };

  const applicantDidnotJoinedMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.APPLICANTS_SCHEDULE_INTERVIEW,
        {
          stage: interviewType ?? locationPathNameArray[3],
          status: 'Not Taken',
          applicant_id: applicantId ?? locationPathNameArray[5],
        }
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] === (interviewType ?? locationPathNameArray[3]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[1] === true &&
            query.queryKey[2] === (applicantId ?? locationPathNameArray[5]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[1] === (applicantId ?? locationPathNameArray[5]) &&
            query.queryKey[2] === (jobId ?? locationPathNameArray[2]) &&
            query.queryKey[3] === (interviewType ?? locationPathNameArray[3]),
        });
        history.replace(
          `/applicants/${jobId ?? locationPathNameArray[2]}/${
            interviewType ?? locationPathNameArray[3]
          }/${applicantName ?? locationPathNameArray[4]}/${
            applicantId ?? locationPathNameArray[5]
          }`
        );
        applicantsDispatch({
          type: 'SETINTERVIEWFEEDBACKMODALVISIBLESTATE',
          payload: false,
        });
        setSliderValue(0);
        setFeedback('');
      },
      onError: (error) => {
        ErrorToast({ text: error?.response?.data?.message });
      },
    }
  );

  const submitCanditeFeedBackAfterInterviewMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.APPLICANTS_ADD_FEEDBACK_AFTER_INTERVIEW,
        data
      );
      return res.data;
    }
  );

  const handelSubmitFeedBack = () => {
    const queryData = {
      applicant_id: applicantId ?? locationPathNameArray[5],
      stage: interviewType ?? locationPathNameArray[3],
      comment: feedback,
      rating: sliderValue,
    };

    submitCanditeFeedBackAfterInterviewMutation.mutate(queryData, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] === (interviewType ?? locationPathNameArray[3]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[1] === true &&
            query.queryKey[2] === (applicantId ?? locationPathNameArray[5]),
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[1] === (applicantId ?? locationPathNameArray[5]) &&
            query.queryKey[2] === (jobId ?? locationPathNameArray[2]) &&
            query.queryKey[3] === (interviewType ?? locationPathNameArray[3]),
        });
        history.replace(
          `/applicants/${jobId ?? locationPathNameArray[2]}/${
            interviewType ?? locationPathNameArray[3]
          }/${applicantName ?? locationPathNameArray[4]}/${
            applicantId ?? locationPathNameArray[5]
          }`
        );
        applicantsDispatch({
          type: 'SETINTERVIEWFEEDBACKMODALVISIBLESTATE',
          payload: false,
        });
        setSliderValue(0);
        setFeedback('');
      },
      onError: (error) => {
        ErrorToast({ text: error?.response?.data?.message });
      },
    });
  };

  return (
    <div>
      <Dialog
        header={header}
        visible={
          showSuccessInterviewModal ||
          applicantsState.is_interview_feedback_modal_visible
        }
        onHide={
          applicantsState.is_interview_feedback_modal_visible
            ? closeFeedBackModal
            : false
        }
        style={{
          borderRadius: '1rem',
        }}
        className={`w-4/5 max-w-4xl lg:w-4/6 lg:max-w-4xl rounded-lg ${
          width < '800' && height > '650'
            ? 'h-50vh'
            : width > '1000' && height > '940'
            ? 'h-60vh'
            : 'h-80vh'
        }`}
        modal
        draggable={false}
        blockScroll={true}
        closable={false}
        dismissableMask={
          applicantsState.is_interview_feedback_modal_visible
            ? closeFeedBackModal
            : false
        }
        position='center'
        resizable={false}
        appendTo='self'
      >
        <div
          className={`w-3/4 mx-auto grid grid-cols-1 place-content-around h-full text-neutrals-black ${
            width > 1000 && height > 1000 ? 'lg:w-3/4' : 'lg:w-1/2'
          }`}
        >
          <div>
            <p className='text-center text-lg font-semibold'>
              {t('jobsAndApplicants:add_a_quick_feedback_on_the_applicant')}
            </p>
          </div>
          <div>
            <div className='flex'>
              <p
                className={`w-10/12 ${
                  width > 1000 && height > 1000 ? 'mb-10' : 'mb-6'
                }`}
              >
                {t('jobsAndApplicants:rate_applicant')} :
              </p>
              <p className='w-2/12 text-primary-rose'>{sliderValue} of 10</p>
            </div>

            <Slider
              value={sliderValue}
              onChange={(e) => setSliderValue(e.value)}
              min={0}
              max={10}
            />
          </div>
          <div>
            <p className={`${width > 1000 && height > 1000 ? 'mb-8' : 'my-4'}`}>
              {t('jobsAndApplicants:comments')}
            </p>
            <InputTextarea
              value={feedback}
              rows={5}
              cols={30}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>
          <div className='flex mx-auto'>
            <button
              className={`opacity-100 ${
                currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
              }`}
              onClick={applicantDidnotJoinedMutation.mutate}
            >
              <BaseButton isLoading={applicantDidnotJoinedMutation.isLoading}>
                {t('jobsAndApplicants:applicant_not_joined')}
              </BaseButton>
            </button>
            <button
              className={`w-28 ${
                !feedback ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
              } ${currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'}`}
              onClick={handelSubmitFeedBack}
              disabled={!feedback}
            >
              <PrimaryButton
                isLoading={
                  submitCanditeFeedBackAfterInterviewMutation.isLoading
                }
              >
                {t('jobsAndApplicants:submit')}
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
