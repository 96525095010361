import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import { ReactComponent as PsyheaderBG } from 'assets/images/bg_for_psy_report_round.svg';
// import { ReactComponent as PsyheaderBG1 } from 'assets/images/bg_for_psy_report_round1.svg';
import { ReactComponent as InfoIlls } from 'assets/images/applicants/infoIlls.svg';
import { ReactComponent as RightIlls } from 'assets/images/applicants/rightIlls.svg';
import { ReactComponent as WrongIlls } from 'assets/images/applicants/wrongIlls.svg';
import { ReactComponent as PercentageIlls } from 'assets/images/applicants/percentageIlls.svg';
import SkillReportBarChart from './Charts/skillReportBarChart';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';
import Interweave from 'interweave';
import { UrlMatcher } from 'interweave-autolink';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import SkillAssessmentPdf from './skillAssessmentPdf';

import PrimaryButton from 'components/Button/primaryButton';

// import dateFormatter from 'helper/dateFormatter';
export default function SkillAssessmentReport({
  name = 'SKILL ASSESSMENT REPORT',
  showSkillAssessmentReport,
  setShowSkillAssessmentReport,
  getApplicantData,
  assesmentInfo,
  totalPercentage,
  setSkillReport,
  isSendToClient,
  skillReport,
}) {
  // console.log(assesmentInfo, 'assesmentInfo');
  const { t } = useTranslation(['jobsAndApplicants']);
  const currentLanguageCode = cookies.get('i18next');
  const [barChartData, setBarChartData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [generateAndDownload, setGenerateAndDownload] = useState(false);

  // console.log(generateAndDownload, 'generateAndDownload skill');

  const header = (
    <div
      className='relative'
      style={{
        background:
          'linear-gradient(139.06deg, #9A73E1 -0.01%, #FA67B6 100.12%)',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
      }}
    >
      <div className='z-50 flex justify-end'>
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
          onClick={() => {
            setShowSkillAssessmentReport(false);
            setGenerateAndDownload(false);
          }}
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FFF'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FFF'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>

      <div className='px-4 md:px-8 py-2'>
        <p className='text-white text-center text-xl md:text-3xl font-bold'>
          {name}
        </p>

        <div className='md:flex items-center my-4 justify-center'>
          <p
            className={`text-white text-base ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            {getApplicantData?.data?.other_info?.applicant_name}
          </p>

          <p
            className={`text-sm text-white font-normal pt-1 md:pt-0 ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            <span className='font-bold'>
              {t('jobsAndApplicants:assessment_date')}
            </span>{' '}
            :{' '}
            {/* {dateFormatter(
              getApplicantData?.Psychometric_Assessment_completion_date,
              'DD MMM YYYY'
            )} */}
            {getApplicantData?.data?.other_info?.assessment_date}
          </p>
          <p
            className={`text-sm text-white font-normal pt-1 md:pt-0 ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            <span className='font-bold'>{t('jobsAndApplicants:role')} </span>:{' '}
            {getApplicantData?.data?.other_info?.role}
          </p>
          <p
            className={`text-sm text-white font-normal pt-1 md:pt-0 ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            <span className='font-bold'>
              {t('jobsAndApplicants:total_score')}{' '}
            </span>
            : {totalPercentage && `${totalPercentage}%`}
          </p>
        </div>
      </div>

      {/* <div className='absolute bottom-16 left-32'>
        <PsyheaderBG />
      </div>

      <div className='absolute bottom-10 right-32'>
        <PsyheaderBG1 />
      </div> */}

      <div className='absolute bottom-8 left-10'>
        <svg
          width='50'
          height='40'
          viewBox='0 0 50 46'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 45.9204L0.104289 -10.5703L49.8288 16.2858L1.70853 45.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
      <div className='absolute bottom-12 left-1/4'>
        <svg
          width='50'
          height='40'
          viewBox='0 0 50 57'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 56.9204L0.104289 0.429731L49.8288 27.2858L1.70853 56.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
      <div className='absolute bottom-4 left-2/4'>
        <svg
          width='50'
          height='40'
          viewBox='0 0 50 57'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 56.9204L0.104289 0.429731L49.8288 27.2858L1.70853 56.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
      <div className='absolute bottom-8 right-10'>
        <svg
          width='50'
          height='40'
          viewBox='0 0 50 57'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 56.9204L0.104289 0.429731L49.8288 27.2858L1.70853 56.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
    </div>
  );

  useEffect(() => {
    var result = getApplicantData?.data?.data?.map((item) => ({
      name: item.folder_name,
      val: item.percentage,
    }));
    setBarChartData(result);
  }, [getApplicantData?.data?.data]);

  return (
    <div>
      <Dialog
        header={header}
        visible={showSkillAssessmentReport}
        onHide={() => {
          setShowSkillAssessmentReport(false);
          setGenerateAndDownload(false);
        }}
        style={{
          borderRadius: '1rem',
        }}
        className='w-11/12 lg:w-11/12 lg:max-w-5xl xl:max-w-6xl h-90vh rounded-lg psy_report'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
      >
        <div className='mt-8 flex flex-wrap lg:flex-nowrap'>
          <div className='flex items-center w-4 md:w-10 font-lato'>
            <p className='transform -rotate-90 text-Neutrals-gray md:text-xl'>
              {t('jobsAndApplicants:percentage')}
            </p>
          </div>
          <SkillReportBarChart
            data={barChartData}
            setImageUrl={setImageUrl}
            isSetValue={true}
            generateAndDownload={generateAndDownload}
          />
          <div className='w-auto mx-auto flex items-center text-sm text-black'>
            <div>
              <div className='flex items-center'>
                <div
                  className={`w-4 h-4 bg-primary-rose ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></div>
                <p className='leading-4'>
                  {t('jobsAndApplicants:secured_the_highest')}
                </p>
              </div>
              <div className='flex mt-4 items-center'>
                <div
                  className={`w-4 h-4 bg-Neutrals-gary-2 ${
                    currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                  }`}
                ></div>
                <p className='leading-4'>
                  {t('jobsAndApplicants:secured_the_least')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full md:w-11/12 mx-auto mt-4'>
          {getApplicantData?.data?.data?.map((item) => (
            <div key={item._id} className='mb-20'>
              <hr className='border-primary-rose border-opacity-40' />
              <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold text-black'>
                <p
                  className={`w-auto bg-white whitespace-nowrap px-2 md:text-xl font-bold ${
                    currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                  }`}
                >
                  {item.folder_name}
                </p>
                <div className='w-full flex justify-end'>
                  <p className='bg-white md:text-lg px-1'>
                    {t('jobsAndApplicants:total_score')} :{' '}
                    <span className='text-primary-rose'>
                      {item.percentage}%
                    </span>
                  </p>
                </div>
              </div>
              <div className='border shadow-lg py-8 md:py-12 px-2 md:px-6 mt-4 rounded-lg'>
                <>
                  {/* MCQ's */}
                  {item.questions.mcq_total > 0 ? (
                    <div>
                      <hr className='border-primary-rose border-opacity-30' />
                      <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                        <p
                          className={`w-auto bg-white whitespace-nowrap px-2 md:text-lg font-bold text-primary-rose ${
                            currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                          }`}
                        >
                          {t('common:mcq')}
                        </p>
                      </div>
                      <div className='md:flex justify-between space-y-4 md:space-y-0 mt-2 px-2 text-gray-2 text-base'>
                        <div className='w-full md:w-1/3 flex items-center'>
                          <InfoIlls
                            className={`px-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:no_of_questions_attempted')}{' '}
                              :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>{`${item.questions.mcq_attempted}/${item.questions.mcq_total}`}</span>
                          </div>
                        </div>
                        <div className='w-full md:w-1/3 flex md:justify-center md:items-center'>
                          <RightIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-6'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:right_answers')} :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>
                              {item.questions.mcq_correct}
                            </span>
                          </div>
                        </div>
                        <div className='flex w-full md:w-1/3 md:justify-end items-center'>
                          <WrongIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:wrong_answers')}:{' '}
                            </span>
                            <span className='font-bold text-gray-1'>
                              {item.questions.mcq_attempted -
                                item.questions.mcq_correct}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* T/F */}
                  {item.questions.tf_total > 0 ? (
                    <div className='mt-12 md:mt-16'>
                      <hr className='border-primary-rose border-opacity-30' />
                      <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                        <p
                          className={`w-auto bg-white whitespace-nowrap px-2 text-lg font-bold text-primary-rose ${
                            currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                          }`}
                        >
                          {t('common:t/f')}
                        </p>
                      </div>
                      <div className='md:flex justify-between mt-2 px-2 text-gray-2 text-base space-y-4 md:space-y-0'>
                        <div className='w-full md:w-1/3 flex items-center'>
                          <InfoIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:no_of_questions_attempted')}{' '}
                              :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>{`${item.questions.tf_attempted}/${item.questions.tf_total}`}</span>
                          </div>
                        </div>
                        <div className='w-full md:w-1/3 flex md:justify-center items-center'>
                          <RightIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:right_answers')} :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>
                              {item.questions.tf_correct}
                            </span>
                          </div>
                        </div>
                        <div className='flex w-full md:w-1/3 md:justify-end items-center'>
                          <WrongIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:wrong_answers')} :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>
                              {item.questions.tf_attempted -
                                item.questions.tf_correct}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* SAQ's */}
                  {item.questions.saq_total ? (
                    <div className='mt-12 md:mt-16'>
                      <hr className='border-primary-rose border-opacity-30' />
                      <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                        <p
                          className={`w-auto bg-white whitespace-nowrap px-2 text-lg font-bold text-primary-rose ${
                            currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                          }`}
                        >
                          {t('common:saq')}
                        </p>
                      </div>
                      <div className='md:flex justify-between mt-2 px-2 text-gray-2 text-base space-y-4 md:space-y-0'>
                        <div className='w-full md:w-1/3 flex items-center'>
                          <InfoIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>
                              {t('jobsAndApplicants:no_of_questions_attempted')}{' '}
                              :{' '}
                            </span>
                            <span className='font-bold text-gray-1'>{`${item.questions.saq_attempted}/${item.questions.saq_total}`}</span>
                          </div>
                        </div>
                        <div className='flex w-full md:w-1/3 md:justify-center items-center'>
                          <PercentageIlls
                            className={`p-1 ${
                              currentLanguageCode === 'ar'
                                ? 'xl:ml-1'
                                : 'xl:mr-1'
                            }`}
                          />
                          <div className='flex justify-between md:justify-start w-full md:w-auto'>
                            <span>{t('jobsAndApplicants:total_score')} : </span>
                            <span className='font-bold text-gray-1'>
                              {item.questions.saq_rating * 10}
                            </span>
                          </div>
                        </div>
                        <div className='flex w-1/3 justify-center'></div>
                      </div>
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          ))}
          <div className='border shadow-lg py-8 md:py-12 px-2 md:px-6 mt-4 rounded-lg'>
            <>
              {assesmentInfo?.data?.data?.questions?.MCQ.length > 0 ? (
                <div>
                  <hr className='border-primary-rose border-opacity-30' />
                  <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                    <p
                      className={`w-auto bg-white whitespace-nowrap px-2 md:text-lg font-bold text-primary-rose ${
                        currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                      }`}
                    >
                      {t('common:mcq')}
                    </p>
                  </div>
                  <div className=''>
                    <ul className='flex flex-col  divide-y px-4'>
                      {assesmentInfo?.data?.data?.questions?.MCQ?.map(
                        (question, index) => {
                          return (
                            <li className='py-3' key={question._id}>
                              <div>
                                <div className='flex'>
                                  <div
                                    className={`font-bold ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  >
                                    {index + 1}.
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={getSanitizeMarkupData(
                                      {
                                        markup: question?.name,
                                        renderHTML: true,
                                      }
                                    )}
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  ></div>
                                </div>

                                <div className='mt-2 flex justify-between items-center'>
                                  {/* <div className='flex space-x-1'>
                                    <p className='font-bold'>Ans:</p>

                                    {question?.app_answer ===
                                    'unatt$mpthrq!' ? (
                                      <p>No answer given by the applicant</p>
                                    ) : (
                                      <div
                                        dangerouslySetInnerHTML={getSanitizeMarkupData(
                                          {
                                            markup: question?.app_answer,
                                            renderHTML: true,
                                          }
                                        )}
                                      ></div>
                                    )}
                                  </div> */}

                                  <div className='flex flex-col '>
                                    {/* <div className='flex'>
                                      <p className='font-bold'>(A)</p>

                                      {question?.app_answer ===
                                      'unatt$mpthrq!' ? (
                                        <p>No answer given by the applicant</p>
                                      ) : (
                                        <div>{question?.app_answer}</div>
                                      )}
                                    </div> */}

                                    {question?.mcq_options?.map(
                                      (option, index) => (
                                        <div
                                          className={`flex ${
                                            option === question.app_answer &&
                                            !question?.a_answer_corr
                                              ? 'text-red-600'
                                              : ''
                                          } ${
                                            option === question.app_answer &&
                                            option === question?.r_answer
                                              ? 'text-primary-green'
                                              : !question?.a_answer_corr &&
                                                option === question?.r_answer
                                              ? 'text-primary-green'
                                              : ''
                                          } `}
                                        >
                                          <span
                                            className={`${
                                              currentLanguageCode === 'ar'
                                                ? 'ml-2'
                                                : 'mr-2'
                                            }`}
                                          >
                                            {index === 0
                                              ? 'A.'
                                              : index === 1
                                              ? 'B.'
                                              : index === 2
                                              ? 'C.'
                                              : index === 3
                                              ? 'D.'
                                              : null}
                                          </span>
                                          <div>{option}</div>
                                        </div>
                                      )
                                    )}
                                  </div>

                                  {question?.app_answer ===
                                  'unatt$mpthrq!' ? null : (
                                    <>
                                      {' '}
                                      {question?.a_answer_corr ? (
                                        <svg
                                          width='20'
                                          height='20'
                                          viewBox='0 0 30 22'
                                          fill='none'
                                          xmlns='https://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M28.971 0.609505C28.7794 0.416373 28.5515 0.26308 28.3004 0.158469C28.0493 0.0538584 27.78 0 27.508 0C27.236 0 26.9666 0.0538584 26.7156 0.158469C26.4645 0.26308 26.2366 0.416373 26.045 0.609505L10.694 15.9811L4.24448 9.51104C4.04559 9.31892 3.81081 9.16785 3.55354 9.06647C3.29627 8.96508 3.02155 8.91536 2.74507 8.92014C2.46858 8.92493 2.19575 8.98412 1.94214 9.09435C1.68853 9.20457 1.45912 9.36367 1.267 9.56256C1.07487 9.76144 0.923808 9.99622 0.822421 10.2535C0.721035 10.5108 0.671313 10.7855 0.676096 11.062C0.68088 11.3385 0.740075 11.6113 0.8503 11.8649C0.960526 12.1185 1.11962 12.3479 1.31851 12.54L9.23099 20.4525C9.42254 20.6456 9.65044 20.7989 9.90154 20.9036C10.1526 21.0082 10.422 21.062 10.694 21.062C10.966 21.062 11.2353 21.0082 11.4864 20.9036C11.7375 20.7989 11.9654 20.6456 12.157 20.4525L28.971 3.6385C29.1801 3.44555 29.347 3.21136 29.4612 2.95071C29.5754 2.69005 29.6343 2.40857 29.6343 2.124C29.6343 1.83944 29.5754 1.55796 29.4612 1.2973C29.347 1.03664 29.1801 0.802458 28.971 0.609505Z'
                                            fill='#92C848'
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width='20'
                                          height='20'
                                          viewBox='0 0 19 19'
                                          fill='none'
                                          xmlns='https://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M3.6543 3.25293L15.6891 15.9919'
                                            stroke='#EB5757'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                          <path
                                            d='M15.7773 3.2373L3.51948 15.7618'
                                            stroke='#EB5757'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                        </svg>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
              {/* T/F */}
              {assesmentInfo?.data?.data?.questions?.TF.length > 0 ? (
                <div className='mt-12 md:mt-16'>
                  <hr className='border-primary-rose border-opacity-30' />
                  <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                    <p
                      className={`w-auto bg-white whitespace-nowrap px-2 text-lg font-bold text-primary-rose ${
                        currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                      }`}
                    >
                      {t('common:t/f')}
                    </p>
                  </div>
                  <div className=' '>
                    <ul className='flex flex-col  divide-y px-4'>
                      {assesmentInfo?.data?.data?.questions?.TF?.map(
                        (question, index) => {
                          return (
                            <li className='py-3' key={question._id}>
                              <div>
                                <div className='flex'>
                                  <div
                                    className={`font-bold ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  >
                                    {index + 1}.
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={getSanitizeMarkupData(
                                      {
                                        markup: question?.name,
                                        renderHTML: true,
                                      }
                                    )}
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  ></div>
                                </div>

                                <div className='mt-2 flex justify-between items-center'>
                                  <div className='flex'>
                                    <p
                                      className={`font-bold ${
                                        currentLanguageCode === 'ar'
                                          ? 'ml-1'
                                          : 'mr-1'
                                      }`}
                                    >
                                      {t('common:answer')}:
                                    </p>
                                    <div
                                      className={`${
                                        currentLanguageCode === 'ar'
                                          ? 'ml-1'
                                          : 'mr-1'
                                      }`}
                                    >
                                      {question?.app_answer ===
                                      'unatt$mpthrq!' ? (
                                        'No answer given by the applicant'
                                      ) : (
                                        <>
                                          {' '}
                                          {question?.app_answer &&
                                            question?.app_answer?.toString()}
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {question?.app_answer ===
                                  'unatt$mpthrq!' ? null : (
                                    <>
                                      {' '}
                                      {question?.a_answer_corr ? (
                                        <svg
                                          width='20'
                                          height='20'
                                          viewBox='0 0 30 22'
                                          fill='none'
                                          xmlns='https://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M28.971 0.609505C28.7794 0.416373 28.5515 0.26308 28.3004 0.158469C28.0493 0.0538584 27.78 0 27.508 0C27.236 0 26.9666 0.0538584 26.7156 0.158469C26.4645 0.26308 26.2366 0.416373 26.045 0.609505L10.694 15.9811L4.24448 9.51104C4.04559 9.31892 3.81081 9.16785 3.55354 9.06647C3.29627 8.96508 3.02155 8.91536 2.74507 8.92014C2.46858 8.92493 2.19575 8.98412 1.94214 9.09435C1.68853 9.20457 1.45912 9.36367 1.267 9.56256C1.07487 9.76144 0.923808 9.99622 0.822421 10.2535C0.721035 10.5108 0.671313 10.7855 0.676096 11.062C0.68088 11.3385 0.740075 11.6113 0.8503 11.8649C0.960526 12.1185 1.11962 12.3479 1.31851 12.54L9.23099 20.4525C9.42254 20.6456 9.65044 20.7989 9.90154 20.9036C10.1526 21.0082 10.422 21.062 10.694 21.062C10.966 21.062 11.2353 21.0082 11.4864 20.9036C11.7375 20.7989 11.9654 20.6456 12.157 20.4525L28.971 3.6385C29.1801 3.44555 29.347 3.21136 29.4612 2.95071C29.5754 2.69005 29.6343 2.40857 29.6343 2.124C29.6343 1.83944 29.5754 1.55796 29.4612 1.2973C29.347 1.03664 29.1801 0.802458 28.971 0.609505Z'
                                            fill='#92C848'
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width='20'
                                          height='20'
                                          viewBox='0 0 19 19'
                                          fill='none'
                                          xmlns='https://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M3.6543 3.25293L15.6891 15.9919'
                                            stroke='#EB5757'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                          <path
                                            d='M15.7773 3.2373L3.51948 15.7618'
                                            stroke='#EB5757'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                        </svg>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
              {/* SAQ's */}
              {assesmentInfo?.data?.data?.questions?.SAQ.length > 0 ? (
                <div className='mt-12 md:mt-16'>
                  <hr className='border-primary-rose border-opacity-30' />
                  <div className='flex transform -translate-y-1/2 -mt-0.5 font-bold'>
                    <p
                      className={`w-auto bg-white whitespace-nowrap px-2 text-lg font-bold text-primary-rose ${
                        currentLanguageCode === 'ar' ? 'md:mr-4' : 'md:ml-4'
                      }`}
                    >
                      {t('common:saq')}
                    </p>
                  </div>
                  <div className=' '>
                    <ul className='flex flex-col  divide-y px-4'>
                      {assesmentInfo?.data?.data?.questions?.SAQ?.map(
                        (question, index) => {
                          return (
                            <li className='py-3' key={question._id}>
                              <div>
                                <div className='flex'>
                                  <div
                                    className={`font-bold ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  >
                                    {index + 1}.
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={getSanitizeMarkupData(
                                      {
                                        markup: question?.name,
                                        renderHTML: true,
                                      }
                                    )}
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  ></div>
                                </div>

                                <div className='my-8'>
                                  <p className='text-primary-rose mb-1'>
                                    {t('jobsAndApplicants:answer_full')} :
                                  </p>
                                  {question?.r_answer === 'None' ? (
                                    <div>
                                      {t(
                                        'jobsAndApplicants:no_answer_given_by_the_applicant'
                                      )}
                                    </div>
                                  ) : (
                                    <div className='prose'>
                                      <Interweave
                                        content={question?.r_answer}
                                        matchers={[new UrlMatcher('url')]}
                                      />
                                    </div>
                                  )}
                                </div>

                                {/* question */}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
            </>
          </div>
        </div>
        <div className='mt-8 md:mt-12 w-full md:w-11/12 md:mx-auto text-A4A4A4 text-tiny md:text-xs'>
          <hr />
          <div className='w-full flex mt-2'>
            <p className='w-full'>
              &copy; {dayjs().year()}. All rights reserved.
            </p>
            {/* <p className='w-full flex justify-center'>flitc.ai</p> */}
            <p className='w-full flex justify-end'>
              {t('common:powered_by_smart_hire')}
            </p>
          </div>
        </div>
        <div className='flex w-full justify-center mt-6'>
          <button
            onClick={() => setGenerateAndDownload(true)}
            className='w-40 mx-4'
          >
            <PrimaryButton isLoading={generateAndDownload && !imageUrl}>
              <p className='text-sm leading-5'>
                {t('jobsAndApplicants:generate_report')}
              </p>
            </PrimaryButton>
          </button>
          <PDFDownloadLink
            document={
              <SkillAssessmentPdf
                getApplicantData={getApplicantData}
                totalPercentage={totalPercentage}
                imageUrl={imageUrl}
                assesmentInfo={assesmentInfo}
              />
            }
            fileName='skill_report.pdf'
            className={`${!imageUrl ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            {({ blob, url, loading, error }) => {
              if (isSendToClient && blob && !skillReport) {
                setSkillReport(blob);
                setShowSkillAssessmentReport(false);
              }

              return isSendToClient ? null : (
                <button className='w-40'>
                  <PrimaryButton>
                    <p className='text-sm leading-5'>
                      {t('jobsAndApplicants:download_report')}
                    </p>
                  </PrimaryButton>
                </button>
              );
            }}
          </PDFDownloadLink>
        </div>
      </Dialog>
    </div>
  );
}
