import React, { useState, useEffect } from 'react';
import { useCurrentPng } from 'recharts-to-png';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  // Label,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Cell,
} from 'recharts';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <Text
      x={x}
      y={y}
      width={100}
      fontSize='14'
      textAnchor='middle'
      verticalAnchor='start'
    >
      {payload.value}
    </Text>
  );
};

// tooltip custom style
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='bg-white w-40 p-2 border h-auto'>
        <p className='font-semibold'>{`${label}`}</p>
        <p className='text-primary-rose font-bold'>{`Val : ${payload[0].value}%`}</p>
      </div>
    );
  }

  return null;
};

// custom label
const CustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  return (
    <text
      x={x + width / 2}
      y={y - 10}
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {`${value}%`}
    </text>
  );
};

export default function SkillReportBarChart({
  data,
  setImageUrl,
  isSetValue = false,
  generateAndDownload,
}) {
  const [heighestValue, setHeighestValue] = useState();
  const [lowestValue, setLowestValue] = useState();

  const [getAreaPng, { ref }] = useCurrentPng();

  useEffect(() => {
    const barchartValues = data?.map((val) => val.val);
    barchartValues?.sort(function (a, b) {
      return b - a;
    });
    barchartValues && setHeighestValue(barchartValues[0]);
    barchartValues && setLowestValue(barchartValues[barchartValues.length - 1]);
  }, [data]);

  useEffect(() => {
    if (isSetValue && generateAndDownload) {
      // setTimeout(() => {
      //   console.time();
      //   getAreaPng().then((response) => {
      //     console.log(response, 'Image response');
      //     setImageUrl(response);
      //   });
      //   console.timeEnd();
      // }, 1000);
      getAreaPng().then((response) => {
        setImageUrl(response);
      });
    }
  }, [getAreaPng, setImageUrl, isSetValue, generateAndDownload]);

  return (
    <div
      style={{ width: '80%', height: 400 }}
      className=' bg-white mt-4 mb-4 py-2'
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          ref={ref}
          margin={{
            top: 40,
            right: 30,
            left: 5,
            bottom: 70,
          }}
        >
          <XAxis
            dataKey='name'
            axisLine={false}
            textAnchor='end'
            sclaeToFit='true'
            verticalAnchor='start'
            interval={0}
            angle='-40'
            orientation='bottom'
            tickLine={false}
            tickMargin={15}
            style={{ fill: '#FF009C' }}
            tick={<CustomizedAxisTick />}
          />
          <YAxis interval='preserveStartEnd' />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke='#FF009C' />
          <Bar dataKey='val' barSize={50}>
            {data?.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={`${
                    entry.val === heighestValue
                      ? '#FF009C'
                      : entry.val === lowestValue
                      ? '#858585'
                      : '#E5E5E5'
                  }`}
                />
              );
            })}
            <LabelList
              dataKey='val'
              position='top'
              content={<CustomizedLabel />}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
