import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import LottiLoader from 'components/LottiLoader';

import JobAddFormProvider from 'contexts/JobAddFormContext';

import UseJobStagelabelName from 'hooks/useJobStageLabel';

import BasePrivateLayout from '../../basePrivateLayout';
import NoPageFound from 'pages/common/error/noPageFound';
import ComponentLevelErrorBoundary from 'pages/common/error/CompnentLevelErrorBoundary';

import 'pages/common/Jobs/jobs.styles.scss';

const JobsListingsPage = lazy(() => import('./jobListingsPage'));
const JobAddPage = lazy(() => import('./JobAddPage'));
const JobOverviewpage = lazy(() =>
  import('pages/common/Jobs/pages/jobOverviewPage')
);

const JobsPage = () => {
  const { path } = useRouteMatch();

  // eslint-disable-next-line no-unused-vars
  const hh = UseJobStagelabelName({ enable: true });

  return (
    <BasePrivateLayout>
      <JobAddFormProvider>
        <ComponentLevelErrorBoundary>
          <Suspense
            fallback={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='max-w-main-page h-70vh flex justify-center items-center '>
                  <LottiLoader />
                </div>
              </div>
            }
          >
            <Switch>
              <Redirect exact from={path} to={`${path}/listings`} />
              <Route path={`${path}/listings`} component={JobsListingsPage} />
              <Route path={`${path}/drafts`} component={JobsListingsPage} />
              <Route path={`${path}/add`} component={JobAddPage} />
              <Route path={`${path}/edit`} component={JobAddPage} />
              <Route
                path={`${path}/overview/:jobId`}
                component={JobOverviewpage}
              />

              {/* DO NOT CHNAGE this empty route position Add everything above this line */}
              <Route component={NoPageFound} />
            </Switch>
          </Suspense>
        </ComponentLevelErrorBoundary>
      </JobAddFormProvider>
    </BasePrivateLayout>
  );
};

export default JobsPage;
