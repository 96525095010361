import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';

import { ApplicantsContext } from 'contexts/applicantsContext';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';

import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';
import getModifiedStagename from 'helper/getModifiedStagename';

import queryKeys from 'reactQuery/constant';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

const ConfirmMoveApplicantModal = ({
  currentStage,
  nextStage,
  applicantID,
  jobId,
}) => {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['common']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const pathNameArray = location.pathname.split('/');

  const moveCandidatetonextStage = useMutation(async (data) => {
    const res = await makeAPIRequest.put(apiEndPoints.JOB_APPLICANTS, data);
    return res.data;
  });

  const handelMoveCandididateToNextStage = () => {
    const queryData = {
      stages: {
        [currentStage]: false,
        [nextStage]: true,
      },
      applicants: [applicantID],
    };
    moveCandidatetonextStage.mutate(queryData, {
      onSuccess: () => {
        handelCloseConfirmMoveModal();
        handelOpenNextStageModal();

        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] === nextStage,
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.allApplicants &&
            query.queryKey[2] === getJobStageNameForBackendQuery(currentStage),
        });

        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[2] === applicantID,
        });
        queryClient.invalidateQueries(queryKeys.allJobs);
        setTimeout(() => {
          applicantsDispatch({
            type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
            payload: false,
          });
          history.push(
            `/applicants/${jobId}/${nextStage}/${pathNameArray[4]}/${pathNameArray[5]}`
          );
        }, 2000);
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    });
  };

  const handelCloseConfirmMoveModal = () => {
    applicantsDispatch({
      type: 'SETCONFIRMMOVEAPPLICANTMODALVISIBLESTATE',
      payload: false,
    });
  };

  const handelOpenNextStageModal = () => {
    applicantsDispatch({
      type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
      payload: true,
    });
  };

  const header = (
    <div className='relative'>
      {/* <p className='text-xl text-center tracking-wide text-black mt-8'>
        Do you want to progress the CV from{' '}
        {/* <span className='text-primary-rose'>move</span> the candidate ? */}
      {/* </p> */}
      <p className='text-base font-normal text-center mt-6'>
        Do you want to progress the CV from{' '}
        <span className='font-bold'>{getModifiedStagename(currentStage, t)}</span>{' '}
        to <span className='font-bold'>{getModifiedStagename(nextStage, t)}</span>
      </p>

      <button
        className='absolute -top-8 right-0'
        onClick={handelCloseConfirmMoveModal}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  return (
    <div>
      <Dialog
        visible={applicantsState.is_confirm_move_applicant_modal_visble}
        onHide={handelCloseConfirmMoveModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        className={`w-10/12 md:w-9/12 xl:w-1/2 mx-auto rounded-xl md:min-h-40vh`}
      >
        <div className='relative'>
          <div className='flex justify-center md:mb-6 md:mt-8'>
            <div className={`${currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'}`}>
              <div className='flex justify-center items-center md:mt-4'>
                <button
                  className='w-28 mx-2 md:mx-3'
                  onClick={handelMoveCandididateToNextStage}
                >
                  <PrimaryButton isLoading={moveCandidatetonextStage.isLoading}>
                    <p className='text-base leading-5'>{t('common:yes')}</p>
                  </PrimaryButton>
                </button>
                <button className='w-28 mx-2 md:mx-3' onClick={handelCloseConfirmMoveModal}>
                  <BaseButton>
                    <p className='text-base leading-5'>{t('common:no')}</p>
                  </BaseButton>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ConfirmMoveApplicantModal;
