import React, { useState } from 'react';
import loadable from '@loadable/component';

import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

// import { AuthContext } from 'contexts/authContext';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import { ReactComponent as SearchIcon } from 'assets/images/team/searchIcon.svg';
import { ReactComponent as NoJobsIllus } from 'assets/images/dashboard/NoJobsIllus.svg';
// import { useHistory } from 'react-router-dom';

const JobStageInfoStackedBarChart = loadable(
  () => import('../Components/Charts/JobStageInfoStackedBarChart'),
  {
    fallback: (
      <div className='mt-auto w-full pr-6'>
        <div className='flex my-4'>
          <Skeleton className='mx-4 mt-1' height='1.25rem' width='6rem' />
          <Skeleton height='3rem' width='80%' />
        </div>
        <div className='flex my-4'>
          <Skeleton className='mx-4 mt-1' height='1.25rem' width='6rem' />
          <Skeleton height='3rem' width='35%' />
        </div>
        <div className='flex my-4'>
          <Skeleton className='mx-4 mt-1' height='1.25rem' width='6rem' />
          <Skeleton height='3rem' width='65%' />
        </div>
        <div className='flex my-4'>
          <Skeleton className='mx-4 mt-1' height='1.25rem' width='6rem' />
          <Skeleton height='3rem' width='50%' />
        </div>

        <div className='flex mb-8'></div>
      </div>
    ),
  }
);

function JobAnalytics({ token, is_client_admin }) {
  // const history = useHistory();
  const [searchFieldValue, setSearchFieldValue] = useState();

  const { t } = useTranslation(['dashboard', 'common']);
  const currentLanguageCode = cookies.get('i18next');

  // const { authState } = useContext(AuthContext);

  const Psychometric_Assessment_Name =
    JSON.parse(localStorage.getItem('psychometric_stage_name')) ??
    'Psychometric Assessment';

  const dashboardActiveJobAnalytics = useQuery(
    ['dashboardActiveJobAnalytics', searchFieldValue],
    async () => {
      let resData;
      if (is_client_admin) {
        if (searchFieldValue?.length) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_CLIENT_ACTIVE_JOBS}?token=${token}&search=${searchFieldValue}`
          );
          return resData;
        } else if (!searchFieldValue) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_CLIENT_ACTIVE_JOBS}?token=${token}`
          );
          return resData;
        }
      } else {
        if (searchFieldValue?.length) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_ANALYTICS}?search=${searchFieldValue}`
          );
          return resData;
        } else if (!searchFieldValue) {
          resData = await makeAPIRequest.get(
            `${apiEndPoints.DASHBOARD_ANALYTICS}`
          );
          return resData;
        }
      }
    },
    { staleTime: 3000 }
  );

  return (
    <div
      className={`flex flex-col w-full lg:w-8/12 lg:mb-0   ${
        currentLanguageCode === 'ar' ? 'lg:ml-6' : 'lg:mr-6'
      }`}
    >
      <div className='text-lg 2xl:text-xl font-bold md:hidden mb-4 text-midnightBlue'>
        {t('dashboard:active_jobs')}
      </div>
      <div className='flex flex-col w-full bg-white rounded-lg border-t shadow-lg hover:shadow-xl h-full py-4 px-2 sm:px-6'>
        <div className='flex  justify-between items-center  w-full'>
          <div className='text-lg 2xl:text-xl font-bold hidden md:block text-midnightBlue'>
            {t('dashboard:active_jobs')}
          </div>
          <div className='flex items-center'>
            {/* {authState.is_client_admin || authState.is_client_team ? null : (
              <button
                className='text-primary-rose mr-8 opacity-80 hover:underline cursor-pointer md:flex hidden'
                onClick={() => {
                  history.push('/dashboard/job-analytics');
                }}
              >
                Job Analytics
              </button>
            )} */}

            {/* <div
                onClick={() => {
                  history.push('/jobs/listings?type=Activated');
                }}
                className='flex text-primary-rose mr-8 cursor-pointer hover:underline'
              >
                See Active Jobs
              </div> */}
            <div className='searchFunctionality hidden md:block'>
              <div className='flex'>
                <span className='sortIcon flex items-center'>
                  <SearchIcon />
                </span>

                <span className=''>
                  <InputText
                    id='searchField'
                    value={searchFieldValue}
                    onChange={(e) => setSearchFieldValue(e.target.value)}
                    placeholder={t('common:search')}
                    className={`${
                      currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                    }`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {dashboardActiveJobAnalytics.isLoading ||
        dashboardActiveJobAnalytics.isFetching ? (
          <div
            className={`flex h-80 max-h-80 w-full mt-6 scroller overflow-y-scroll ${
              currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
            }`}
          >
            <div
              className={`mt-auto w-full sm:w-3/4  ${
                currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'
              }`}
            >
              <div className='flex my-4'>
                <Skeleton
                  className={`mt-1 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                  height='1.25rem'
                  width='6rem'
                />
                <Skeleton height='3rem' width='80%' />
              </div>
              <div className='flex my-4'>
                <Skeleton
                  className={`mt-1 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                  height='1.25rem'
                  width='6rem'
                />
                <Skeleton height='3rem' width='35%' />
              </div>
              <div className='flex my-4'>
                <Skeleton
                  className={`mt-1 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                  height='1.25rem'
                  width='6rem'
                />
                <Skeleton height='3rem' width='65%' />
              </div>
              <div className='flex my-4 mt-1'>
                <Skeleton
                  className={`mt-1 ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                  height='1.25rem'
                  width='6rem'
                />
                <Skeleton height='3rem' width='50%' />
              </div>

              <div className='flex mb-8'></div>
            </div>
            <div className='sm:flex flex-col w-1/4 text-sm hidden mt-6'>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='7rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='9rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='10rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='7rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='5rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='7.5rem' />
              </div>
              <div className='flex my-2'>
                <span
                  className={`w-4 h-4 rounded  ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1rem' width='1rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='8.5rem' />
              </div>
            </div>
          </div>
        ) : dashboardActiveJobAnalytics?.data?.data?.data?.length ? (
          <div
            className={`flex flex-wrap h-80 max-h-80 w-full mt-6 scroller overflow-y-scroll  ${
              currentLanguageCode === 'ar' ? 'pl-2 ' : 'pr-2 '
            }`}
          >
            <div
              className={`mt-auto w-full md:w-3/4  ${
                currentLanguageCode === 'ar' ? 'md:pl-6' : 'md:pr-6'
              }`}
            >
              <JobStageInfoStackedBarChart
                chartData={dashboardActiveJobAnalytics?.data?.data?.data?.map(
                  (job) => {
                    return {
                      'CV Screening': job?.CV_Screening_count,
                      'Skill Assessment': job?.Skill_Assessment_count,
                      [Psychometric_Assessment_Name]:
                        job?.Psychometric_Assessment_count,
                      'Pre-hire Assessment': job?.Holistic_Assessment_count,
                      Interview: job?.Technical_Interview_count,
                      HR: job?.HR_Interview_count,
                      Hired: job?.hired_count,
                      jobtitle: job?.jobtitle,
                    };
                  }
                )}
                height={
                  dashboardActiveJobAnalytics?.data?.data?.data?.length > 5
                    ? dashboardActiveJobAnalytics?.data?.data?.data?.length * 70
                    : dashboardActiveJobAnalytics?.data?.data?.data?.length > 3
                    ? dashboardActiveJobAnalytics?.data?.data?.data?.length * 80
                    : dashboardActiveJobAnalytics?.data?.data?.data?.length > 2
                    ? dashboardActiveJobAnalytics?.data?.data?.data?.length *
                      100
                    : dashboardActiveJobAnalytics?.data?.data?.data?.length > 0
                    ? dashboardActiveJobAnalytics?.data?.data?.data?.length *
                      150
                    : 350
                }
              />
            </div>
            <div
              className={`flex md:pt-6 flex-col md:w-1/4 text-xs  ${
                currentLanguageCode === 'ar' ? 'mr-4 sm:mr-0' : 'ml-4 sm:ml-0'
              }`}
            >
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-1 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:cv_screening')}
              </div>
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-2 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:skill_assessment')}
              </div>
              <div className='flex my-3 '>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-3 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {Psychometric_Assessment_Name}
              </div>
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-4 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:pre_hire_assessment')}
              </div>
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-5 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:interview')}
              </div>
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-primary-rose-shade-6 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:hr')}
              </div>
              <div className='flex my-3'>
                <span
                  className={`w-4 h-4 rounded bg-Neutrals-gray-1 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                ></span>{' '}
                {t('common:hired')}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`h-80 max-h-80 w-full flex mt-6 flex-col justify-center items-center  ${
              currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2 '
            }`}
          >
            <div className='flex flex-1 justify-center items-center flex-col'>
              <NoJobsIllus />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('dashboard:no_active_jobs')}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobAnalytics;
