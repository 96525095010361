import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'contexts/authContext';

import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

import queryKeys from '../reactQuery/constant';

export default function UseSpecificApplicant({
  enable = true,
  applicantID,
  setJobStatus,
}) {
  const { authState } = useContext(AuthContext);
  return useQuery(
    [queryKeys.specificApplicant, enable, applicantID],
    async () => {
      let url = `${
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_JOB_APPLICANTS
          : apiEndPoints.JOB_APPLICANTS
      }?_id=${applicantID}${
        authState.is_client_admin || authState.is_client_team
          ? `&token=${authState.token_client}`
          : ''
      }`;

      const { data } = await makeAPIRequest.get(url);
      return data;
    },
    {
      staleTime: 180000,
      enabled: enable,
      onSuccess: (data) => {
        if (setJobStatus) {
          setJobStatus(data.data[0].job_status === 'Deactivated');
        }
      },
    }
  );
}
