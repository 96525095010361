import { useMutation } from 'react-query';
import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

export default function useUpdateDetails(setSuccessModal) {
  return useMutation(
    async (data) => {
      const response = await makeAPIRequest.post(
        apiEndPoints.APPLICANT_DETAILS,
        data
      );

      return response?.data;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSuccessModal(true);
      },
      onError: (data) => {
        setSuccessModal(true);
      },
    }
  );
}
