import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { ErrorToast } from 'services/toasterService';
// import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
// import { Popover, Transition } from '@headlessui/react';
// import { ReactComponent as EditIcon } from 'assets/images/dashboard/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/dashboard/DeleteIcon.svg';
// import { ReactComponent as OptionsIcon } from 'assets/images/dashboard/OptionsIcon.svg';

function ActivitySingle({ activity, updateActivity, deleteActivity }) {
  const [checkboxValue, setCheckboxValue] = useState(activity.completed);
  // const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  const completed = (data) => {
    updateActivity.mutate(data, {
      // onSuccess: (resdata) => {},
      onError: (err) => {
        setCheckboxValue(!data.completed);
        ErrorToast({ text: err.response.data.message });
      },
    });
  };
  const deleteActivityFn = (data) => {
    deleteActivity.mutate(data, {
      // onSuccess: (resdata) => {},
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    });
  };

  useEffect(() => {
    setCheckboxValue(activity.completed);
  }, [activity.completed]);

  return (
    <div
      className={`flex w-full  my-4  ${
        currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
      }`}
    >
      <div
        className={`p-field-checkbox  flex items-start  ${
          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
        }`}
      >
        <Checkbox
          checked={checkboxValue}
          onChange={(event) => {
            setCheckboxValue(event.checked);
            completed({ completed: event.checked, id: activity.id });
          }}
        />
      </div>
      <div
        className={`text-sm flex-1 text-9A9A9A mx-2 ${
          activity.completed ? 'line-through' : ''
        }`}
      >
        {activity?.activity}
      </div>
      <div>
        <div
          onClick={() => {
            deleteActivityFn({ activity_ids: [activity.id] });
          }}
          className='relative bg-white text-sm flex justify-start cursor-pointer'
        >
          <div className='mt-0.5'>
            <span>
              <DeleteIcon className='w-4 h-5' />
            </span>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitySingle;
