import React, { useState, useContext } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { useQuery, useMutation } from 'react-query';
import { NotificationContext } from 'contexts/notificationContext';
import { AuthContext } from 'contexts/authContext';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import SingleNotification from './singleNotification';
import { ReactComponent as EmptyNotification } from 'assets/images/noNotificationIlls.svg';

const NotificationPanel = ({
  notificationVisible,
  setNotificationPanelVisible,
  setEmailSettingsPopupOpen,
}) => {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['navbar']);
  const { setNotificationCount } = useContext(NotificationContext);
  const { authState } = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState('notifications');

  const getNotificationData = useQuery(
    ['notifications-data'],
    async () => {
      const { data } = await makeAPIRequest.get(apiEndPoints.NOTIFICATIONS);
      return data;
    },
    {
      enabled:
        !!authState.is_recruiter ||
        !!authState.is_hiring_manager ||
        !!authState.is_hiring_manager_team ||
        !!authState.is_recruiter_team,
      refetchInterval: 5000,
      onSuccess: (data) => {
        setNotificationCount(data.count + data.mentions_count);
        setEmailSettingsPopupOpen(!data?.email_working);
      },
    }
  );

  const updateMarkallRead = useMutation(async () => {
    const data = await makeAPIRequest.post(apiEndPoints.NOTIFICATIONS);
    return data;
  });

  return (
    <div className='notification_overlay_container'>
      <Transition.Root show={notificationVisible} as={Fragment}>
        <Dialog
          as='div'
          auto-reopen='true'
          className='fixed inset-0 '
          style={{ zIndex: '2000' }}
          onClose={setNotificationPanelVisible}
        >
          <div className='absolute inset-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 transition-opacity notification-panel-overlay-bg' />
            </Transition.Child>
            <div
              className={`fixed inset-y-0  max-w-full flex ${
                currentLanguageCode === 'ar' ? 'pr-10 left-0' : 'pl-10 right-0'
              }`}
            >
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom={`${
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }`}
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo={`${
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }`}
              >
                <div className='relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div
                      className={`absolute -top-2 pt-4 flex ${
                        currentLanguageCode === 'ar'
                          ? 'pl-2 sm:pl-4 left-0'
                          : 'pr-2 sm:pr-4 right-0'
                      }`}
                    >
                      <button
                        className='rounded-md focus:outline-none'
                        onClick={() => setNotificationPanelVisible(false)}
                      >
                        <span className='sr-only'>
                          {t('navbar:close_panel')}
                        </span>
                        <XIcon
                          className='h-6 w-6 text-primary-rose'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='h-full py-6 bg-white shadow-xl'>
                    <div className='mt-3 relative flex-1 px-4 sm:px-6'>
                      <ul className='flex items-center text-lg'>
                        <li
                          className={`${
                            currentTab === 'notifications'
                              ? 'border-primary-rose'
                              : 'border-white'
                          } ${
                            currentLanguageCode === 'ar'
                              ? 'ml-1 ml-4 md:pl-2'
                              : 'mr-1 md:mr-4 md:pr-2'
                          } cursor-pointer pb-1 flex items-center border-b-2 select-none`}
                          onClick={() => setCurrentTab('notifications')}
                        >
                          {t('navbar:notifications')}{' '}
                          <span
                            className={`rounded-full bg-primary-rose text-white px-1 text-xs leading-4 mt-1 ${
                              currentLanguageCode === 'ar' ? 'mr-0.5' : 'ml-0.5'
                            }`}
                          >
                            {getNotificationData?.data?.count < 10
                              ? getNotificationData?.data?.count
                              : '9+'}
                          </span>
                        </li>

                        <li
                          className={`${
                            currentTab === 'mentions'
                              ? 'border-primary-rose'
                              : 'border-white'
                          } ${
                            currentLanguageCode === 'ar'
                              ? 'ml-1 md:ml-4'
                              : 'mr-1 md:mr-4'
                          } px-2 cursor-pointer pb-1 flex items-center border-b-2 select-none`}
                          onClick={() => setCurrentTab('mentions')}
                        >
                          {t('navbar:mentions')}
                          <span
                            className={`rounded-full bg-primary-rose text-white px-1 text-xs leading-4 mt-1 ${
                              currentLanguageCode === 'ar' ? 'mr-0.5' : 'ml-0.5'
                            }`}
                          >
                            {getNotificationData?.data?.mentions_count < 10
                              ? getNotificationData?.data?.mentions_count
                              : '9+'}
                          </span>
                        </li>
                      </ul>
                    </div>
                    {currentTab === 'notifications' ? (
                      <div className='px-2 md:px-4 my-4 mt-6 overflow-y-auto max-h-86vh scroller'>
                        {getNotificationData?.data?.count > 0 ? (
                          <div
                            className={`w-full text-right ${
                              currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                            }`}
                          >
                            <small
                              className='cursor-pointer'
                              onClick={() => {
                                updateMarkallRead.mutate();
                                setNotificationPanelVisible(false);
                              }}
                            >
                              {t('navbar:mark_all_as_read')}
                            </small>
                          </div>
                        ) : null}
                        {getNotificationData?.data?.data?.length ? (
                          <div>
                            {getNotificationData?.data?.data.map((item) => (
                              <SingleNotification
                                key={item._id}
                                {...item}
                                setNotificationPanelVisible={
                                  setNotificationPanelVisible
                                }
                              />
                            ))}
                          </div>
                        ) : (
                          <div className='w-1/2 mt-20 mx-auto'>
                            <EmptyNotification />
                            <p className='mt-4 text-Neutrals-gray-1 select-none'>
                              {t('navbar:no_notifications_to_show')}
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='px-2 md:px-4 my-4 mt-6 overflow-y-auto max-h-86vh scroller'>
                        {getNotificationData?.data?.mentions_count > 0 ? (
                          <div
                            className={`w-full text-right ${
                              currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                            }`}
                          >
                            <small
                              className='cursor-pointer'
                              onClick={() => {
                                updateMarkallRead.mutate();
                                setNotificationPanelVisible(false);
                              }}
                            >
                              {t('navbar:mark_all_as_read')}
                            </small>
                          </div>
                        ) : null}
                        {getNotificationData?.data?.mentions.length ? (
                          <div>
                            {getNotificationData?.data?.mentions.map((item) => (
                              <SingleNotification
                                key={item._id}
                                {...item}
                                setNotificationPanelVisible={
                                  setNotificationPanelVisible
                                }
                              />
                            ))}
                          </div>
                        ) : (
                          <div className='w-1/2 mt-20 mx-auto'>
                            <EmptyNotification />
                            <p className='mt-4 text-Neutrals-gray-1 select-none'>
                              {t('navbar:no_mentions_to_show')}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default NotificationPanel;
