import React from 'react';
import { Dialog } from 'primereact/dialog';
import PrimaryButton from 'components/Button/primaryButton';

const header = (
  <div>
    <h1 className='text-primary-rose text-2xl text-center whitespace-nowrap'>
      Terms and conditions
    </h1>
  </div>
);

const TermsAndConditionModal = ({
  showTermsAndConditions,
  setShowTermsAndConditions,
}) => {
  return (
    <div>
      <Dialog
        header={header}
        visible={showTermsAndConditions}
        onHide={() => setShowTermsAndConditions(false)}
        breakpoints={{ '960px': '85vw' }}
        style={{
          width: '85vw',
          height: '90vh',
          borderRadius: '1rem',
        }}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
      >
        <div className='relative mx-auto' dir='ltr'>
          <div className='mb-4 lg:mb-12 flex items-center justify-center space-x-2 w-full px-8'>
            {/* <div>
          <BGTandC />
        </div> */}

            {/* <div>
          <BGTandC />
        </div> */}
          </div>
          <div className='px-2 lg:px-4 text-justify'>
            <p className='text-xl font-medium mb-2'>Background</p>
            <p className='text-gray-500'>
              These Terms and Conditions, together with any and all other
              documents referred to herein, set out the terms of use under which
              you may use this website,  
              <a
                href='https://smart-hire.ai'
                target='_blank'
                rel='noreferrer'
              >
                https://smart-hire.ai
              </a>
               (“Our Site”). Please read these Terms and Conditions carefully
              and ensure that you understand them. Your agreement to comply with
              and be bound by these Terms and Conditions is deemed to occur upon
              your first use of Our Site and you will be required to read and
              accept these Terms and Conditions when signing up for an Account.
              If you do not agree to comply with and be bound by these Terms and
              Conditions, you must stop using Our Site immediately.
            </p>
          </div>
          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>1</span> Definitions and Interpretation
            </p>
            <div className='my-2'>
              <p className='text-gray-500 px-4 flex items-start'>
                <span className='mr-2'>1.1</span>
                In these Terms and Conditions, unless the context otherwise
                requires, the following expressions have the following meanings:
              </p>

              <div className='lg:px-8 px-4 lg:pl-12 mt-4'>
                <div className='grid grid-cols-12 lg:gap-x-28 mb-4'>
                  <p className='font-bold col-span-6 md:col-span-2 lg:col-span-1'>
                    “Account”
                  </p>
                  <p className='col-span-6 text-gray-500 md:col-span-10 lg:col-span-8 xl:col-span-9'>
                    means an account required for a User to access certain areas
                    of Our Site
                  </p>
                </div>
                <div className='grid grid-cols-12 lg:gap-x-28 mb-4'>
                  <p className='font-bold col-span-6 md:col-span-2 lg:col-span-1'>
                    “Content”
                  </p>
                  <p className='col-span-6 text-gray-500 md:col-span-10 lg:col-span-8 xl:col-span-9'>
                    means any and all text, images, audio, video, scripts, code,
                    software, databases and any other form of information
                    capable of being stored on a computer that appears on,or
                    forms part of, Our Site
                  </p>
                </div>
                <div className='grid grid-cols-12 lg:gap-x-28 mb-4'>
                  <p className='font-bold col-span-6 md:col-span-2 lg:col-span-1 whitespace-nowrap'>
                    “Our Site”
                  </p>
                  <p className='col-span-6 text-gray-500 md:col-span-10 lg:col-span-8 xl:col-span-9'>
                    means this website, https://smart-hire.ai/ and a
                    reference to “Our Site” includes reference to any and all
                    Content included there in (including User content, unless
                    expressly stated otherwise);
                  </p>
                </div>

                <div className='grid grid-cols-12 lg:gap-x-28 mb-4'>
                  <p className='font-bold col-span-6 md:col-span-2 lg:col-span-1'>
                    “User”
                  </p>
                  <p className='col-span-6 text-gray-500 md:col-span-10 lg:col-span-8 xl:col-span-9'>
                    means a user of Our Site
                  </p>
                </div>
                <div className='grid grid-cols-12 lg:gap-x-28 mb-4'>
                  <p className='font-bold col-span-6 md:col-span-2 lg:col-span-1'>
                    “We/Us/Our”
                  </p>
                  <p className='col-span-6 text-gray-500 md:col-span-10 lg:col-span-8 xl:col-span-9'>
                    means Future Look ITC a company, whose registered address is
                    Al Amir Sultan Ibn Abdulaziz street Al Olaya Dist Riyadh
                    Noor Building office No. 10 3rd floor.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>2</span> Information About Us
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>2.1</span>
                Our Site is operated by Future Look ITC.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>2.2</span>
                Registered address: Al Amir Sultan Ibn Abdulaziz street Al Olaya Dist Riyadh Noor
            Building office No. 10 3rd floor.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>2.3</span>
                Email address:{' '}
                <span className='text-primary-rose ml-1'>
                info@smart-hire.ai
                </span>{' '}
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>3</span> Accounts
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.1</span>
                Certain parts of Our Site (including the ability to submit User
                Content) may require an Account in order to access them.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.2</span>
                When creating an Account, the information you provide must be
                accurate and complete. If any of your information changes at a
                later date, it is your responsibility to ensure that your
                Account is kept up-to-date.
              </p>
              {/* <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.3</span>
                When creating an Account, the information you provide must be
                accurate and complete. If any of your information changes at a
                later date, it is your responsibility to ensure that your
                Account is kept up-to-date.
              </p> */}
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.3</span>
                We require that you choose a strong password for your Account,
                consisting of at least 8 characters, e.g. "a combination of
                lowercase and uppercase letters, numbers, and symbols". It is
                your responsibility to keep your password safe. You must not
                share your Account with anyone else. If you believe your Account
                is being used without your permission, please contact Us
                immediately at  info@smart-hire.ai. We will not be liable for
                any unauthorised use of your Account.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.4</span>
                You must not use anyone else’s Account without the express
                permission of the User to whom the Account belongs.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.5</span>
                If you wish to close your Account, you may do so at any time.
                Closing your Account will result in the removal of your
                information. Closing your Account will also remove access to any
                areas of Our Site requiring an Account for access.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>3.6</span>
                If you close and delete your Account, any User Content you have
                submitted to Our Site will also be deleted.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>4</span> Intellectual Property Rights and
              Our Site
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>4.1</span>
                With the exception of User Content, all Content on Our Site and
                the copyright and other intellectual property rights subsisting
                in that Content, unless specifically labelled otherwise, belongs
                to or has been licensed by Us. All Content (including User
                Content) is protected by applicable Indian and international
                intellectual property Intellectual Property Rights and User
                Content
              </p>
            </div>
          </div>
          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>5</span> Acceptable Usage Policy
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>5.1</span>
                You may only use Our Site in a manner that is lawful and
                Specifically:
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.1.1</span>
                you must ensure that you comply fully with any and all
                applicable local, national, and international laws and/or
                regulations;
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.1.2</span>
                you must not use Our Site in any way, or for any purpose, that
                is unlawful or fraudulent;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.1.3</span>
                you must not use Our Site to knowingly send, upload, or in any
                other way transmit data that contains any form of virus or other
                malware, or any other code designed to adversely affect computer
                hardware, software, or data of any kind; and
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.1.4</span>
                you must not use Our Site in any way, or for any purpose, that
                is intended to harm any person or persons in any way.
              </p>{' '}
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>5.2</span>
                When submitting User Content (or communicating in any other way
                using Our Site), you must not submit, communicate or otherwise
                do anything that:
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.1</span>
                is sexually explicit;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.2</span>
                is obscene, deliberately offensive, hateful, or otherwise
                inflammatory;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.3</span>
                promotes violence;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.4</span>
                promotes or assists in any form of unlawful activity;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.5</span>
                discriminates against, or is in any way defamatory of, any
                person, group or class of persons, race, sex, religion,
                nationality, disability, sexual orientation, or age;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.6</span>
                is intended or otherwise likely to threaten, harass, annoy,
                alarm, inconvenience, upset, or embarrass another person;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.7</span>
                is calculated or otherwise likely to deceive;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.8</span>
                is intended or otherwise likely to infringe (or threaten to
                infringe) another person’s right to privacy or otherwise uses
                their personal data in a way that you do not have a right to;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.9</span>
                misleadingly impersonates any person or otherwise misrepresents
                your identity or affiliation in a way that is calculated to
                deceive;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.10</span>
                implies any form of affiliation with Us where none exists;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.11</span>
                infringes, or assists in the infringement of, the intellectual
                property rights (including, but not limited to, copyright,
                patents, trade marks and database rights) of any other party; or
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.2.12</span>
                is in breach of any legal duty owed to a third party including,
                but not limited to, contractual duties and duties of confidence.
              </p>{' '}
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>5.3</span>
                We reserve the right to suspend or terminate your Account and/or
                your access to Our Site if you materially breach the provisions
                of this Clause or any of the other provisions of these Terms and
                Conditions. Specifically, we may take one or more of the
                following actions:
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.1</span>
                suspend, whether temporarily or permanently, your Account and/or
                your right to access Our Site;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.2</span>
                remove any of your User Content which violates this Acceptable
                Usage Policy;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.3</span>
                issue you with a written warning;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.4</span>
                take legal proceedings against you for reimbursement of any and
                all relevant costs on an indemnity basis resulting from your
                breach;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.5</span>
                take further legal action against you as appropriate;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.6</span>
                disclose such information to law enforcement authorities as
                required or as we deem reasonably necessary; and/or
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>5.3.7</span>
                any other actions which we deem reasonably appropriate (and
                lawful).
              </p>{' '}
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>5.4</span>
                We hereby exclude any and all liability arising out of any
                actions (including, but not limited to those set out above) that
                we may take in response to breaches of these Terms and
                Conditions.
              </p>
            </div>
          </div>
          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>6</span> Links to Our Site
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>6.1</span>
                You may link to Our Site provided that:
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.1.1</span>
                you do so in a fair and legal manner;
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.1.2</span>
                you do not do so in a manner that suggests any form of
                association, endorsement or approval on Our part where none
                exists;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.1.3</span>
                you do not use any logos or trade marks displayed on Our Site
                without Our express written permission; and
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.1.4</span>
                you do not do so in a way that is calculated to damage Our
                reputation or to take unfair advantage of it.
              </p>{' '}
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>6.2</span>
                <span>
                  {' '}
                  Framing or embedding of Our Site on other websites is not
                  permitted without Our express written permission. Please
                  contact Us
                  <span className='text-primary-rose ml-1'>
                  info@smart-hire.ai
                  </span>{' '}
                  for further information.
                </span>
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>6.3</span>
                You may not link to Our Site from any other site the content of
                which contains material that:
              </p>
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.1</span>
                is sexually explicit;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.2</span>
                is obscene, deliberately offensive, hateful or otherwise
                inflammatory;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.3</span>
                promotes violence;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.4</span>
                promotes or assists in any form of unlawful activity;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.5</span>
                Discriminates against, or is in any way defamatory of, any
                person, group or class of persons, race, sex, religion,
                nationality, disability, sexual orientation, or age;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.6</span>
                is intended or is otherwise likely to threaten, harass, annoy,
                alarm, inconvenience, upset, or embarrass another person;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.7</span>
                is calculated or is otherwise likely to deceive;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.8</span>
                is intended or is otherwise likely to infringe (or to threaten
                to infringe) another person’s privacy;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.9</span>
                misleadingly impersonates any person or otherwise misrepresents
                the identity or affiliation of a particular person in a way that
                is calculated to deceive (obvious parodies are not included in
                this definition provided that they do not fall within any of the
                other provisions of this sub-Clause 9.4);
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.10</span>
                implies any form of affiliation with Us where none exists;
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.11</span>
                infringes, or assists in the infringement of, the intellectual
                property rights (including, but not limited to, copyright,
                patents, trade marks an database rights) of any other party; or
              </p>{' '}
              <p className='text-gray-500 sm:px-4 sm:pl-12 pl-8 my-3 flex items-start'>
                <span className='mr-2'>6.3.12</span>
                is made in breach of any legal duty owed to a third party
                including, but not limited to, contractual duties and duties of
                confidence.
              </p>{' '}
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>7</span> Advertising
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>7.1</span>
                We may feature advertising on Our Site and we reserve the right
                to display advertising on the same page as any User Content.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>7.2</span>
                You agree that you will not attempt to remove or hide any
                advertising using HTML/CSS or by any other method.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>7.3</span>
                We are not responsible for the content of any advertising on Our
                Site.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>8</span> Disclaimers and Legal Rights
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>8.1</span>
                Nothing on Our Site constitutes advice on which you should rely.
                It is provided for general information purposes only.
                Professional or specialist advice should always be sought before
                taking any action relating to the job match reports and advice
                produced on Our site.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>8.2</span>
                We make reasonable efforts to ensure that Our Content on Our
                Site is complete, accurate, and up-to-date. We do not, however,
                make any representations, warranties or guarantees (whether
                express or implied) that such Content is complete, accurate, or
                up-to-date.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>8.3</span>
                We are not responsible for the content or accuracy of, or for
                any opinions, views, or values expressed in User Content. Any
                such opinions, views or values are those of the relevant User
                and do not reflect Our opinions, views, or values in any way.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>9</span> Our Liability
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.1</span>
                To the fullest extent permissible by law, we accept no liability
                to any User for any loss or damage, whether foreseeable or
                otherwise, in contract, tort (including negligence), for breach
                of statutory duty, or otherwise, arising out of or in connection
                with the use of (or inability to use) Our Site or the use of or
                reliance upon any Content (whether it is provided by Us or
                whether it is User Content) included on Our Site.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.2</span>
                To the fullest extent permissible by law, we exclude all
                representations, warranties, and guarantees (whether express or
                implied) that may apply to Our Site or any Content (including
                User Content) included on Our Site.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.3</span>
                Our Site is intended for non-commercial use only. If you are a
                business user, we accept no liability for loss of profits,
                sales, business or revenue; loss of business opportunity, good
                will or reputation; loss of anticipated savings; business
                interruption; or for any indirect or consequential loss or
                damage.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.4</span>
                We exercise all reasonable skill and care to ensure that Our
                Site is free from viruses and other malware. Subject to
                sub-Clause 12.3, we accept no liability for any loss or damage
                resulting from a virus or other malware, a distributed denial of
                service attack, or other harmful material or event that may
                adversely affect your hardware, software, data or other material
                that occurs as a result of your use of Our Site (including the
                downloading of any Content(including User Content) from it) or
                any other site referred to on Our Site.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.5</span>
                We neither assume nor accept responsibility or liability arising
                out of any disruption or non-availability of Our Site resulting
                from external causes including,but not limited to, ISP equipment
                failure, host equipment failure, communications network failure,
                natural events, acts of war, or legal restrictions and
                censorship.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>9.6</span>
                Nothing in these Terms and Conditions excludes or restricts Our
                liability for fraud or fraudulent misrepresentation, for death
                or personal injury resulting from negligence, or for any other
                forms of liability which cannot be excluded or restricted by
                law. For full details of consumers’ legal rights, including
                those relating to digital content, please contact your local
                Citizens’ Advice Bureau or Trading Standards Office.
              </p>
            </div>
          </div>
          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>10</span> Communications from Us
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>10.1</span>
                If you have an Account, we may from time to time send you
                important notices by email. Such notices may relate to matters
                including, but not limited to, service changes, changes to these
                Terms and Conditions, and changes to your Account.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>10.2</span>
                We will never send you marketing emails of any kind without your
                express consent. If you do give such consent, you may opt out at
                any time. Any and all marketing emails sent by Us include an
                unsubscribe link.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>10.3</span>
                <span>
                  For questions or complaints about communications from Us
                  (including, but not limited to marketing emails), please
                  contact Us via{' '}
                  <span className='text-primary-rose ml-1'>
                  info@smart-hire.ai
                  </span>{' '}
                </span>
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>11</span> Changes to these Terms and
              Conditions
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>11.1</span>
                We may alter these Terms and Conditions at any time. If we do
                so, details of the changes will be highlighted at the top of
                this page. Any such changes will become binding on you upon your
                first use of Our Site after the changes have been implemented.
                You are therefore advised to check this page from time to time.
              </p>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>11.2</span>
                In the event of any conflict between the current version of
                these Terms and Conditions and any previous version(s), the
                provisions current and in effect shall prevail unless it is
                expressly stated otherwise.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>12</span> Cancellation Fees
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>12.1</span>
                There is no cancellation fee, however there is no partial
                refunds.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>13</span> Contacting Us
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>13.1</span>
                We may alter these Terms and Conditions at any time. If we do
                so, details of the changes will be highlighted at the top of
                this page. Any such changes will become binding on you upon your
                first use of Our Site after the changes have been implemented.
                You are therefore advised to check this page from time to time.
              </p>
            </div>
          </div>

          <div className='px-2 lg:px-4 my-4'>
            <p className='text-lg font-bold'>
              <span className='mr-3'>14</span> Law and Jurisdiction
            </p>
            <div className='my-2'>
              <p className='text-gray-500 sm:px-4 my-3 flex items-start'>
                <span className='mr-2'>14.1</span>
                These Terms and Conditions, and the relationship between you and
                Us (whether contractual or otherwise) shall be governed by the
                laws of India.
              </p>
            </div>
          </div>
          <div className='w-full flex justify-center items-center'>
            <button
              className='w-36 text-center cursor-pointer mx-auto my-4'
              onClick={() => setShowTermsAndConditions(false)}
            >
              <PrimaryButton>
                <p className='text-sm leading-5 w-full h-full'>Back</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TermsAndConditionModal;
