const getModifiedStagename = (stagename, t) => {
  let displayStageName = stagename?.replaceAll('_', ' ');

  switch (displayStageName) {
    case 'Technical Interview':
      return `${t('common:interview')}`;
    case 'HR Interview':
      return `${t('common:hr')}`;
    case 'Holistic Assessment':
      return `${t('common:pre_hire_assessment')}`;
    case 'Skill Assessment':
      return `${t('common:skill_assessment')}`;
    case 'Psychometric Assessment':
      return t(
        `common:${JSON.parse(
          localStorage
            .getItem('psychometric_stage_name')
            .replaceAll(' ', '_')
            ?.toLowerCase()
        )}`
      );

    default:
      return displayStageName;
  }
};

export default getModifiedStagename;
