import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import PsychometricRadarChart from '../components/Charts/RadarChart';
// import { ReactComponent as NoImgIlls } from 'assets/images/Interviews/noImg.svg';
// import { ReactComponent as DownIlls } from 'assets/images/Interviews/downIlls.svg';
import { ReactComponent as ProgressIcon } from 'assets/images/Interviews/progressIcon.svg';
// import psyreportIlls from 'assets/images/Interviews/psyreportIlls.png';
// import { ReactComponent as LogoIlls } from 'assets/images/Interviews/smallLogo.svg';
// import smallLogo from "assets/images/smallLogo.png";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as PsyheaderBG } from 'assets/images/bg_for_psy_report_round.svg';
import { ReactComponent as PsyheaderBG1 } from 'assets/images/bg_for_psy_report_round1.svg';
import dateFormatter from 'helper/dateFormatter';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PsychometricReportPdf from './psychometricReportPdf';
import dayjs from 'dayjs';

import PrimaryButton from 'components/Button/primaryButton';

const staticScale = [
  { value: 1, id: 'ds1' },
  { value: 2, id: 'ds2' },
  { value: 3, id: 'ds3' },
  { value: 4, id: 'ds4' },
  { value: 5, id: 'ds5' },
  { value: 6, id: 'ds6' },
  { value: 7, id: 'ds7' },
  { value: 8, id: 'ds8' },
  { value: 9, id: 'ds9' },
  { value: 10, id: 'ds10' },
];

export default function PsychometricReport({
  showPsychometricReport,
  setShowPsychometricReport,
  radarChartData,
  getApplicantData,
  psyReport,
  setPSYReport,
  isSendToClient,
}) {
  const { t } = useTranslation(['jobsAndApplicants', 'psychometricReport']);
  const currentLanguageCode = cookies.get('i18next');
  const [bigFiveData, setBigFiveData] = useState([]);
  const [bigSixteenData, setBigSixteenData] = useState([]);

  const [imageUrl, setImageUrl] = useState();
  const [generateAndDownload, setGenerateAndDownload] = useState(false);

  const scaleObj = {
    Warmth: {
      scale: 'fA',
      leftDescription: {
        heading: t('psychometricReport:distant_aloof'),
        traits: `${t('psychometricReport:reserved_distant')}, ${t(
          'psychometricReport:de-tached'
        )}, ${t('psychometricReport:impersonal')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:empathetic'),
        traits: `${t('psychometricReport:affable')}, ${t(
          'psychometricReport:personable'
        )}, ${t('psychometricReport:warm-hearted')}`,
      },
    },
    Reasoning: {
      scale: 'B',
      leftDescription: {
        heading: t('psychometricReport:low_intellectance'),
        traits: t(
          'psychometricReport:lacking_confidence_in_own_intellectual_abilities'
        ),
      },
      rightDescription: {
        heading: t('psychometricReport:high_intellectance'),
        traits: t('psychometricReport:confident_of_own_intellectual_abilities'),
      },
    },
    'Emotional Stability': {
      scale: 'fC',
      leftDescription: {
        heading: t('psychometricReport:affected_by_feelings'),
        traits: `${t('psychometricReport:emotional')}, ${t(
          'psychometricReport:changeable_'
        )}, ${t('psychometricReport:labile')}, ${t(
          'psychometricReport:moody'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:emotionally_stable'),
        traits: `${t('psychometricReport:mature')}, ${t(
          'psychometricReport:calm'
        )}, ${t('psychometricReport:phlegmatic')}`,
      },
    },
    Dominance: {
      scale: 'fE',
      leftDescription: {
        heading: t('psychometricReport:accommodating'),
        traits: `${t('psychometricReport:passive')}, ${t(
          'psychometricReport:mild'
        )}, ${t('psychometricReport:humble')}, ${t(
          'psychometricReport:deferential'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:dominant'),
        traits: `${t('psychometricReport:assertive')}, ${t(
          'psychometricReport:competitive'
        )}, ${t('psychometricReport:aggressive')}, ${t(
          'psychometricReport:forceful'
        )}`,
      },
    },
    Liveliness: {
      scale: 'fF',
      leftDescription: {
        heading: t('psychometricReport:sober_serious'),
        traits: `${t('psychometricReport:restrained')}, ${t(
          'psychometricReport:tacitum'
        )}, ${t('psychometricReport:cautious')}, ${t(
          'psychometricReport:forceful'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:enthusiastic'),
        traits: `${t('psychometricReport:lively')}, ${t(
          'psychometricReport:cheerful'
        )}, ${t('psychometricReport:happy-go-lucky')}, ${t(
          'psychometricReport:carefree'
        )}`,
      },
    },
    'Rule-Consciousness': {
      scale: 'fG',
      leftDescription: {
        heading: t('psychometricReport:expedient'),
        traits: `${t('psychometricReport:spontaneous')}, ${t(
          'psychometricReport:disregarding_of_rules'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:conscientious'),
        traits: `${t('psychometricReport:preserving')}, ${t(
          'psychometricReport:dutiful'
        )}, ${t('psychometricReport:detail_conscious')}`,
      },
    },
    Abstractedness: {
      scale: 'fM',
      leftDescription: {
        heading: t('psychometricReport:concrete'),
        traits: `${t('psychometricReport:solution-focused')}, ${t(
          'psychometricReport:realistic'
        )}, ${t('psychometricReport:down-to-earth')}, ${t(
          'psychometricReport:practical'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:abstract'),
        traits: `${t('psychometricReport:imaginative')}, ${t(
          'psychometricReport:absent-minded'
        )}, ${t('psychometricReport:impractical')}`,
      },
    },
    Apprehension: {
      scale: 'fO',
      leftDescription: {
        heading: t('psychometricReport:confident'),
        traits: `${t('psychometricReport:secure')}, ${t(
          'psychometricReport:self-assured'
        )}, ${t('psychometricReport:unworried')}, ${t(
          'psychometricReport:guilt-free'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:self-doubting'),
        traits: `${t('psychometricReport:worrying')}, ${t(
          'psychometricReport:insecure'
        )}, ${t('psychometricReport:apprehensive')}`,
      },
    },

    'Openness to Change': {
      scale: 'fQ1',
      leftDescription: {
        heading: t('psychometricReport:conventional'),
        traits: `${t('psychometricReport:traditional')}, ${t(
          'psychometricReport:conservative'
        )}, ${t('psychometricReport:conforming')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:radical'),
        traits: `${t('psychometricReport:experimenting')}, ${t(
          'psychometricReport:open_to_change'
        )}, ${t('psychometricReport:unconventional')}`,
      },
    },
    Perfectionism: {
      scale: 'fQ3',
      leftDescription: {
        heading: t('psychometricReport:informal'),
        traits: `${t('psychometricReport:undisciplined')}, ${t(
          'psychometricReport:uncontrolled'
        )}, ${t('psychometricReport:lax')}, ${t(
          'psychometricReport:follows_own_urges'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:self-desciplined'),
        traits: `${t('psychometricReport:compulsive')}, ${t(
          'psychometricReport:fastidious'
        )}, ${t('psychometricReport:exacting_willpower')}`,
      },
    },
    Privateness: {
      scale: 'fN',
      leftDescription: {
        heading: t('psychometricReport:direct'),
        traits: `${t('psychometricReport:genuine')}, ${t(
          'psychometricReport:artless'
        )}, ${t('psychometricReport:forthright')}, ${t(
          'psychometricReport:straightforward'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:restrained'),
        traits: `${t('psychometricReport:diplomatic')}, ${t(
          'psychometricReport:socially_astute'
        )}, ${t('psychometricReport:socially_aware')}, ${t(
          'psychometricReport:discreet'
        )}`,
      },
    },

    'Self-Reliance': {
      scale: 'fQ2',
      leftDescription: {
        heading: t('psychometricReport:group_oriented'),
        traits: `${t('psychometricReport:sociable')}, ${t(
          'psychometricReport:group_dependent'
        )}, ${t('psychometricReport:a_joiner')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:self-sufficient'),
        traits: `${t('psychometricReport:solitary')}, ${t(
          'psychometricReport:slef_reliant'
        )}, ${t('psychometricReport:individualistc')}`,
      },
    },
    Sensitivity: {
      scale: 'fI',
      leftDescription: {
        heading: t('psychometricReport:hard-headed'),
        traits: `${t('psychometricReport:utilitarian')}, ${t(
          'psychometricReport:unsentimental'
        )}, ${t('psychometricReport:lacks_asthetic_sensitivity')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:tender-minded'),
        traits: `${t('psychometricReport:sensitive')}, ${t(
          'psychometricReport:aesthetically'
        )} ${t('psychometricReport:aware')}, ${t(
          'psychometricReport:sentimental'
        )}`,
      },
    },
    'Social Boldness': {
      scale: 'fH',
      leftDescription: {
        heading: t('psychometricReport:retiring'),
        traits: `${t('psychometricReport:timid')}, ${t(
          'psychometricReport:self-conscious'
        )}, ${t('psychometricReport:hesitant_in_social_settings')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:socially-bold'),
        traits: `${t('psychometricReport:venturesome')}, ${t(
          'psychometricReport:talkative'
        )}, ${t('psychometricReport:socially_confident')}`,
      },
    },
    Tension: {
      scale: 'fQ4',
      leftDescription: {
        heading: t('psychometricReport:composed'),
        traits: `${t('psychometricReport:relaxed')}, ${t(
          'psychometricReport:placid'
        )}, ${t('psychometricReport:patient')}`,
      },
      rightDescription: {
        heading: t('psychometricReport:tense-driven'),
        traits: `${t('psychometricReport:impatient')}, ${t(
          'psychometricReport:low_frustation_tolerance_'
        )}, ${t('psychometricReport:irritable')}`,
      },
    },
    Vigilance: {
      scale: 'fL',
      leftDescription: {
        heading: t('psychometricReport:trusting'),
        traits: `${t('psychometricReport:accepting')}, ${t(
          'psychometricReport:unsuspecting'
        )}, ${t('psychometricReport:credulous')}, ${t(
          'psychometricReport:tolerant'
        )}`,
      },
      rightDescription: {
        heading: t('psychometricReport:suspicious'),
        traits: `${t('psychometricReport:sceptical')}, ${t(
          'psychometricReport:cynical'
        )}, ${t('psychometricReport:doubting')}, ${t(
          'psychometricReport:critical'
        )}`,
      },
    },
  };

  // console.log(generateAndDownload, 'generateAndDownload psy');

  useEffect(() => {
    const bigFiveArray = [];
    const bigSixteenArray = [];

    if (radarChartData?.length) {
      radarChartData?.map((item) =>
        item.traits === 'Agreeable' ||
        item.traits === 'Conscientiousness' ||
        item.traits === 'Extraversion' ||
        item.traits === 'Openness' ||
        item.traits === 'Neuroticism'
          ? bigFiveArray.push(item)
          : bigSixteenArray.push({
              scale: scaleObj[item.traits].scale,
              leftDescription: scaleObj[item.traits].leftDescription,
              rightDescription: scaleObj[item.traits].rightDescription,
              percentile: item.score,
              scalevalue:
                item.score >= 10
                  ? Math.floor(item.score / 10)
                  : Math.ceil(item.score / 10),
              id: scaleObj[item.traits],
              scaleName: item.traits,
            })
      );
      setBigFiveData(bigFiveArray);
      setBigSixteenData(bigSixteenArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radarChartData, showPsychometricReport]);
  const convertLowerAndUnderScore = (string) => {
    return string.toLowerCase().replaceAll(' ', '_');
  };
  const header = (
    <div
      className='relative'
      style={{
        background:
          'linear-gradient(139.06deg, #9A73E1 -0.01%, #FA67B6 100.12%)',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
      }}
    >
      <div className='z-50 flex justify-end'>
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
          onClick={() => {
            setShowPsychometricReport(false);
            setGenerateAndDownload(false);
          }}
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FFF'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FFF'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>

      <div className='px-6 md:px-8 py-2'>
        <p className='text-white text-center text-xl md:text-3xl font-bold'>
          {JSON.parse(localStorage.getItem('psychometric_stage_name'))} Report
        </p>

        <div className='md:flex space-y-1 md:space-y-0 md:items-center my-4 md:justify-center'>
          <p
            className={`text-white text-base ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            {' '}
            {getApplicantData?.name}
          </p>

          <p
            className={`text-sm text-white font-normal ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            <span className='font-bold'>
              {t('jobsAndApplicants:assessment_date')}
            </span>{' '}
            :{' '}
            {dateFormatter(
              getApplicantData?.Psychometric_Assessment_completion_date ||
                getApplicantData?.Holistic_Assessment_completion_date,
              'DD MMM YYYY'
            )}
          </p>
          <p
            className={`text-sm text-white font-normal ${
              currentLanguageCode === 'ar' ? 'md:ml-16' : 'md:mr-16'
            }`}
          >
            <span className='font-bold'> {t('jobsAndApplicants:role')} </span> :{' '}
            {getApplicantData?.job_name}
          </p>
        </div>
      </div>

      <div className='absolute top-4 left-4 md:bottom-16 md:left-32'>
        <PsyheaderBG className='w-12 h-12' />
      </div>

      <div className='absolute bottom-10 right-8 md:right-32'>
        <PsyheaderBG1 />
      </div>

      <div className='absolute top-0 left-1/2'>
        <svg
          width='50'
          height='46'
          viewBox='0 0 50 46'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 45.9204L0.104289 -10.5703L49.8288 16.2858L1.70853 45.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>

      <div className='absolute top-4 left-3/4'>
        <svg
          width='50'
          height='57'
          viewBox='0 0 50 57'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 56.9204L0.104289 0.429731L49.8288 27.2858L1.70853 56.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
      <div className='absolute bottom-0 right-72'>
        <svg
          width='50'
          height='57'
          viewBox='0 0 50 57'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M1.70853 56.9204L0.104289 0.429731L49.8288 27.2858L1.70853 56.9204Z'
            fill='white'
            fillOpacity='0.08'
          />
        </svg>
      </div>
    </div>
  );

  const getScore = (data) => {
    const res = radarChartData?.find((item) => item.traits === data)?.score;
    return res;
  };

  return (
    <div>
      <Dialog
        header={header}
        visible={showPsychometricReport}
        onHide={() => {
          setShowPsychometricReport(false);
          setGenerateAndDownload(false);
        }}
        style={{
          borderRadius: '1rem',
        }}
        className='w-11/12 lg:w-11/12 lg:max-w-5xl xl:max-w-7xl h-90vh rounded-lg psy_report'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll
        closable={false}
        position='center'
        resizable={false}
      >
        {bigSixteenData.length ? (
          <>
            <div>
              <div className='flex justify-center'>
                <p
                  className={`text-primary-rose bg-white px-2 font-semibold text-lg md:text-xl md:mb-5 ${
                    bigSixteenData.length > 0 ? 'mt-4' : ' mt-4'
                  }`}
                >
                  {t('psychometricReport:big_5_traits')}
                </p>
              </div>

              <div
                className={`my-4 mb-8 flex flex-col lg:flex-row justify-between md:items-center ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div
                  className={`w-full lg:w-3/5 relative ${
                    currentLanguageCode === 'ar'
                      ? 'lg:ml-4 lg:-mr-4'
                      : 'lg:mr-4 lg:-ml-4'
                  }`}
                >
                  <PsychometricRadarChart
                    radarChartData={bigFiveData}
                    isReportPage
                    setImageUrl={setImageUrl}
                    isSetValue={true}
                    generateAndDownload={generateAndDownload}
                  />
                </div>

                <div
                  className={`w-px h-52 bg-primary-rose opacity-40 hidden lg:block ${
                    currentLanguageCode === 'ar' ? 'lg:ml-4' : 'lg:mr-4'
                  }`}
                ></div>
                <div
                  className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 md:gap-x-8 gap-y-4 md:mx-auto ${
                    currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                  }`}
                >
                  <div>
                    <p className='text-base font-bold'>
                      {t('psychometricReport:agreeable')}
                      <span
                        className={`text-primary-rose mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                        }`}
                      >
                        ({getScore('Agreeable')}%)
                      </span>
                    </p>

                    <p className='mt-3 text-gray-500'>
                      {t('psychometricReport:indicative_traits')}
                    </p>
                    <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                      {getScore('Agreeable') < 50 ? (
                        <>
                          {' '}
                          <li>{t('psychometricReport:critical')}</li>
                          <li>{t('psychometricReport:trusting')}</li>
                          <li>{t('psychometricReport:empathetic')}</li>
                        </>
                      ) : (
                        <>
                          {' '}
                          <li>{t('psychometricReport:helpful')}</li>
                          <li>{t('psychometricReport:trusting')}</li>
                          <li>{t('psychometricReport:empathetic')}</li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div>
                    <p className='text-base font-bold'>
                      {t('psychometricReport:conscientiousness')}
                      <span
                        className={`text-primary-rose mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                        }`}
                      >
                        ({getScore('Conscientiousness')}%)
                      </span>
                    </p>

                    <p className='mt-3 text-gray-500'>
                      {t('psychometricReport:indicative_traits')}
                    </p>
                    <ul className='text-gray-500 list-disc px-5 my-2 text-sm'>
                      {getScore('Conscientiousness') < 50 ? (
                        <>
                          <li>{t('psychometricReport:impulsive')}</li>
                          <li>{t('psychometricReport:careless')}</li>
                          <li>{t('psychometricReport:disorganized')}</li>
                        </>
                      ) : (
                        <>
                          <li>{t('psychometricReport:hardworking')}</li>
                          <li>{t('psychometricReport:dependable')}</li>
                          <li>{t('psychometricReport:organized')}</li>
                        </>
                      )}
                    </ul>
                  </div>

                  <div>
                    <p className='text-base font-bold'>
                      {t('psychometricReport:extraversion')}
                      <span
                        className={`text-primary-rose mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                        }`}
                      >
                        ({getScore('Extraversion')}%)
                      </span>
                    </p>

                    <p className='mt-3 text-gray-500'>
                      {t('psychometricReport:indicative_traits')}
                    </p>
                    <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                      {getScore('Extraversion') < 50 ? (
                        <>
                          {' '}
                          <li>{t('psychometricReport:quite')}</li>
                          <li>{t('psychometricReport:reserved')}</li>
                          <li>{t('psychometricReport:withdrawn')}</li>
                        </>
                      ) : (
                        <>
                          {' '}
                          <li>{t('psychometricReport:outgoing')}</li>
                          <li>{t('psychometricReport:warm')}</li>
                          <li>{t('psychometricReport:seeks_adventure')}</li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div>
                    <p className='text-base font-bold'>
                      {t('psychometricReport:neuroticism')}
                      <span
                        className={`text-primary-rose mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                        }`}
                      >
                        ({getScore('Neuroticism')}%)
                      </span>
                    </p>

                    <p className='mt-3 text-gray-500'>
                      {t('psychometricReport:indicative_traits')}
                    </p>
                    <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                      {getScore('Neuroticism') < 50 ? (
                        <>
                          {' '}
                          <li>{t('psychometricReport:calm')}</li>
                          <li>{t('psychometricReport:even-tempered')}</li>
                          <li>{t('psychometricReport:secure')}</li>
                        </>
                      ) : (
                        <>
                          {' '}
                          <li>{t('psychometricReport:anxious')}</li>
                          <li>{t('psychometricReport:unhappy')}</li>
                          <li>
                            {t('psychometricReport:prone_to_negative_emotions')}
                          </li>
                        </>
                      )}
                    </ul>
                  </div>

                  <div>
                    <p className='text-base font-bold'>
                      {t('psychometricReport:openness')}
                      <span
                        className={`text-primary-rose mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                        }`}
                      >
                        ({getScore('Openness')}%)
                      </span>
                    </p>

                    <p className='mt-3 text-gray-500'>
                      {t('psychometricReport:indicative_traits')}
                    </p>
                    <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                      {getScore('Openness') < 50 ? (
                        <>
                          {' '}
                          <li>{t('psychometricReport:practical')}</li>
                          <li>{t('psychometricReport:conventional')}</li>
                          <li>{t('psychometricReport:prefers_routine')}</li>
                        </>
                      ) : (
                        <>
                          {' '}
                          <li>{t('psychometricReport:curious')}</li>
                          <li>{t('psychometricReport:multiple_interests')}</li>
                          <li>{t('psychometricReport:independent')}</li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`relative ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div className='border border-primary-rose opacity-30 z-0'></div>
                <div className='bg-white z-50 absolute'>
                  <p
                    className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                      currentLanguageCode === 'ar'
                        ? 'mr-2 md:mr-12'
                        : 'ml-2 md:ml-12'
                    }`}
                  >
                    {t('psychometricReport:agreeableness')}
                  </p>
                </div>
                <p className='mt-5 mx-auto text-justify md:w-10/12'>
                  {t(
                    'psychometricReport:this_personality_dimension_includes_attributes_such_as_trust'
                  )}
                </p>
                <div className='relative w-11/12 md:w-10/12 mx-auto'>
                  <div className='w-full mx-auto mt-12 border-dashed border-1 border-purple'></div>
                  <div className='w-4 h-4 bg-purple absolute right-0 rounded-full -mt-2'></div>
                  <div className='w-4 h-4 bg-purple absolute rounded-full -mt-2'></div>
                  <div className='w-full mx-auto z-50 absolute'>
                    <p
                      style={{ paddingLeft: `${getScore('Agreeable')}%` }}
                      className={`w-2 -mt-6 ${
                        currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                      }`}
                    >
                      <ProgressIcon className='w-12 h-12' />
                      <span
                        className={`text-purple mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                        }`}
                      >
                        {getScore('Agreeable')}%
                      </span>
                    </p>
                  </div>
                  <div className='md:flex'>
                    <div className='mt-12 md:w-1/2'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:low')}
                      </p>
                      <p className='mt-4'>
                        {t(
                          'psychometricReport:takes_little_interest_in_others'
                        )}
                      </p>
                      <p>
                        {t(
                          "psychometricReport:doesn't_care_about_how_other_people_feel"
                        )}
                      </p>
                      <p>
                        {t(
                          "psychometricReport:has_little_interest_in_other_people's_problems"
                        )}
                      </p>
                      <p>
                        {t('psychometricReport:insults_and_belittles_others')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:manipulates_others_to_get_what_they_want'
                        )}
                      </p>
                    </div>
                    <div className='bg-gray-300 w-0.5 mt-16 opacity-30 hidden md:block'></div>
                    <hr className='border border-gray-300 my-5 opacity-40' />
                    <div className=' md:mt-12 md:w-1/2 md:text-right'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:high')}
                      </p>
                      <p className='mt-4'>
                        {t(
                          'psychometricReport:has_great_deal_of_interest_in_other_people'
                        )}
                      </p>
                      <p>{t('psychometricReport:cares_about_others')}</p>
                      <p>
                        {t(
                          'psychometricReport:feels_empathy_and_concern_for_other_people'
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:enjoys_helping_and_contributing_to_the_happiness_of_others'
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:assists_others_who_are_in_need_of_help'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`relative mt-12 ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div className='border border-primary-rose opacity-30 z-0'></div>
                <div className='bg-white z-50 absolute'>
                  <p
                    className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                      currentLanguageCode === 'ar'
                        ? 'mr-2 md:mr-12'
                        : 'ml-2 md:ml-12'
                    }`}
                  >
                    {t('psychometricReport:conscientiousness')}
                  </p>
                </div>
                <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                  {t(
                    'psychometricReport:standard_features_of_this_dimension_include_high_levels_of_thoughtfulness'
                  )}
                </p>
                <div className='relative w-11/12 md:w-10/12 mx-auto'>
                  <div className='w-full mx-auto mt-12 border-dashed border-1 border-purple'></div>
                  <div className='w-4 h-4 bg-purple absolute right-0 rounded-full -mt-2'></div>
                  <div className='w-4 h-4 bg-purple absolute rounded-full -mt-2'></div>
                  <div className='w-full mx-auto z-50 absolute'>
                    <p
                      style={{
                        paddingLeft: `${getScore('Conscientiousness')}%`,
                      }}
                      className={`w-2 -mt-6 ${
                        currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                      }`}
                    >
                      <ProgressIcon className='w-12 h-12' />
                      <span
                        className={`text-purple mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                        }`}
                      >
                        {getScore('Conscientiousness')}%
                      </span>
                    </p>
                  </div>
                  <div className='md:flex'>
                    <div className='mt-12 md:w-1/2'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:low')}
                      </p>
                      <p className='mt-4'>
                        {t(
                          'psychometricReport:dislike_structure_and_schedules'
                        )}
                      </p>
                      <p>
                        {t(
                          "psychometricReport:makes_messes_and_doesn't_take_care_of_things"
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:fails_to_return_the_things_or_put_them_back_where_they_belongs'
                        )}
                      </p>
                      <p>
                        {t('psychometricReport:procastinates_important_tasks')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:fails_to_complete_necessery_or_assigned_tasks'
                        )}
                      </p>
                    </div>
                    <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                    <hr className='border border-gray-300 my-5 opacity-40' />
                    <div className='md:mt-12 md:w-1/2 md:text-right'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:high')}
                      </p>
                      <p className='mt-4'>
                        {t('psychometricReport:spends_time_preparing')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:finishes_important_tasks_right_away'
                        )}
                      </p>
                      <p>{t('psychometricReport:pays_attention_to_detail')}</p>
                      <p>
                        {t('psychometricReport:enjoys_having_a_set_schedule')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`relative mt-12 ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div className='border border-primary-rose opacity-30 z-0'></div>
                <div className='bg-white z-50 absolute'>
                  <p
                    className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                      currentLanguageCode === 'ar'
                        ? 'mr-2 md:mr-12'
                        : 'ml-2 md:ml-12'
                    }`}
                  >
                    {t('psychometricReport:extraversion')}
                  </p>
                </div>
                <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                  {t(
                    'psychometricReport:extraversion_(or_extroversion)_is_characterized_by_excitability'
                  )}
                </p>
                <div className='relative w-11/12 md:w-10/12 mx-auto'>
                  <div className='w-full mx-auto mt-12 border-dashed border-1 border-purple'></div>
                  <div className='w-4 h-4 bg-purple absolute right-0 rounded-full -mt-2'></div>
                  <div className='w-4 h-4 bg-purple absolute rounded-full -mt-2'></div>
                  <div className='w-full mx-auto z-50 absolute'>
                    <p
                      style={{ paddingLeft: `${getScore('Extraversion')}%` }}
                      className={`w-2 -mt-6 ${
                        currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                      }`}
                    >
                      <ProgressIcon className='w-12 h-12' />
                      <span
                        className={`text-purple mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                        }`}
                      >
                        {getScore('Extraversion')}%
                      </span>
                    </p>
                  </div>
                  <div className='md:flex'>
                    <div className='mt-12 md:w-1/2'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:low')}
                      </p>
                      <p className='mt-4'>
                        {t('psychometricReport:prefers_solitude')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:feels_exhausted_when_having_to_socialize_a_lot'
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:finds_it_difficult_to_start_conversations'
                        )}
                      </p>
                      <p>
                        {t('psychometricReport:dislike_making_small_talks')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:carefully_thinks_things_before_speak'
                        )}
                      </p>
                    </div>
                    <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                    <hr className='border border-gray-300 my-5 opacity-40' />
                    <div className='md:mt-12 md:w-1/2 md:text-right'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:high')}
                      </p>

                      <p className='mt-4'>
                        {t(
                          'psychometricReport:enjoys_being_the_center_of_attention'
                        )}
                      </p>
                      <p>
                        {t('psychometricReport:likes_to_start_conversations')}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:finds_it_easy_to_make_new_friends'
                        )}
                      </p>
                      <p>{t('psychometricReport:enjoys_meeting_new_people')}</p>
                      <p>
                        {t(
                          'psychometricReport:say_things_before_thinking_about_them'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`relative mt-12 ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div className='border border-primary-rose opacity-30 z-0'></div>
                <div className='bg-white z-50 absolute'>
                  <p
                    className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                      currentLanguageCode === 'ar'
                        ? 'mr-2 md:mr-12'
                        : 'ml-2 md:ml-12'
                    }`}
                  >
                    {t('psychometricReport:neuroticism')}
                  </p>
                </div>
                <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                  {t(
                    'psychometricReport:neuroticism_is_a_trait_characterized_by_sadness'
                  )}
                </p>
                <div className='relative w-11/12 md:w-10/12 mx-auto'>
                  <div className='w-full mx-auto mt-12 border-dashed border-1 border-purple'></div>
                  <div className='w-4 h-4 bg-purple absolute right-0 rounded-full -mt-2'></div>
                  <div className='w-4 h-4 bg-purple absolute rounded-full -mt-2'></div>
                  <div className='w-full mx-auto z-50 absolute'>
                    <p
                      style={{ paddingLeft: `${getScore('Neuroticism')}%` }}
                      className={`w-2 -mt-6 ${
                        currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                      }`}
                    >
                      <ProgressIcon className='w-12 h-12' />
                      <span
                        className={`text-purple mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                        }`}
                      >
                        {getScore('Neuroticism')}%
                      </span>
                    </p>
                  </div>
                  <div className='md:flex'>
                    <div className='mt-12 md:w-1/2'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:low')}
                      </p>
                      <p className='mt-4'>
                        {t('psychometricReport:emotionally_stable')}
                      </p>
                      <p>{t('psychometricReport:deals_well_with_stress')}</p>
                      <p>{t("psychometricReport:doesn't_worry_much")}</p>
                      <p>
                        {t('psychometricReport:rarely_feels_sad_or_depressed')}
                      </p>
                      <p>{t('psychometricReport:is_very_relaxed')}</p>
                    </div>
                    <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                    <hr className='border border-gray-300 my-5 opacity-40' />
                    <div className='md:mt-12 md:w-1/2 md:text-right'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:high')}
                      </p>

                      <p className='mt-4'>
                        {t('psychometricReport:experience_a_lot_of_stress')}
                      </p>
                      <p>{t('psychometricReport:gets_upset_easily')}</p>
                      <p>{t('psychometricReport:feels_anxious')}</p>
                      <p>
                        {t(
                          'psychometricReport:worries_about_many_different_things'
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:experience_dramatic_shifts_in_mood'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`relative mt-12 ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <div className='border border-primary-rose opacity-30 z-0'></div>
                <div className='bg-white z-50 absolute'>
                  <p
                    className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                      currentLanguageCode === 'ar'
                        ? 'mr-2 md:mr-12'
                        : 'ml-2 md:ml-12'
                    }`}
                  >
                    {t('psychometricReport:openness')}
                  </p>
                </div>
                <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                  {t(
                    'psychometricReport:this_trait_features_characteristics_such_as_imagination_and_insight'
                  )}
                </p>
                <div className='relative w-11/12 md:w-10/12 mx-auto'>
                  <div className='w-full mx-auto mt-12 border-dashed border-1 border-purple'></div>
                  <div className='w-4 h-4 bg-purple absolute right-0 rounded-full -mt-2'></div>
                  <div className='w-4 h-4 bg-purple absolute rounded-full -mt-2'></div>
                  <div className='w-full mx-auto z-50 absolute'>
                    <p
                      style={{ paddingLeft: `${getScore('Openness')}%` }}
                      className={`w-2 -mt-6 ${
                        currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                      }`}
                    >
                      <ProgressIcon className='w-12 h-12' />
                      <span
                        className={`text-purple mt-1 ${
                          currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                        }`}
                      >
                        {getScore('Openness')}%
                      </span>
                    </p>
                  </div>
                  <div className='md:flex'>
                    <div className='mt-12 md:w-1/2'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:low')}
                      </p>
                      <p className='mt-4'>
                        {t('psychometricReport:dislikes_change')}
                      </p>
                      <p>{t('psychometricReport:does_not_enjoy_new_things')}</p>
                      <p>{t('psychometricReport:resists_new_ideas')}</p>
                      <p>{t('psychometricReport:not_very_imaginative')}</p>
                      <p>
                        {t(
                          'psychometricReport:dislike_abstract_or_theoritical_concepts'
                        )}
                      </p>
                    </div>
                    <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                    <hr className='border border-gray-300 my-5 opacity-40' />
                    <div className='md:mt-12 md:w-1/2 md:text-right'>
                      <p className='text-primary-rose'>
                        {t('psychometricReport:high')}
                      </p>
                      <p className='mt-4'>
                        {t('psychometricReport:very_creative')}
                      </p>
                      <p>{t('psychometricReport:open_to_trying_new_things')}</p>
                      <p>
                        {t(
                          'psychometricReport:focused_on_tackling_new_chanlleges'
                        )}
                      </p>
                      <p>
                        {t(
                          'psychometricReport:happy_to_think_about_abstract_concepts'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center mt-8'>
              <p className='text-center text-primary-rose text-xl md:text-2xl font-bold bg-white -mb-3.5 px-2'>
                {t('psychometricReport:16_personality_factors_scores')}
              </p>
            </div>
            <div
              className='p-4 py-10 rounded my-8 w-max mx-auto'
              style={{ backgroundColor: '#FFEDF8' }}
            >
              <table className='w-full rounded border-separate mb-10'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-2 py-2  text-gray-500 max-w-6rem'>
                      {t('psychometricReport:scale')}
                    </th>
                    <th className='px-2 py-2  text-gray-500 text-right'>
                      {t('psychometricReport:left_description')}
                    </th>
                    <th className='px-6 py-2  text-gray-900 w-1/3'>
                      <ul className='flex justify-between font-bold'>
                        {staticScale.map((staticItem) => (
                          <li
                            className={`w-6 justify-center items-center flex py-2 rounded ${
                              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                            }`}
                            key={staticItem.id}
                          >
                            <p>{staticItem.value}</p>
                          </li>
                        ))}
                      </ul>
                    </th>
                    <th className='px-2 py-2  text-left text-gray-500'>
                      {t('psychometricReport:right_description')}
                    </th>
                    <th className='px-6 py-2  text-gray-500'>%ile</th>
                  </tr>
                </thead>

                <tbody className='bg-white'>
                  {bigSixteenData.map((item) => (
                    <tr className='border-b-2 border-white' key={item.scale}>
                      <td className='px-6 py-2 font-bold text-white bg-primary-rose rounded'>
                        {t(
                          `psychometricReport:${convertLowerAndUnderScore(
                            item.scaleName
                          )}`
                        )}
                      </td>

                      <td className='px-2 py-2 max-w-14rem'>
                        <div className='text-right'>
                          <p className='font-bold'>
                            {item.leftDescription.heading}
                          </p>
                          <p className='text-sm'>
                            {item.leftDescription.traits}
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-2 text-sm text-gray-900'>
                        <ul className='flex justify-between'>
                          {staticScale.map((staticItem) => (
                            <li
                              className={`w-6 justify-center items-center flex py-2 rounded ${
                                staticItem.value === item.scalevalue
                                  ? 'bg-primary-rose text-white font-bold'
                                  : 'bg-blue-50'
                              } ${
                                currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                              }`}
                              key={staticItem.id}
                            >
                              <p>{staticItem.value}</p>
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td className='px-2 py-2 max-w-14rem'>
                        {' '}
                        <div className='text-left'>
                          <p className='font-bold'>
                            {' '}
                            {item.rightDescription.heading}
                          </p>
                          <p className='text-sm'>
                            {item.rightDescription.traits}
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-2 font-bold'>{item.percentile}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <hr
                className={`mt-8 w-full ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              />
              <div
                className={`flex justify-between mt-4 text-tiny md:text-sm text-Neutrals-gray min-w-full ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <p>
                  &copy; {dayjs().year()}.{' '}
                  {t('psychometricReport:all_rights_reserved.')}
                </p>
                {/* <p className='text-center'>flitc.ai</p> */}
                <div className='flex'>
                  {/* <LogoIlls className='w-8 h-8 -mt-1 mr-1' /> */}
                  {/* <img src={smallLogo} alt="" className='w-3 h-3 mr-1 mt-1' /> */}
                  <p className='text-right'>
                    {t('psychometricReport:powered_by_smart_hire')}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className='flex justify-center'>
              <p
                className={`text-primary-rose bg-white px-2 font-semibold text-lg md:text-xl md:mb-5 ${
                  bigSixteenData.length > 0 ? '-mt-4' : ' mt-4'
                }`}
              >
                {t('psychometricReport:big_5_traits')}
              </p>
            </div>

            <div
              className={`my-4 mb-8 flex flex-col lg:flex-row justify-between md:items-center ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div
                className={`w-full lg:w-3/5 ${
                  currentLanguageCode === 'ar'
                    ? 'lg:ml-4 lg:-mr-4'
                    : 'lg:-ml-4 lg:mr-4'
                }`}
              >
                <PsychometricRadarChart
                  radarChartData={bigFiveData}
                  isReportPage
                  setImageUrl={setImageUrl}
                  isSetValue={true}
                  generateAndDownload={generateAndDownload}
                />
              </div>

              <div
                className={`w-px h-52 bg-primary-rose opacity-40 hidden lg:block ${
                  currentLanguageCode === 'ar' ? 'lg:ml-4' : 'lg:mr-4'
                }`}
              ></div>
              <div
                className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 md:gap-x-8 gap-y-4 md:mx-auto ${
                  currentLanguageCode === 'ar' ? 'lg:ml-4' : 'lg:mr-4'
                }`}
              >
                <div>
                  <p className='text-base font-bold'>
                    {t('psychometricReport:agreeable')}
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                      }`}
                    >
                      ({getScore('Agreeable')}%)
                    </span>
                  </p>

                  <p className='mt-3 text-gray-500'>
                    {' '}
                    {t('psychometricReport:indicative_traits')}
                  </p>
                  <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                    {getScore('Agreeable') < 50 ? (
                      <>
                        {' '}
                        <li>{t('psychometricReport:critical')}</li>
                        <li>{t('psychometricReport:trusting')}</li>
                        <li>{t('psychometricReport:empathetic')}</li>
                      </>
                    ) : (
                      <>
                        {' '}
                        <li>{t('psychometricReport:helpful')}</li>
                        <li>{t('psychometricReport:trusting')}</li>
                        <li>{t('psychometricReport:empathetic')}</li>
                      </>
                    )}
                  </ul>
                </div>
                <div>
                  <p className='text-base font-bold'>
                    {t('psychometricReport:conscientiousness')}
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                      }`}
                    >
                      ({getScore('Conscientiousness')}%)
                    </span>
                  </p>

                  <p className='mt-3 text-gray-500'>
                    {' '}
                    {t('psychometricReport:indicative_traits')}
                  </p>
                  <ul className='text-gray-500 list-disc px-5 my-2 text-sm'>
                    {getScore('Conscientiousness') < 50 ? (
                      <>
                        <li>{t('psychometricReport:impulsive')}</li>
                        <li>{t('psychometricReport:careless')}</li>
                        <li>{t('psychometricReport:disorganized')}</li>
                      </>
                    ) : (
                      <>
                        <li>{t('psychometricReport:hardworking')}</li>
                        <li>{t('psychometricReport:dependable')}</li>
                        <li>{t('psychometricReport:organized')}</li>
                      </>
                    )}
                  </ul>
                </div>

                <div>
                  <p className='text-base font-bold'>
                    {t('psychometricReport:extraversion')}
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                      }`}
                    >
                      ({getScore('Extraversion')}%)
                    </span>
                  </p>

                  <p className='mt-3 text-gray-500'>
                    {' '}
                    {t('psychometricReport:indicative_traits')}{' '}
                  </p>
                  <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                    {getScore('Extraversion') < 50 ? (
                      <>
                        {' '}
                        <li>{t('psychometricReport:quite')}</li>
                        <li>{t('psychometricReport:reserved')}</li>
                        <li>{t('psychometricReport:withdrawn')}</li>
                      </>
                    ) : (
                      <>
                        {' '}
                        <li>{t('psychometricReport:outgoing')}</li>
                        <li>{t('psychometricReport:warm')}</li>
                        <li>{t('psychometricReport:seeks_adventure')}</li>
                      </>
                    )}
                  </ul>
                </div>
                <div>
                  <p className='text-base font-bold'>
                    {t('psychometricReport:neuroticism')}
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                      }`}
                    >
                      ({getScore('Neuroticism')}%)
                    </span>
                  </p>

                  <p className='mt-3 text-gray-500'>
                    {' '}
                    {t('psychometricReport:indicative_traits')}{' '}
                  </p>
                  <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                    {getScore('Neuroticism') < 50 ? (
                      <>
                        {' '}
                        <li>{t('psychometricReport:calm')}</li>
                        <li>{t('psychometricReport:even-tempered')}</li>
                        <li>{t('psychometricReport:secure')}</li>
                      </>
                    ) : (
                      <>
                        {' '}
                        <li>{t('psychometricReport:anxious')}</li>
                        <li>{t('psychometricReport:unhappy')}</li>
                        <li>
                          {t('psychometricReport:prone_to_negative_emotions')}
                        </li>
                      </>
                    )}
                  </ul>
                </div>

                <div>
                  <p className='text-base font-bold'>
                    {t('psychometricReport:openness')}
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                      }`}
                    >
                      ({getScore('Openness')}%)
                    </span>
                  </p>

                  <p className='mt-3 text-gray-500'>
                    {' '}
                    {t('psychometricReport:indicative_traits')}
                  </p>
                  <ul className=' list-disc px-5 my-2 text-sm text-gray-500'>
                    {getScore('Openness') < 50 ? (
                      <>
                        {' '}
                        <li>{t('psychometricReport:practical')}</li>
                        <li>{t('psychometricReport:conventional')}</li>
                        <li>{t('psychometricReport:prefers_routine')}</li>
                      </>
                    ) : (
                      <>
                        {' '}
                        <li>{t('psychometricReport:curious')}</li>
                        <li>{t('psychometricReport:multiple_interests')}</li>
                        <li>{t('psychometricReport:independent')}</li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`relative ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div className='border border-primary-rose opacity-30 z-0'></div>
              <div className='bg-white z-50 absolute'>
                <p
                  className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                    currentLanguageCode === 'ar'
                      ? 'mr-2 md:mr-12'
                      : 'ml-2 md:ml-12'
                  }`}
                >
                  {t('psychometricReport:agreeableness')}
                </p>
              </div>
              <p className='mt-5 mx-auto text-justify md:w-10/12'>
                {t(
                  'psychometricReport:this_personality_dimension_includes_attributes_such_as_trust'
                )}
              </p>
              <div className='relative w-11/12 md:w-10/12 mx-auto'>
                <div className='w-full mx-auto mt-12 border-dashed border-1 border-primary-rose'></div>
                <div className='w-4 h-4 bg-primary-rose absolute right-0 rounded-full -mt-2'></div>
                <div className='w-4 h-4 bg-primary-rose absolute rounded-full -mt-2'></div>
                <div className='w-full mx-auto z-50 absolute'>
                  <p
                    style={{ paddingLeft: `${getScore('Agreeable')}%` }}
                    className={`w-2 -mt-6 ${
                      currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                    }`}
                  >
                    <ProgressIcon className='w-12 h-12' />
                    <span className='text-primary-rose ml-3 mt-1'>
                      {getScore('Agreeable')}%
                    </span>
                  </p>
                </div>
                <div className='md:flex'>
                  <div className='mt-12 md:w-1/2'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:low')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:takes_little_interest_in_others')}
                    </p>
                    <p>
                      {t(
                        "psychometricReport:doesn't_care_about_how_other_people_feel"
                      )}
                    </p>
                    <p>
                      {t(
                        "psychometricReport:has_little_interest_in_other_people's_problems"
                      )}
                    </p>
                    <p>
                      {t('psychometricReport:insults_and_belittles_others')}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:manipulates_others_to_get_what_they_want'
                      )}
                    </p>
                  </div>
                  <div className='bg-gray-300 w-0.5 mt-16 opacity-30 hidden md:block'></div>
                  <hr className='border border-gray-300 my-5 opacity-40' />
                  <div className=' md:mt-12 md:w-1/2 md:text-right'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:high')}
                    </p>
                    <p className='mt-4'>
                      {t(
                        'psychometricReport:has_great_deal_of_interest_in_other_people'
                      )}
                    </p>
                    <p>{t('psychometricReport:cares_about_others')}</p>
                    <p>
                      {t(
                        'psychometricReport:feels_empathy_and_concern_for_other_people'
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:enjoys_helping_and_contributing_to_the_happiness_of_others'
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:assists_others_who_are_in_need_of_help'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`relative mt-12 ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div className='border border-primary-rose opacity-30 z-0'></div>
              <div className='bg-white z-50 absolute'>
                <p
                  className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                    currentLanguageCode === 'ar'
                      ? 'mr-2 md:mr-12'
                      : 'ml-2 md:ml-12'
                  }`}
                >
                  {t('psychometricReport:conscientiousness')}
                </p>
              </div>
              <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                {t(
                  'psychometricReport:standard_features_of_this_dimension_include_high_levels_of_thoughtfulness'
                )}
              </p>
              <div className='relative w-11/12 md:w-10/12 mx-auto'>
                <div className='w-full mx-auto mt-12 border-dashed border-1 border-primary-rose'></div>
                <div className='w-4 h-4 bg-primary-rose absolute right-0 rounded-full -mt-2'></div>
                <div className='w-4 h-4 bg-primary-rose absolute rounded-full -mt-2'></div>
                <div className='w-full mx-auto z-50 absolute'>
                  <p
                    style={{ paddingLeft: `${getScore('Conscientiousness')}%` }}
                    className={`w-2 -mt-6 ${
                      currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                    }`}
                  >
                    <ProgressIcon className='w-12 h-12' />
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                      }`}
                    >
                      {getScore('Conscientiousness')}%
                    </span>
                  </p>
                </div>
                <div className='md:flex'>
                  <div className='mt-12 md:w-1/2'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:low')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:dislike_structure_and_schedules')}
                    </p>
                    <p>
                      {t(
                        "psychometricReport:makes_messes_and_doesn't_take_care_of_things"
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:fails_to_return_the_things_or_put_them_back_where_they_belongs'
                      )}
                    </p>
                    <p>
                      {t('psychometricReport:procastinates_important_tasks')}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:fails_to_complete_necessery_or_assigned_tasks'
                      )}
                    </p>
                  </div>
                  <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                  <hr className='border border-gray-300 my-5 opacity-40' />
                  <div className='md:mt-12 md:w-1/2 md:text-right'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:high')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:spends_time_preparing')}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:finishes_important_tasks_right_away'
                      )}
                    </p>
                    <p>{t('psychometricReport:pays_attention_to_detail')}</p>
                    <p>
                      {t('psychometricReport:enjoys_having_a_set_schedule')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`relative mt-12 ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div className='border border-primary-rose opacity-30 z-0'></div>
              <div className='bg-white z-50 absolute'>
                <p
                  className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                    currentLanguageCode === 'ar'
                      ? 'mr-2 md:mr-12'
                      : 'ml-2 md:ml-12'
                  }`}
                >
                  {t('psychometricReport:extraversion')}
                </p>
              </div>
              <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                {t(
                  'psychometricReport:extraversion_(or_extroversion)_is_characterized_by_excitability'
                )}
              </p>
              <div className='relative w-11/12 md:w-10/12 mx-auto'>
                <div className='w-full mx-auto mt-12 border-dashed border-1 border-primary-rose'></div>
                <div className='w-4 h-4 bg-primary-rose absolute right-0 rounded-full -mt-2'></div>
                <div className='w-4 h-4 bg-primary-rose absolute rounded-full -mt-2'></div>
                <div className='w-full mx-auto z-50 absolute'>
                  <p
                    style={{ paddingLeft: `${getScore('Extraversion')}%` }}
                    className={`w-2 -mt-6 ${
                      currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                    }`}
                  >
                    <ProgressIcon className='w-12 h-12' />
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                      }`}
                    >
                      {getScore('Extraversion')}%
                    </span>
                  </p>
                </div>
                <div className='md:flex'>
                  <div className='mt-12 md:w-1/2'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:low')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:prefers_solitude')}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:feels_exhausted_when_having_to_socialize_a_lot'
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:finds_it_difficult_to_start_conversations'
                      )}
                    </p>
                    <p>{t('psychometricReport:dislike_making_small_talks')}</p>
                    <p>
                      {t(
                        'psychometricReport:carefully_thinks_things_before_speak'
                      )}
                    </p>
                  </div>
                  <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                  <hr className='border border-gray-300 my-5 opacity-40' />
                  <div className='md:mt-12 md:w-1/2 md:text-right'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:high')}
                    </p>

                    <p className='mt-4'>
                      {t(
                        'psychometricReport:enjoys_being_the_center_of_attention'
                      )}
                    </p>
                    <p>
                      {t('psychometricReport:likes_to_start_conversations')}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:finds_it_easy_to_make_new_friends'
                      )}
                    </p>
                    <p>{t('psychometricReport:enjoys_meeting_new_people')}</p>
                    <p>
                      {t(
                        'psychometricReport:say_things_before_thinking_about_them'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`relative mt-12 ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div className='border border-primary-rose opacity-30 z-0'></div>
              <div className='bg-white z-50 absolute'>
                <p
                  className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                    currentLanguageCode === 'ar'
                      ? 'mr-2 md:mr-12'
                      : 'ml-2 md:ml-12'
                  }`}
                >
                  {t('psychometricReport:neuroticism')}
                </p>
              </div>
              <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                {t(
                  'psychometricReport:neuroticism_is_a_trait_characterized_by_sadness'
                )}
              </p>
              <div className='relative w-11/12 md:w-10/12 mx-auto'>
                <div className='w-full mx-auto mt-12 border-dashed border-1 border-primary-rose'></div>
                <div className='w-4 h-4 bg-primary-rose absolute right-0 rounded-full -mt-2'></div>
                <div className='w-4 h-4 bg-primary-rose absolute rounded-full -mt-2'></div>
                <div className='w-full mx-auto z-50 absolute'>
                  <p
                    style={{ paddingLeft: `${getScore('Neuroticism')}%` }}
                    className={`w-2 -mt-6 ${
                      currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                    }`}
                  >
                    <ProgressIcon className='w-12 h-12' />
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                      }`}
                    >
                      {getScore('Neuroticism')}%
                    </span>
                  </p>
                </div>
                <div className='md:flex'>
                  <div className='mt-12 md:w-1/2'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:low')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:emotionally_stable')}
                    </p>
                    <p>{t('psychometricReport:deals_well_with_stress')}</p>
                    <p>{t("psychometricReport:doesn't_worry_much")}</p>
                    <p>
                      {t('psychometricReport:rarely_feels_sad_or_depressed')}
                    </p>
                    <p>{t('psychometricReport:is_very_relaxed')}</p>
                  </div>
                  <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                  <hr className='border border-gray-300 my-5 opacity-40' />
                  <div className='md:mt-12 md:w-1/2 md:text-right'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:high')}
                    </p>

                    <p className='mt-4'>
                      {t('psychometricReport:experience_a_lot_of_stress')}
                    </p>
                    <p>{t('psychometricReport:gets_upset_easily')}</p>
                    <p>{t('psychometricReport:feels_anxious')}</p>
                    <p>
                      {t(
                        'psychometricReport:worries_about_many_different_things'
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:experience_dramatic_shifts_in_mood'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`relative mt-12 ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <div className='border border-primary-rose opacity-30 z-0'></div>
              <div className='bg-white z-50 absolute'>
                <p
                  className={`text-primary-rose font-semibold -mt-4 bg-white px-2 ${
                    currentLanguageCode === 'ar'
                      ? 'mr-2 md:mr-12'
                      : 'ml-2 md:ml-12'
                  }`}
                >
                  {t('psychometricReport:openness')}
                </p>
              </div>
              <p className='mt-5 md:w-10/12 mx-auto text-justify'>
                {t(
                  'psychometricReport:this_trait_features_characteristics_such_as_imagination_and_insight'
                )}
              </p>
              <div className='relative w-11/12 md:w-10/12 mx-auto'>
                <div className='w-full mx-auto mt-12 border-dashed border-1 border-primary-rose'></div>
                <div className='w-4 h-4 bg-primary-rose absolute right-0 rounded-full -mt-2'></div>
                <div className='w-4 h-4 bg-primary-rose absolute rounded-full -mt-2'></div>
                <div className='w-full mx-auto z-50 absolute'>
                  <p
                    style={{ paddingLeft: `${getScore('Openness')}%` }}
                    className={`w-2 -mt-6 ${
                      currentLanguageCode === 'ar' ? '-mr-4' : '-ml-4'
                    }`}
                  >
                    <ProgressIcon className='w-12 h-12' />
                    <span
                      className={`text-primary-rose mt-1 ${
                        currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                      }`}
                    >
                      {getScore('Openness')}%
                    </span>
                  </p>
                </div>
                <div className='md:flex'>
                  <div className='mt-12 md:w-1/2'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:low')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:dislikes_change')}
                    </p>
                    <p>{t('psychometricReport:does_not_enjoy_new_things')}</p>
                    <p>{t('psychometricReport:resists_new_ideas')}</p>
                    <p>{t('psychometricReport:not_very_imaginative')}</p>
                    <p>
                      {t(
                        'psychometricReport:dislike_abstract_or_theoritical_concepts'
                      )}
                    </p>
                  </div>
                  <div className='bg-gray-300 w-0.5 mt-16 opacity-20 hidden md:block'></div>
                  <hr className='border border-gray-300 my-5 opacity-40' />
                  <div className='md:mt-12 md:w-1/2 md:text-right'>
                    <p className='text-primary-rose'>
                      {t('psychometricReport:high')}
                    </p>
                    <p className='mt-4'>
                      {t('psychometricReport:very_creative')}
                    </p>
                    <p>{t('psychometricReport:open_to_trying_new_things')}</p>
                    <p>
                      {t(
                        'psychometricReport:focused_on_tackling_new_chanlleges'
                      )}
                    </p>
                    <p>
                      {t(
                        'psychometricReport:happy_to_think_about_abstract_concepts'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {bigSixteenData.length ? null : (
          <>
            {' '}
            <hr
              className={`mt-8 w-full ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            />
            <div
              className={`flex justify-between mt-4 text-tiny md:text-sm text-Neutrals-gray min-w-full ${
                currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
              }`}
            >
              <p>
                &copy; {dayjs().year()}.{' '}
                {t('psychometricReport:all_rights_reserved.')}
              </p>
              {/* <p className='text-center'>flitc.ai</p> */}
              <div className='flex'>
                {/* <LogoIlls className='w-8 h-8 -mt-1 mr-1' /> */}
                {/* <img src={smallLogo} alt="" className='w-3 h-3 mr-1 mt-1'/> */}
                <p className='text-right'>
                  {t('psychometricReport:powered_by_smart_hire')}
                </p>
              </div>
            </div>
          </>
        )}

        <div className='flex w-full justify-center mt-6'>
          <button
            onClick={() => setGenerateAndDownload(true)}
            className='w-40 mx-4'
          >
            <PrimaryButton isLoading={generateAndDownload && !imageUrl}>
              <p className='text-sm leading-5'>
                {t('psychometricReport:generate_report')}
              </p>
            </PrimaryButton>
          </button>
          <PDFDownloadLink
            document={
              <PsychometricReportPdf
                getApplicantData={getApplicantData}
                imageUrl={imageUrl}
                radarChartData={bigFiveData}
                bigSixteenData={bigSixteenData}
              />
            }
            fileName='report.pdf'
            className={`${!imageUrl ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            {({ blob, url, loading, error }) => {
              if (isSendToClient && blob && !psyReport) {
                setPSYReport(blob);
                setShowPsychometricReport(false);
              }

              return isSendToClient ? null : (
                <button className='w-40'>
                  <PrimaryButton>
                    <p className='text-sm leading-5'>
                      {t('jobsAndApplicants:download_report')}
                    </p>
                  </PrimaryButton>
                </button>
              );
            }}
          </PDFDownloadLink>
        </div>
      </Dialog>
    </div>
  );
}
