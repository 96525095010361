import { Dialog } from 'primereact/dialog';

import { ReactComponent as Close } from 'assets/images/resumebank/close.svg';
import { ReactComponent as ThankYouIlls } from 'assets/images/resumebank/ThankYouIlls.svg';
import { ReactComponent as FailureIlls } from 'assets/images/resumebank/FailureIlls.svg';

const SuccessModal = ({ success, setSuccess, addResume }) => {
  const header = (
    <div
      className='flex justify-end cursor-pointer'
      onClick={() => setSuccess(false)}
    >
      <Close />
    </div>
  );
  return (
    <div>
      <Dialog
        header={header}
        visible={success}
        onHide={() => setSuccess(false)}
        style={{
          borderRadius: '1rem',
        }}
        className='w-11/12 max-w-md rounded-lg'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
        
      >
        {addResume?.isSuccess ? (
          <div className='flex flex-col justify-center items-center'>
            <ThankYouIlls className='p-8' />
            <p className='text-[#515151] text-xl'>Thank You </p>
          </div>
        ) : (
          <div className='flex flex-col justify-center items-center'>
            <FailureIlls className='' />
            <p className='text-[#515151] text-xl mt-8'>
              {addResume?.error?.response?.data?.message ?? ' Please try again'}
            </p>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SuccessModal;
