import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import Loader from 'components/Loader';
import BasePrivateLayout from '../../basePrivateLayout';
import ProfileContainer from './ProfileContainer.jsx';

import TeamMemberRestrictedRouteProfile from 'routes/teamMemberRestrictedRouteProfile';

const Profile = lazy(() => import('./profile'));
const Companytheme = lazy(() => import('./companyTheme'));
const Settings = lazy(() => import('./settings'));
const Billing = lazy(() => import('./billing'));
const Upgrade = lazy(() => import('./upgrade'));
const UpgradeSuccess = lazy(() => import('./upgradeSuccess'));
const ReferralAndWallet = lazy(() => import('./ReferralAndWallet'));

const ProfileRoute = () => {
  const { path } = useRouteMatch();

  return (
    <BasePrivateLayout>
      <ProfileContainer>
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='mt-12'>
                <Loader width={40} height={40} color='#6600C9' />
              </div>
            </div>
          }
        >
          <Switch>
            <Redirect exact from={path} to={`${path}/myprofile`} />

            <Route path={`${path}/myprofile`} component={Profile} />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/companytheme`}
              component={Companytheme}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/settings`}
              component={Settings}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/billing`}
              component={Billing}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/upgrade`}
              component={Upgrade}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/addon/upgradeSuccess`}
              component={UpgradeSuccess}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/upgradeSuccess`}
              component={UpgradeSuccess}
            />
            <TeamMemberRestrictedRouteProfile
              path={`${path}/referral`}
              component={ReferralAndWallet}
            />
          </Switch>
        </Suspense>
      </ProfileContainer>
    </BasePrivateLayout>
  );
};
export default ProfileRoute;
