import { useQuery } from 'react-query';
import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

export default function UseCountryCodes(enable = true) {
  return useQuery(
    ['country-codes', enable],
    async () => {
      const { data } = await makeAPIRequest.get(apiEndPoints.COUNTRY_CODE);
      return data;
    },
    { staleTime: 180000, enabled: enable }
  );
}
