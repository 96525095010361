import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import Loader from 'components/Loader';

import NoPageFound from 'pages/common/error/noPageFound';

import BasePrivateLayout from '../../basePrivateLayout';

const ResumeListingPage = lazy(() => import('./ResumeListingPage'));
const ApplicantsPage = lazy(() => import('./ApplicantsPage'));
const ResumeUploadPage = lazy(() => import('./UploadResume'));

const ResumeBank = () => {
  const { path } = useRouteMatch();
  const routeLink = localStorage.getItem('scannerURL');

  const qrUrl = routeLink?.split('root_folder_id');
  console.log(qrUrl);
  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='mt-12'>
              <Loader width={40} height={40} color='#6600C9' />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/listing`} />

          <Route exact path={`${path}/listing`} component={ResumeListingPage} />
          <Route
            path={`${path}/listing/:name/:folderID`}
            component={ApplicantsPage}
          />
          <Route exact path='/ResumesRootFolder' component={ResumeUploadPage} />

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
};

export default ResumeBank;
