import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Text,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <Text x={x} y={y} width={80} textAnchor='middle' verticalAnchor='start'>
      {payload.value}
    </Text>
  );
};

const BarChartData = ({ chartData }) => {
  return (
    <div
      style={{ width: '100%', height: 280, fontSize: '14px' }}
      className='mx-auto mt-6 mb-4 py-2  '
    >
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 40,
          }}
        >
          <XAxis
            dataKey='name'
            tickMargin={15}
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            label={{
              value: 'Days',
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
            }}
          />
          <Tooltip />
          <Bar
            dataKey='value'
            radius={[5, 5, 0, 0]}
            fill='#F26122'
            barSize={40}
          >
            {' '}
            <LabelList dataKey='value' position='top' fill='#F26122' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartData;
