import React, { useState, useContext, useEffect } from 'react';
// import { useWindowSize } from 'react-use';
// import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Dialog } from 'primereact/dialog';
import { AuthContext } from 'contexts/authContext';

import AuthHeader from 'components/Header/authHeader';
import LogoutModal from 'components/LogoutModal';
import NotificationPanel from 'components/notification';
import PrimaryButton from 'components/Button/primaryButton';

import { getTokenFn } from '../firebase';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import ComponentLevelErrorBoundary from 'pages/common/error/CompnentLevelErrorBoundary';

import { ReactComponent as EmailSettingWarning } from 'assets/images/emailSettingWarning.svg';

// import { ReactComponent as Logo } from 'assets/images/logo.svg';
// import { ReactComponent as LowIlls } from 'assets/images/profile/lowIlls.svg';
// import { ReactComponent as MobileScreenIlls } from 'assets/images/profile/MobileScreenIlls.svg';

const BasePrivateLayout = ({ children }) => {
  // const { width } = useWindowSize();
  const location = useLocation();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [isEmailSettingsPopupOpen, setEmailSettingsPopupOpen] = useState(false);

  const { authState, authDispatch } = useContext(AuthContext);

  // A state to make sure pushNotificationToken and avoid multiple API request to backend
  const [pushNotificationToken, setPushNotificationToken] = useState();

  // Todo: A mutation needs to be created to send token to backend
  /**
   * pushNotificationMutation
   *
   * pushNotificationMutation mutation helps in sending push notification token to backend
   */
  const pushNotificationMutation = useMutation(
    async (firebaseToken) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.PUSH_NOTIFICATION_TOKEN_REGISTRATION,
        firebaseToken
      );
      return res.data;
    },
    {
      onSuccess: () => {
        if (pushNotificationToken) {
          authDispatch({
            type: 'UPDATEPUSHNOTIFICATION',
            payload: pushNotificationToken,
          });
        }
      },
    }
  );

  /**
   *  UseEffect here is responsible for generating token from firebase and sending it to backend
   * getTokenFn is the function used for generating token
   *
   */
  useEffect(() => {
    if (window?.safari === undefined) {
      (async () => {
        const isBlockPushNotification =
          window.location.protocol === 'http:' &&
          window.location.hostname !== 'localhost';
        if (
          authState.is_recruiter ||
          authState.is_hiring_manager ||
          authState.is_recruiter_team ||
          authState.is_hiring_manager_team
        ) {
          if (!authState.push_notification_token && !isBlockPushNotification) {
            const token = await getTokenFn();
            setPushNotificationToken(token);
            if (token) {
              pushNotificationMutation.mutate({ token: token });
            }
          }
        }
      })();
    }
    // self executing function for making the process synchronous

    // eslint-disable-next-line
  }, []);

  // if (width < 750 || isMobile) {
  //   return (
  //     <div className='h-90vh relative'>
  //       <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
  //         <Logo className='w-full mx-auto mb-6' />
  //         <div className='relative bg-white shadow-xl h-60vh text-center w-10/12 mx-auto border rounded-md'>
  //           <MobileScreenIlls className='w-4/5 mt-16 mb-8 mx-auto' />
  //           <p className='text-tag-not-scheduled text-2xl pb-2 font-semibold'>
  //             We’re sorry!
  //           </p>
  //           <p className='text-sm text-141414 px-4'>
  //             Currently, this application is not mobile responsive yet.
  //           </p>
  //           <p className='text-sm text-141414 px-4 pt-1'>
  //             Please login using your laptop or a desktop.
  //           </p>
  //           <LowIlls className='absolute bottom-8 w-full mx-auto' />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <AuthHeader
        handelLogoutModal={setIsLogoutModalOpen}
        setShowNotificationModal={setShowNotificationModal}
      />

      {/* subscription ended tag */}
      {authState?.subscription_ended ? (
        <div className='bg-FFEBE5 py-3 sticky top-20 z-40 text-sm md:text-base'>
          <p className='text-center'>
            Subscription has expired, to renew{' '}
            <Link to='/profile/billing?tab=mySub'>
              <span className='text-primary-rose underline'>click here</span>
            </Link>{' '}
          </p>
        </div>
      ) : null}

      <ComponentLevelErrorBoundary>
        <div>{children}</div>
      </ComponentLevelErrorBoundary>
      <LogoutModal
        handelLogoutModal={setIsLogoutModalOpen}
        isLogoutModalOpen={isLogoutModalOpen}
      />
      <NotificationPanel
        notificationVisible={showNotificationModal}
        setNotificationPanelVisible={setShowNotificationModal}
        setEmailSettingsPopupOpen={setEmailSettingsPopupOpen}
      />

      {/* email settings popup */}
      {location?.search === '?selected=email-account' ||
      authState.first_login_email ? null : (
        <Dialog
          visible={isEmailSettingsPopupOpen}
          onHide={() => null}
          style={{
            borderRadius: '1rem',
          }}
          className='w-11/12 max-w-lg rounded-lg'
          modal
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          closable={false}
          position='center'
          resizable={false}
        >
          <div className='w-full'>
            <div className='flex justify-center pr-4'>
              <EmailSettingWarning className='w-60' />
            </div>
            <p className='text-center'>Please check your email credentials.</p>
            <div className='flex justify-center'>
              <Link to='/profile/settings?selected=email-account'>
                <button className='w-32 mt-6 select-none'>
                  <PrimaryButton>Go to settings</PrimaryButton>
                </button>
              </Link>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default BasePrivateLayout;
