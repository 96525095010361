import { useQuery } from 'react-query';
import { useContext } from 'react';

import { AuthContext } from 'contexts/authContext';
import { JobContext } from 'contexts/jobContext';

import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

export default function UseJobStagelabelName({ enable = true }) {
  const { jobDispatch } = useContext(JobContext);
  const { authState } = useContext(AuthContext);

  return useQuery(
    ['job-stages-labels-hook', enable],
    async () => {
      const url = `${
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_CHANGE_JOB_STAGE_LABEL_NAME
          : apiEndPoints.CHANGE_JOB_STAGE_LABEL_NAME
      }${
        authState.is_client_admin || authState.is_client_team
          ? `?token=${authState.token_client}`
          : ''
      }`;
      const { data } = await makeAPIRequest.get(url);
      return data.data;
    },
    {
      staleTime: 180000,
      enabled: enable,
      onSuccess: (data) => {
        jobDispatch({
          type: 'SETPSYCHOMETRICSTAGENAME',
          payload: {
            PsychometricStageName: data[0].psychometric_assessment,
          },
        });
      },
    }
  );
}
