import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';

import BasePrivateLayout from '../../basePrivateLayout';

const Client = lazy(() => import('./components/Client'));

function ClientsPage() {
  const { path } = useRouteMatch();
  return (
    <BasePrivateLayout>
      <Switch>
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='max-w-main-page h-70vh flex justify-center items-center '>
                <LottiLoader />
              </div>
            </div>
          }
        >
          <Switch>
            <Redirect exact from={path} to={`${path}/overview`} />
            <Route path={`${path}/overview`} component={Client} />
            {/* DO NOT CHNAGE this empty route position Add everything above this line */}
            <Route component={NoPageFound} />
          </Switch>
        </Suspense>
      </Switch>
    </BasePrivateLayout>
  );
}

export default ClientsPage;
