import { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { ApplicantsContext } from 'contexts/applicantsContext';
import { AuthContext } from 'contexts/authContext';

import getModifiedStagename from 'helper/getModifiedStagename';

import PrimaryButton from 'components/Button/primaryButton';
import Lottie from 'react-lottie-player';

import SuccessLottie from 'assets/lottieFiles/SuccessLottie.json';

import { ReactComponent as BG } from 'assets/images/Interviews/success_bg_modal.svg';

const ApplicantMovedToNextStageModal = ({
  headerText,
  nextStage,
  clientViewMode = false,
}) => {
  const { t } = useTranslation(['common']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);
  const { authState } = useContext(AuthContext);

  const handelCloseScheduleSuccessModal = () => {
    applicantsDispatch({
      type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
      payload: false,
    });
  };

  const header = (
    <>
      {authState?.is_recruiter_team || authState?.is_recruiter ? (
        <>
          {nextStage?.includes('Interview') || clientViewMode ? (
            <p className='text-xl text-center tracking-wide text-black'>
              Applicant {`${clientViewMode ? 'shared' : 'moved'}`} to{' '}
              <span className='text-primary-rose'>client</span> successfully
            </p>
          ) : (
            <p className='text-xl text-center tracking-wide text-black'>
              Applicant moved to{' '}
              <span className='text-primary-rose'>
                {getModifiedStagename(headerText, t)}
              </span>{' '}
              successfully
            </p>
          )}
        </>
      ) : (
        <p className='text-xl text-center tracking-wide text-black'>
          Applicant moved to{' '}
          <span className='text-primary-rose'>
            {getModifiedStagename(headerText, t)}
          </span>{' '}
          successfully
        </p>
      )}
    </>
  );

  return (
    <div>
      <Dialog
        visible={applicantsState.is_applicants_moved_next_stage_modal_visible}
        onHide={handelCloseScheduleSuccessModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        position='middle'
        resizable={false}
        header={header}
        className=' w-3/5  lg:w-1/2 mx-auto rounded-xl'
      >
        <div className='relative'>
          <div className='bg-F9F9F9 w-60 h-60 mx-auto rounded-full mb-4 mt-4 z-50 flex justify-center items-center'>
            <div>
              <Lottie
                animationData={SuccessLottie}
                background='transparent'
                speed='1'
                loop
                play
              />
            </div>
          </div>
          <div className='absolute w-full top-10'>
            <BG />
          </div>
          <div className='flex justify-center mt-10 mb-6'>
            <button className='w-28' onClick={handelCloseScheduleSuccessModal}>
              <PrimaryButton>
                <p className='text-base'>{t('common:back')}</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ApplicantMovedToNextStageModal;
