import React, { useState, useEffect } from 'react';
import { Skeleton } from 'primereact/skeleton';
import BarChartData from './barChartData';
import JobAnalyticsData from './jobAnalyticsData';
import cookies from 'js-cookie';

const JobChartCard = ({ jobAnalyticsData, isLoading }) => {
  const currentLanguageCode = cookies.get('i18next');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let arr = [];
    jobAnalyticsData?.stages?.forEach((item) => {
      let itemObj = {
        name:
          item === 'Holistic Assessment'
            ? 'Pre-hire Assessment'
            : item === 'Psychometric Assessment'
            ? JSON.parse(localStorage.getItem('psychometric_stage_name'))
            : item,
        value:
          item === 'CV Screening'
            ? jobAnalyticsData?.applicants_cv_avg?.cv_screening_avg_in_days?.toFixed(
                2
              ) || 0
            : jobAnalyticsData?.applicants_stages_avg?.[
                `${item.replace(' ', '_')}_avg_in_days`
              ]?.toFixed(2) || 0,
      };
      arr.push(itemObj);
    });
    setChartData(arr);
  }, [
    jobAnalyticsData?.stages,
    jobAnalyticsData?.applicants_stages_avg,
    jobAnalyticsData?.applicants_cv_avg,
  ]);

  if (isLoading) {
    return (
      <div className='flex flex-col justify-center lg:justify-none items-center lg:flex-row rounded-lg shadow-lg border w-auto h-auto mx-10 my-5 bg-white  '>
        <div
          className={`lg:w-1/2 w-full px-6 ${
            currentLanguageCode === 'ar' ? 'lg:ml-6' : 'lg:mr-6'
          }`}
        >
          <div>
            <div className='flex justify-center'>
              <div
                className={`pt-48 ${
                  currentLanguageCode === 'ar' ? 'mr-6' : 'ml-6'
                }`}
              >
                {' '}
                <Skeleton height='3rem' width='2rem' borderRadius='5px' />
              </div>
              <div
                className={`pt-20 ${
                  currentLanguageCode === 'ar' ? 'mr-5' : 'ml-5'
                }`}
              >
                {' '}
                <Skeleton height='10rem' width='2rem' borderRadius='5px' />
              </div>
              <div
                className={`pt-8 ${
                  currentLanguageCode === 'ar' ? 'mr-5' : 'ml-5'
                }`}
              >
                {' '}
                <Skeleton height='13rem' width='2rem' borderRadius='5px' />
              </div>
              <div
                className={`pt-20 ${
                  currentLanguageCode === 'ar' ? 'mr-5' : 'ml-5'
                }`}
              >
                {' '}
                <Skeleton height='10rem' width='2rem' borderRadius='5px' />
              </div>
              <div
                className={`pt-40 ${
                  currentLanguageCode === 'ar' ? 'mr-5' : 'ml-5'
                }`}
              >
                {' '}
                <Skeleton height='5rem' width='2rem' borderRadius='5px' />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex flex-col mt-5 ${
              currentLanguageCode === 'ar' ? 'mr-5' : 'ml-5'
            }`}
          >
            <div className='flex  py-3 justify-center'>
              <div
                className={`flex ${
                  currentLanguageCode === 'ar' ? 'ml-10' : 'mr-10'
                }`}
              >
                <Skeleton height='1rem' width='1rem' borderRadius='100%' />
                <div className='font-normal mx-2 text-sm text-Neutrals-gray-1 '>
                  <Skeleton height='1rem' width='6rem' borderRadius='5px' />
                </div>
              </div>
              <div
                className={`flex ${
                  currentLanguageCode === 'ar' ? 'ml-10' : 'mr-10'
                }`}
              >
                <Skeleton height='1rem' width='1rem' borderRadius='100%' />
                <div className='font-normal mx-2 text-sm text-Neutrals-gray-1 '>
                  <Skeleton height='1rem' width='6rem' borderRadius='5px' />
                </div>
              </div>
              <div
                className={`flex ${
                  currentLanguageCode === 'ar' ? 'ml-10' : 'mr-10'
                }`}
              >
                <Skeleton height='1rem' width='1rem' borderRadius='100%' />
                <div className='font-normal text-sm text-Neutrals-gray-1 mx-2'>
                  <Skeleton height='1rem' width='6rem' borderRadius='5px' />
                </div>
              </div>
            </div>
            <div className='flex justify-center py-5'>
              <div
                className={`rounded-lg shadow-lg border bg-white w-36 h-20 pt-3 text-center ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                <div className='text-base text-primary-green font-bold flex justify-center pb-2 '>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div className=' flex justify-center pt-2 '>
                  <Skeleton height='1rem' width='5rem' borderRadius='5px' />
                </div>
              </div>
              <div
                className={`rounded-lg shadow-lg border bg-white w-36 h-20 pt-3 text-center ${
                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                }`}
              >
                <div className='text-base text-primary-green font-bold flex justify-center pb-2'>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div className=' flex justify-center pt-2 '>
                  <Skeleton height='1rem' width='5rem' borderRadius='5px' />
                </div>
              </div>
            </div>
            <div className='flex text-center justify-center text-base text-black font-semibold'>
              <Skeleton height='1rem' width='14rem' borderRadius='5px' />
            </div>
            <div className='flex justify-center py-5'>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
              <div
                className={`flex flex-col space-y-4 justify-center items-center text-primary-rose ${
                  currentLanguageCode === 'ar'
                    ? 'xl:ml-12 md:ml-8'
                    : 'xl:mr-12 md:mr-8'
                }`}
              >
                <div>
                  <Skeleton height='1rem' width='2rem' borderRadius='5px' />
                </div>
                <div>
                  <Skeleton height='3rem' width='3rem' borderRadius='100%' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    // <div className=' flex flex-col rounded-lg shadow-lg border  lg:h-100vh w-auto h-auto mx-10 my-6  bg-white'>
    <div
      key={jobAnalyticsData.job_details.job_id}
      className='flex flex-col justify-center lg:justify-none items-center lg:flex-row rounded-lg shadow-lg border w-auto h-auto mx-10 my-5 bg-white  '
    >
      <div
        className={`lg:w-1/2 w-full px-6 ${
          currentLanguageCode === 'ar' ? 'lg:ml-6' : 'lg:mr-6'
        }`}
      >
        <div
          className={`text-black text-base font-semibold pt-8 ${
            currentLanguageCode === 'ar' ? 'mr-10' : 'ml-10'
          }`}
        >
          {jobAnalyticsData.job_details.jobtitle}
        </div>
        <BarChartData chartData={chartData} />
      </div>
      <div
        className={`lg:w-1/2 w-full px-6 ${
          currentLanguageCode === 'ar' ? 'lg:ml-6' : 'lg:mr-6'
        }`}
      >
        <div className=' lg:border-l-1 lg:border-opacity-50 lg:border-primary-rose '>
          <JobAnalyticsData jobData={jobAnalyticsData} />
        </div>
      </div>
    </div>
  );
};

export default JobChartCard;
