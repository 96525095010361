import React from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import AnimatedCircularProgressbarProvider from 'components/animatedCircularProgressbarProvider';

const CircularProgressbar = ({ value, isAnimated }) => {
  if (isAnimated) {
    return (
      <AnimatedCircularProgressbarProvider
        valueStart={0}
        valueEnd={value}
        duration={1}
      >
        {(value) => {
          // const roundedValue = Math.round(value);
          return (
            <CircularProgressbarWithChildren
              value={value}
              styles={buildStyles({
                pathColor: '#EB249E',
                trailColor: 'transparent',
                pathTransition: 'none',
              })}
              strokeWidth={5}
              counterClockwise
            >
              <div
                style={{ fontSize: 12 }}
                className='border border-primary-rose rounded-full h-10 w-10 flex justify-center items-center'
              >
                <strong className='font-bold'>{Math.round(value)}%</strong>
              </div>
            </CircularProgressbarWithChildren>
            //     <CircularProgressbar
            //       value={value}
            //       text={`${roundedValue}%`}
            //       /* This is important to include, because if you're fully managing the
            // animation yourself, you'll want to disable the CSS animation. */
            //       styles={buildStyles({ pathTransition: "none" })}
            //     />
          );
        }}
      </AnimatedCircularProgressbarProvider>
    );
  }

  return (
    <CircularProgressbarWithChildren
      value={value}
      styles={buildStyles({
        pathColor: '#EB249E',
        trailColor: 'transparent',
      })}
      strokeWidth={5}
      counterClockwise
    >
      <div
        style={{ fontSize: 12 }}
        className='border border-primary-rose rounded-full h-10 w-10 flex justify-center items-center'
      >
        <strong className='font-bold'>{value}%</strong>
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressbar;
