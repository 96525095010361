// import { Fab } from 'react-tiny-fab';
// import 'react-tiny-fab/dist/styles.css';
import { useEffect } from 'react';
import AppRoutes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';

import withClearCache from './withClearCache';
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';

import AuthProvider from 'contexts/authContext';
import JobProvider from 'contexts/jobContext';
import { TourProvider } from '@reactour/tour';
import NotificationProvider from 'contexts/notificationContext';

// import whatapp_icon from './assets/icons/whatsapp-96.png';
// import telegram_icon from './assets/icons/telegram-app-96.png';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const CacheClearWithHOC = withClearCache(AppWithProviders);

// const Whatsapp = () => {
//   return (
//     <a href='https://wa.me/917337335560' target='_blank' rel='noreferrer'>
//       <img src={whatapp_icon} alt='Whatapp aptagrim' />
//     </a>
//   );
// };
// const Telegram = () => {
//   return (
//     <a href='https://t.me/Aptagrim' target='_blank' rel='noreferrer'>
//       <img src={telegram_icon} alt='telegram aptagrim' />
//     </a>
//   );
// };

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

function App() {
  const currentLanguageCode = cookies.get('i18next') || 'ar';
  // const currentLanguageCode = cookies.get('i18next') || 'ar';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log('Setting page stuff');
    document.body.dir = currentLanguage.dir || 'ltr';
    localStorage.setItem('dir', JSON.stringify(currentLanguage.dir || 'ltr'));
    // document.title = t('app_title');
  }, [currentLanguage, t]);

  return <CacheClearWithHOC />;
}

function AppWithProviders() {
  const steps = [];

  /**
   * TourProvider implements React Tour in our application
   *
   * we are using data-tour attribute for application wide naming of tour steps
   * using setCurrentStep in useEffect of various components we are controlling React tour
   * React Tour needs to be shown only on first login
   *
   * for docs visit - https://github.com/elrumordelaluz/reactour/tree/master/packages/tour
   *
   */

  return (
    <div className='App' id='App-root'>
      <AuthProvider>
        <TourProvider
          steps={steps}
          showNavigation={false}
          disableKeyboardNavigation={true}
          showBadge={true}
          badgeContent={({ totalSteps, currentStep }) =>
            `${currentStep + 1}/${totalSteps}`
          }
          showCloseButton={false}
          disableInteraction
          onClickMask={() => {}} // for blocking mask click
          styles={{
            popover: (base) => ({
              ...base,
              '--reactour-accent': '#FF009C',
              borderRadius: '0.5rem',
            }),
            maskArea: (base) => ({ ...base, rx: '0.5rem' }),
            maskWrapper: (base) => ({
              ...base,
              color: '#000000',
              opacity: '0.6',
            }),
          }}
        >
          <JobProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <AppRoutes />

                {/*  <Fab
                  icon={<Whatsapp />}
                  alwaysShowTitle={true}
                  style={{ bottom: 52, right: 15 }}
                  mainButtonStyles={{
                    backgroundColor: 'inherit',
                    borderRadius: '0',
                    width: '40px',
                    boxShadow: 'none',
                    transform: 'none',
                    transition: 'none',
                  }}
                  event='click'
                ></Fab>

                <Fab
                  icon={<Telegram />}
                  alwaysShowTitle={true}
                  style={{ bottom: 52, right: 65 }}
                  mainButtonStyles={{
                    backgroundColor: 'inherit',
                    borderRadius: '0',
                    width: '40px',
                    boxShadow: 'none',
                    transform: 'none',
                    transition: 'none',
                  }}
                  event='click'
                ></Fab> */}
              </QueryClientProvider>
            </NotificationProvider>
          </JobProvider>
        </TourProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
