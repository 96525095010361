// This file acts as Popup component of React Tour

import React, { useContext } from 'react';
import { useTour } from '@reactour/tour';
import { AuthContext } from 'contexts/authContext';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/Button/primaryButton';
import cookies from 'js-cookie';

function ReactTourPopUp({
  content,
  isSmallPopUp = false,
  isButtonsVisible = true,
  history,
}) {
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const { authState, authDispatch } = useContext(AuthContext);
  const { t } = useTranslation(['productTour']);
  const currentLanguageCode = cookies.get('i18next');

  const NextButtonFn = () => {
    if (authState.is_recruiter) {
      if (currentStep === 0) {
        history.push('/teams/team?filter=active');
        setTimeout(() => {
          setCurrentStep(1);
        }, 1000);
      } else if (currentStep === 1) {
        setTimeout(() => {
          setCurrentStep(2);
        }, 100);
      } else if (currentStep === 2) {
        history.push('/recruiter/clients');
        setTimeout(() => {
          setCurrentStep(3);
        }, 1000);
      } else if (currentStep === 3) {
        setTimeout(() => {
          setCurrentStep(4);
        }, 100);
      } else if (currentStep === 4) {
        history.push('/assessmentBank');
        setTimeout(() => {
          setCurrentStep(5);
        }, 1000);
      } else if (currentStep === 5) {
        setTimeout(() => {
          setCurrentStep(6);
        }, 100);
      } else if (currentStep === 6) {
        history.push(
          `${
            authState.is_recruiter
              ? '/recruiter/jobs/listings?type=Activated'
              : '/jobs/listings?type=Activated'
          }`
        );
        setTimeout(() => {
          setCurrentStep(7);
        }, 1000);
      } else if (currentStep === 7) {
        setTimeout(() => {
          setCurrentStep(20);
        }, 100);
      } else if (currentStep === 8) {
        history.push('/resume-bank/listing');
        setTimeout(() => {
          setCurrentStep(9);
        }, 1000);
      } else if (currentStep === 9) {
        setTimeout(() => {
          setCurrentStep(25);
        }, 100);
      }
    } else {
      if (currentStep === 0) {
        history.push('/teams/team?filter=active');
        setTimeout(() => {
          setCurrentStep(1);
        }, 1000);
      } else if (currentStep === 1) {
        setTimeout(() => {
          setCurrentStep(2);
        }, 100);
      } else if (currentStep === 2) {
        history.push('/assessmentBank');
        setTimeout(() => {
          setCurrentStep(3);
        }, 1000);
      } else if (currentStep === 3) {
        setTimeout(() => {
          setCurrentStep(4);
        }, 100);
      } else if (currentStep === 4) {
        history.push(
          `${
            authState.is_recruiter
              ? '/recruiter/jobs/listings?type=Activated'
              : '/jobs/listings?type=Activated'
          }`
        );
        setTimeout(() => {
          setCurrentStep(5);
        }, 1000);
      } else if (currentStep === 5) {
        setTimeout(() => {
          setCurrentStep(20);
        }, 100);
      } else if (currentStep === 6) {
        history.push('/resume-bank/listing');
        setTimeout(() => {
          setCurrentStep(7);
        }, 1000);
      } else if (currentStep === 7) {
        setTimeout(() => {
          setCurrentStep(25);
        }, 100);
      }
    }
  };

  const PrevButtonFn = () => {
    if (authState.is_recruiter) {
      if (currentStep === 2) {
        history.push('/teams/team?filter=active');
        setTimeout(() => {
          setCurrentStep(1);
        }, 1000);
      } else if (currentStep === 4 || currentStep === 3) {
        setTimeout(() => {
          setCurrentStep(2);
        }, 100);
      } else if (currentStep === 6 || currentStep === 5) {
        setTimeout(() => {
          setCurrentStep(4);
        }, 100);
      } else if (currentStep === 7 || currentStep === 8) {
        setTimeout(() => {
          setCurrentStep(6);
        }, 100);
      } else if (currentStep === 9) {
        setTimeout(() => {
          setCurrentStep(8);
        }, 100);
      }
    } else {
      if (currentStep === 2) {
        history.push('/teams/team?filter=active');
        setTimeout(() => {
          setCurrentStep(1);
        }, 1000);
      } else if (currentStep === 4 || currentStep === 3) {
        setTimeout(() => {
          setCurrentStep(2);
        }, 100);
      } else if (currentStep === 5 || currentStep === 6) {
        setTimeout(() => {
          setCurrentStep(4);
        }, 100);
      } else if (currentStep === 7) {
        setTimeout(() => {
          setCurrentStep(6);
        }, 100);
      }
    }
  };

  return (
    <div className='relative flex flex-col mx-2'>
      {/* <span
        onClick={() => {
          setIsOpen(false);
          authDispatch({
            type: 'UPDATETOURSTATUS',
            payload: !authState.first_login,
          });
        }}
        className={`cursor-pointer absolute -right-9 -top-5 ${
          isButtonsVisible ? 'hidden' : ''
        }`}
      >
        <svg
          width='32'
          height='32'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FB7C51'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FB7C51'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </span> */}

      <div className='my-4'>{content}</div>
      <div
        className={`mt-4 ${isButtonsVisible ? 'flex' : 'hidden'} ${
          isSmallPopUp && isButtonsVisible ? 'flex-col' : ''
        } justify-center`}
      >
        <button
          disabled={currentStep === 0 || currentStep === 1}
          onClick={() => {
            PrevButtonFn();
          }}
          className={`w-32 ${
            currentStep === 0 || currentStep === 1
              ? 'opacity-60 cursor-not-allowed'
              : ''
          }`}
        >
          <PrimaryButton isLoading={false}>
            <p className='text-sm leading-5'>{t('productTour:prev')}</p>
          </PrimaryButton>
        </button>
        <button
          onClick={() => {
            setIsOpen(false);
            authDispatch({
              type: 'UPDATETOURSTATUS',
              payload: false,
            });
          }}
          className={`${isSmallPopUp ? 'my-2' : 'ml-4'} w-32`}
        >
          <PrimaryButton isLoading={false}>
            <p className='text-sm leading-5'>{t('productTour:quit')}</p>
          </PrimaryButton>
        </button>
        <button
          onClick={() => {
            NextButtonFn();
          }}
          className={`w-32 ${currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'}`}
        >
          <PrimaryButton isLoading={false}>
            <p className='text-sm leading-5'>{t('productTour:next')}</p>
          </PrimaryButton>
        </button>
      </div>
    </div>
  );
}

export default ReactTourPopUp;
