const getUserInitialsFromname = (name = '') => {
  return name
    .match(/(^\S\S?|\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
};

export default getUserInitialsFromname;
