import React, { useContext, useState } from 'react';
import { AuthContext } from 'contexts/authContext';
import Tip from 'components/Tooltip';
import { useQuery } from 'react-query';
// import { useTranslation } from 'react-i18next';
// import cookies from 'js-cookie';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import JobAnalytics from './JobAnalytics';
import MyActivity from './MyActivity';
import ClientJobAssignments from './ClientJobAssignments';
import ClientAnalytics from './ClientAnalytics';
import MeetingsAnalytics from './MeetingsAnalytics';
import MyCheckListPanel from 'components/MyCheckList';

import { ReactComponent as ActivityToggle } from 'assets/images/dashboard/ActivityToggle.svg';

function Overview() {
  const { authState } = useContext(AuthContext);
  // const { t } = useTranslation(['dashboard']);
  // const currentLanguageCode = cookies.get('i18next');
  const [myActivityPanelVisible, setMyActivityPanelVisible] = useState(false);

  const dashboardPendingAssignments = useQuery(
    ['dashboardPendingAssignments', authState.token_client],
    async () => {
      if (authState?.is_client_admin || authState?.is_client_team) {
        return await makeAPIRequest.get(
          `${apiEndPoints.DASHBOARD_CLIENT_PENDING_JOBS}?token=${authState?.token_client}`
        );
      }
    },
    {
      enabled: !!authState?.token_client,
    }
  );

  return (
    <>
      <div
        className={`flex flex-col relative max-w-main-page w-full mx-auto min-h-80vh mt-4 xl:px-10 px-6 pb-8`}
      >
        <div className='flex justify-end w-full mb-3'>
          {authState.is_recruiter || authState.is_recruiter_team ? (
            <Tip content='My Checklist'>
              <ActivityToggle
                onClick={() => {
                  setMyActivityPanelVisible(true);
                }}
                className='h-5 cursor-pointer'
              />
            </Tip>
          ) : null}
        </div>
        <div className='flex flex-col lg:flex-row w-full mb-6'>
          <JobAnalytics
            is_recruiter={authState.is_recruiter || authState.is_recruiter_team}
            is_hiring_manager={
              authState.is_hiring_manager || authState.is_hiring_manager_team
            }
            is_client_admin={
              authState.is_client_admin || authState.is_client_team
            }
            token={authState.token_client}
          />
          {authState.is_recruiter ||
          authState.is_recruiter_team ||
          authState.is_hiring_manager ||
          authState.is_hiring_manager_team ? (
            authState.is_recruiter || authState.is_recruiter_team ? (
              <ClientAnalytics />
            ) : (
              <MyActivity
                setMyActivityPanelVisible={setMyActivityPanelVisible}
              />
            )
          ) : authState.is_client_admin || authState.is_client_team ? (
            <ClientJobAssignments
              dashboardPendingAssignments={dashboardPendingAssignments}
            />
          ) : null}
          <></>
        </div>
        <div className='flex flex-col lg:flex-row w-full'>
          <MeetingsAnalytics
            is_recruiter={authState.is_recruiter || authState.is_recruiter_team}
            is_hiring_manager={
              authState.is_hiring_manager || authState.is_hiring_manager_team
            }
            is_client_admin={
              authState.is_client_admin || authState.is_client_team
            }
            dashboardPendingAssignments={dashboardPendingAssignments}
            token={authState.token_client}
          />
        </div>
        {authState.is_recruiter ||
        authState.is_recruiter_team ||
        authState.is_hiring_manager ||
        authState.is_hiring_manager_team ? (
          <MyCheckListPanel
            myActivityPanelVisible={myActivityPanelVisible}
            setMyActivityPanelVisible={setMyActivityPanelVisible}
          />
        ) : null}
      </div>
    </>
  );
}

export default Overview;
