import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import LottiLoader from 'components/LottiLoader';

import NoPageFound from 'pages/common/error/noPageFound';

import BasePrivateLayout from '../../basePrivateLayout';

import './assessmentBank.styles.scss';

const MainPage = lazy(() => import('./MainPage/MainPage'));
const SkillAssessmentPage = lazy(() =>
  import('./SkillAssessmentPage/SkillAssessmentPage')
);
const PsychometricAssessmentPage = lazy(() =>
  import('./PsychometricAssessmentPage/PsychometricAssessmentPage')
);

const AssessmentBank = () => {
  const { path } = useRouteMatch();
  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='max-w-main-page h-70vh flex justify-center items-center '>
              <LottiLoader />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/main-page`} />
          <Route path={`${path}/main-page`} component={MainPage} />
          <Route
            path={`${path}/skill-assessment/:name/:id/:dificulty/:sortFieldValue/:skillSearchFieldValue`}
            component={SkillAssessmentPage}
          />
          <Route
            path={`${path}/psychometric-assessment/:name/:id/:dificulty/:sortFieldValue/:psySearchFieldValue`}
            component={PsychometricAssessmentPage}
          />

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
};

export default AssessmentBank;
