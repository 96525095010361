import React from 'react';
import PublicHeader from 'components/Header/publicHeader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PrimaryButton from 'components/Button/primaryButton';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as UnderlineIlls } from 'assets/images/plans/underline.svg';
import { ReactComponent as Underline2Ills } from 'assets/images/plans/underline2.svg';
import { ReactComponent as UserIlls } from 'assets/images/plans/user.svg';
import { ReactComponent as DateIlls } from 'assets/images/plans/date.svg';
import { ReactComponent as JobsIlls } from 'assets/images/plans/jobs.svg';
import { ReactComponent as NumberIlls } from 'assets/images/plans/number.svg';
import { ReactComponent as CustomIlls } from 'assets/images/plans/custom.svg';
import { ReactComponent as ClientsIlls } from 'assets/images/plans/clients.svg';
import { ReactComponent as ArrowIlls } from 'assets/images/plans/arrow.svg';
import { ReactComponent as PlanIlls } from 'assets/images/plans/plans.svg';

const Plans = () => {
  const { t } = useTranslation(['common', 'profileSetting']);
  const currentLanguageCode = cookies.get('i18next');
  const { height } = useWindowSize();

  const plansData = useQuery(
    ['plans-data'],
    async () => {
      const { data } = await makeAPIRequest.get(apiEndPoints.GET_ALL_PLANS);
      return data;
    },
    {
      staleTime: 180000,
    }
  );

  return (
    <>
      <PublicHeader />
      <div className='mt-8 pt-8 sm:pt-6 md:pt-6 px-4 w-11/12 border mx-auto shadow-lg bg-white relative h-80vh max-w-main-page'>
        <Tabs className='sm:pt-4 pt-2 select-none'>
          <TabList>
            <Tab>{t('common:hiring_manager')}</Tab>
            <Tab>{t('common:recruiter')}</Tab>
          </TabList>
          <TabPanel>
            <div
              className={`grid grid-flow-row-dense gap-x-12  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:h-full overflow-y-scroll scroller lg:mt-4 mt-8 px-8 text-neutrals-black ${
                height < 420 ? 'h-45vh sm:mt-8' : 'h-60vh sm:mt-10'
              }`}
            >
              {plansData.data?.data?.map((item) => (
                <div
                  className={
                    item.amount === 0
                      ? `p-0.5 rounded-lg z-10 my-8 h-80`
                      : `p-0.5 rounded-lg z-10 my-4 h-84`
                  }
                  style={{
                    backGround: 'rgb(248,247,247)',
                    background:
                      'linear-gradient(106deg, rgba(248,247,247,1) 0%, rgba(251,124,81,1) 100%)',
                  }}
                  key={item.id}
                >
                  <div
                    className={
                      item.amount === 0
                        ? `h-84 py-5 rounded-lg shadow-lg bg-white`
                        : `h-84 py-4 rounded-lg shadow-lg bg-white`
                    }
                  >
                    <p className='font-normal text-lg text-center text-primary-rose'>
                      {item.name}
                    </p>
                    <p className='text-center text-lg text-black font-normal'>
                      {item.amount ? `USD ${item.amount}/ MONTH` : null}
                    </p>
                    <div className='w-36 m-auto'>
                      <Underline2Ills className='h-6' />
                    </div>
                    <div className='w-28 m-auto'>
                      <p className='text-primary-rose'>
                        {item.name === 'FREE TRIAL' ? null : `Billed Monthly`}
                      </p>
                    </div>
                    <div className='w-40 m-auto my-4 text-sm'>
                      <div className='flex pt-2'>
                        <DateIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>{item.validity.split(',')[0]}</p>
                      </div>
                      <div className='flex pt-2'>
                        <UserIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.team_count}{' '}
                          {item.team_count === 1 ? 'user' : 'users'}
                        </p>
                      </div>
                      <div className='flex pt-2'>
                        <JobsIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.job_postings === -1
                            ? t('common:unlimited')
                            : item.job_postings}{' '}
                          {item.job_postings === 1 ? 'Job' : 'Jobs'}
                        </p>
                      </div>
                      <div className='flex pt-2'>
                        <NumberIlls
                          className={`w-5 h-5 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.applicants_count === -1
                            ? 'Unlimited Applicants'
                            : item.applicants_count}{' '}
                          {item.applicants_count === -1 ? null : "CV's / Job"}
                        </p>
                      </div>
                      <div className='w-28 text-center cursor-pointer mx-auto lg:pt-8 sm:pt-8 pt-8'>
                        <PrimaryButton>
                          <p className='text-sm leading-5'>
                            {t('profileSetting:choose_plan')}
                          </p>
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className='p-0.5 rounded-lg z-10 my-4 h-84'
                style={{
                  backGround: 'rgb(248,247,247)',
                  background:
                    'linear-gradient(106deg, rgba(248,247,247,1) 0%, rgba(251,124,81,1) 100%)',
                }}
              >
                <div className='h-84 py-4 rounded-lg shadow-lg bg-white'>
                  <p className='text-lg text-center text-primary-rose'>
                    {t('profileSetting:custom_plan')}
                  </p>
                  <div className='w-36 m-auto'>
                    <UnderlineIlls className='h-6' />
                  </div>
                  <div className='w-4/12 m-auto my-8 text-sm'>
                    <CustomIlls />
                  </div>
                  <p className='text-center text-sm'>Looking for a plan </p>
                  <p className='text-center text-sm'>
                    meeting your requirements?
                  </p>
                  <div className='w-28 text-center cursor-pointer mx-auto lg:mt-9 sm:mt-7 md:pb-6 lg:pb-4 mt-4'>
                    <PrimaryButton>
                      <p className='text-sm leading-5'>
                        {t("profileSetting:let's_discuss")}
                      </p>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
            <div className='absolute bottom-1 invisible lg:visible z-0'>
              <ArrowIlls className='w-full h-16' />
            </div>
            <div className='absolute top-24 left-16 z-0 invisible lg:visible'>
              <PlanIlls />
            </div>
            <div className='absolute bottom-8 right-72 z-0 invisible lg:visible'>
              <PlanIlls />
            </div>
          </TabPanel>
          <TabPanel>
            <div className='grid grid-flow-row-dense sm:gap-8 md:gap-x-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 h-60vh xl:h-full overflow-y-scroll scroller sm:mt-12 lg:mt-4 mt-8 px-8 text-neutrals-black'>
              {plansData.data?.data.map((item) => (
                <div
                  className={
                    item.amount === 0
                      ? `p-0.5 rounded-lg z-10 my-8 h-80`
                      : `p-0.5 rounded-lg z-10 my-4 h-84`
                  }
                  style={{
                    backGround: 'rgb(248,247,247)',
                    background:
                      'linear-gradient(106deg, rgba(248,247,247,1) 0%, rgba(251,124,81,1) 100%)',
                  }}
                  key={item.id}
                >
                  <div
                    className={
                      item.amount === 0
                        ? `h-84 py-5 rounded-lg shadow-lg bg-white`
                        : `h-84 py-4 rounded-lg shadow-lg bg-white`
                    }
                  >
                    <p className='font-normal text-center text-lg text-primary-rose'>
                      {item.name}
                    </p>
                    <p className='text-center text-lg text-black font-normal'>
                      {item.amount ? `USD ${item.amount}/ MONTH` : null}
                    </p>
                    <div className='w-36 m-auto'>
                      <Underline2Ills className='h-6' />
                    </div>
                    <div className='w-28 m-auto'>
                      <p className='font-normal text-primary-rose'>
                        {item.name === 'FREE TRIAL' ? null : `Billed Monthly`}
                      </p>
                    </div>
                    <div className='w-40 m-auto mt-4 text-sm'>
                      <div className='flex pt-2'>
                        <DateIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>{item.validity.split(',')[0]}</p>
                      </div>
                      <div className='flex pt-2'>
                        <UserIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.team_count}{' '}
                          {item.team_count === 1 ? 'user' : 'users'}
                        </p>
                      </div>
                      <div className='flex pt-2'>
                        <JobsIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.job_postings === -1
                            ? t('common:unlimited')
                            : item.job_postings}{' '}
                          {item.job_postings === 1 ? 'Job' : 'Jobs'}
                        </p>
                      </div>
                      <div className='flex pt-2'>
                        <NumberIlls
                          className={`w-5 h-5 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>
                          {item.applicants_count === -1
                            ? 'Unlimited Applicants'
                            : item.applicants_count}{' '}
                          {item.applicants_count === -1 ? null : "CV's / Job"}
                        </p>
                      </div>
                      <div className='flex pt-2'>
                        <ClientsIlls
                          className={`w-6 h-6 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        />
                        <p>{item.clients_count} Clients</p>
                      </div>
                      <div className='w-28 text-center cursor-pointer mx-auto pt-4'>
                        <PrimaryButton>
                          <p className='text-sm leading-5'>
                            {t('profileSetting:choose_plan')}
                          </p>
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className='p-0.5 rounded-lg z-10 my-4 h-84'
                style={{
                  backGround: 'rgb(248,247,247)',
                  background:
                    'linear-gradient(106deg, rgba(248,247,247,1) 0%, rgba(251,124,81,1) 100%)',
                }}
              >
                <div className='h-84 py-4 rounded-lg shadow-lg bg-white'>
                  <p className='text-lg text-center text-primary-rose'>
                    {t('profileSetting:custom_plan')}
                  </p>
                  <div className='w-36 m-auto'>
                    <UnderlineIlls className='h-6' />
                  </div>
                  <div className='w-4/12 m-auto my-8 text-sm'>
                    <CustomIlls />
                  </div>
                  <p className='text-center text-sm'>Looking for a plan </p>
                  <p className='text-center text-sm'>
                    meeting your requirements?
                  </p>
                  <div className='w-28 text-center cursor-pointer mx-auto lg:mt-9 sm:mt-7 md:pb-6 lg:pb-4 mt-4'>
                    <PrimaryButton>
                      <p className='text-sm leading-5'>
                        {t("profileSetting:let's_discuss")}
                      </p>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
            <div className='absolute bottom-1 invisible z-0 lg:visible'>
              <ArrowIlls className='w-full h-16' />
            </div>
            <div className='absolute top-24 left-16 z-0 invisible lg:visible'>
              <PlanIlls />
            </div>
            <div className='absolute bottom-8 right-72 z-0 invisible lg:visible'>
              <PlanIlls />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default Plans;
