import DOMPurify from 'dompurify';

const getSanitizeMarkupData = ({ markup, renderHTML = false }) => {
  if (renderHTML) {
    return {
      __html: DOMPurify.sanitize(markup),
    };
  } else {
    return DOMPurify.sanitize(markup);
  }
};

export default getSanitizeMarkupData;
