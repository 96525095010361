import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import Loader from 'components/Loader';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';
import PrimaryButton from 'components/Button/primaryButton';
import ActivitySingle from './ActivitySingle';

import { ReactComponent as NoActivityIllus } from 'assets/images/dashboard/NoActivityIllus.svg';

const MyCheckListPanel = ({
  myActivityPanelVisible,
  setMyActivityPanelVisible,
}) => {
  const [showAddActivity, setShowAddActivity] = useState(false);

  const queryClient = useQueryClient();
  const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  const { control, handleSubmit, reset } = useForm();

  const getActivity = useQuery(['getActivity'], async () => {
    const resData = await makeAPIRequest.get(
      `${apiEndPoints.DASHBOARD_ACTIVITY_TRACKER}`
    );
    return resData?.data;
  });

  const createNewActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        reset({ activity: '' });
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const updateActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );
  const deleteActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.delete(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        { data }
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const onSubmit = (data) => {
    if (data?.activity?.length > 0 && !createNewActivity.isLoading) {
      createNewActivity.mutate(data);
    }
  };

  return (
    <div className='notification_overlay_container'>
      <Transition.Root show={myActivityPanelVisible} as={Fragment}>
        <Dialog
          as='div'
          auto-reopen='true'
          className='fixed inset-0 '
          style={{ zIndex: '2000' }}
          onClose={setMyActivityPanelVisible}
        >
          <div className='absolute inset-0 '>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 transition-opacity notification-panel-overlay-bg' />
            </Transition.Child>
            <div
              className={` fixed inset-y-0   max-w-full flex ${
                currentLanguageCode === 'ar' ? 'pr-10 left-0' : 'pl-10 right-0'
              }`}
            >
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom={`${
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }`}
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo={`${
                  currentLanguageCode === 'ar'
                    ? '-translate-x-full'
                    : 'translate-x-full'
                }`}
              >
                <div className='relative w-screen max-w-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div
                      className={`absolute -top-2  pt-4  flex   ${
                        currentLanguageCode === 'ar'
                          ? 'pl-2 sm:pl-4 left-0'
                          : 'pr-2 sm:pr-4 right-0'
                      }`}
                    >
                      <button
                        className='rounded-md focus:outline-none'
                        onClick={() => setMyActivityPanelVisible(false)}
                      >
                        <span className='sr-only'>{t('close_panel')}</span>
                        <XIcon
                          className='h-6 w-6 text-primary-rose'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='h-full py-6 bg-white shadow-xl'>
                    {/* <div className='mt-3 relative flex-1 px-4 sm:px-6'>
                      <ul className='flex items-center space-x-4 text-lg'>
                        <li className='pr-2 cursor-pointer border-b-2 border-primary-rose pb-1'>
                          My Checklist
                        </li>
                      </ul>
                    </div> */}
                    <div className='px-4 my-4 mt-6 overflow-y-auto min-h-86vh h-full max-h-86vh scroller'>
                      <div className='w-full h-full flex flex-col px-6'>
                        <div className='flex justify-between items-center  w-full'>
                          <div className='text-lg 2xl:text-xl text-primary-rose font-bold pb-2'>
                            {t('my_checklist')}
                          </div>
                        </div>
                        {getActivity.isLoading ? (
                          <div className=' flex flex-1 flex-col justify-between'>
                            <div className='flex flex-1 min-h-80 max-h-96 flex-col scroller overflow-y-auto'>
                              <div className='flex my-4'>
                                <span
                                  className={`w-6 h-6 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-2'
                                      : 'mr-2'
                                  }`}
                                >
                                  <Skeleton height='1.5rem' width='1.5rem' />
                                </span>{' '}
                                <Skeleton height='1.25rem' width='20rem' />
                                <span
                                  className={`w-1 h-4 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'mr-4'
                                      : 'ml-4'
                                  }`}
                                >
                                  <Skeleton height='1rem' width='0.25rem' />
                                </span>{' '}
                              </div>
                              <div className='flex my-4'>
                                <span
                                  className={`w-6 h-6 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-2'
                                      : 'mr-2'
                                  }`}
                                >
                                  <Skeleton height='1.5rem' width='1.5rem' />
                                </span>{' '}
                                <Skeleton height='1.25rem' width='20rem' />
                                <span
                                  className={`w-1 h-4 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'mr-4'
                                      : 'ml-4'
                                  }`}
                                >
                                  <Skeleton height='1rem' width='0.25rem' />
                                </span>{' '}
                              </div>
                              <div className='flex my-4'>
                                <span
                                  className={`w-6 h-6 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-2'
                                      : 'mr-2'
                                  }`}
                                >
                                  <Skeleton height='1.5rem' width='1.5rem' />
                                </span>{' '}
                                <Skeleton height='1.25rem' width='20rem' />
                                <span
                                  className={`w-1 h-4 rounded    ${
                                    currentLanguageCode === 'ar'
                                      ? 'mr-4'
                                      : 'ml-4'
                                  }`}
                                >
                                  <Skeleton height='1rem' width='0.25rem' />
                                </span>{' '}
                              </div>
                            </div>
                            <div className='flex my-2'>
                              <Skeleton height='2.5rem' width='100%' />
                            </div>
                          </div>
                        ) : getActivity?.data?.data.length ||
                          showAddActivity ? (
                          <div className=' flex flex-1 flex-col justify-between'>
                            <div className='flex flex-1 min-h-80 max-h-96 flex-col scroller overflow-y-auto'>
                              {getActivity?.data?.data.length ? (
                                getActivity?.data?.data.map((activity) => (
                                  <ActivitySingle
                                    key={activity.id}
                                    activity={activity}
                                    updateActivity={updateActivity}
                                    deleteActivity={deleteActivity}
                                  />
                                ))
                              ) : (
                                <div className='flex h-80 mb-4 justify-center items-center flex-col'>
                                  <NoActivityIllus />
                                  <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                                    {t('add_items_todo_text')}
                                  </span>
                                </div>
                              )}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className='flex items-center'>
                                <div className='flex-1 searchFunctionality'>
                                  <Controller
                                    name='activity'
                                    control={control}
                                    render={({ field }) => (
                                      <InputText
                                        id={field.name}
                                        {...field}
                                        placeholder={t(
                                          'start_typing_here_and_press_enter'
                                        )}
                                        className={` ${
                                          currentLanguageCode === 'ar'
                                            ? 'pr-2'
                                            : 'pl-2'
                                        }`}
                                      />
                                    )}
                                  />
                                </div>
                                {createNewActivity.isLoading ? (
                                  <div className='w-6 h-6'>
                                    <Loader color='#6600C9' />
                                  </div>
                                ) : null}
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div className=' flex justify-center items-center'>
                            <div className='flex h-96 mb-4 justify-center items-center flex-col'>
                              <NoActivityIllus />
                              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                                {t('add_items_todo_text')}
                              </span>
                              <div className='mt-6'>
                                <button
                                  onClick={() => setShowAddActivity(true)}
                                  className='w-24 md:w-32 text-center cursor-pointer mx-auto my-4'
                                >
                                  <PrimaryButton isLoading={false}>
                                    <p className='text-sm '>{t('add_now')}</p>
                                  </PrimaryButton>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MyCheckListPanel;
