import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'contexts/authContext';

import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

import queryKeys from '../reactQuery/constant';

export default function UseGetSpecificJobStageList({ enable = true, jobId }) {
  const { authState } = useContext(AuthContext);
  const url = `${
    authState.is_client_admin || authState.is_client_team
      ? apiEndPoints.SPECIFICCLIENT_JOB_POSTINGTAGE
      : apiEndPoints.SPECIFIC_JOBSTAGE
  }?job_id=${jobId}${
    authState.is_client_admin || authState.is_client_team
      ? `&token=${authState.token_client}`
      : ''
  }`;

  return useQuery(
    [queryKeys.specificJobStage, enable, jobId],
    async () => {
      const { data } = await makeAPIRequest.get(url);
      return data.data;
    },
    {
      staleTime: 180000,
      enabled: enable,
    }
  );
}
