import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Skeleton } from 'primereact/skeleton';

import PrimaryButton from 'components/Button/primaryButton';

// import UseGetApplicationFormData from 'hooks/useGetApplicationFormData';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';

import queryKeys from 'reactQuery/constant';

const EditApplicantsDetailsModal = ({
  showApplicantsEditModal,
  setShowApplicantsEditModal,
  applicantsData,
}) => {
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const jobApplicationData = useQuery(
    ['job-application-data2'],
    async () => {
      const { data } = await makeAPIRequest.get(
        apiEndPoints.JOB_APPLICATION_API
      );
      const newArray = data?.data.map((field) => {
        if (
          field?.be_name === 'name' ||
          field?.be_name === 'email' ||
          // field?.be_name === 'your_current_position' ||
          field?.be_name === 'country'
        ) {
          return {
            ...field,
            mandatory: true,
          };
        } else {
          return {
            ...field,
            mandatory: false,
          };
        }
      });
      return newArray;
    },
    { enabled: showApplicantsEditModal }
  );

  const queryClient = useQueryClient();

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='p-error text-xs'>{errors[name].message}</small>
      )
    );
  };

  const saveDefaultvalue = (name) => {
    switch (name) {
      case 'name':
        return applicantsData.name;
      case 'email':
        return applicantsData.email;
      case 'mobile_number':
        return applicantsData.mobile_number;
      case 'relevant_experience':
        return applicantsData.relevant_experience;
      case 'notice_period_days':
        return applicantsData.notice_period_days;
      case 'your_current_location':
        return applicantsData.your_current_location;
      case 'your_current_position':
        return applicantsData.your_current_position;
      case 'country':
        return applicantsData.country;

      default:
        return applicantsData[name];
    }
  };

  const updateApplicantDetailsMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.JOB_APPLICANTS_DETAILS_UPDATE,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.specificApplicant &&
            query.queryKey[2] === applicantsData._id,
        });
        queryClient.invalidateQueries('all-applicants');
        setShowApplicantsEditModal(false);
      },
      onError: (err) => {
        ErrorToast({ text: err?.response?.data?.message });
      },
    }
  );

  const onSubmit = (data) => {
    updateApplicantDetailsMutation.mutate({
      data: data,
      applicant_id: applicantsData._id,
    });
  };

  const LoaderPdf = (
    <div className='flex flex-col space-y-2 w-35rem mt-6 overflow-hidden'>
      <Skeleton width='90%' height='1.2rem' />
      <Skeleton width='40%' height='1.2rem' />
      <Skeleton width='30%' height='1.2rem' />
      <Skeleton width='60%' height='1.2rem' />
      <Skeleton width='80%' height='1.2rem' />
      <Skeleton width='90%' height='1.2rem' />
      <Skeleton width='40%' height='1.2rem' />
      <Skeleton width='50%' height='1.2rem' />
      <Skeleton width='70%' height='1.2rem' />
      <Skeleton width='30%' height='1.2rem' />
      <Skeleton width='80%' height='1.2rem' />
      <Skeleton width='50%' height='1.2rem' />
      <Skeleton width='40%' height='1.2rem' />
    </div>
  );

  const header = (
    <div className='flex items-start justify-between'>
      <div></div>
      <p className='text-3xl text-primary-rose text-center'>
        {t('jobsAndApplicants:update_profile')}
      </p>
      <button
        className='z-50 px-2 py-1'
        onClick={() => {
          setShowApplicantsEditModal(false);
          reset();
        }}
      >
        <svg
          width='23'
          height='23'
          viewBox='0 0 23 23'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M20.2939 2.95898L2.29395 20.959'
            stroke='#FF009C'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M2.29395 2.95898L20.2939 20.959'
            stroke='#FF009C'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  const footer = (
    <div className='flex justify-center mt-4'>
      {jobApplicationData.isLoading ? (
        <Skeleton height='2.3rem' width='7rem' />
      ) : (
        <button
          className='w-40 outline-none '
          onClick={handleSubmit((d) => onSubmit(d))}
        >
          <PrimaryButton isLoading={updateApplicantDetailsMutation.isLoading}>
            <p className='text-base leading-5'>{t('common:save')}</p>
          </PrimaryButton>
        </button>
      )}
    </div>
  );

  return (
    <div>
      <Dialog
        visible={showApplicantsEditModal}
        onHide={() => {
          setShowApplicantsEditModal(false);
          reset();
        }}
        header={header}
        footer={footer}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        className='h-90vh max-h-90vh w-11/12 mx-auto rounded-xl overflow-hidden'
        style={{ maxWidth: '1200px' }}
      >
        <div className='mt-6 relative flex'>
          <div className='h-60vh overflow-y-auto overflow-x-auto scroller hidden lg:block'>
            <Document
              file={applicantsData?.resume}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={LoaderPdf}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
          <form className='lg:w-1/2 w-full px-10 relative lg:h-60vh lg:overflow-y-auto scroller py-8'>
            {jobApplicationData.isLoading ? (
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8 gap-y-12'>
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
                <Skeleton height='3rem' />
              </div>
            ) : (
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1  gap-8 gap-y-12'>
                {jobApplicationData?.data?.map((fieldItem) => {
                  if (
                    fieldItem.be_name === 'upload_image' ||
                    fieldItem.be_name === 'resume' ||
                    fieldItem.field_type === 'file'
                  ) {
                    return null;
                  }
                  return (
                    <div key={fieldItem._id}>
                      {fieldItem.field_type === 'text' ? (
                        <span className='p-float-label'>
                          <Controller
                            name={fieldItem.be_name}
                            control={control}
                            rules={
                              fieldItem.mandatory
                                ? {
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                  }
                                : {}
                            }
                            render={({ field }) => {
                              return (
                                <InputText id={field.be_name} {...field} />
                              );
                            }}
                            defaultValue={() =>
                              saveDefaultvalue(fieldItem.be_name)
                            }
                          />
                          <label htmlFor={fieldItem.be_name}>
                            {`${fieldItem.name} ${
                              fieldItem.mandatory ? '*' : ''
                            }`}
                          </label>
                        </span>
                      ) : fieldItem.field_type === 'email' ? (
                        <span className='p-float-label'>
                          <Controller
                            name={fieldItem.be_name}
                            control={control}
                            rules={
                              fieldItem.mandatory
                                ? {
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: t('authentication:invalid_email_address'),
                                    },
                                  }
                                : {
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: t('authentication:invalid_email_address'),
                                    },
                                  }
                            }
                            render={({ field }) => {
                              return (
                                <InputText id={field.be_name} {...field} />
                              );
                            }}
                            defaultValue={() =>
                              saveDefaultvalue(fieldItem.be_name)
                            }
                          />
                          <label htmlFor={fieldItem.be_name}>
                            {`${fieldItem.name} ${
                              fieldItem.mandatory ? '*' : ''
                            }`}
                          </label>
                        </span>
                      ) : fieldItem.field_type === 'int' ? (
                        <span className='p-float-label'>
                          <Controller
                            name={fieldItem.be_name}
                            control={control}
                            rules={
                              fieldItem.mandatory
                                ? {
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                    pattern: {
                                      value:
                                        fieldItem.be_name ===
                                        'relevant_experience'
                                          ? /^\d+(\.\d+)?$/
                                          : /^[0-9]*$/i,
                                      message: 'Please provide numbers',
                                    },
                                  }
                                : {
                                    pattern: {
                                      value:
                                        fieldItem.be_name ===
                                        'relevant_experience'
                                          ? /^\d+(\.\d+)?$/
                                          : /^[0-9]*$/i,
                                      message: 'Please provide numbers',
                                    },
                                  }
                            }
                            render={({ field }) => {
                              return (
                                <InputText id={field.be_name} {...field} />
                              );
                            }}
                            defaultValue={() =>
                              saveDefaultvalue(fieldItem.be_name)
                            }
                          />
                          <label htmlFor={fieldItem.be_name}>
                            {`${fieldItem.name} ${
                              fieldItem.mandatory ? '*' : ''
                            }`}
                          </label>
                        </span>
                      ) : fieldItem.field_type === 'drop_down' ? (
                        <span className='p-float-label'>
                          <Controller
                            name={fieldItem.be_name}
                            control={control}
                            rules={
                              fieldItem.mandatory
                                ? {
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                  }
                                : {}
                            }
                            render={({ field }) => (
                              <Dropdown options={fieldItem.values} {...field} />
                            )}
                            defaultValue={() =>
                              saveDefaultvalue(fieldItem.be_name)
                            }
                          />
                          <label htmlFor={fieldItem.be_name}>
                            {`${fieldItem.name} ${
                              fieldItem.mandatory ? '*' : ''
                            }`}
                          </label>
                        </span>
                      ) : fieldItem.field_type === 'date' ? (
                        <span className='p-float-label'>
                          <Controller
                            name={fieldItem.be_name}
                            control={control}
                            rules={
                              fieldItem.mandatory
                                ? {
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                  }
                                : {}
                            }
                            render={({ field }) => (
                              <Calendar
                                id={field.be_name}
                                dateFormat='dd/mm/yy'
                                monthNavigator
                                yearNavigator
                                yearRange='1960:2030'
                                showIcon
                                {...field}
                                onSelect={(e) => {
                                  field.onChange(e.value);
                                }}
                              />
                            )}
                            defaultValue={() =>
                              isValidDate(
                                new Date(saveDefaultvalue(fieldItem.be_name))
                              )
                                ? new Date(saveDefaultvalue(fieldItem.be_name))
                                : null
                            }
                          />
                          <label htmlFor={fieldItem.be_name}>
                            {`${fieldItem.name} ${
                              fieldItem.mandatory ? '*' : ''
                            }`}
                          </label>
                        </span>
                      ) : null}
                      {getFormErrorMessage(fieldItem.be_name)}
                    </div>
                  );
                })}
              </div>
            )}
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default EditApplicantsDetailsModal;
