import React, { useState, useContext } from 'react';
import { useAtomValue } from 'jotai/utils';
import { Dialog } from 'primereact/dialog';
import PrimaryButton from 'components/Button/primaryButton';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { useMutation, useQuery } from 'react-query';
import ComposeEmail from './composeEmailmodal';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { AuthContext } from 'contexts/authContext';

import { isjobDeactivatedAtomREC } from '../../InterviewStages/container';

import { ReactComponent as MailsIlls } from 'assets/images/notes/MailsIlls.svg';
import { ReactComponent as SeenIlls } from 'assets/images/notes/seenIlls.svg';
import { ReactComponent as ReplyIlls } from 'assets/images/notes/replyIlls.svg';
import { ReactComponent as EmailSettingsIlls } from 'assets/images/settings/emailsettings.svg';
import dateFormatter from 'helper/dateFormatter';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';

export default function ApplicantMails({
  showEmailModal,
  setShowEmailModal,
  applicantEmail,
  enableQuery,
  setEmailCount,
}) {
  const { t } = useTranslation(['jobsAndApplicants']);
  const currentLanguageCode = cookies.get('i18next');

  const [composeEmail, setComposeEmail] = useState(false);
  const [emailData, setEmailData] = useState([]);

  const { authState } = useContext(AuthContext);

  const isjobDeactivated = useAtomValue(isjobDeactivatedAtomREC);

  const header = (
    <div>
      <div className='flex justify-center'>
        <p className='text-center flex-1 text-primary-rose text-2xl mt-1'>
          {t('jobsAndApplicants:mails')}
        </p>
        <div className='z-50 flex justify-end'>
          <svg
            width='40'
            height='40'
            viewBox='0 0 67 65'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
            className='cursor-pointer'
            onClick={() => setShowEmailModal(false)}
          >
            <path
              d='M41.3962 24.3962L24.9824 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M24.9824 24.3962L41.3962 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
      <div className='w-full flex justify-end mt-4'>
        {isjobDeactivated ? (
          <button
            className='w-36 whitespace-nowrap text-base z-50 cursor-not-allowed opacity-50'
            disabled
          >
            <PrimaryButton>
              + {t('jobsAndApplicants:compose_mail')}
            </PrimaryButton>
          </button>
        ) : (
          <button
            className='w-36 whitespace-nowrap text-base z-50'
            onClick={() => {
              setComposeEmail(true);
              setShowEmailModal(false);
            }}
          >
            <PrimaryButton>
              + {t('jobsAndApplicants:compose_mail')}
            </PrimaryButton>
          </button>
        )}
      </div>
    </div>
  );

  // eslint-disable-next-line no-unused-vars
  const getApplicantMails = useQuery(
    ['get-applicant-mails'],
    async () => {
      const res = await makeAPIRequest.get(
        `${
          authState.is_client_admin || authState.is_client_team
            ? apiEndPoints.CLIENT_APPLICANTS_EMAILS
            : apiEndPoints.APPLICANTS_EMAILS
        }?applicant_email=${applicantEmail}${
          authState.is_client_admin || authState.is_client_team
            ? `&token=${authState.token_client}`
            : ''
        }`
      );
      return res.data;
    },
    {
      enabled: enableQuery,
      onSuccess: (data) => {
        setEmailCount(data.not_seen_count);
        // updating data with showless attribute
        setEmailData(data.data.map((item) => ({ ...item, showLess: true })));
      },
    }
  );

  const handleClick = (data) => {
    updateSeenStatus.mutate({
      email_ids: [data._id],
      token: authState.token_client,
    });
  };

  const updateSeenStatus = useMutation(async (data) => {
    const res = await makeAPIRequest.put(
      authState.is_client_admin || authState.is_client_team
        ? apiEndPoints.CLIENT_APPLICANTS_EMAILS
        : apiEndPoints.APPLICANTS_EMAILS,
      data
    );
    return res;
  });

  // html file to plain text
  function getText(html) {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || '';
  }

  const handleviewMore = (data) => {
    const newData = emailData.map((item) => {
      return item._id === data._id
        ? { ...item, showLess: !item.showLess }
        : { ...item };
    });
    setEmailData(newData);
  };

  return (
    <div className='relative'>
      <Dialog
        header={header}
        visible={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        style={{
          borderRadius: '1rem',
        }}
        className='w-11/12 max-w-5xl lg:w-11/12 lg:max-w-5xl h-90vh rounded-lg'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
        appendTo='self'
      >
        <div className='absolute top-0 left-0 opacity-80'>
          <MailsIlls className='h-28 transform -translate-x-20' />
        </div>
        {emailData?.length > 0 ? (
          <div
            className={`h-full overflow-y-scroll scroller ${
              currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
            }`}
          >
            {emailData?.map((item) => (
              <div key={item._id}>
                <p className='text-gray-1'>{item.from_user}</p>
                <div className='flex py-1 text-Neutrals-gray-1'>
                  <p
                    className={`text-xs font-semibold ${
                      currentLanguageCode === 'ar' ? 'pl-8' : 'pr-8'
                    }`}
                  >
                    {item.to_user}
                  </p>
                  <p className='text-xs font-semibold'>
                    {dateFormatter(
                      item.local_message_date,
                      'DD MMM YYYY, hh:mm A'
                    )}
                  </p>
                </div>
                {item.showLess ? (
                  <div className='flex'>
                    <div
                      className={`w-11/12 text-justify text-gray-2 pt-2 text-sm ${
                        currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'
                      }`}
                    >
                      {applicantEmail === item.from_user ? (
                        <p className='whitespace-nowrap truncate'>{`${getText(
                          item.message
                        ).slice(0, 120)}...`}</p>
                      ) : (
                        <p className='whitespace-nowrap truncate'>{`${getText(
                          item.message
                        ).slice(50, 180)}...`}</p>
                      )}
                    </div>
                    <div
                      className='w-1/12 text-primary-rose text-xs mt-2 select-none cursor-pointer hover:underline whitespace-nowrap'
                      onClick={() => {
                        handleviewMore(item);
                        handleClick(item);
                      }}
                    >
                      {t('jobsAndApplicants:view_more')}
                    </div>
                  </div>
                ) : (
                  <div className='flex'>
                    <div
                      className={`w-11/12 text-justify text-gray-2 pt-2 text-sm ${
                        currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'
                      }`}
                      onClick={() => handleClick(item)}
                      dangerouslySetInnerHTML={getSanitizeMarkupData({
                        markup: item.message,
                        renderHTML: true,
                      })}
                    ></div>
                    <div
                      className='w-1/12 text-primary-rose text-xs mt-2 select-none cursor-pointer hover:underline'
                      onClick={() => handleviewMore(item)}
                    >
                      {t('jobsAndApplicants:view_less')}
                    </div>
                  </div>
                )}
                {applicantEmail === item.from_user ? (
                  <div className='flex'>
                    <div className='w-20 text-base'>
                      <button
                        className='mt-2 w-20 flex border-primary-rose border-1 py-1 px-2 rounded-md text-primary-rose'
                        onClick={() => {
                          setComposeEmail(true);
                          setShowEmailModal(false);
                        }}
                      >
                        <ReplyIlls
                          className={`w-4 h-4 mt-1.5 ${
                            currentLanguageCode === 'ar' ? 'pl-1' : 'pr-1'
                          }`}
                        />
                        <p>{t('jobsAndApplicants:reply')}</p>
                      </button>
                    </div>
                    <div
                      className={`flex justify-end w-full ${
                        currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                      }`}
                    >
                      {item.seen ? (
                        <>
                          <SeenIlls
                            className={`w-4 h-4 mt-5 ${
                              currentLanguageCode === 'ar' ? 'pl-0.5' : 'pr-0.5'
                            }`}
                          />
                          <p className='text-sm text-primary-rose mt-4'>
                            {t('jobsAndApplicants:seen')}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <hr className='mx-2 mt-4 mb-2 border-primary-rose opacity-40' />
              </div>
            ))}
          </div>
        ) : (
          <div className='w-60 mx-auto mt-8'>
            <EmailSettingsIlls
              className={`w-60 h-60 ${
                currentLanguageCode === 'ar' ? '-mr-8' : '-ml-8'
              }`}
            />
            <p
              className={`text-center -mt-4 ${
                currentLanguageCode === 'ar' ? '-mr-10' : '-ml-10'
              }`}
            >
              {t('jobsAndApplicants:no_emails_to_show')}
            </p>
          </div>
        )}
      </Dialog>
      <ComposeEmail
        setComposeEmail={setComposeEmail}
        composeEmail={composeEmail}
        applicantEmail={applicantEmail}
        setShowEmailModal={setShowEmailModal}
      />
    </div>
  );
}
