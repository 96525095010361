import React from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import BaseButton from 'components/Button/baseButton';
import PrimaryButton from 'components/Button/primaryButton';

const ReloginModal = ({
  isReloginModal,
  setIsReloginModal,
  mutationLogin,
  loading,
}) => {
  const { t } = useTranslation(['common', 'psychometricReport']);
  const currentLanguageCode = cookies.get('i18next');

  return (
    <div>
      <Dialog
        visible={isReloginModal}
        onHide={() => setIsReloginModal(false)}
        breakpoints={{ '960px': '50vw', '700px': '35vw', '600px': '80vw' }}
        style={{
          width: '35vw',
          borderRadius: '1rem',
        }}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        // appendTo={rootElement}
        closable={false}
        className=''
      >
        <div className='relative w-full z-40'>
          {/* <div className='flex justify-center items-center w-1/3 mx-auto'>
            <LogoutIlls />
          </div> */}
          <p className='text-xl text-center mt-4'>
            {t(
              'psychometricReport:you_already_have_a_active_session_are_you_sure_you_want_to'
            )}{' '}
            <span className='font-bold'>{t('psychometricReport:log_in')}</span>
            {t('psychometricReport:in_this_browser')}
          </p>
          <div className='w-full flex justify-center items-center mt-8'>
            <button
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              onClick={() => setIsReloginModal(false)}
            >
              <BaseButton>{t('common:no')}</BaseButton>
            </button>
            <button
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              onClick={mutationLogin}
            >
              <PrimaryButton isLoading={loading}>
                <p className='w-full'>{t('common:yes')}</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
        {/* <div className='absolute bottom-6 w-full h-auto left-0'>
          <ForgotPasswordIlls />
        </div> */}
      </Dialog>
    </div>
  );
};

export default ReloginModal;
