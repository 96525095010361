import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import { useDebounce } from 'react-use';

import LoadMoreButton from 'components/Button/LoadMoreButton';

import JobChartCard from '../jobAnalytics/Components/jobChartCard.jsx';

import { ReactComponent as Noanalytics } from 'assets/images/dashboard/noanalytics.svg';

const Jobanalytics = () => {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [searchFieldValue, setSearchFieldValue] = useState(
    parsed?.search || ''
  );

  const [debouncedsearchFieldValue, setDebouncedSearchFieldValue] = useState(
    parsed?.search || ''
  );

  const [,] = useDebounce(
    () => {
      setDebouncedSearchFieldValue(searchFieldValue);
    },
    500,
    [searchFieldValue]
  );

  const {
    data: jobAnalyticsData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['job-analytics-data', debouncedsearchFieldValue],
    async ({ pageParam }) => {
      let data;
      data = await makeAPIRequest.get(
        createQueryParamsForGetReq(apiEndPoints.JOB_ANALYTICS_DATA, {
          paginate_id: pageParam,
          search: debouncedsearchFieldValue,
        })
      );
      return data;
    },
    {
      staleTime: 180000,
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.last_page === false
          ? lastPage?.data?.last_id
          : undefined;
      },
    }
  );

  const handelJobSearchFieldvalue = (val) => {
    setSearchFieldValue(val);

    if (val === '') {
      history.push(`${location.pathname}`);
    }
    if (val !== '' && !location.search.includes('?search')) {
      history.push(`${location.pathname}${location.search}?search=${val}`);
    }

    if (val !== '' && location.search.includes('search')) {
      history.push(
        `${location.pathname}${location.search
          .split('?')
          .filter((el) => !el.startsWith('sear'))
          .join('?')}?search=${val}`
      );
    }
  };

  return (
    <div className='flex flex-col '>
      <div className='max-w-screen-xl mx-auto flex flex-row w-full pt-6 lg:pt-4 2xl:pt-8 lg:px-10 2xl:px-10 md:px-4 justify-between items-center text-xs sm:text-base lg:text-lg '>
        {' '}
        <div className='flex items-center text-2xl 2xl:text-3xl font-bold text-primary-rose '>
          {t('dashboard:job_analytics')}
        </div>
        <div className='flex justify-end items-center'>
          <div className='relative w-48 lg:w-40'>
            <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
              <svg
                width='16'
                height='16'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='https://www.w3.org/2000/svg'
              >
                <path
                  d='M18.8686 17.6544L15.385 14.199C16.7372 12.513 17.3921 10.3729 17.2149 8.21892C17.0377 6.06492 16.042 4.06066 14.4324 2.61827C12.8229 1.17588 10.7219 0.404985 8.56141 0.464103C6.40094 0.523221 4.34523 1.40786 2.81697 2.93611C1.28872 4.46437 0.40408 6.52008 0.344962 8.68055C0.285844 10.841 1.05674 12.942 2.49913 14.5516C3.94152 16.1611 5.94578 17.1568 8.09979 17.334C10.2538 17.5112 12.3938 16.8564 14.0798 15.5041L17.5353 18.9596C17.6225 19.0476 17.7264 19.1174 17.8408 19.1651C17.9552 19.2128 18.078 19.2373 18.2019 19.2373C18.3259 19.2373 18.4486 19.2128 18.563 19.1651C18.6775 19.1174 18.7813 19.0476 18.8686 18.9596C19.0378 18.7845 19.1324 18.5505 19.1324 18.307C19.1324 18.0635 19.0378 17.8295 18.8686 17.6544ZM8.81219 15.5041C7.51221 15.5041 6.24142 15.1187 5.16052 14.3964C4.07963 13.6742 3.23718 12.6477 2.73969 11.4466C2.24221 10.2456 2.11205 8.92403 2.36566 7.64903C2.61928 6.37403 3.24528 5.20287 4.1645 4.28364C5.08373 3.36442 6.25489 2.73842 7.52989 2.4848C8.80489 2.23119 10.1265 2.36135 11.3275 2.85884C12.5285 3.35632 13.5551 4.19877 14.2773 5.27966C14.9995 6.36056 15.385 7.63134 15.385 8.93132C15.385 10.6745 14.6925 12.3464 13.4599 13.579C12.2272 14.8116 10.5554 15.5041 8.81219 15.5041Z'
                  fill='#6600C9'
                />
              </svg>
            </div>
            <input
              type='text'
              value={searchFieldValue}
              onChange={({ currentTarget }) => {
                handelJobSearchFieldvalue(currentTarget.value);
              }}
              className={`h-10 focus:ring-primary-rose border-b-2 focus:border-primary-rose outline-none w-full bg-transparent sm:text-sm border-primary-rose ${
                currentLanguageCode === 'ar' ? ' pr-5 pl-1' : ' pl-5 pr-1'
              }`}
              placeholder='Search jobs '
            />
          </div>
        </div>
      </div>
      <div className='w-full max-w-7xl mx-auto'>
        <>
          {isLoading ? (
            <>
              <JobChartCard isLoading={true} />
            </>
          ) : (
            <>
              {' '}
              {jobAnalyticsData?.pages[0]?.data?.data?.length > 0 ? (
                <>
                  {' '}
                  {jobAnalyticsData?.pages?.map((page, index) => (
                    <React.Fragment key={index}>
                      {page?.data?.data?.map((jobanAlytics) => (
                        <JobChartCard
                          key={jobanAlytics._id}
                          jobAnalyticsData={jobanAlytics}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <div className='flex flex-col justify-center lg:justify-none items-center lg:flex-row  w-auto h-auto mx-10 my-5'>
                  <div className='flex flex-col py-28'>
                    <div className='flex justify-center items-center'>
                      <Noanalytics />
                    </div>
                    <div className='flex justify-center text-center pt-4'>
                      {t('dashboard:no_data_to_show')}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div
            className={`${
              hasNextPage ? 'flex' : 'hidden'
            } mt-16 mb-4 justify-center`}
          >
            <div onClick={fetchNextPage} className='flex'>
              <button className='w-36 lg:w-40'>
                <LoadMoreButton isLoading={isFetchingNextPage} />
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Jobanalytics;
