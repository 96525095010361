import React from 'react';

import fontRegular from '../../../../../assets/fonts/Lato-Regular.ttf';
import fontBold from '../../../../../assets/fonts/Lato-Bold.ttf';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import infoIlls from 'assets/images/applicants/infoIlls.png';
import rightIlls from 'assets/images/applicants/rightIlls.png';
import wrongIlls from 'assets/images/applicants/wrongIlls.png';
import percentageIlls from 'assets/images/applicants/percentageIlls.png';
import SkillCoverPage from 'assets/images/applicants/SkillCoverPage.png';
import dayjs from 'dayjs';
import RichText from './RichText';

Font.register({
  family: 'Lato',
  fonts: [
    { src: fontRegular, fontWeight: 400 },
    { src: fontBold, fontWeight: 700 },
  ],
});

export default function SkillAssessmentPdf({
  name = 'SKILL ASSESSMENT REPORT',
  getApplicantData,
  totalPercentage,
  imageUrl,
  assesmentInfo,
}) {
  const styles = StyleSheet.create({
    page: {
      paddingBottom: '30pt',
    },
    mainView: {
      backgroundColor: '#ED2C94',
      fontFamily: 'Lato',
      marginBottom: '5pt',
    },
    header: {
      textAlign: 'center',
      fontSize: 20,
      color: '#ffffff',
      padding: 20,
      fontWeight: 'bold',
    },
    content: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#ffffff',
      paddingBottom: 20,
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: 20,
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexWithJusCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    subHeading: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
    },
    chartMain: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    imageContainer: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textRotate90: {
      fontSize: 9,
      paddingBottom: 30,
      color: 'rgba(142, 142, 142, 1)',
    },
    chartImage: {
      display: 'flex',
      width: '100%',
    },
    colorAndText: {
      display: 'flex',
      flexDirection: 'row',
    },
    orangeSquare: {
      width: 10,
      height: 10,
      backgroundColor: '#ED2C94',
      marginRight: 5,
      marginLeft: 10,
    },
    greySquare: {
      width: 10,
      height: 10,
      backgroundColor: '#858585',
      marginRight: 5,
      marginLeft: 10,
    },
    smallText: {
      fontSize: 8,
    },
    sectionTwo: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 40,
      marginBottom: 5,
    },
    footerSection: {
      position: 'absolute',
      paddingLeft: 20,
      paddingRight: 20,
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
      opacity: 0.6,
    },
    // pageNumber: {
    //   position: 'absolute',
    //   fontSize: 12,
    //   bottom: 5,
    //   right: 10,
    //   textAlign: 'center',
    //   color: 'grey',
    // },
  });

  return (
    <>
      <Document>
        <Page
          size='A4'
          style={{
            flexDirection: 'row',
            backgroundColor: '#ED2C94',
            width: '100%',
            height: '100%',
            orientation: 'portrait',
          }}
        >
          <View
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Image
              source={SkillCoverPage}
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            />
          </View>
        </Page>
        <Page size='A4' wrap style={styles.page}>
          <View style={styles.mainView} fixed>
            {/* <Text style={styles.header}>SKILL ASSESSMENT REPORT</Text> */}
            <View style={styles.headerContent}>
              <Text style={[styles.content, { fontSize: 11 }]}>
                {' '}
                {getApplicantData?.data?.other_info?.applicant_name}
              </Text>
              <Text style={[styles.content, { fontSize: 11 }]}>
                Assessment Date:{' '}
                {getApplicantData?.data?.other_info?.assessment_date}
              </Text>
              <Text style={[styles.content, { fontSize: 11 }]}>
                Role : {getApplicantData?.data?.other_info?.role}
              </Text>
              <Text style={[styles.content, { fontSize: 11 }]}>
                Total Score : {totalPercentage && `${totalPercentage}%`}
              </Text>
            </View>
          </View>
          <View style={styles.bodyContainer}>
            <View style={styles.chartMain}>
              <Text style={styles.textRotate90}>Percentage</Text>

              <View style={styles.imageContainer}>
                <Image source={imageUrl} style={styles.chartImage} />
                <View style={styles.flexWithJusCenter}>
                  <View style={styles.colorAndText}>
                    <View style={styles.orangeSquare}></View>
                    <Text style={styles.smallText}>Secured the highest</Text>
                  </View>
                  <View style={styles.colorAndText}>
                    <View style={styles.greySquare}></View>
                    <Text style={styles.smallText}>Secured the least</Text>
                  </View>
                </View>
              </View>
            </View>
            {getApplicantData?.data?.data?.map((item) => (
              <View wrap={false}>
                <View style={styles.sectionTwo}>
                  <View>
                    <Text style={[styles.flex, { fontSize: 14 }]}>
                      {item?.folder_name}
                    </Text>
                  </View>
                  <View style={styles.flex}>
                    <Text
                      style={[styles.flex, { fontSize: 14, marginRight: 4 }]}
                    >
                      Total Score :
                    </Text>
                    <Text
                      style={[styles.flex, { fontSize: 14, color: '#ED2C94' }]}
                    >
                      {item?.percentage} %
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    padding: 20,
                    marginBottom: 25,
                  }}
                >
                  {item?.questions?.mcq_total ? (
                    <View
                      style={[
                        {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                      ]}
                    >
                      <View
                        style={{
                          display: 'flex',
                          color: '#ED2C94',
                          fontSize: 14,
                        }}
                      >
                        <Text>MCQ's</Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 10,
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '36%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={infoIlls}
                              style={{ width: 12, height: 12, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              No of questions attempted :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.mcq_attempted}/
                              {item?.questions?.mcq_total}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={rightIlls}
                              style={{ width: 14, height: 14, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              Right Answers :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.mcq_correct}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={wrongIlls}
                              style={{ width: 14, height: 14, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              Wrong Answers :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.mcq_attempted -
                                item?.questions?.mcq_correct}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          opacity: 0.2,
                          marginBottom: 20,
                          borderBottom: 1,
                          borderColor: 'rgb(251, 124, 81)',
                        }}
                      ></View>
                    </View>
                  ) : null}

                  {item?.questions?.tf_total ? (
                    <View
                      style={[
                        {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                      ]}
                    >
                      <View
                        style={{
                          display: 'flex',
                          color: '#ED2C94',
                          fontSize: 14,
                        }}
                      >
                        <Text>T/F</Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 10,
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '36%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={infoIlls}
                              style={{ width: 12, height: 12, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              No of questions attempted :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.tf_attempted}/
                              {item?.questions?.tf_total}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={rightIlls}
                              style={{ width: 14, height: 14, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              Right Answers :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.tf_correct}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={wrongIlls}
                              style={{ width: 14, height: 14, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              Wrong Answers :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.tf_attempted -
                                item?.questions?.tf_correct}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          opacity: 0.2,
                          marginBottom: 20,
                          borderBottom: 1,
                          borderColor: 'rgb(251, 124, 81)',
                        }}
                      ></View>
                    </View>
                  ) : null}
                  {item?.questions?.saq_total ? (
                    <View
                      style={[
                        {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                      ]}
                    >
                      <View
                        style={{
                          display: 'flex',
                          color: '#ED2C94',
                          fontSize: 14,
                        }}
                      >
                        <Text>SAQ's</Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          fontSize: 10,
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '36%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={infoIlls}
                              style={{ width: 12, height: 12, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              No of questions attempted :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.saq_attempted}/
                              {item?.questions?.saq_total}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Image
                              source={percentageIlls}
                              style={{ width: 12, height: 12, margin: 2 }}
                            ></Image>
                          </View>
                          <View style={[styles.flex, { marginRight: 4 }]}>
                            <Text
                              style={{
                                marginRight: 4,
                                color: 'rgba(142, 142, 142, 1)',
                              }}
                            >
                              Total score :
                            </Text>
                            <Text style={{ fontSize: 11 }}>
                              {item?.questions?.saq_rating * 10}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '32%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        ></View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          width: '100%',
                          opacity: 0.2,
                          marginBottom: 20,
                          borderBottom: 1,
                          borderColor: 'rgb(251, 124, 81)',
                        }}
                      ></View>
                    </View>
                  ) : null}
                </View>
              </View>
            ))}
            {/* <View break></View> */}
            {/* Questions and answers*/}
            <View break>
              <View
                style={{
                  padding: 20,
                }}
              >
                {assesmentInfo?.data?.data?.questions?.MCQ.length > 0 ? (
                  <View
                    style={[
                      {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                    ]}
                  >
                    <View
                      style={{
                        display: 'flex',
                        color: '#ED2C94',
                        fontSize: 14,
                      }}
                    >
                      <Text>MCQ's</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 10,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      {assesmentInfo?.data?.data?.questions?.MCQ?.map(
                        (question, index) => {
                          return (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                              wrap={false}
                            >
                              <View
                                style={[
                                  styles.flex,
                                  { marginRight: 4, width: '90%' },
                                ]}
                              >
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        {index + 1}.
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        display: 'flex',
                                        marginRight: 4,
                                        color: 'rgba(73, 80, 87, 1)',
                                      }}
                                    >
                                      <RichText
                                        note={
                                          getSanitizeMarkupData({
                                            markup: question?.name,
                                            renderHTML: true,
                                          }).__html
                                        }
                                      />
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'col',
                                    }}
                                  >
                                    <View
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'col',
                                      }}
                                    >
                                      {question?.mcq_options?.map(
                                        (option, index) => (
                                          <View
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              color: `${
                                                option ===
                                                  question.app_answer &&
                                                !question?.a_answer_corr
                                                  ? 'rgba(220, 38, 38, 1)'
                                                  : option ===
                                                      question.app_answer &&
                                                    option ===
                                                      question?.r_answer
                                                  ? 'rgba(33, 150, 83, 1)'
                                                  : !question?.a_answer_corr &&
                                                    option ===
                                                      question?.r_answer
                                                  ? 'rgba(33, 150, 83, 1)'
                                                  : 'rgba(73, 80, 87, 1)'
                                              }`,
                                            }}
                                          >
                                            <View>
                                              <Text
                                                style={{
                                                  fontSize: 11,
                                                  marginRight: 2,
                                                }}
                                              >
                                                {index === 0
                                                  ? 'A.'
                                                  : index === 1
                                                  ? 'B.'
                                                  : index === 2
                                                  ? 'C.'
                                                  : index === 3
                                                  ? 'D.'
                                                  : null}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                marginRight: 4,
                                              }}
                                            >
                                              <RichText
                                                note={
                                                  getSanitizeMarkupData({
                                                    markup: option,
                                                    renderHTML: true,
                                                  }).__html
                                                }
                                              />
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {/* <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        Ans.
                                      </Text>
                                    </View>
                                    <View>
                                      {question?.app_answer ===
                                      'unatt$mpthrq!' ? (
                                        <Text
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          No answer given by the applicant
                                        </Text>
                                      ) : (
                                        <View
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          <RichText
                                            note={
                                              getSanitizeMarkupData({
                                                markup: question?.app_answer,
                                                renderHTML: true,
                                              }).__html
                                            }
                                          />
                                        </View>
                                      )}
                                    </View>
                                  </View> */}
                                </View>
                              </View>
                              <View>
                                {question?.app_answer ===
                                'unatt$mpthrq!' ? null : (
                                  <View>
                                    {question?.a_answer_corr ? (
                                      <View
                                        style={[styles.flex, { marginLeft: 4 }]}
                                      >
                                        <Image
                                          source={rightIlls}
                                          style={{
                                            width: 14,
                                            height: 14,
                                          }}
                                        ></Image>
                                      </View>
                                    ) : (
                                      <View
                                        style={[styles.flex, { marginLeft: 4 }]}
                                      >
                                        <Image
                                          source={wrongIlls}
                                          style={{
                                            width: 14,
                                            height: 14,
                                          }}
                                        ></Image>
                                      </View>
                                    )}
                                  </View>
                                )}
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        width: '100%',
                        opacity: 0.2,
                        // marginBottom: 20,
                        borderBottom: 1,
                        borderColor: 'rgb(251, 124, 81)',
                      }}
                    ></View>
                  </View>
                ) : null}
              </View>
            </View>
            <View break>
              <View
                style={{
                  padding: 20,
                }}
              >
                {assesmentInfo?.data?.data?.questions?.TF.length > 0 ? (
                  <View
                    style={[
                      {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                    ]}
                  >
                    <View
                      style={{
                        display: 'flex',
                        color: '#ED2C94',
                        fontSize: 14,
                      }}
                    >
                      <Text>T/F</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 10,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      {assesmentInfo?.data?.data?.questions?.TF?.map(
                        (question, index) => {
                          return (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                              wrap={false}
                            >
                              <View
                                style={[
                                  styles.flex,
                                  { marginRight: 4, width: '90%' },
                                ]}
                              >
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        {index + 1}.
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        marginRight: 4,
                                        color: 'rgba(73, 80, 87, 1)',
                                      }}
                                    >
                                      <RichText
                                        note={
                                          getSanitizeMarkupData({
                                            markup: question?.name,
                                            renderHTML: true,
                                          }).__html
                                        }
                                      />
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        Ans.
                                      </Text>
                                    </View>

                                    <View>
                                      {question?.app_answer ===
                                      'unatt$mpthrq!' ? (
                                        <Text
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          No answer given by the applicant
                                        </Text>
                                      ) : (
                                        <View
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          <RichText
                                            note={
                                              getSanitizeMarkupData({
                                                markup: question?.app_answer,
                                                renderHTML: true,
                                              }).__html
                                            }
                                          />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              </View>
                              <View>
                                {question?.app_answer ===
                                'unatt$mpthrq!' ? null : (
                                  <View>
                                    {question?.a_answer_corr ? (
                                      <View
                                        style={[styles.flex, { marginLeft: 4 }]}
                                      >
                                        <Image
                                          source={rightIlls}
                                          style={{
                                            width: 14,
                                            height: 14,
                                          }}
                                        ></Image>
                                      </View>
                                    ) : (
                                      <View
                                        style={[styles.flex, { marginLeft: 4 }]}
                                      >
                                        <Image
                                          source={wrongIlls}
                                          style={{
                                            width: 14,
                                            height: 14,
                                          }}
                                        ></Image>
                                      </View>
                                    )}
                                  </View>
                                )}
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        width: '100%',
                        opacity: 0.2,
                        // marginBottom: 20,
                        borderBottom: 1,
                        borderColor: 'rgb(251, 124, 81)',
                      }}
                    ></View>
                  </View>
                ) : null}
              </View>
            </View>
            <View break>
              <View
                style={{
                  padding: 20,
                }}
              >
                {assesmentInfo?.data?.data?.questions?.SAQ.length > 0 ? (
                  <View
                    style={[
                      {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                    ]}
                  >
                    <View
                      style={{
                        display: 'flex',
                        color: '#ED2C94',
                        fontSize: 14,
                      }}
                    >
                      <Text>SAQ's</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 10,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      {assesmentInfo?.data?.data?.questions?.SAQ?.map(
                        (question, index) => {
                          return (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                              wrap={false}
                            >
                              <View
                                style={[
                                  styles.flex,
                                  { marginRight: 4, width: '90%' },
                                ]}
                              >
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        {index + 1}.
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        marginRight: 4,
                                        color: 'rgba(73, 80, 87, 1)',
                                      }}
                                    >
                                      <RichText
                                        note={
                                          getSanitizeMarkupData({
                                            markup: question?.name,
                                            renderHTML: true,
                                          }).__html
                                        }
                                      />
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <View>
                                      <Text
                                        style={{ fontSize: 11, marginRight: 2 }}
                                      >
                                        Ans.
                                      </Text>
                                    </View>

                                    <View>
                                      {question?.r_answer === 'None' ? (
                                        <Text
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          No answer given by the applicant
                                        </Text>
                                      ) : (
                                        <View
                                          style={{
                                            marginRight: 4,
                                            color: 'rgba(73, 80, 87, 1)',
                                          }}
                                        >
                                          <RichText
                                            note={
                                              getSanitizeMarkupData({
                                                markup: question?.r_answer,
                                                renderHTML: true,
                                              }).__html
                                            }
                                          />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View>
                                {question?.r_answer === 'None' ? null : (
                                  <View>
                                    {question?.rated_yet ? (
                                      <View
                                        style={[styles.flex, { marginLeft: 4 }]}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 11,
                                            marginRight: 2,
                                          }}
                                        >
                                          {question?.rate_answer + '/' + 10}
                                        </Text>
                                      </View>
                                    ) : null}
                                  </View>
                                )}
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    {/* <View
                      style={{
                        display: 'flex',
                        width: '100%',
                        opacity: 0.2,
                        marginBottom: 20,
                        borderBottom: 1,
                        borderColor: 'rgb(251, 124, 81)',
                      }}
                    ></View> */}
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          <View style={styles.footerSection} fixed>
            <View
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontSize: 9,
                color: 'grey',
                paddingTop: 10,
                borderTop: 1,
                borderColor: 'grey',
              }}
            >
              <Text style={{ flex: '1 1 0%' }}>
                &copy; {dayjs().year()}. All rights reserved.
              </Text>
              <Text style={{ flex: '1 1 0%' }}></Text>
              <Text style={{ flex: '1 1 0%' }}>Powered by Smart Hire</Text>
            </View>
          </View>
          {/* <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          /> */}
        </Page>
      </Document>
    </>
  );
}
