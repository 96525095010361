import React, { Suspense, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { lazy } from '@loadable/component';
import cookies from 'js-cookie';

import Loader from 'components/Loader';

import PublicHeader from 'components/Header/publicHeader';

import TermsAndConditionModal from './components/termsAndConditionModal';

import Login from './login';
const Register = lazy(() => import('./register'));
const ForgotPassword = lazy(() => import('./forgotPassword'));
const ChangePassword = lazy(() => import('./changePassword'));
const VerificationCode = lazy(() => import('./verification'));
const RegsiterSuccess = lazy(() => import('./registerSuccess'));
const ForgotPasswordVerification = lazy(() =>
  import('./forgotPassword/Components/forgotPasswordVerifucation')
);

const AuthRoute = () => {
  const currentLanguageCode = cookies.get('i18next');
  let { path } = useRouteMatch();
  const location = useLocation();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  return (
    <div>
      <PublicHeader />
      <Switch>
        <Redirect exact from={path} to={`${path}/login`} />
        <Route path={`${path}/login`} component={Login} />
        <Route path={`${path}/client/login`} component={Login} />
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='mt-12'>
                <Loader width={40} height={40} color='#6600C9' />
              </div>
            </div>
          }
        >
          <Switch>
            <Route path={`${path}/register`} component={Register} />
            <Route path={`${path}/forgotPassword`} component={ForgotPassword} />
            <Route
              path={`${path}/client/forgotPassword`}
              component={ForgotPassword}
            />
            <Route path={`${path}/changePassword`} component={ChangePassword} />
            <Route
              path={`${path}/client/changePassword`}
              component={ChangePassword}
            />
            <Route path={`${path}/verification`} component={VerificationCode} />
            <Route
              path={`${path}/forgot-password/verification`}
              component={ForgotPasswordVerification}
            />
            <Route
              path={`${path}/client/forgot-password/verification`}
              component={ForgotPasswordVerification}
            />
            <Route
              path={`${path}/register-success`}
              component={RegsiterSuccess}
            />
          </Switch>
        </Suspense>
      </Switch>
      {location.pathname.includes('/auth/login') ? (
        <section className='-mt-6'>
          <div
            className='w-full mx-auto  bg-gray-200'
            style={{ height: '1px' }}
          ></div>

          <div className={`p-4 flex justify-between ${currentLanguageCode === 'ar' ? 'pl-24' : 'pr-24'}`}>
            {/* <a
              href='https://apta-hr.com'
              target='_blank'
              rel='noreferrer'
              className=' text-sm text-gray-500'
            > */}
            Smart Hire © {new Date().getFullYear()}
            {/* </a> */}
            <div className='flex '>
              <a
                href='https://smart-hire.ai/contact'
                target='_blank'
                rel='noreferrer'
                className=' text-sm text-gray-500'
              >
                <p className='text-sm text-gray-500 mx-2'>Contact us</p>
              </a>
              <p
                className=' text-sm text-gray-500 cursor-pointer'
                onClick={() => setShowTermsAndConditions(true)}
              >
                Terms and Conditions
              </p>
            </div>
          </div>
        </section>
      ) : null}

      <TermsAndConditionModal
        showTermsAndConditions={showTermsAndConditions}
        setShowTermsAndConditions={setShowTermsAndConditions}
      />
    </div>
  );
};
export default AuthRoute;
