import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import cookies from 'js-cookie';

function SkeletonMeeting() {
  const currentLanguageCode = cookies.get('i18next');
  return (
    <div className='flex items-center h-16 w-full p-3'>
      <div className='flex flex-col w-12 items-center justify-center h-12 bg-white rounded border-t shadow-lg'>
        <Skeleton height='3rem' width='3rem' />
      </div>
      <div className='flex flex-1 mx-2 flex-col'>
        <Skeleton className='my-1' height='0.8rem' width='8rem' />
        <Skeleton className='my-1' height='0.8rem' width='6rem' />
      </div>
      <div className='flex'>
        <Skeleton height='1.5rem' width='1.5rem' />
      </div>
      <div
        className={`flex  px-1  ${
          currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
        }`}
      >
        <Skeleton height='1rem' width='0.25rem' />
      </div>
    </div>
  );
}

export default SkeletonMeeting;
