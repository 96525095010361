import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import cx from 'classnames';

const Tip = ({ children, content, ...props }) => {
  return (
    <TooltipPrimitive.Provider delayDuration={500}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          sideOffset={4}
          className={cx(
            'radix-side-top:animate-slide-down-fade',
            'radix-side-right:animate-slide-left-fade',
            'radix-side-bottom:animate-slide-up-fade',
            'radix-side-left:animate-slide-right-fade',
            'inline-flex items-center px-2 py-1.5 rounded',
            'bg-gray-800'
          )}
        >
          {/* <TooltipPrimitive.Arrow className='text-white fill-current dark:text-gray-800' /> */}
          <span className='block text-xs leading-3 text-gray-200'>
            {content}
          </span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
export default Tip;
