import React, { useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { AuthContext } from 'contexts/authContext';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import PrimaryButton from 'components/Button/primaryButton';
import { ReactComponent as InviteTourIllus } from 'assets/images/dashboard/InviteTourIllus.svg';
import { ReactComponent as JobURLTourIllus } from 'assets/images/dashboard/JobURLTourIllus.svg';

function ReactTourJobModal({ isVisible, setVisible }) {
  const { t } = useTranslation(['productTour']);
  const currentLanguageCode = cookies.get('i18next');
  const { authState, authDispatch } = useContext(AuthContext);
  const [viewContent, setViewContent] = useState(1);
  const { setIsOpen, setCurrentStep } = useTour();

  const header = (
    <div>
      <div className='z-50 flex justify-between'>
        <div className='w-10 h-10'></div>
        <div className='flex flex-col justify-center items-center'>
          <p className='text-center text-normal  text-primary-rose text-xl'>
            {viewContent === 1
              ? t('productTour:share_the_job_details_externally')
              : t('productTour:invite_applicants')}
          </p>
        </div>
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
          onClick={() => {
            setVisible(false);
            authDispatch({
              type: 'UPDATETOURSTATUS',
              payload: false,
            });
          }}
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        visible={isVisible}
        closable={false}
        onHide={() => setVisible(false)}
        className='w-full max-w-xl rounded-lg'
        modal
        draggable={false}
        dismissableMask={false}
        blockScroll={true}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col w-full items-center justify-center px-4'>
          <div className='flex justify-center items-center w-full mb-6'>
            {viewContent === 1 ? (
              <p className='text-sm font-normal text-center '>
                Select the menu on the top-right corner, choose <b> Copy URL</b>{' '}
                from the list of options. You can paste the unique URL anywhere
                you need to invite the applicants.
              </p>
            ) : (
              <p className='text-sm font-normal text-center'>
                Select the menu on the top-right corner, choose{' '}
                <b> Invite Applicants</b> from the list of options. Add an email
                to invite an applicant, so they can apply with ease
              </p>
            )}
          </div>
          <div className='flex justify-center items-center w-full h-72'>
            {viewContent === 1 ? <JobURLTourIllus /> : <InviteTourIllus />}
          </div>
          <div className='w-full flex justify-center mt-6'>
            <button
              onClick={() => {
                setVisible(false);
                authDispatch({
                  type: 'UPDATETOURSTATUS',
                  payload: false,
                });
              }}
              className='w-32'
            >
              <PrimaryButton isLoading={false}>
                <p className='text-sm leading-5'>
                  {t('productTour:quit_tour')}
                </p>
              </PrimaryButton>
            </button>
            {viewContent === 1 ? (
              <button
                onClick={() => {
                  setViewContent(2);
                }}
                className={`w-36 ${
                  currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                }`}
              >
                <PrimaryButton isLoading={false}>
                  <p className='text-sm leading-5'>{t('productTour:next')}</p>
                </PrimaryButton>
              </button>
            ) : (
              <button
                onClick={() => {
                  setVisible(false);
                  setIsOpen(true);
                  setTimeout(() => {
                    if (authState.is_recruiter) {
                      setCurrentStep(8);
                    } else if (authState.is_hiring_manager) {
                      setCurrentStep(6);
                    }
                  }, 500);
                }}
                className={`w-36 ${
                  currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                }`}
              >
                <PrimaryButton isLoading={false}>
                  <p className='text-sm leading-5'>{t('productTour:next')}</p>
                </PrimaryButton>
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ReactTourJobModal;
