import React from 'react';
import PrimaryButton from 'components/Button/primaryButton';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import cookies from 'js-cookie';

import { ReactComponent as PendingAssigments } from 'assets/images/dashboard/PendingAssigments.svg';

function ClientJobAssignments({ dashboardPendingAssignments }) {
  const history = useHistory();

  const { t } = useTranslation(['dashboard']);
  // const currentLanguageCode = cookies.get('i18next');

  return (
    <>
      <div className='w-full flex flex-col lg:w-4/12 bg-white rounded-lg border-t shadow-lg hover:shadow-xl px-6 py-4'>
        <div className='flex justify-between items-center  w-full'>
          <div className='text-lg 2xl:text-xl font-bold pb-2'>
            {t('pending_assessments')}
          </div>
        </div>
        <div className=' flex justify-center items-center'>
          <div className='flex h-80 mb-4 justify-center items-center flex-col'>
            <PendingAssigments />
            <span className='text-primary-rose font-bold text-5xl mt-6 mb-2'>
              {dashboardPendingAssignments?.data?.data?.data
                ?.pending_jobs_count || 0}
            </span>
            <div className='mt-2'>
              <button
                onClick={() => {
                  if (
                    dashboardPendingAssignments?.data?.data?.data
                      ?.pending_jobs_count > 0
                  ) {
                    history.push('/dashboard/pending-assignments');
                  }
                }}
                disabled={
                  dashboardPendingAssignments?.data?.data?.data
                    ?.pending_jobs_count === 0
                }
                className={`w-24  text-center mx-auto my-4 ${
                  dashboardPendingAssignments?.data?.data?.data
                    ?.pending_jobs_count > 0
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed opacity-70'
                }`}
              >
                <PrimaryButton isLoading={false}>
                  <p className='text-sm '>{t('view')}</p>
                </PrimaryButton>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientJobAssignments;
