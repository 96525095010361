import React, { createContext, useEffect, useReducer } from 'react';
export const JobContext = createContext();

let initialState = {
  Job_listing_pathname:
    JSON.parse(localStorage.getItem('Job_listing_pathname')) || '',
  Job_listing_searchparam:
    JSON.parse(localStorage.getItem('Job_listing_searchparam')) || '',
  Job_applicant_overview_pathname:
    JSON.parse(localStorage.getItem('Job_applicant_overview_pathname')) || '',
  Job_applicant_overview_searchparam:
    JSON.parse(localStorage.getItem('Job_applicant_overview_searchparam')) ||
    '',
  Psychometric_stage_name:
    JSON.parse(localStorage.getItem('psychometric_stage_name')) || '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SETJOBROUTERSTATE':
      localStorage.setItem(
        'Job_listing_pathname',
        JSON.stringify(action.payload.jobPathname)
      );
      localStorage.setItem(
        'Job_listing_searchparam',
        JSON.stringify(action.payload.jobSearchParam)
      );

      return {
        ...state,
        Job_listing_pathname: action.payload.jobPathname,
        Job_listing_searchparam: action.payload.jobSearchParam,
      };

    case 'SETJOBAPPLICANTOVERVIEWROUTERSTATE':
      localStorage.setItem(
        'Job_applicant_overview_pathname',
        JSON.stringify(action.payload.applicantsPathname)
      );
      localStorage.setItem(
        'Job_applicant_overview_searchparam',
        JSON.stringify(action.payload.applicantsSearchParam)
      );

      return {
        ...state,
        Job_applicant_overview_pathname: action.payload.applicantsPathname,
        Job_applicant_overview_searchparam:
          action.payload.applicantsSearchParam,
      };

    case 'RESETJOBROUTERSTATE':
      localStorage.removeItem('Job_listing_pathname');
      localStorage.removeItem('Job_listing_searchparam');
      // localStorage.removeItem('Job_applicant_overview_pathname');
      // localStorage.removeItem('Job_applicant_overview_searchparam');
      return {
        ...state,
        Job_listing_pathname: '',
        Job_listing_searchparam: '',
      };

    case 'SETPSYCHOMETRICSTAGENAME':
      localStorage.setItem(
        'psychometric_stage_name',
        JSON.stringify(action.payload.PsychometricStageName) ?? ''
      );

      return {
        ...state,
        Psychometric_stage_name: action.payload.PsychometricStageName,
      };
    default:
      return state;
  }
};

const JobProvider = ({ children }) => {
  const [jobState, jobDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <JobContext.Provider
      value={{
        jobState,
        jobDispatch,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export default JobProvider;
