import React, { useEffect } from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  Tooltip,
  PolarRadiusAxis,
} from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }) => {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['psychometricReport']);
  if (active && payload && payload.length) {
    switch (label) {
      case 'Neuroticism':
        if (payload[0].value < 50) {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('calm')}</li>
                <li>{t('even-tempered')}</li>
                <li>{t('secure')}</li>
              </ul>
            </div>
          );
        } else {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('anxious')}</li>
                <li>{t('unhappy')}</li>
                <li>{t('prone_to_negative_emotions')}</li>
              </ul>
            </div>
          );
        }

      case 'Agreeable':
        if (payload[0].value < 50) {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('critical')}</li>
                <li>{t('uncooperative')}</li>
                <li>{t('suspicious')}</li>
              </ul>
            </div>
          );
        } else {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('helpful')}</li>
                <li>{t('trusting')}</li>
                <li>{t('empathetic')}</li>
              </ul>
            </div>
          );
        }

      case 'Openness':
        if (payload[0].value < 50) {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('practical')}</li>
                <li>{t('conventional')}</li>
                <li>{t('prefers_routine')}</li>
              </ul>
            </div>
          );
        } else {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('curious')}</li>
                <li>{t('multiple_interests')}</li>
                <li>{t('independent')}</li>
              </ul>
            </div>
          );
        }

      case 'Extraversion':
        if (payload[0].value < 50) {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('quite')}</li>
                <li>{t('reserved')}</li>
                <li>{t('withdrawn')}</li>
              </ul>
            </div>
          );
        } else {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('outgoing')}</li>
                <li>{t('warm')}</li>
                <li>{t('seeks_adventure')}</li>
              </ul>
            </div>
          );
        }
      case 'Conscientiousness':
        if (payload[0].value < 50) {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('impulsive')}</li>
                <li>{t('careless')}</li>
                <li>{t('disorganized')}</li>
              </ul>
            </div>
          );
        } else {
          return (
            <div className=' bg-white p-2 px-4 border rounded'>
              <p className='label'>
                {`${label}`}
                <span
                  className={`text-primary-rose ${
                    currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                  }`}
                >
                  {payload[0].value}%
                </span>
              </p>
              <p className='mt-1'>{t('indicative_traits')}</p>
              <ul className='mt-2 list-inside list-disc'>
                <li>{t('hardworking')}</li>
                <li>{t('dependable')}</li>
                <li>{t('organized')}</li>
              </ul>
            </div>
          );
        }
      default:
        return <></>;
    }
  }

  return null;
};
const CustomTooltip16T = ({ active, payload, label }) => {
  const convertLowerAndUnderScore = (string) => {
    return string.toLowerCase().replace(' ', '_');
  };

  const { t } = useTranslation(['common']);

  if (active && payload && payload.length) {
    return (
      <div className=' bg-white p-2 px-4 border rounded'>
        <p className='label'>{t(convertLowerAndUnderScore(`${label}`))}</p>
        <p className='mt-1'>
          {t('score')}{' '}
          <span dir='ltr' className='text-primary-rose mx-1'>
            {payload[0].value}%
          </span>
        </p>
      </div>
    );
  }

  return null;
};

const CustomizedAxisTick = (props) => {
  const { t } = useTranslation(['psychometricReport']);
  const convertLowerAndUnderScore = (string) => {
    return string.toLowerCase().replace(' ', '_');
  };
  return (
    <g>
      <foreignObject
        x={props.x - 10}
        y={props.y - 15}
        width={25}
        height={25}
        textAnchor='middle'
      >
        <p
          xmlns='https://www.w3.org/1999/xhtml'
          style={{
            background: '#FF009C',
            color: '#ffffff',
            display: 'inline-block',
            borderRadius: '4px',
            padding: '1px 3px',
            fontSize: '11px',
          }}
        >
          {t(convertLowerAndUnderScore(`${props.value}`))}
        </p>
      </foreignObject>
    </g>
  );
};

const PsychometricRadarChart = ({
  radarChartData,
  isReportPage,
  setImageUrl,
  isSetValue,
  generateAndDownload,
}) => {
  const [getAreaPng, { ref }] = useCurrentPng();
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['psychometricReport']);
  const convertLowerAndUnderScore = (string) => {
    return string.toLowerCase().replace(' ', '_');
  };

  useEffect(() => {
    if (isSetValue && generateAndDownload) {
      getAreaPng().then((response) => {
        setImageUrl(response);
      });
    }
  }, [getAreaPng, setImageUrl, isSetValue, generateAndDownload]);
  function customTick({ payload, x, y, textAnchor, stroke, radius }) {
    return (
      <g className='recharts-layer recharts-polar-angle-axis-tick'>
        <text
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          className='recharts-text recharts-polar-angle-axis-tick-value'
          text-anchor={textAnchor}
        >
          <tspan x={x} dy='0em'>
            {t(convertLowerAndUnderScore(`${payload.value}`))}
          </tspan>
        </text>
      </g>
    );
  }
  return (
    <>
      {/* for pdf generation (start)*/}
      <div
        style={{
          width: 440,
          height: 300,
          position: 'absolute',
          left: '-10000px',
        }}
        className='text-xs'
      >
        <ResponsiveContainer width='100%' height='100%'>
          <RadarChart
            data={radarChartData}
            ref={ref}
            cx='50%'
            cy='50%'
            outerRadius='80%'
          >
            <PolarGrid
              gridType={radarChartData?.length > 5 ? 'circle' : 'polygon'}
            />
            <PolarAngleAxis
              dataKey='traits'
              // orient='vertical'
              tick={customTick}
              orientation={currentLanguageCode === 'ar' ? 'right' : 'outer'}
              axisLine={false}
            />

            <PolarRadiusAxis
              angle={30}
              domain={[0, 105]}
              tick={false}
              axisLine={false}
            />
            <Radar
              dataKey='score'
              stroke='#FF009C'
              fill='#FF009C'
              fillOpacity={0.3}
            />
            <Tooltip
              content={radarChartData?.length < 6 && <CustomTooltip />}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>

      {/* for pdf generation (end) */}
      <div
        style={{
          width: '100%',
          height: isReportPage ? 400 : 300,
        }}
        className='mx-auto mt-4 mb-4 py-2 text-xs'
      >
        <ResponsiveContainer width='100%' height='100%'>
          <RadarChart data={radarChartData} cx='50%' cy='50%' outerRadius='80%'>
            <PolarGrid
              gridType={radarChartData?.length > 5 ? 'circle' : 'polygon'}
            />
            <PolarAngleAxis
              dataKey='traits'
              // right, inner, outer
              tick={customTick}
              orientation={currentLanguageCode === 'ar' ? 'right' : 'outer'}
              // orient='vertical'
              // tick={props => renderPolarAngleAxis(props)}
              axisLine={false}
            />

            <PolarRadiusAxis
              angle={30}
              domain={[0, 105]}
              tick={false}
              axisLine={false}
            />
            <Radar
              dataKey='score'
              stroke='#FF009C'
              fill='#FF009C'
              label={radarChartData?.length > 5 ? null : CustomizedAxisTick}
              fillOpacity={0.3}
            />
            <Tooltip
              content={
                radarChartData?.length > 5 ? (
                  <CustomTooltip16T />
                ) : (
                  <CustomTooltip />
                )
              }
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PsychometricRadarChart;
