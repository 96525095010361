import React, { useState, useContext } from 'react';
import { AuthContext } from 'contexts/authContext';
import { InputText } from 'primereact/inputtext';
import ReactPlayer from 'react-player/file';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

// import { ReactComponent as UpArrowIcon } from '../../../../assets/images/helpSupport/UpArrowIcon.svg';
// import { ReactComponent as DownArrowIcon } from '../../../../assets/images/helpSupport/DownArrowIcon.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/images/helpSupport/ArrowIcon.svg';
// import { ReactComponent as VideoPlayerIcon } from '../../../../assets/images/helpSupport/VideoPlayerIcon.svg';

function Help() {
  const { t } = useTranslation(['profileSetting']);
  const { authState } = useContext(AuthContext);
  const helpQuestionData = [
    {
      question: 'How to set up an email account',
      id: 101,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+set+up+an+email+account.mp4',
      ],
    },
    {
      question: 'How to add a team member',
      id: 110,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+add+a+team+member.mp4',
      ],
    },
    {
      question: 'How to create an assesment',
      id: 107,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+create+an+assesment.mp4',
      ],
    },
    {
      question: 'How to create a psychometric assessment bank',
      id: 108,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+create+a+psychometric+assessment+bank.mp4',
      ],
    },
    {
      question: 'How to add a job',
      id: 112,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+add+a+job.mp4',
      ],
    },
    {
      question: 'How to invite an applicant',
      id: 103,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+invite+an+applicant.mp4',
      ],
    },
    {
      question: 'How to multi schedule an assessment',
      id: 102,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+multi-schedule+an+assessment.mp4',
      ],
    },
    {
      question: 'How to download job applicants data',
      id: 104,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+download+job+applicants+data.mp4',
      ],
    },
    {
      question: 'How to customize a job evaluation workflow',
      id: 105,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+customize+a+job+evaluation+workflow.mp4',
      ],
    },
    {
      question: 'how to customize a job application',
      id: 106,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/how+to+customize+a+job+application.mp4',
      ],
    },
    {
      question: 'How to change the job screening stage label',
      id: 109,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+change+the+job+screening+stage+label.mp4',
      ],
    },
    {
      question: 'How does an integrated online interview work',
      id: 111,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+does+an+integrated+online+interview+work.mp4',
      ],
    },
    {
      question: 'Disabled reject button for next round',
      id: 201,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/Disabled%20REJECT%20button%20for%20next%20round.mp4',
      ],
    },
    {
      question: 'How to add questions from a database',
      id: 202,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How-to-add-questions-from-a-database.mp4',
      ],
    },
    {
      question: 'How to auto schedule an assessment',
      id: 203,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How-to-auto-schedule-an-assessment.mp4',
      ],
    },
    {
      question: 'How to customise  your email template',
      id: 204,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to customise  your email template.mpeg.mp4',
      ],
    },
    {
      question: 'how to download applicant performance report',
      id: 205,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/how to download applicant performance report.mp4',
      ],
    },
    {
      question: 'How to move applicants to CV bank',
      id: 206,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to move applicants to CV bank.mp4',
      ],
    },
    {
      question: 'How to sync interview scedule to your calender',
      id: 207,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to sync interview scedule to your calender_v2.mp4',
      ],
    },
    {
      question: 'How to upload multiple jobs using',
      id: 208,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to upload multiple jobs using.mp4',
      ],
    },
    {
      question: 'Team access to hired and closed',
      id: 209,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/Team access to hired and closed.mp4',
      ],
    },
  ];
  const recHelpQuestionData = [
    {
      question: 'How to set up an email account',
      id: 101,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+set+up+an+email+account+(recruiter).mp4',
      ],
    },
    {
      question: 'how to add a team member ',
      id: 104,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/how+to+add+a+team+member+(+recruiter+).mp4',
      ],
    },
    {
      question: 'How to add a client',
      id: 114,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+add+a+client.mp4',
      ],
    },
    {
      question: 'How to add an assessment bank',
      id: 105,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+add+an+assessment+bank+(recruiter).mp4',
      ],
    },
    {
      question: 'How to add a psychometric assessment bank',
      id: 103,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+add+a+psychometric+assessment+bank+(recruiter).mp4',
      ],
    },
    {
      question: 'How to add a job',
      id: 102,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+add+a+job.mp4',
      ],
    },
    {
      question: 'How to invite a job applicant',
      id: 110,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+invite+a+job+applicant+recruiter.mp4',
      ],
    },
    {
      question: 'How to move a job applicant to client',
      id: 111,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+move+a+job+applicant+to+client.mp4',
      ],
    },
    {
      question: 'How to move a job applicant to next stage',
      id: 112,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+move+a+job+applicant+to+next+stage+(recruiter).mp4',
      ],
    },
    {
      question: 'How to process multiple job applications at once',
      id: 113,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+process+multiple+job+applications+at+once+(recruiter).mp4',
      ],
    },
    {
      question: 'How to change the label of an assessment stage',
      id: 106,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+change+the+label+of+an+assessment+stage+(+recruiter).mp4',
      ],
    },
    {
      question: 'How to compare mutiple applicants at once',
      id: 107,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+compare+mutiple+applicants+at+once+(recruiter).mp4',
      ],
    },
    {
      question: 'how to customize a job application',
      id: 108,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/how+to+customize+a+job+application+(recruiter).mp4',
      ],
    },
    {
      question:
        'How to download the list of job applications received for a position',
      id: 109,
      open: false,
      video: [
        'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/Recruiter/How+to+download+the+list+of+job+applications+received+for+a+position+(recruiter).mp4',
      ],
    },
    {
      question: 'Disabled reject button for next round',
      id: 201,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/Disabled%20REJECT%20button%20for%20next%20round.mp4',
      ],
    },
    {
      question: 'How to add questions from a database',
      id: 202,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How-to-add-questions-from-a-database.mp4',
      ],
    },
    {
      question: 'How to auto schedule an assessment',
      id: 203,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How-to-auto-schedule-an-assessment.mp4',
      ],
    },
    {
      question: 'How to customise  your email template',
      id: 204,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to customise  your email template.mpeg.mp4',
      ],
    },
    {
      question: 'how to download applicant performance report',
      id: 205,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/how to download applicant performance report.mp4',
      ],
    },
    {
      question: 'How to move applicants to CV bank',
      id: 206,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to move applicants to CV bank.mp4',
      ],
    },
    {
      question: 'How to sync interview scedule to your calender',
      id: 207,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to sync interview scedule to your calender_v2.mp4',
      ],
    },
    {
      question: 'How to upload multiple jobs using',
      id: 208,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How to upload multiple jobs using.mp4',
      ],
    },
    {
      question: 'Team access to hired and closed',
      id: 209,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/Team access to hired and closed.mp4',
      ],
    },
  ];

  const helpQuestionDataApplicants = [
    {
      question: 'How an applicant takes skill assessment',
      id: 101,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr25/How%20an%20applicant%20takes%20the%20skill%20assessment.mp4',
      ],
    },
    {
      question: 'How an applicant takes a psychometric assessment',
      id: 102,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How%20an%20applicant%20takes%20the%20psychometric%20assessment.mp4',
      ],
    },
    {
      question: 'How an applicant takes pre-hire assessment',
      id: 103,
      open: false,
      video: [
        'https://stgaptahr.blob.core.windows.net/devaptahr/How%20an%20applicant%20takes%20a%20pre-hire%20assessment.mp4',
      ],
    },
  ];
  // eslint-disable-next-line
  const [helpQuestionsData, setHelpQuestionsData] = useState(
    authState.is_recruiter || authState.is_recruiter_team
      ? recHelpQuestionData
      : helpQuestionData
  );
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [activeTab, setActiveTab] = useState('user');
  const currentLanguageCode = cookies.get('i18next');
  // searchChangeHandle

  const searchChangeHandle = (data) => {
    updateInput(data);
  };

  var re = new RegExp('^[a-zA-Z0-9_@. ]*$');

  const updateInput = async (input) => {
    if (input.match(re)) {
      let filtered;
      if (activeTab === 'user') {
        if (authState.is_recruiter || authState.is_recruiter_team) {
          filtered = recHelpQuestionData?.filter((question) => {
            return (
              question?.question?.toLowerCase()?.search(input.toLowerCase()) !==
              -1
            );
          });
        } else {
          filtered = helpQuestionData?.filter((question) => {
            return (
              question?.question?.toLowerCase()?.search(input.toLowerCase()) !==
              -1
            );
          });
        }
      } else {
        filtered = helpQuestionDataApplicants?.filter((question) => {
          return (
            question?.question?.toLowerCase()?.search(input.toLowerCase()) !==
            -1
          );
        });
      }

      setSearchFieldValue(input);
      setHelpQuestionsData(filtered);
      // setHelpQuestionsData({ data: filtered });
    }
  };

  const [activeQuestion, setActiveQuestion] = useState({
    question: 'How to set up an email account',
    id: 101,
    open: false,
    video: [
      'https://s3.amazonaws.com/atsv2.1/HR2.0static/Helpvideos/How+to+set+up+an+email+account.mp4',
    ],
  });
  return (
    <div className='lg:px-10 sm:px-4 py-8 min-h-86vh h-full w-full flex flex-col items-center justify-start'>
      <div className='flex w-full bg-white border shadow-lg rounded-lg h-56 p-6 bg-helpCardRightBG bg-no-repeat bg-right-bottom mb-4'>
        <div className='flex flex-col items-center justify-center w-full  h-full lg:bg-helpCardLeftBG bg-no-repeat bg-left-top'>
          <h3 className='font-bold text-3xl text-black'>
            {t('profileSetting:hey_there_how_can_we_help_you')}
          </h3>
          <InputText
            className='w-full max-w-xs lg:max-w-lg rounded-lg p-2 px-4 my-6'
            placeholder='Search for answers'
            value={searchFieldValue}
            onChange={(e) => searchChangeHandle(e.target.value)}
            name='HelpInput'
            id='HelpInputID'
          />
        </div>
      </div>
      <div className='flex w-full h-full  my-4'>
        <div
          className={`flex flex-col w-5/12 bg-white border shadow-lg rounded-lg h-full min-h-70vh max-h-70vh p-6 ${
            currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
          }`}
        >
          <div className='flex flex-col lg:flex-row mb-6 lg:mb-0 justify-between'>
            <h3 className='text-primary-rose text-2xl font-bold mb-4'>
              {t('profileSetting:help_topics')}
            </h3>
            <div className='flex'>
              <div className='flex h-6'>
                <p
                  onClick={() => {
                    setActiveTab('user');
                    setActiveQuestion(
                      authState.is_recruiter || authState.is_recruiter_team
                        ? recHelpQuestionData[0]
                        : helpQuestionData[0]
                    );
                    setHelpQuestionsData(
                      authState.is_recruiter || authState.is_recruiter_team
                        ? recHelpQuestionData
                        : helpQuestionData
                    );
                    setSearchFieldValue('');
                  }}
                  className={`flex font-bold cursor-pointer ${
                    activeTab === 'user'
                      ? 'border-b border-gradient text-primary-rose'
                      : ''
                  } ${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                >
                  {t('profileSetting:user')}
                </p>
                <p
                  onClick={() => {
                    setActiveTab('app');
                    setActiveQuestion(helpQuestionDataApplicants[0]);
                    setHelpQuestionsData(helpQuestionDataApplicants);
                    setSearchFieldValue('');
                  }}
                  className={`flex font-bold cursor-pointer ${
                    activeTab === 'app'
                      ? 'border-b border-gradient text-primary-rose'
                      : ''
                  } ${currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'}`}
                >
                  {t('profileSetting:applicant')}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col overflow-y-scroll scroller ${
              currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
            }`}
          >
            {helpQuestionsData.map((data) => (
              <div
                key={data?.id}
                onClick={() => setActiveQuestion(data)}
                className={`flex items-center bg-white hover:shadow-xl p-4 cursor-pointer mb-4 ${
                  data?.id === activeQuestion?.id
                    ? 'border-2 border-purple shadow-xl rounded border-opacity-80'
                    : 'shadow-lg'
                }`}
              >
                <span>
                  <ArrowIcon
                    className={`w-7 ${
                      currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                    }`}
                  />
                </span>
                <p className='font-bold text-Neutrals-gray-1'>
                  {data?.question}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`flex w-7/12 bg-white border shadow-lg rounded-lg h-full min-h-70vh max-h-70vh p-6 ${
            currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
          }`}
        >
          <div className='flex flex-col w-full'>
            <div className='flex flex-col border border-primary-rose h-full w-full bg-white rounded-xl'>
              <div className='flex justify-between items-center p-4'>
                <div className='text-orange font-bold'>
                  {activeQuestion.question}
                </div>
                {/* {!activeQuestion.open ? (
                  <UpArrowIcon
                    onClick={() => {
                      setActiveQuestion({
                        ...activeQuestion,
                        open: !activeQuestion.open,
                      });
                    }}
                    className='cursor-pointer'
                  />
                ) : (
                  <DownArrowIcon
                    onClick={() => {
                      setActiveQuestion({
                        ...activeQuestion,
                        open: !activeQuestion.open,
                      });
                    }}
                    className='cursor-pointer'
                  />
                )} */}
              </div>
              <div className='flex w-full h-full justify-center items-center p-4'>
                <div className='flex w-11/12 my-4 h-72 bg-gray-1 rounded-lg mx-auto'>
                  <ReactPlayer
                    // Disable download button
                    config={{
                      file: { attributes: { controlsList: 'nodownload' } },
                    }}
                    // Disable right click
                    onContextMenu={(e) => e.preventDefault()}
                    width='100%'
                    height='100%'
                    url={activeQuestion.video}
                    style={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                    }}
                    controls
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
