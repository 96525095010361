import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';

import { ApplicantsContext } from 'contexts/applicantsContext';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';

import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';

import queryKeys from 'reactQuery/constant';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

const ConfirmMoveApplicantToClientModal = ({
  currentStage,
  nextStage,
  applicantsList,
  jobId,
  skillReport,
  psyReport,
  assesmentInfo,
  assesmentInfoPSY,
  disableThird = false,
  setClientViewMode,
}) => {
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);
  const [sendToClientOption, setSendToClientOption] = useState(1);

  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const pathNameArray = location.pathname.split('/');

  const moveCandidatetoclient = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.MOVE_APPLICANTS_TO_CLIENT,
      data
    );
    return res.data;
  });

  const moveCandidatetoclientAndTriggerMail = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.MOVE_APPLICANTS_TO_CLIENT_MAIL_REPORTS,
      data
    );
    return res.data;
  });

  const moveCandidatetonextStage = useMutation(async (data) => {
    const res = await makeAPIRequest.put(apiEndPoints.JOB_APPLICANTS, data);
    return res.data;
  });

  const handelMoveCandididateToNextStage = (seletedOptionObj) => {
    const queryData = {
      stages: {
        [currentStage]: false,
        [nextStage]: true,
      },
      applicants: applicantsList.map((item) => item._id),
    };
    const queryDatamoveCadidateToclient = {
      applicants: applicantsList.map((item) => item._id),
      ...seletedOptionObj,
    };
    const queryDataClientformData = new FormData();
    if (seletedOptionObj.option === 3) {
      queryDataClientformData.append(
        'applicant_id',
        applicantsList.map((item) => item._id)
      );
      if (skillReport) {
        const skillPdfFile = new File(
          [skillReport],
          `skill_report_${pathNameArray[5]}.pdf`
        );
        queryDataClientformData.append('skill_report', skillPdfFile);
      }
      if (psyReport) {
        const psyPdfFile = new File(
          [psyReport],
          `psychometric_report_${pathNameArray[5]}.pdf`
        );
        queryDataClientformData.append('psy_report', psyPdfFile);
      }
    }

    moveCandidatetonextStage.mutate(queryData, {
      onSuccess: () => {
        if (seletedOptionObj.option === 3) {
          moveCandidatetoclientAndTriggerMail.mutate(queryDataClientformData, {
            onSuccess: () => {
              setClientViewMode(true);
              handelCloseConfirmMoveModal();
              handelOpenNextStageModal();
              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.allApplicants &&
                  query.queryKey[2] === nextStage,
              });
              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.allApplicants &&
                  query.queryKey[2] ===
                    getJobStageNameForBackendQuery(currentStage),
              });

              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.specificApplicant,
              });
              queryClient.invalidateQueries(queryKeys.allJobs);
              setTimeout(() => {
                applicantsDispatch({
                  type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
                  payload: false,
                });

                if (
                  pathNameArray[3] !== 'overview' &&
                  seletedOptionObj?.option === 1
                ) {
                  history.push(
                    `/recruiter/applicants/${jobId}/${nextStage}/${pathNameArray[5]}/${pathNameArray[6]}`
                  );
                }
              }, 2000);
            },
            onError: (error) => {
              ErrorToast({ text: error.response.data.message });
              moveCandidatetonextStage.mutate({
                stages: {
                  [currentStage]: true,
                  [nextStage]: false,
                },
                applicants: applicantsList.map((item) => item._id),
              });
            },
          });
        } else {
          moveCandidatetoclient.mutate(queryDatamoveCadidateToclient, {
            onSuccess: () => {
              if (seletedOptionObj?.option !== 2) {
                setClientViewMode(false);
              } else {
                setClientViewMode(true);
              }
              handelCloseConfirmMoveModal();
              handelOpenNextStageModal();

              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.allApplicants &&
                  query.queryKey[2] === nextStage,
              });
              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.allApplicants &&
                  query.queryKey[2] ===
                    getJobStageNameForBackendQuery(currentStage),
              });

              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey[0] === queryKeys.specificApplicant,
              });
              queryClient.invalidateQueries(queryKeys.allJobs);
              setTimeout(() => {
                applicantsDispatch({
                  type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
                  payload: false,
                });

                if (
                  pathNameArray[3] !== 'overview' &&
                  seletedOptionObj?.option !== 2
                ) {
                  history.push(
                    `/recruiter/applicants/${jobId}/${nextStage}/${pathNameArray[5]}/${pathNameArray[6]}`
                  );
                }
              }, 2000);
            },
            onError: (error) => {
              ErrorToast({ text: error.response.data.message });
              moveCandidatetonextStage.mutate({
                stages: {
                  [currentStage]: true,
                  [nextStage]: false,
                },
                applicants: applicantsList.map((item) => item._id),
              });
            },
          });
        }
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    });
  };

  const submitSendApplicantsToClient = () => {
    if (sendToClientOption === 1) {
      handelMoveCandididateToNextStage({ option: 1 });
    } else if (sendToClientOption === 2) {
      handelMoveCandididateToNextStage({ option: 2 });
    } else if (sendToClientOption === 3) {
      if (skillReport || psyReport) {
        handelMoveCandididateToNextStage({ option: 3 });
      } else {
        if (assesmentInfoPSY?.data?.data?.assessment_results) {
          handelOpenPSYReportsModal();
        }
        if (assesmentInfo?.data?.data?.assessment_results) {
          handelOpenReportsModal();
        }
      }
    }
  };

  const handelCloseConfirmMoveModal = () => {
    applicantsDispatch({
      type: 'SETCONFIRMMOVEAPPLICANTTOCLIENTMODALVISIBLESTATE',
      payload: false,
    });
  };

  const handelOpenReportsModal = () => {
    applicantsDispatch({
      type: 'SET_REPORTS_MODAL_VISIBLE',
      payload: true,
    });
  };

  const handelOpenPSYReportsModal = () => {
    applicantsDispatch({
      type: 'SET_PSY_REPORTS_MODAL_VISIBLE',
      payload: true,
    });
  };

  const handelOpenNextStageModal = () => {
    applicantsDispatch({
      type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
      payload: true,
    });
  };

  const header = (
    <div className='relative'>
      <p className='text-base font-normal text-center mt-6'>
        {t(
          'jobsAndApplicants:are_you_sure_you_want_to_move_the_applicant_to_the_client'
        )}
        {currentLanguageCode === 'ar' ? (
          ''
        ) : (
          <span className='font-bold'>Client?</span>
        )}
      </p>

      <button
        className='absolute -top-8 right-0'
        onClick={handelCloseConfirmMoveModal}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  return (
    <div>
      <Dialog
        visible={
          applicantsState.is_confirm_move_applicant_to_client_modal_visble
        }
        onHide={handelCloseConfirmMoveModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        className={`w-9/12 xl:w-1/2 mx-auto rounded-xl min-h-40vh`}
      >
        <div className='relative'>
          <div className='flex mx-16 mt-8'>
            <div className='flex flex-col'>
              <div className='p-field-radiobutton my-2 flex items-center'>
                <RadioButton
                  inputId='hirm'
                  name='hirm'
                  value={1}
                  onChange={(e) => setSendToClientOption(e.value)}
                  checked={sendToClientOption === 1}
                />
                <label htmlFor='hirm' className='mx-2'>
                  {t(
                    'jobsAndApplicants:send_applicant_details_to_the_client_and_allow_them_to_process_further_stages'
                  )}
                </label>
              </div>
              <div className='p-field-radiobutton my-2 flex items-center'>
                <RadioButton
                  inputId='rcc'
                  name='rcc'
                  value={2}
                  tabIndex='-1'
                  onChange={(e) => setSendToClientOption(e.value)}
                  checked={sendToClientOption === 2}
                />
                <label htmlFor='rcc' className='mx-2'>
                  {t(
                    'jobsAndApplicants:send_applicant_details_to_the_client_in_view_only_mode'
                  )}
                </label>
              </div>
              <div
                className={`p-field-radiobutton my-2 flex items-center ${
                  disableThird || applicantsList?.length > 1 ? 'opacity-60' : ''
                }`}
              >
                <RadioButton
                  inputId='rcc3'
                  name='rcc3'
                  value={3}
                  disabled={disableThird || applicantsList?.length > 1}
                  tabIndex='-1'
                  onChange={(e) => setSendToClientOption(e.value)}
                  checked={sendToClientOption === 3}
                />
                <label htmlFor='rcc3' className='mx-2'>
                  {t(
                    'jobsAndApplicants:send_applicant_details_to_the_client_via_email'
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className='flex justify-center mb-6 my-4'>
            <div
              className={`${currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'}`}
            >
              <div className='flex justify-center items-center mt-4'>
                <button
                  className='w-40 mx-3'
                  onClick={submitSendApplicantsToClient}
                >
                  <PrimaryButton
                    isLoading={
                      moveCandidatetoclient.isLoading ||
                      moveCandidatetoclientAndTriggerMail.isLoading
                    }
                  >
                    <p className='text-base leading-5'>
                      {(sendToClientOption === 3 &&
                        !skillReport &&
                        assesmentInfo?.data?.data?.assessment_results) ||
                      (sendToClientOption === 3 &&
                        !psyReport &&
                        assesmentInfoPSY?.data?.data?.assessment_results)
                        ? 'Generate reports'
                        : 'Submit'}
                    </p>
                  </PrimaryButton>
                </button>
                <button
                  className='w-28 mx-3'
                  onClick={handelCloseConfirmMoveModal}
                >
                  <BaseButton>
                    <p className='text-base leading-5'>{t('common:no')}</p>
                  </BaseButton>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ConfirmMoveApplicantToClientModal;
