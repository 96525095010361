import React, {
  useContext,
  Fragment,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useTour } from '@reactour/tour';
import ReactTourInstructionsModal from 'components/ReactTourInstructionsModal';

import UserDropDown from './HeaderUserDropdown';
import { NotificationContext } from 'contexts/notificationContext';
import { AuthContext } from 'contexts/authContext';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import i18next from 'i18next';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Notification } from 'assets/icons/bell.svg';
// import { ReactComponent as Message } from 'assets/icons/message.svg';
import { ReactComponent as MenuBarIcon } from 'assets/icons/menuBarIcon.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboardIlls.svg';
import { ReactComponent as JobIcon } from 'assets/icons/jobIlls.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/teamIlls.svg';
import { ReactComponent as LogoutIlls } from 'assets/icons/logoutIlls.svg';
import { ReactComponent as AssessmentIcon } from 'assets/icons/assessmentIcon.svg';
import ReactTourPopUp from 'components/ReactTourPopUp';
import ReactTourSuccessModal from 'components/ReactTourSuccessModal';
import ReactTourJobModal from 'components/ReactTourJobModal';
import LogoutModal from '../LogoutModal';

import { Transition, Dialog } from '@headlessui/react';

const AuthHeader = ({
  handelLogoutModal,
  setShowNotificationModal,
  disabled,
}) => {
  const { notificationCount } = useContext(NotificationContext);

  const { t } = useTranslation(['navbar', 'productTour']);
  const currentLanguageCode = cookies.get('i18next');

  const { authState } = useContext(AuthContext);

  const location = useLocation();
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);

  const [isLogoutModalopen, setLogoutModalOpen] = useState(false);
  const [isTourModalopen, setTourModalOpen] = useState(false);
  const [isTourSuccessModalopen, setTourSuccessModalOpen] = useState(false);
  const [isTourJobModalopen, setTourJobModalOpen] = useState(false);

  const closeBtnRef = useRef(null);
  const menuBtnRef = useRef(null);

  const removeToast = () => {
    toast.remove();
  };

  const isResumebanOpenInJob = () => {
    if (
      location.pathname.includes('/resume-bank/listing') &&
      location.search.includes('?jobId=')
    ) {
      return true;
    }
    return;
  };

  // Application tour steps
  // List of React Tour steps for Hiring Manager account

  const HMSteps = [
    {
      selector: '[data-tour="tour-first-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:team_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_team_to_add_your_team_members')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-second-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:team_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:click_on_plus')}</li>
                <li className='my-1'>
                  {t('productTour:add_your_team_members')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },

    {
      selector: '[data-tour="tour-third-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:create_assessment')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t(
                    'productTour:select_assessments_to_build_your_assessment_bank'
                  )}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-fourth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:build_your_skill_assessment_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:add_assessments')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
    // {
    //   selector: '[data-tour="tour-seventh-step"]',
    //   content: () => (
    //     <ReactTourPopUp
    //       history={history}
    //
    //       content={
    //         <div className='flex flex-col'>
    //           <p className='font-bold text-xl mb-2'>
    //             Build your PSYCHOMETRIC assessment bank
    //           </p>
    //           <ul className='list-disc px-5'>
    //             <li className='my-1'>Add assessments</li>
    //           </ul>
    //         </div>
    //       }
    //     />
    //   ),
    // },
    {
      selector: '[data-tour="tour-fifth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:list_your_job')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_jobs_and_Applicants_to_list_your_job')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-sixth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              {/* <p className='font-bold text-xl mb-2'>List your job</p> */}
              <ul className='list-disc px-5'>
                {/* <li className='my-1'>click on '+' / 'Add Job'</li> */}
                <li className='my-1'>{t('productTour:select_add_jobs')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-seventh-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:create_cv_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_cv_bank_to_build_your_cv_bank')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-eighth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:build_your_cv_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:click_on_create_folder')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
  ];

  // List of React Tour steps for Recruiter account

  const RECSteps = [
    {
      selector: '[data-tour="tour-first-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:team_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_team_to_add_your_team_members')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-second-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:team_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:click_on_plus')}</li>
                <li className='my-1'>
                  {t('productTour:add_your_team_members')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },

    {
      selector: '[data-tour="tour-third-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:client_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_client_to_add_your_clients')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-fourth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:client_setup')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:add_your_clients')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-fifth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:create_assessment')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t(
                    'productTour:select_assessments_to_build_your_assessment_bank'
                  )}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-sixth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:build_your_skill_assessment_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:add_assessments')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-seventh-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:list_your_job')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_jobs_and_Applicants_to_list_your_job')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-eighth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              {/* <p className='font-bold text-xl mb-2'>List your job</p> */}
              <ul className='list-disc px-5'>
                {/* <li className='my-1'>click on '+' / 'Add Job'</li> */}
                <li className='my-1'>{t('productTour:select_add_jobs')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-ninth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:create_cv_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>
                  {t('productTour:select_cv_bank_to_build_your_cv_bank')}
                </li>
              </ul>
            </div>
          }
        />
      ),
    },
    {
      selector: '[data-tour="tour-tenth-step"]',
      content: () => (
        <ReactTourPopUp
          history={history}
          content={
            <div className='flex flex-col'>
              <p className='font-bold text-xl mb-2'>
                {t('productTour:build_your_cv_bank')}
              </p>
              <ul className='list-disc px-5'>
                <li className='my-1'>{t('productTour:add_cv')}</li>
              </ul>
            </div>
          }
        />
      ),
    },
  ];

  // Application tour code starts here

  /**
   * useTour implements React Tour in our application
   *
   * @returns {Object} Returns a list of operations for accessibility
   */

  const { isOpen, setSteps, currentStep, setIsOpen } = useTour();

  // useEffect is responsible for initiating tour by opening menu item
  useEffect(() => {
    if (authState.first_login && isOpen && currentStep === 1) {
      menuBtnRef.current.click();
    }
    // eslint-disable-next-line
  }, [menuBtnRef, isOpen]);

  // useEffect is used to set setps and open React tour

  useEffect(() => {
    // authState.first_login provides condition for tour to either open or not
    if (authState.first_login && !isOpen) {
      if (authState.is_recruiter || authState.is_hiring_manager) {
        // tour is available only for recruiter and hiring manger
        setSteps(authState.is_recruiter ? RECSteps : HMSteps); // RECSteps and HMSteps provides steps for Tour
        if (!isOpen) {
          setTourModalOpen(true);
        }
        // if (location.pathname.includes('dashboard')) {
        //   setTourModalOpen(true);
        // } else {
        //   setIsOpen(true);
        // }
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authState.first_login && currentStep === 20) {
      setTourJobModalOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
    if (authState.first_login && currentStep === 25) {
      setTourSuccessModalOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
    // eslint-disable-next-line
  }, [currentStep, setIsOpen]);

  // Application tour code ends here

  return (
    <>
      <div className='sticky top-0 z-50 w-full h-20 bg-white shadow-md'>
        <div className='flex justify-between px-4 2xl:px-1 py-2 max-w-main-page mx-auto items-center'>
          <div className='w-full md:w-min lg:w-1/4 pt-1'>
            <Logo className='w-36 h-16 pb-2' />
          </div>

          <div className='lg:w-3/4 w-4/5 hidden md:flex justify-center items-center text-xs sm:text-base lg:text-lg'>
            <ul
              className={`flex ${
                authState.is_client_admin || authState.is_client_team
                  ? 'justify-center'
                  : 'justify-between'
              } items-center w-full lg:w-4/5 mt-2 transition-all`}
            >
              <li
                className={` cursor-pointer whitespace-nowrap ${
                  location.pathname.includes('/dashboard')
                    ? 'border-b-3 border-gradient'
                    : ' text-gray-700'
                } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                onClick={removeToast}
              >
                <Link to='/dashboard' className='px-1 sm:px-3 pb-1'>
                  {t('navbar:dashboard')}
                </Link>
              </li>

              {authState.is_client_admin || authState.is_client_team ? (
                <li
                  className={`cursor-pointer whitespace-nowrap ${
                    location.pathname.includes('/jobs') ||
                    location.pathname.includes('/applicants')
                      ? 'border-b-3 border-gradient'
                      : ' text-gray-700'
                  } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                  onClick={removeToast}
                >
                  <Link
                    to='/client/jobs/listings?type=Activated'
                    className='px-1 sm:px-3 pb-1'
                  >
                    {t('navbar:jobs_and_applicant')}
                  </Link>
                </li>
              ) : (
                <li
                  className={` cursor-pointer whitespace-nowrap ${
                    location.pathname.includes('/jobs') ||
                    location.pathname.includes('/applicants') ||
                    isResumebanOpenInJob()
                      ? 'border-b-3 border-gradient'
                      : ' text-gray-700'
                  } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                  onClick={removeToast}
                  data-tour={
                    authState.is_hiring_manager
                      ? 'tour-fifth-step'
                      : 'tour-seventh-step'
                  }
                >
                  <Link
                    to={`${
                      authState.is_recruiter || authState.is_recruiter_team
                        ? '/recruiter/jobs/listings?type=Activated'
                        : '/jobs/listings?type=Activated'
                    }`}
                    className='px-1 sm:px-3 pb-1'
                  >
                    {t('navbar:jobs_and_applicant')}
                  </Link>
                </li>
              )}

              {authState.is_client_admin || authState.is_client_team ? null : (
                <li
                  className={`cursor-pointer  ${
                    location.pathname.includes('/assessmentBank')
                      ? 'border-b-3 border-gradient'
                      : 'text-gray-700'
                  } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                  onClick={removeToast}
                  data-tour={
                    authState.is_hiring_manager
                      ? 'tour-third-step'
                      : 'tour-fifth-step'
                  }
                >
                  <Link to='/assessmentBank' className='px-1 sm:px-3 pb-1'>
                    {t('navbar:assessments')}
                  </Link>
                </li>
              )}

              {authState.is_client_admin || authState.is_client_team ? null : (
                <li
                  className={`cursor-pointer ${
                    location.pathname.includes('/resume-bank') &&
                    !isResumebanOpenInJob()
                      ? 'border-b-3 border-gradient'
                      : 'text-gray-700'
                  } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                  onClick={removeToast}
                  data-tour={
                    authState.is_hiring_manager
                      ? 'tour-seventh-step'
                      : 'tour-ninth-step'
                  }
                >
                  <Link
                    to='/resume-bank/listing'
                    className='px-1 sm:px-3 pb-1 whitespace-nowrap'
                  >
                    {t('productTour:cv_bank')}
                  </Link>
                </li>
              )}

              {authState.is_recruiter || authState.is_recruiter_team ? (
                <li
                  className={`cursor-pointer ${
                    location.pathname.includes('/recruiter/clients')
                      ? 'border-b-3 border-gradient'
                      : 'text-gray-700'
                  } ${currentLanguageCode === 'ar' ? 'ml-16' : 'mr-16'}`}
                  onClick={removeToast}
                  data-tour='tour-third-step'
                >
                  <Link to='/recruiter/clients' className='px-1 sm:px-3 pb-1'>
                    {t('navbar:clients')}
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
          {/* translation */}
          <div
            className={`${
              currentLanguageCode === 'ar' ? 'ml-4  mr-10' : 'ml-4'
            } mb-4 flex items-center `}
          >
            <button
              type='button'
              onClick={() => {
                i18next.changeLanguage('en');
                localStorage.setItem('dir', JSON.stringify('ltr'));
              }}
              className={`${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
            >
              <p
                className={`${
                  currentLanguageCode === 'en'
                    ? 'text-purple'
                    : 'text-custome_gray'
                } `}
              >
                En
              </p>
            </button>
            <button
              className={`flex items-start mb-1 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              type='button'
              onClick={() => {
                i18next.changeLanguage('ar');
                localStorage.setItem('dir', JSON.stringify('rtl'));
              }}
            >
              <p
                className={`w-full rounded text-xl mt-1 ${
                  currentLanguageCode === 'ar'
                    ? 'text-purple'
                    : 'text-custome_gray'
                } `}
              >
                العربية
              </p>
            </button>
          </div>
          {/* translation */}

          <div className='w-1/6 lg:w-1/4 justify-end items-center flex mr-0'>
            <div className='flex justify-center items-center'>
              {authState.is_recruiter ||
              authState.is_recruiter_team ||
              authState.is_hiring_manager ||
              authState.is_hiring_manager_team ? (
                <>
                  {/* <Message className='hidden md:block' /> */}

                  {disabled ? (
                    <div
                      className={`cursor-pointer relative ${
                        currentLanguageCode === 'ar'
                          ? 'ml-3 lg:ml-6'
                          : 'mr-3 lg:mr-6'
                      }`}
                      role='button'
                    >
                      <Notification />
                      <p className='absolute rounded-full bg-primary-rose text-white px-1 text-xs leading-4 top-0 right-0 transform translate-x-0.5'>
                        {notificationCount === 0
                          ? null
                          : notificationCount < 10
                          ? notificationCount
                          : '9+'}
                      </p>
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowNotificationModal(true)}
                      className='cursor-pointer relative'
                      role='button'
                    >
                      <Notification />
                      <p className='absolute rounded-full bg-primary-rose text-white px-1 text-xs leading-4 top-0 right-0 transform translate-x-0.5'>
                        {notificationCount === 0
                          ? null
                          : notificationCount < 10
                          ? notificationCount
                          : '9+'}
                      </p>
                    </div>
                  )}
                </>
              ) : null}

              <div className='hidden md:block'>
                <UserDropDown
                  handelLogoutModal={handelLogoutModal}
                  setTourModalOpen={setTourModalOpen}
                  RECSteps={RECSteps}
                  HMSteps={HMSteps}
                  menuBtnRef={menuBtnRef}
                />
              </div>
              <div className='md:hidden py-2'>
                <MenuBarIcon
                  className='w-9 h-9'
                  onClick={() => setOpenMenu(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile screen menu bar start*/}
      <div>
        <Transition.Root show={openMenu} as={Fragment} style={{ zIndex: '80' }}>
          <Dialog
            as='div'
            className='fixed inset-0 overflow-hidden menu-zindex'
            onClose={setOpenMenu}
            initialFocus={closeBtnRef}
          >
            <div className='absolute inset-0 overflow-hidden md:hidden'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-500'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-500'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>
              <div
                className={`fixed inset-y-0 right-0 max-w-full flex ${
                  currentLanguageCode === 'ar' ? 'pr-10' : 'pl-10'
                }`}
              >
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500 sm:duration-700'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-500 sm:duration-700'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <div className='relative w-screen max-w-md'>
                    <div className='h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll px-2'>
                      <div className='px-4 sm:px-6'>
                        <div className='flex'>
                          {/* <Dialog.Title className='w-full text-lg font-medium text-gray-900'>
                            <Logo className='h-8 w-28' />
                          </Dialog.Title> */}
                          <div className='w-full flex justify-end items-center'>
                            <span
                              ref={closeBtnRef}
                              onClick={() => setOpenMenu(false)}
                              className='text-primary-rose z-80 text-2xl font-semibold'
                            >
                              x
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='mt-4 relative flex-1 px-4 sm:px-6 text-xl text-primary-rose '>
                        {/* Replace with your content */}
                        <Link to='/dashboard/overview'>
                          <div
                            className='flex items-center'
                            onClick={() => setOpenMenu(false)}
                          >
                            <DashboardIcon
                              className={`w-6 h-6 ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            />
                            <p>{t('navbar:dashboard')}</p>
                          </div>
                        </Link>
                        {authState.is_client_admin ||
                        authState.is_client_team ? (
                          <Link to='/client/jobs/listings?type=Activated'>
                            <div
                              className='flex my-8'
                              onClick={() => setOpenMenu(false)}
                            >
                              <JobIcon
                                className={`w-6 h-6 ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              />
                              <p>{t('navbar:jobs_and_applicant')}</p>
                            </div>
                          </Link>
                        ) : (
                          <Link
                            to={`${
                              authState.is_recruiter ||
                              authState.is_recruiter_team
                                ? '/recruiter/jobs/listings?type=Activated'
                                : '/jobs/listings?type=Activated'
                            }`}
                          >
                            <div
                              className='flex my-8'
                              onClick={() => setOpenMenu(false)}
                            >
                              <JobIcon
                                className={`w-6 h-6 ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              />
                              <p>{t('navbar:jobs_and_applicant')}</p>
                            </div>
                          </Link>
                        )}

                        {authState.is_client_admin ||
                        authState.is_client_team ? null : (
                          <Link to='/assessmentBank/main-page/skill-assessment'>
                            <div
                              className='flex my-8'
                              onClick={() => setOpenMenu(false)}
                            >
                              <AssessmentIcon
                                className={`w-6 h-6 mt-0.5 ${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              />
                              <p>{t('navbar:assessments')}</p>
                            </div>
                          </Link>
                        )}

                        <Link to='/teams/team?filter=active'>
                          <div
                            className='flex'
                            onClick={() => setOpenMenu(false)}
                          >
                            <TeamIcon
                              className={`w-6 h-6 ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            />
                            <p>{t('navbar:team')}</p>
                          </div>
                        </Link>
                        <div
                          className='flex mt-8'
                          onClick={() => setLogoutModalOpen(true)}
                        >
                          <LogoutIlls
                            className={`w-6 h-6 mt-0.5 ${
                              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                            }`}
                          />
                          <p>{t('navbar:logout')}</p>
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <LogoutModal
        handelLogoutModal={setLogoutModalOpen}
        isLogoutModalOpen={isLogoutModalopen}
      />
      <ReactTourInstructionsModal
        isVisible={isTourModalopen}
        setVisible={setTourModalOpen}
        isRecruiter={authState.is_recruiter}
      />

      <ReactTourSuccessModal
        isVisible={isTourSuccessModalopen}
        setVisible={setTourSuccessModalOpen}
      />
      <ReactTourJobModal
        isVisible={isTourJobModalopen}
        setVisible={setTourJobModalOpen}
      />

      {/* mobile screen menu bar End*/}
    </>
  );
};

export default AuthHeader;
