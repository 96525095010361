import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { pdfjs, Document, Page } from 'react-pdf';
import PrimaryButton from 'components/Button/primaryButton';
import LottiLoader from 'components/LottiLoader';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ZoomIn } from 'assets/icons/ZoomIn.svg';
import { ReactComponent as ZoomOut } from 'assets/icons/ZoomOut.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewResumeModal({ url, viewResume, setViewResume }) {
  const { t } = useTranslation(['jobsAndApplicants']);
  const [numPages, setNumPages] = useState(null);
  const [downloadButton, setDownloadButton] = useState(false);

  const [zoomValue, setZoomValue] = useState(10);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setDownloadButton(true);
  }

  const header = (
    <div className='flex justify-end'>
      <div className='z-50 flex justify-end'>
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
          onClick={() => {
            setZoomValue(10);
            setViewResume(false);
          }}
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );

  const renderFooter = () => {
    return (
      <div className='flex justify-center pt-4'>
        {downloadButton ? (
          <a href={`${url}`} target='_blank' rel='noreferrer' download>
            <button className='whitespace-nowrap w-36'>
              <PrimaryButton>{t('jobsAndApplicants:download_cv')}</PrimaryButton>
            </button>
          </a>
        ) : null}
      </div>
    );
  };

  const loader = () => {
    return (
      <div className='flex justify-center items-center w-full mt-32 max-w-screen-xl mx-auto px-3'>
        <LottiLoader />
      </div>
    );
  };

  return (
    <div className=''>
      <Dialog
        header={header}
        visible={viewResume}
        onHide={() => {
          setZoomValue(10);
          setViewResume(false);
        }}
        style={{
          borderRadius: '1rem',
        }}
        className='w-full max-w-md md:max-w-2xl xl:max-w-6xl h-90vh rounded-lg modal-with-footer relative'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
        footer={renderFooter()}
      >
        <div className='flex justify-center w-full max-w-2xl mx-auto'>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={loader()}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                scale={zoomValue / 10}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
          {/* <iframe
            src={`${url}#view=fitH`}
            title='testPdf'
            height='100%'
            width='100%'
          /> */}
          <div className='absolute right-8 bottom-20 flex flex-col'>
            <button
              onClick={() => {
                if (zoomValue >= 10 && zoomValue < 18) {
                  setZoomValue(zoomValue + 1);
                }
              }}
              className=' mb-2'
            >
              <ZoomIn className='w-6 h-6 cursor-pointer' />
            </button>
            <button
              onClick={() => {
                if (zoomValue > 10 && zoomValue <= 18) {
                  setZoomValue(zoomValue - 1);
                }
              }}
              className=' mt-2'
            >
              <ZoomOut className='w-6 h-6 cursor-pointer' />
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
