import React, { Fragment, useState, useContext } from 'react';
import { AuthContext } from 'contexts/authContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import dayjs from 'dayjs';

import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';
import Loader from 'components/Loader';

import truncate from 'helper/trancute';
import { Popover, Transition } from '@headlessui/react';
import dateFormatter from 'helper/dateFormatter';

import { ReactComponent as ClockIcon } from 'assets/images/dashboard/ClockIcon.svg';
import { ReactComponent as OptionsIconBlack } from 'assets/images/dashboard/OptionsIconBlack.svg';
// import { ReactComponent as PanelInterviewIcon } from 'assets/images/dashboard/PanelInterviewIcon.svg';
import PanelInterviewIcon from 'assets/images/dashboard/PanelInterviewIcon.png';

function MeetingTilePanel({ meeting, stage, is_recruiter }) {
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  const [getToken, setGetToken] = useState(false);

  // eslint-disable-next-line
  const appplicantJWTForJisi2 = useQuery(
    ['applicant-jitsi-token2', meeting.room_name],
    async () => {
      const url = `${
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANTS_VIDEOINTERVIEW_JITSI
          : apiEndPoints.APPLICANTS_VIDEO_INTERVIEW_JITSI
      }?room_name=${meeting.room_name}${
        authState.is_client_admin || authState.is_client_team
          ? `&token=${authState.token_client}`
          : ''
      }`;
      const { data } = await makeAPIRequest.get(url);
      handelRedirectTOJoinMeeting(data.data.jwt, data.data.room_name);
      return data.data;
    },
    {
      enabled: getToken,
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const handelRedirectTOJoinMeeting = (jwt, room_name) => {
    if (authState.is_client_admin || authState.is_client_team) {
      history.push(
        `/client/applicants/interview/${meeting.job_id}/${
          meeting.stage
        }/${encodeURIComponent(meeting.applicant_name)}/${
          meeting.applicant_id
        }/?token=${jwt}&roomName=${room_name}`
      );
    } else {
      history.push(
        `/applicants/interview/${meeting.job_id}/${
          meeting.stage
        }/${encodeURIComponent(meeting.applicant_name)}/${
          meeting.applicant_id
        }/?token=${jwt}&roomName=${room_name}`
      );
    }
  };

  return (
    <div className='flex items-center h-16 w-full p-3'>
      <div className='flex flex-col w-12 items-center justify-center h-12 bg-white rounded border-t shadow-lg'>
        <ClockIcon />
        <span className='mt-1 text-xs text-primary-rose font-bold'>
          {dateFormatter(meeting?.schedule, 'HH : mm')}
        </span>
      </div>
      <div className='flex flex-1 mx-2 flex-col'>
        <div
          onClick={() => {
            history.push(
              `${
                authState.is_client_admin || authState.is_client_team
                  ? '/client' + meeting.link
                  : authState.is_recruiter || authState.is_recruiter_team
                  ? '/recruiter' + meeting.link
                  : meeting.link
              }`
            );
          }}
          className='text-xs truncate font-bold text-neutrals-black cursor-pointer'
        >
          {truncate(meeting?.applicant_name, 18)}
        </div>
        <div className='text-xs text-Neutrals-gray-1'>
          {truncate(meeting?.job_title, 18)}
        </div>
      </div>
      <div className='flex'>
        {/* <PanelInterviewIcon /> */}
        <img className='w-6 h-6' src={PanelInterviewIcon} alt='Panel' />
      </div>
      <div
        className={`flex  px-1  ${
          currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
        }`}
      >
        {is_recruiter ? null : (
          <Popover className='relative'>
            {({ open }) => (
              <>
                <Popover.Button className='px-1'>
                  <OptionsIconBlack />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='opacity-0 translate-y-1'
                  enterTo='opacity-100 translate-y-0'
                  leave='transition ease-in duration-150'
                  leaveFrom='opacity-100 translate-y-0'
                  leaveTo='opacity-0 translate-y-1'
                >
                  <Popover.Panel
                    className={`absolute z-10  w-32 p-2 transform -translate-x-1/2 ${
                      stage === 'interview' ? '-bottom-16' : '-bottom-6'
                    } ${
                      currentLanguageCode === 'ar' ? '-right-16' : '-left-16'
                    }   sm:px-0 `}
                  >
                    <div className='overflow-hidden rounded-md shadow-lg'>
                      <div
                        onClick={() => {
                          history.push(
                            `${
                              authState.is_client_admin ||
                              authState.is_client_team
                                ? '/client' + meeting.link
                                : authState.is_recruiter ||
                                  authState.is_recruiter_team
                                ? '/recruiter' + meeting.link
                                : meeting.link
                            }`
                          );
                        }}
                        className='relative p-2 px-4 border-b border-primary-rose border-opacity-30 bg-white text-sm flex justify-start cursor-pointer'
                      >
                        <div className='flex items-center justify-start text-xs font-bold text-neutrals-black'>
                          {t('open')}
                        </div>
                      </div>
                      {stage === 'interview' ? (
                        dayjs().isAfter(
                          dayjs(meeting?.schedule).subtract(3, 'minute')
                        ) &&
                        dayjs().isBefore(dayjs(meeting?.completion_date)) &&
                        meeting?.can_join ? (
                          <div
                            onClick={() => {
                              setGetToken(true);
                            }}
                            className='relative p-2 px-4 bg-white text-sm flex justify-start cursor-pointer'
                          >
                            <div className='flex items-center justify-start text-xs font-bold text-neutrals-black'>
                              {appplicantJWTForJisi2.isLoading ? (
                                <Loader
                                  width='16'
                                  height='16'
                                  color='#6600C9'
                                />
                              ) : (
                                t('join')
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className='relative p-2 px-4 bg-white text-sm flex justify-start '>
                            <div className='flex items-center justify-start text-xs font-bold text-neutrals-black cursor-not-allowed opacity-60'>
                              {t('join')}
                            </div>
                          </div>
                        )
                      ) : null}
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </div>
    </div>
  );
}

export default MeetingTilePanel;
