import React from 'react';
import PublicHeader from 'components/Header/publicHeader';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import PrimaryButton from 'components/Button/primaryButton';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import useCountryCodes from 'hooks/useCountryCodes.js';

import { ReactComponent as RequestDemo } from 'assets/images/requestDemo/requestDemo.svg';
import { ReactComponent as LeftIcon } from 'assets/images/requestDemo/leftIcon.svg';
import { ReactComponent as RightIcon } from 'assets/images/requestDemo/rightIcon.svg';

const Demopage = () => {
  const { t } = useTranslation(['common']);
  const currentLanguageCode = cookies.get('i18next');
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  const onSubmit = () => {
    reset();
  };

  const countryCode = useCountryCodes();

  return (
    <>
      <PublicHeader />
      <div className='sm:flex lg:hidden absolute p-4 w-50'>
        <LeftIcon />
      </div>
      <div className='lg:flex lg:p-8 sm:py-0 max-w-screen-xl mx-auto sm:mt-32 lg:mt-0'>
        <div className='sm:w-4/6 lg:w-1/2 lg:mx-4 sm:mx-auto'>
          <p className='text-primary-rose text-2xl text-center'>
            {t('common:request_demo')}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex mt-4'>
              <div
                className={`w-1/2 my-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <span className='p-float-label'>
                  <Controller
                    name='first_name'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='first_name'>{t('common:first_name')}</label>
                </span>
                {getFormErrorMessage('first_name')}
              </div>
              <div
                className={`w-1/2 my-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <span className='p-float-label'>
                  <Controller
                    name='last_name'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='last_name'>{t('common:last_name')}</label>
                </span>
                {getFormErrorMessage('last_name')}
              </div>
            </div>
            <div className='flex'>
              <div
                className={`w-1/2 my-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <span className='p-float-label'>
                  <Controller
                    name='company'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='company'>{t('common:company')}</label>
                </span>
                {getFormErrorMessage('company')}
              </div>
              <div
                className={`w-1/2 my-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <span className='p-float-label'>
                  <Controller
                    name='email'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('authentication:invalid_email_address'),
                      },
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='email'>{t('common:email')}</label>
                </span>
                {getFormErrorMessage('email')}
              </div>
            </div>
            <div className='flex'>
              <div
                className={`w-1/2 my-4 flex ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <div
                  className={`w-4/12 ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Controller
                    name='country_code'
                    useGrouping={false}
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        placeholder='+00'
                        options={countryCode?.data?.message}
                      />
                    )}
                  />
                </div>
                <div className='w-full'>
                  <span className='p-float-label'>
                    <Controller
                      name='contact'
                      control={control}
                      rules={{
                        required: 'This is a required field',
                        pattern: {
                          value: /^[0-9]*$/i,
                          message: 'Only numbers allowed.',
                        },
                        maxLength: {
                          value: 14,
                          message: 'Give valid number',
                        },
                      }}
                      render={({ field }) => (
                        <InputText id={field.name} {...field} />
                      )}
                    />
                    <label htmlFor='contact'>{t('productTour:contact')}</label>
                  </span>
                  {getFormErrorMessage('contact')}
                </div>
              </div>
              <div
                className={`w-1/2 my-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <span className='p-float-label'>
                  <Controller
                    name='date_time'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='date_time'>
                    {t('common:request_demo_date_and_time')}
                  </label>
                </span>
                {getFormErrorMessage('date_time')}
              </div>
            </div>
            <div className='w-full my-4'>
              <span className='p-float-label'>
                <Controller
                  name='message'
                  control={control}
                  rules={{
                    required: 'This is a required field',
                  }}
                  render={({ field }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={5}
                      autoResize
                    />
                  )}
                />
                <label htmlFor='message'>{t('common:write_a_message')}</label>
              </span>
              {getFormErrorMessage('message')}
            </div>
            <div className='w-32 mx-auto'>
              <button className='w-32'>
                <PrimaryButton>{t('common:submit')}</PrimaryButton>
              </button>
            </div>
          </form>
        </div>
        <div className='sm:flex lg:hidden absolute right-8'>
          <RightIcon />
        </div>
        <div className='hidden lg:flex w-1/2 '>
          <div className='w-96 mx-auto mt-12 '>
            <RequestDemo className='h-80 w-96' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Demopage;
