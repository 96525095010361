import React from 'react';
import ReactQuill, { Quill } from 'react-quill';

let Link = Quill.import('formats/link');

class CustomLink extends Link {
  static sanitize(url) {
    let value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++)
        if (value.startsWith(CustomLink.PROTOCOL_WHITELIST[i])) return value;
      return `https://${value}`;
    }
    return value;
  }
}
Quill.register(CustomLink);

const Editor = ({ onChange, text }) => {
  return (
    <div className='wrapper'>
      <ReactQuill
        theme='snow'
        onChange={onChange}
        value={text}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'.editor'}
        style={{ height: '200px' }}
      />
    </div>
  );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'link',
];

export default Editor;
