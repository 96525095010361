import { useState, useContext } from 'react';
import { useWindowSize } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';

import { useMutation, useQueryClient, useQuery } from 'react-query';

import { ApplicantsContext } from 'contexts/applicantsContext';
import { AuthContext } from 'contexts/authContext';
import { JobContext } from 'contexts/jobContext';

import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

import { ErrorToast } from 'services/toasterService';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';
import queryKeys from 'reactQuery/constant';

const ConfirmRejectApplicantModal = ({ applicantsList, currentStage }) => {
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const queryClient = useQueryClient();
  const { height } = useWindowSize();
  const [sendEmail, setSendEmail] = useState(false);
  const [urlForEmailTemplates, setUrlForEmailTemplates] = useState(null);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);

  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);
  const { authState } = useContext(AuthContext);
  const { jobState } = useContext(JobContext);

  const history = useHistory();

  const handelgobackToApplicantsOverviewpage = () => {
    history.push(
      `${jobState.Job_applicant_overview_pathname}${jobState.Job_applicant_overview_searchparam}`
    );
  };

  const genarateClassName = () => {
    if (height < 650) {
      return 'min-h-80vh';
    } else if (height > 900) {
      return 'min-h-50vh';
    } else {
      return 'min-h-70vh';
    }
  };

  const updateApplicantsStatusMutation = useMutation(async (data) => {
    let queryData;

    if (authState.is_client_admin || authState.is_client_team) {
      queryData = {
        ...data,
        token: authState.token_client,
      };
    } else {
      queryData = data;
    }
    let url =
      authState.is_client_admin || authState.is_client_team
        ? apiEndPoints.CLIENT_APPLICANT_CHANGE_STATUS
        : apiEndPoints.APPLICANT_CHANGE_STATUS;
    const res = await makeAPIRequest.post(url, queryData);
    return res.data;
  });

  const handelRejectApplicants = () => {
    updateApplicantsStatusMutation.mutate(
      {
        status: 'Rejected',
        send_mail: sendEmail,
        applicants: applicantsList.map((item) => item._id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.allApplicants &&
              query.queryKey[2] ===
                getJobStageNameForBackendQuery(currentStage),
          });
          queryClient.invalidateQueries(queryKeys.specificApplicant);
          queryClient.invalidateQueries(queryKeys.allJobs);

          handelCloseRejectCandidateModal();
          handelgobackToApplicantsOverviewpage();
        },
        onError: (error) => {
          ErrorToast({ text: error.response.data.message });
        },
      }
    );
  };

  const handleAPIcall = () => {
    if (sendEmail && applicantsList.length <= 1) {
      const scheduleDetails = applicantsList.map((item) => item._id);
      const url = `${
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_APPLICANT_REJECTION_EMAIL
          : apiEndPoints.JOB_SHOW_REJECT_TEMPLATE
      }?applicant=${scheduleDetails}${
        authState.is_client_admin || authState.is_client_team
          ? `&token=${authState.token_client}`
          : ''
      }`;
      setUrlForEmailTemplates(url);
    } else {
      handelRejectApplicants();
    }
  };

  const handelCloseRejectCandidateModal = () => {
    setShowEmailTemplate(false);
    setSendEmail(false);
    setUrlForEmailTemplates(null);
    applicantsDispatch({
      type: 'SETREJECTAPPLICANTMODALVISIBLESTATE',
      payload: false,
    });
  };

  const emailTemplateData = useQuery(
    ['show-reject-email-template', urlForEmailTemplates],
    async () => {
      const res = await makeAPIRequest.get(urlForEmailTemplates);
      return res;
    },
    {
      enabled: urlForEmailTemplates !== null,
      onSuccess: (data) => {
        setShowEmailTemplate(true);
      },
    }
  );

  const header = (
    <div className='relative'>
      {showEmailTemplate ? (
        <p className='text-xl text-center tracking-wide text-black mt-8'>
          <span className='text-primary-rose'>Reject</span> candidate
        </p>
      ) : (
        <p className='text-xl text-center tracking-wide text-black mt-8'>
          Are your sure, you want to{' '}
          <span className='text-primary-rose'>reject</span> the candidate ?
        </p>
      )}

      {showEmailTemplate ? null : (
        <p className='text-base font-normal text-center mt-6'>
          By rejecting candidate you will move them to <br /> closed applicants
        </p>
      )}

      <button
        className='absolute -top-8 right-0'
        onClick={handelCloseRejectCandidateModal}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  const renderFooter = () => {
    return (
      <div className='mt-4 w-full flex justify-center'>
        <button onClick={() => handelRejectApplicants()}>
          <PrimaryButton isLoading={updateApplicantsStatusMutation.isLoading}>
            {t('jobsAndApplicants:send')}
          </PrimaryButton>
        </button>
        <button onClick={handelCloseRejectCandidateModal}>
          <BaseButton>{t('jobsAndApplicants:cancel')}</BaseButton>
        </button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={applicantsState.is_reject_applicant_modal_visible}
        onHide={handelCloseRejectCandidateModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        footer={showEmailTemplate ? renderFooter() : null}
        className={
          showEmailTemplate
            ? `w-9/12 xl:w-1/2 mx-auto rounded-xl modal-with-footer ${genarateClassName()}`
            : `w-8/12 xl:w-1/2 mx-auto rounded-xl min-h-50vh`
        }
      >
        {showEmailTemplate ? (
          <div
            dangerouslySetInnerHTML={getSanitizeMarkupData({
              markup: emailTemplateData?.data?.data?.data,
              renderHTML: true,
            })}
          ></div>
        ) : (
          <div className='relative'>
            <div className='flex justify-center mb-6 mt-8'>
              <div
                className={`${currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'}`}
              >
                <div className='flex items-center py-2 mb-2'>
                  <Checkbox
                    value={sendEmail}
                    onChange={(e) => setSendEmail(!sendEmail)}
                    checked={sendEmail}
                  />
                  <p className='text-sm mx-2'>
                    {t(
                      'jobsAndApplicants:send_rejection_email_to_applicant_in_3_days'
                    )}
                  </p>
                </div>
                <div className='flex justify-center items-center'>
                  <button
                    className={`w-28 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                    onClick={() => handleAPIcall()}
                  >
                    <PrimaryButton
                      isLoading={
                        emailTemplateData.isLoading ||
                        emailTemplateData.isFetching ||
                        updateApplicantsStatusMutation.isLoading
                      }
                    >
                      <p className='text-base leading-5'>{t('common:yes')}</p>
                    </PrimaryButton>
                  </button>
                  <button
                    className={`w-28 ${
                      currentLanguageCode === 'ar' ? 'ml-6' : 'mr-6'
                    }`}
                    onClick={handelCloseRejectCandidateModal}
                  >
                    <BaseButton>
                      <p className='text-base leading-5'>{t('common:no')}</p>
                    </BaseButton>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ConfirmRejectApplicantModal;
