import React, { useState, useContext, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import dateFormatter from 'helper/dateFormatter';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';

import queryKeys from 'reactQuery/constant';

import { AuthContext } from 'contexts/authContext';

import PrimaryButton from 'components/Button/primaryButton';
import Tip from 'components/Tooltip';
import Loader from 'components/Loader';

import { ErrorToast, SuccessToast } from 'services/toasterService';

import { MentionsInput, Mention } from 'react-mentions';

import { ReactComponent as NotesIlls } from 'assets/images/notes/NotesIlls.svg';
import { ReactComponent as RightIlls } from 'assets/images/notes/activeRightIlls.svg';
import { ReactComponent as WrongIlls } from 'assets/images/notes/wrongIlls.svg';
import { ReactComponent as DeleteIlls } from 'assets/images/notes/delete.svg';
import { ReactComponent as EditIlls } from 'assets/images/notes/edit.svg';
import { ReactComponent as NoAssessmentIllustration } from 'assets/images/assessmentBank/NoAssessmentIllustration.svg';
import UseTeammembersForMentions from 'hooks/useTeamMembersFormentions';

function NotesModal({
  showNotesModal,
  setShowNotesModal,
  applicantID,
  isJobDeactivated,
}) {
  const { authState } = useContext(AuthContext);
  const { t } = useTranslation(['jobsAndApplicants']);
  const currentLanguageCode = cookies.get('i18next');
  const queryClient = useQueryClient();

  const location = useLocation();
  const [addNote, setAddNote] = useState(false);
  const [updateNote, setUpdateNote] = useState(false);
  const [noteID, setNoteID] = useState('');

  const [mentionedMsg, setMentionedMsg] = useState();
  const [plainTextMsg, setPlainTextMsg] = useState();
  const [mentions, setMentions] = useState();

  const [suggestionFormention, setSuggestionForMention] = useState();

  // retreving notes of applicants
  const applicantsNotesData = useQuery(
    [queryKeys.applicantsNotes, applicantID],
    async () => {
      let url = `${
        authState.is_client_admin || authState.is_client_team
          ? apiEndPoints.CLIENT_JOB_APPLICANTS_NOTES
          : apiEndPoints.JOB_APPLICANTS_NOTES
      }?applicant_id=${applicantID}${
        authState.is_client_admin || authState.is_client_team
          ? `&token=${authState.token_client}`
          : ''
      }`;
      const { data } = await makeAPIRequest.get(url);
      return data;
    },
    { staleTime: 180000, enabled: !!showNotesModal }
  );

  // Note Adding mutation
  const addNoteMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.JOB_APPLICANTS_NOTES,
        data
      );
      return res;
    },
    {
      onSuccess: (data) => {
        handleDataReset();
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.applicantsNotes &&
            query.queryKey[1] === applicantID,
        });
        setAddNote(false);
        SuccessToast({ text: data?.data?.message });
      },
      onError: () => {
        handleDataReset();
      },
    }
  );

  // handling edit button
  const handleEdit = (data) => {
    setAddNote(false);
    setUpdateNote(true);
    setMentionedMsg(data.mentioned_msg);
    setPlainTextMsg(data.msg);
    setMentions(data.mentions);
    setNoteID(data._id);
  };

  // Note update mutation
  const updateNoteMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.JOB_APPLICANTS_NOTES,
        data
      );
      return res;
    },
    {
      onSuccess: (data) => {
        handleDataReset();
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.applicantsNotes &&
            query.queryKey[1] === applicantID,
        });
        setUpdateNote(false);
        SuccessToast({ text: data?.data?.message });
        setTimeout(() => setNoteID(''), 500);
      },
      onError: (error) => {
        ErrorToast({ text: error?.response?.data?.message });
        setTimeout(() => setNoteID(''), 500);
        setUpdateNote(false);
        handleDataReset();
      },
    }
  );

  const handleAddNoteClose = () => {
    setShowNotesModal(false);
    setAddNote(false);
    setUpdateNote(false);
  };

  // submit handler for adding new note
  const handleAddNoteSubmit = (e) => {
    e.preventDefault();
    const newData = {
      applicant_id: applicantID,
      mentions: mentions,
      msg: plainTextMsg,
      mentioned_msg: mentionedMsg,
    };
    addNoteMutation.mutate(newData);
  };

  const handleDelete = (data) => {
    deleteNoteMutation.mutate({ note_id: data._id });
  };

  // mutation for deleting note
  const deleteNoteMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.delete(
        apiEndPoints.JOB_APPLICANTS_NOTES,
        {
          data: data,
        }
      );
      return res;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.applicantsNotes &&
            query.queryKey[1] === applicantID,
        });
        SuccessToast({ text: data?.data?.message });
      },
    }
  );

  const allTeamMembersForMentions = UseTeammembersForMentions(
    showNotesModal && !location.pathname.includes('/client/')
  );

  useEffect(() => {
    setSuggestionForMention(
      allTeamMembersForMentions?.data
        ?.map((item) => {
          return { id: item.id, display: item.first_name };
        })
        .filter(
          (item) => item.id !== JSON.parse(localStorage.getItem('user_id'))
        )
    );
  }, [allTeamMembersForMentions?.data]);

  const header = (
    <div>
      <div className='flex justify-center'>
        <p className='text-center flex-1 text-primary-rose text-2xl mt-1'>
          {t('jobsAndApplicants:notes')}
        </p>
        <div className='z-50 flex justify-end'>
          <svg
            width='40'
            height='40'
            viewBox='0 0 67 65'
            fill='none'
            xmlns='https://www.w3.org/2000/svg'
            className='cursor-pointer'
            onClick={() => {
              handleAddNoteClose();
              handleDataReset();
              setNoteID();
            }}
          >
            <path
              d='M41.3962 24.3962L24.9824 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M24.9824 24.3962L41.3962 40.2906'
              stroke='#FF009C'
              strokeWidth='5.70168'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
      {authState.is_client_admin || authState.is_client_team ? null : (
        <>
          {!addNote && !updateNote && !isJobDeactivated ? (
            <div className='w-full flex justify-end mb-2 mt-2 '>
              <button
                className={`w-24 text-base ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                onClick={() => setAddNote(true)}
              >
                <PrimaryButton>{t('jobsAndApplicants:add')}</PrimaryButton>
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );

  // Note mention onChange handler
  const handleAddNoteChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setMentionedMsg(event.target.value);
    setPlainTextMsg(newPlainTextValue);
    const mentionData = mentions.map((item) => item.id);
    setMentions(mentionData);
  };

  // reset local state data for mention
  const handleDataReset = () => {
    setMentionedMsg();
    setPlainTextMsg();
    setMentions();
  };

  // updating the existing note
  const handleEditnoteSubmit = (e) => {
    e.preventDefault();
    const newData = {
      note_id: noteID,
      mentions: mentions,
      msg: plainTextMsg,
      mentioned_msg: mentionedMsg,
    };
    updateNoteMutation.mutate(newData);
  };

  // Note Mention custom style
  const styles = {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
    },
    mentionStyle: {
      backgroundColor: '#DEBCFF',
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
      overflow: 'hidden',
      height: 85,
    },
    input: {
      padding: 9,
      border: '1px solid #6600C9',
      height: 85,
      overflow: 'auto',
      width: '100%',
      borderRadius: '5px',
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        fontSize: 14,
        transition: 'background-color .4s',
        boxShadow:
          '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        marginLeft: '5px',
      },
      item: {
        padding: '5px 15px',
        '&focused': {
          backgroundColor: '#eceff1',
        },
      },
    },
  };

  return (
    <div>
      <Dialog
        header={header}
        visible={showNotesModal}
        onHide={() => handleAddNoteClose()}
        style={{
          borderRadius: '1rem',
        }}
        className='w-10/12 max-w-3xl lg:w-10/12 lg:max-w-4xl h-90vh rounded-lg'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
        appendTo='self'
      >
        <Transition
          show={addNote}
          enter='transition ease-out duration-200'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'
        >
          <form onSubmit={(e) => handleAddNoteSubmit(e)}>
            <div className='transition ease-in duration-700'>
              <div className='w-full my-4 min-h-20'>
                <span className='p-float-label'>
                  <MentionsInput
                    value={mentionedMsg}
                    onChange={(event, newValue, newPlainTextValue, mentions) =>
                      handleAddNoteChange(
                        event,
                        newValue,
                        newPlainTextValue,
                        mentions
                      )
                    }
                    placeholder={"Mention people using '@'"}
                    style={styles}
                  >
                    <Mention
                      trigger='@'
                      data={suggestionFormention}
                      style={styles.mentionStyle}
                      appendSpaceOnAdd
                    />
                  </MentionsInput>
                </span>
              </div>
              <div className='flex justify-end mb-2'>
                {addNoteMutation.isLoading ? (
                  <button
                    disabled
                    className='w-8 h-8 bg-E9D3FF flex justify-center items-center rounded mx-1.5'
                  >
                    <div className='w-4'>
                      <Loader color='#6600C9' width={18} height={14} />
                    </div>
                  </button>
                ) : (
                  <Tip content='Save note'>
                    <button
                      className='w-8 h-8 bg-E9D3FF flex justify-center items-center rounded hover:bg-DEBCFF transition-colors hover:bg-opacity-60 mx-1.5'
                      disabled={!mentionedMsg?.length}
                    >
                      <div>
                        <RightIlls className='w-5 h-5' />
                      </div>
                    </button>
                  </Tip>
                )}

                <Tip content='cancel'>
                  <button
                    className='w-8 h-8 bg-E9D3FF flex justify-center items-center rounded hover:bg-DEBCFF transition-colors hover:bg-opacity-60 mx-1.5'
                    onClick={(e) => {
                      e.preventDefault();
                      setAddNote(false);
                      handleDataReset();
                    }}
                  >
                    <div className='w-4'>
                      <WrongIlls />
                    </div>
                  </button>
                </Tip>
              </div>
            </div>
          </form>
        </Transition>
        {applicantsNotesData?.data?.data?.length ? (
          <>
            <div>
              {applicantsNotesData.isLoading ? (
                <>
                  <div>
                    <div className='flex'>
                      <div className={`shadow-lg border p-1 rounded-3xl w-10 h-10 ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}>
                        <NotesIlls className={`pt-1 ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`} />
                      </div>
                      <div className='flex w-full flex-col space-y-3'>
                        <Skeleton width='60%' height='1.2rem' />
                        <Skeleton width='80%' height='1.2rem' />
                      </div>
                    </div>

                    <div className='flex mt-6'>
                      <div className={`shadow-lg border p-1 rounded-3xl w-10 invisible ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}>
                        <NotesIlls className={`pt-1 ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`} />
                      </div>
                      <div className='w-full -mb-4 flex justify-between'>
                        <Skeleton
                          width='20%'
                          height='1.4rem'
                          animation='none'
                        />
                        <Skeleton
                          width='20%'
                          height='1.4rem'
                          animation='none'
                        />
                      </div>
                    </div>

                    <hr className='mt-2 mb-3 border-primary-rose opacity-30' />
                  </div>
                  <div>
                    <div className='flex'>
                      <div className={`shadow-lg border p-1 rounded-3xl w-10 h-10 ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}>
                        <NotesIlls className={`pt-1 ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`} />
                      </div>
                      <div className='flex w-full flex-col space-y-3'>
                        <Skeleton width='60%' height='1.2rem' />
                        <Skeleton width='80%' height='1.2rem' />
                      </div>
                    </div>

                    <div className='flex mt-6'>
                      <div className={`shadow-lg border p-1 rounded-3xl w-10 invisible ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}>
                        <NotesIlls className={`pt-1 ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`} />
                      </div>
                      <div className='w-full -mb-4 flex justify-between'>
                        <Skeleton
                          width='20%'
                          height='1.4rem'
                          animation='none'
                        />
                        <Skeleton
                          width='20%'
                          height='1.4rem'
                          animation='none'
                        />
                      </div>
                    </div>

                    <hr className='mt-2 mb-3 border-primary-rose opacity-30' />
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  {applicantsNotesData?.data?.data?.map((item) => (
                    <div key={item._id}>
                      {noteID === item._id ? (
                        <>
                          <Transition
                            show={updateNote}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 translate-y-1'
                          >
                            <form onSubmit={(e) => handleEditnoteSubmit(e)}>
                              <div className='transition ease-in duration-700'>
                                <div className='w-full my-4 min-h-20'>
                                  <span className='p-float-label'>
                                    <MentionsInput
                                      value={mentionedMsg}
                                      onChange={(
                                        event,
                                        newValue,
                                        newPlainTextValue,
                                        mentions
                                      ) =>
                                        handleAddNoteChange(
                                          event,
                                          newValue,
                                          newPlainTextValue,
                                          mentions
                                        )
                                      }
                                      placeholder={"Mention people using '@'"}
                                      style={styles}
                                    >
                                      <Mention
                                        trigger='@'
                                        data={suggestionFormention}
                                        style={styles.mentionStyle}
                                        appendSpaceOnAdd
                                      />
                                    </MentionsInput>
                                  </span>
                                </div>
                                <div className='flex justify-end mb-2'>
                                  {updateNoteMutation.isLoading ? (
                                    <button
                                      disabled
                                      className='w-8 h-8 bg-E9D3FF flex justify-center items-center mx-1.5'
                                    >
                                      <div className='w-4'>
                                        <Loader
                                          color='#6600C9'
                                          width={18}
                                          height={14}
                                        />
                                      </div>
                                    </button>
                                  ) : (
                                    <Tip content='Edit note'>
                                      <button className='w-8 h-8 bg-E9D3FF flex justify-center items-center mx-1.5'>
                                        <div>
                                          <RightIlls className='w-5 h-5' />
                                        </div>
                                      </button>
                                    </Tip>
                                  )}

                                  <Tip content='cancel'>
                                    <button
                                      className='w-8 h-8 bg-E9D3FF flex justify-center items-center mx-1.5'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUpdateNote(false);
                                        setNoteID('');
                                      }}
                                    >
                                      <div className='w-4'>
                                        <WrongIlls />
                                      </div>
                                    </button>
                                  </Tip>
                                </div>
                              </div>
                            </form>
                          </Transition>
                        </>
                      ) : (
                        <>
                          <div className='flex'>
                            <div className={`shadow-lg border p-1 rounded-3xl w-10 h-10 ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}>
                              <NotesIlls className={`pt-1 ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`} />
                            </div>
                            <div className={`w-11/12 ${currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'}`}>
                              <p className='text-justify text-gray-1 pt-2 text-sm'>
                                {item.msg}
                              </p>
                            </div>
                          </div>
                          {item.created_by_id === authState.user_id &&
                          !isJobDeactivated ? (
                            <div className='flex mt-3 w-full'>
                              {authState.is_client_admin ||
                              authState.is_client_team ? (
                                <div
                                  className={`w-full flex invisible ${currentLanguageCode === 'ar' ? 'pr-14' : 'pl-14'}`}
                                  tabIndex='-1'
                                >
                                  <Tip content='Edit note'>
                                    <p className='cursor-pointer'>
                                      <EditIlls className={`w-6 h-6 ${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`} />
                                    </p>
                                  </Tip>
                                  {deleteNoteMutation.isLoading &&
                                  item._id === noteID ? (
                                    <div className='w-4'>
                                      <Loader
                                        color='#6600C9'
                                        width={18}
                                        height={14}
                                      />
                                    </div>
                                  ) : (
                                    <DeleteIlls className='w-5 h-5 cursor-pointer' />
                                  )}
                                </div>
                              ) : (
                                <div className={`w-full flex ${currentLanguageCode === 'ar' ? 'pr-14' : 'pl-14'}`}>
                                  <Tip content='Edit note'>
                                    <p
                                      className='cursor-pointer'
                                      onClick={() => handleEdit(item)}
                                    >
                                      <EditIlls className={`w-6 h-6 ${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`} />
                                    </p>
                                  </Tip>
                                  {deleteNoteMutation.isLoading &&
                                  item._id === noteID ? (
                                    <div className='w-4'>
                                      <Loader
                                        color='#6600C9'
                                        width={18}
                                        height={14}
                                      />
                                    </div>
                                  ) : (
                                    <DeleteIlls
                                      className='w-5 h-5 cursor-pointer'
                                      onClick={() => {
                                        setNoteID(item._id);
                                        handleDelete(item);
                                      }}
                                    />
                                  )}
                                </div>
                              )}

                              <p className='text-Neutrals-gray-1 text-xs w-full justify-end text-right'>
                                {dateFormatter(
                                  item.created_time,
                                  'DD MMM YYYY, hh:mm A '
                                )}
                              </p>
                            </div>
                          ) : (
                            <div className='flex mt-3 w-full'>
                              {authState.is_client_admin ||
                              authState.is_client_team ? (
                                <p className={`text-xs w-full text-gray-2 font-semibold ${currentLanguageCode === 'ar' ? 'pr-14' : 'pl-14'}`}>
                                  {t('jobsAndApplicants:added_by')}{' '}
                                  {item.created_by_name}
                                </p>
                              ) : (
                                <p className={`text-xs w-full text-gray-2 font-semibold invisible ${currentLanguageCode === 'ar' ? 'pr-14' : 'pl-14'}`}>
                                  {t('jobsAndApplicants:added_by')}{' '}
                                  {item.created_by_name}
                                </p>
                              )}

                              <p className='text-Neutrals-gray-1 text-xs w-full justify-end text-right'>
                                {dateFormatter(
                                  item.created_time,
                                  'DD MMM YYYY, hh:mm A'
                                )}
                              </p>
                            </div>
                          )}
                          <hr className='mt-2 mb-3 border-primary-rose opacity-30' />
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        ) : (
          <div className='w-40 mt-12 mx-auto'>
            <NoAssessmentIllustration />
            <p className={`text-center mt-4 text-gray-1 ${currentLanguageCode === 'ar' ? 'pr-6' : 'pl-6'}`}>
              {t('jobsAndApplicants:no_notes_to_show')}
            </p>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default NotesModal;
