import { useContext } from 'react';
import { useQuery } from 'react-query';

import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

import { AuthContext } from 'contexts/authContext';

import queryKeys from '../reactQuery/constant';

export default function UseApplicantsAssessmentInfo({
  enable = true,
  applicantID,
  jobId,
  jobStage,
}) {
  const { authState } = useContext(AuthContext);
  return useQuery(
    [queryKeys.specificApplicant, applicantID, jobId, jobStage],
    async () => {
      const { data } = await makeAPIRequest.get(
        createQueryParamsForGetReq(
          authState.is_client_admin || authState.is_client_team
            ? apiEndPoints.CLIENT_APPLICANT_ASSESSMENT_INFO
            : apiEndPoints.APPLICANTS_ASSESSMENT_INFO,
          {
            applicant_id: applicantID,
            stage: jobStage,
            job_id: jobId,
            token:
              authState.is_client_admin || authState.is_client_team
                ? authState.token_client
                : null,
          }
        )
      );
      return data;
    },
    {
      staleTime: 180000,
      enabled: enable,
    }
  );
}
