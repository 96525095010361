import React, { useState, useContext } from 'react';
import { useWindowSize } from 'react-use';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';
import { DatePicker, TimePicker } from '@atlaskit/datetime-picker';
import Textfield from '@atlaskit/textfield';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';
import getDisplaynameForJobStages from 'helper/getDisplaynameForJobStages';
import getModifiedStagename from 'helper/getModifiedStagename';

import { ApplicantsContext } from 'contexts/applicantsContext';

import { ErrorToast } from 'services/toasterService';

import dateFormatter from 'helper/dateFormatter';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';

import queryKeys from 'reactQuery/constant';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

import { ReactComponent as SettingsIlls } from 'assets/images/Interviews/settings_ills.svg';

const utc = require('dayjs/plugin/utc');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const ScheduleInterviewModal = ({
  currentStage,
  nextStage,
  selectedApplicantsList,
  setSelectedApplicantsList,
}) => {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['common', 'jobsAndApplicants']);
  const queryClient = useQueryClient();

  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const [formDate, setFormDate] = useState('');
  const { height } = useWindowSize();
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [timePickerVal, setTimePickerVal] = useState('');
  const [interviewDuration, setInterviewDuration] = useState(30);
  const [urlForEmailTemplates, setUrlForEmailTemplates] = useState(null);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);

  const genarateClassName = () => {
    if (height < 650) {
      return 'min-h-80vh';
    } else if (height > 900) {
      return 'min-h-50vh';
    } else {
      return 'min-h-70vh';
    }
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const handelCloseInterviewScheduleModal = () => {
    setShowEmailTemplate(false);
    setSelectedTime(new Date());
    setTimePickerVal('');
    setFormDate('');
    setUrlForEmailTemplates(null);
    applicantsDispatch({
      type: 'SETSCHEDULEVIDEOINTERVIEWMODALVISIBLESTATE',
      payload: false,
    });
    applicantsDispatch({
      type: 'SETRESCHEDULEVIDEOINTERVIEWMODALVISIBLESTATE',
      payload: false,
    });
  };

  const scheduleInterverMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.APPLICANTS_SCHEDULE_INTERVIEW,
      data
    );
    return res.data;
  });

  const handelApplicantsInterviewScheduling = () => {
    const queryDataForSchedulingInterview = {
      stage: getDisplaynameForJobStages(currentStage),
      from_date: getDateAndTimeValForBackend(),
      applicants: selectedApplicantsList.map((item) => item._id),
      buffer_time: interviewDuration,
    };

    const handelScheduleInterviewMutation = () => {
      scheduleInterverMutation.mutate(queryDataForSchedulingInterview, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.allApplicants &&
              query.queryKey[2] === nextStage,
          });
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.allApplicants &&
              query.queryKey[2] ===
                getJobStageNameForBackendQuery(currentStage),
          });
          queryClient.invalidateQueries(queryKeys.allJobs);
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.specificApplicant &&
              query.queryKey[1] ===
                selectedApplicantsList.map((item) => item._id)[0],
          });

          if (setSelectedApplicantsList) {
            setSelectedApplicantsList([]);
          }
          handelCloseInterviewScheduleModal();
        },
        onError: (error) => {
          ErrorToast({ text: error?.response?.data?.message });
        },
      });
    };
    handelScheduleInterviewMutation();
  };

  const getTimesFromDate = () => {
    var result = [];
    let nowHour = 0;
    if (isValidDate(selectedTime)) {
      if (
        selectedTime.toISOString().split('T')[0] ===
        new Date().toISOString().split('T')[0]
      ) {
        nowHour = new Date().getHours();
      }
      for (var i = nowHour; i < 24; i++) {
        // let a =
        result.push(i + ':00');
        result.push(i + ':15');
        result.push(i + ':30');
        result.push(i + ':45');
        // Put loop counter into array with "00" next to it
      }
    }
    return result;
  };

  const handelsetSelectedDate = (val) => {
    const hour =
      (new Date().getHours() < 10 ? '0' : '') + new Date().getHours();
    const min =
      (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes();
    const value = `${dateFormatter(val, 'LL')} ${hour}:${min}`;

    setSelectedTime(new Date(value));
  };

  const getDateAndTimeValForBackend = () => {
    const value = `${dateFormatter(formDate, 'LL')} ${timePickerVal}`;

    const utcDate = dayjs
      .utc(value)
      .format()
      .replace('T', ' ')
      .replace('Z', '');
    return utcDate;
  };

  const emailTemplateData = useQuery(
    ['video-interview-email-template', urlForEmailTemplates],
    async () => {
      const res = await makeAPIRequest.get(urlForEmailTemplates);
      return res;
    },
    {
      enabled: urlForEmailTemplates !== null,
      onSuccess: (data) => {
        setShowEmailTemplate(true);
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
        setUrlForEmailTemplates(null);
      },
    }
  );
  const getQuryData = () => {
    const newData = {
      stage: getDisplaynameForJobStages(currentStage),
      from_date: getDateAndTimeValForBackend(),
      applicants: selectedApplicantsList.map((item) => item._id),
      reschedule: applicantsState.is_reschedule_video_interview_modal_visible,
      buffer_time: interviewDuration,
    };
    return newData;
  };

  const getEmailForSchedulling = () => {
    const scheduleDetails = getQuryData();
    const url = `${apiEndPoints.JOB_VIDEO_INTERVIEW_EMAIL_TEMPLATE}?stage=${
      scheduleDetails.stage
    }&from_date=${scheduleDetails.from_date}&applicant=${
      scheduleDetails.applicants
    }&reschedule=${scheduleDetails.reschedule ? 'True' : ''}&buffer_time=${
      scheduleDetails.buffer_time
    }`;
    setUrlForEmailTemplates(url);
  };

  const header = (
    <div className='relative'>
      <p className='text-xl text-center tracking-wide text-primary-rose mt-4'>
        {applicantsState.is_reschedule_video_interview_modal_visible
          ? 'Reschedule'
          : 'Schedule'}{' '}
        <span className='text-primary-rose'>
          {getModifiedStagename(currentStage, t)}
        </span>
      </p>
      {showEmailTemplate ? null : (
        <p className='text-base font-normal text-center mt-2'>
          {t(
            'jobsAndApplicants:schedule_a_time_for_the_applicant_to_take_the_interview'
          )}
        </p>
      )}

      <button
        className='absolute -top-8 right-0'
        onClick={handelCloseInterviewScheduleModal}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  const renderFooter = () => {
    return (
      <div className='mt-4 w-full flex justify-center'>
        <button
          onClick={handelApplicantsInterviewScheduling}
          className={`${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
        >
          <PrimaryButton isLoading={scheduleInterverMutation.isLoading}>
            {t('jobsAndApplicants:send')}
          </PrimaryButton>
        </button>
        <button
          onClick={handelCloseInterviewScheduleModal}
          className={`${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
        >
          <BaseButton>{t('jobsAndApplicants:cancel')}</BaseButton>
        </button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={
          applicantsState.is_video_interview_modal_visible ||
          applicantsState.is_reschedule_video_interview_modal_visible
        }
        onHide={handelCloseInterviewScheduleModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        footer={showEmailTemplate ? renderFooter() : null}
        className={`w-10/12 md:w-9/12 xl:w-1/2 mx-auto rounded-xl modal-with-footer ${genarateClassName()} `}
      >
        {showEmailTemplate ? (
          <div
            dangerouslySetInnerHTML={getSanitizeMarkupData({
              markup: emailTemplateData?.data?.data?.data,
              renderHTML: true,
            })}
          ></div>
        ) : (
          <>
            <div className='relative'>
              <div className='mt-6 flex justify-between w-11/12 lg:w-11/12 mx-auto'>
                <div
                  className={`mt-4 relative w-1/3 ${
                    currentLanguageCode === 'ar'
                      ? 'ml-6 lg:ml-0'
                      : 'mr-6 lg:mr-0'
                  }`}
                >
                  <label htmlFor='form-date' className='block mb-1 text-sm'>
                    {t('common:date')}
                  </label>

                  <div
                    style={{
                      borderColor: '#6600C9 !important',
                    }}
                  >
                    <DatePicker
                      value={
                        isValidDate(formDate) ? formDate?.toISOString() : ''
                      }
                      onChange={(value) => {
                        if (value) {
                          setFormDate(new Date(value));
                          handelsetSelectedDate(value);
                        } else {
                          setFormDate('');
                        }
                      }}
                      minDate={new Date().toISOString().split('T')[0]}
                      aria-label='Select date'
                      placeholder='Select date'
                      dateFormat='DD/MM/YYYY'
                    />
                  </div>
                </div>
                <div
                  className={`mt-4 relative  w-1/3 ${
                    currentLanguageCode === 'ar'
                      ? 'ml-6 lg:ml-0'
                      : 'mr-6 lg:mr-0'
                  }`}
                >
                  <label htmlFor='to-date' className='block mb-1 text-sm'>
                    {t('common:time')}
                  </label>
                  <TimePicker
                    times={getTimesFromDate()}
                    aria-label='Select time'
                    placeholder='Select time'
                    timeIsEditable={false}
                    value={timePickerVal}
                    onChange={(val) => {
                      if (val) {
                        setTimePickerVal(val);
                      } else {
                        setTimePickerVal('');
                      }
                    }}
                  />
                </div>
                <div
                  className={`mt-4 relative ${
                    currentLanguageCode === 'ar'
                      ? 'ml-6 lg:ml-0'
                      : 'mr-6 lg:mr-0'
                  }`}
                >
                  <label htmlFor='to-date' className='block mb-1 text-sm'>
                    {t('jobsAndApplicants:duration')}(mins)
                  </label>

                  <Textfield
                    name='interview_duration'
                    aria-label={t('common:interview_duration')}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setInterviewDuration(e.target.value)}
                    defaultValue={interviewDuration}
                  />
                </div>
              </div>
              <div className='flex justify-center mt-10 mb-6'>
                {formDate && timePickerVal && interviewDuration ? (
                  <button
                    className='w-28 mx-3'
                    // onClick={handelApplicantsInterviewScheduling}
                    onClick={getEmailForSchedulling}
                  >
                    <PrimaryButton isLoading={emailTemplateData.isLoading}>
                      <p className='text-base leading-5'>
                        {t('jobsAndApplicants:submit')}
                      </p>
                    </PrimaryButton>
                  </button>
                ) : (
                  <button className='bg-purple text-white mx-3 px-2 py-2  rounded-md cursor-not-allowed bg-opacity-50 w-28'>
                    <p className='text-base leading-5'>
                      {t('jobsAndApplicants:submit')}
                    </p>
                  </button>
                )}

                <button
                  className='w-28 mx-3'
                  onClick={handelCloseInterviewScheduleModal}
                >
                  <BaseButton>
                    <p className='text-base leading-5'>
                      {t('jobsAndApplicants:cancel')}
                    </p>
                  </BaseButton>
                </button>
              </div>
            </div>
            <div className=' w-full absolute left-0 bottom-6'>
              <SettingsIlls />
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ScheduleInterviewModal;
