import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import PrimaryButton from 'components/Button/primaryButton';

import ReloginModal from 'components/reloginModal';

import { AuthContext } from 'contexts/authContext.js';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';

const Login = () => {
  const { authDispatch } = useContext(AuthContext);
  const { t } = useTranslation(['authentication', 'common',"psychometricReport"]);
  const currentLanguageCode = cookies.get('i18next');

  const [showReloginModal, setShowReloginModal] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const [loginData, setLoginData] = useState({});

  const history = useHistory();
  const location = useLocation();

  const defaultValues = {
    username_or_email: '',
    password: '',
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const loginMutation = useMutation(async (data) => {
    if (location.pathname.includes('client')) {
      const clientData = {
        email: data.username_or_email,
        password: data.password,
      };
      const res = await makeAPIRequest.post(
        apiEndPoints.CLIENT_LOGIN,
        clientData
      );
      return res.data.data;
    } else {
      const res = await makeAPIRequest.post(apiEndPoints.LOGIN, data);
      return res.data;
    }
  });

  const handelReloginMudataion = () => {
    loginMutation.mutate(
      { ...loginData, re_login: true },
      {
        onSuccess: (data) => {
          setShowReloginModal(false);
          reset();
          authDispatch({
            type: 'LOGIN',
            payload: data,
          });
          history.replace(
            location?.state?.from?.pathname
              ? `${location?.state?.from?.pathname}${
                  location?.state?.from?.search
                    ? `/${location?.state?.from?.search}`
                    : ''
                }`
              : '/dashboard/overview'
          );
        },
        onError: (err) => {
          const errString = err?.response?.data?.non_field_errors;
          if (
            err?.response?.data?.non_field_errors[0] ===
            'User matching query does not exist.'
          ) {
            ErrorToast({ text: 'User does not exist' });
          } else {
            const errMsg = errString[0].split('string=');
            ErrorToast({
              text: errMsg[1].split(',')[0].replace(/'/g, ''),
            });
          }
        },
      }
    );
  };

  const otpMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.RESEND_OTP, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({
          text: data.message,
        });
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const onSubmit = (loginData) => {
    setLoginData(loginData);
    loginMutation.mutate(loginData, {
      onSuccess: (data) => {
        if (data.message === 'user have active session') {
          setShowReloginModal(true);
        } else {
          setShowReloginModal(false);
          reset();
          authDispatch({
            type: 'LOGIN',
            payload: data,
          });
          history.replace(
            location?.state?.from?.pathname
              ? `${location?.state?.from?.pathname}${
                  location?.state?.from?.search
                    ? `/${location?.state?.from?.search}`
                    : ''
                }`
              : '/dashboard/overview'
          );
        }
      },
      onError: (err) => {
        if (location.pathname.includes('client')) {
          ErrorToast({ text: err.response.data.message });
        } else {
          const errString = err?.response?.data?.non_field_errors;
          if (
            err?.response?.data?.non_field_errors[0] ===
            'User matching query does not exist.'
          ) {
            ErrorToast({ text: 'User does not exist' });
          } else if (
            err?.response?.data?.non_field_errors[0]?.includes(
              'User account is not verified.'
            )
          ) {
            otpMutation.mutate(
              {
                email: loginData.username_or_email,
                forgot_password: false,
                resend: false,
              },
              {
                onSuccess: () => {
                  history.push(
                    `/auth/verification/?email=${loginData.username_or_email}`
                  );
                },
              }
            );
          } else {
            const errMsg = errString[0].split('string=');
            ErrorToast({
              text: errMsg[1].split(',')[0].replace(/'/g, ''),
            });
          }
        }
      },
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  return (
    <>
      <div
        className={`auth-bg-overlay max-w-main-page mx-auto ${
          currentLanguageCode === 'ar' ? 'auth-bg-left' : 'auth-bg-right'
        }`}
      >
        <div className='grid grid-cols-4 pt-12 sm:pt-6 md:pt-10 px-4 relative h-86vh'>
          <div className='bigtab:col-span-2 lg:col-span-2 col-span-4 relative'>
            <div className='px-2 sm:px-6 absolute w-full top-12'>
              <div>
                <p className='text-xl text-center sm:text-2xl text-primary-rose '>
                  {t('authentication:sign_in_to_smart_hire')}
                </p>
              </div>

              <div className='my-4 px-2 sm:px-4 sm:w-8/12 lg:w-4/5  bigtab:w-full lg:max-w-auth-form mx-auto'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='w-full my-4'>
                    <span className='p-field'>
                      <label
                        htmlFor='username_or_email'
                        className={`text-Neutrals-gray ${
                          currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                        }`}
                      >
                        {t('authentication:email')}*
                      </label>
                      <Controller
                        name='username_or_email'
                        control={control}
                        rules={{
                          required: t('common:this_is_a_required_field'),
                          // pattern: {
                          //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          //   message: t('authentication:invalid_email_address'),
                          // },
                        }}
                        render={({ field }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            className='mt-1'
                            placeholder={t(
                              'psychometricReport:enter_working_email'
                            )}
                          />
                        )}
                      />
                    </span>
                    {getFormErrorMessage('username_or_email')}
                  </div>
                  <div className='w-full mb-3'>
                    <span className='p-field'>
                      <label
                        htmlFor='password'
                        className={`text-Neutrals-gray ${
                          currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                        }`}
                      >
                        {t('authentication:password')}*
                      </label>
                      <Controller
                        name='password'
                        control={control}
                        rules={{
                          required: t('common:this_is_a_required_field'),
                        }}
                        render={({ field }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            feedback={false}
                            className='mt-1'
                            placeholder={t(
                              'psychometricReport:enter_your_password'
                            )}
                          />
                        )}
                      />
                    </span>
                    {getFormErrorMessage('password')}
                  </div>

                  <div className='flex justify-between items-center'>
                    <div className='p-field-checkbox flex items-center'>
                      <Checkbox
                        inputId='binary'
                        checked={checkboxValue}
                        onChange={(e) => {
                          setCheckboxValue(e.checked);
                        }}
                      />
                      <label
                        htmlFor='binary'
                        className={`text-sm ${
                          currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                        }`}
                      >
                        {t('authentication:remember_me')}
                      </label>
                    </div>
                    <p className='text-sm text-purple cursor-pointer hover:underline'>
                      <Link
                        to={
                          location.pathname.includes('client')
                            ? '/auth/client/forgotPassword'
                            : '/auth/forgotPassword'
                        }
                      >
                        {t('authentication:forgot_password')}?
                      </Link>
                    </p>
                  </div>
                  <div className='w-full flex justify-center items-center mt-4'>
                    <button className='w-2/5 md:w-2/6 xl:w-1/4 text-center cursor-pointer my-4'>
                      <PrimaryButton isLoading={loginMutation.isLoading}>
                        <p className='text-sm leading-5 w-full h-full'>
                          {t('authentication:log_in')}
                        </p>
                      </PrimaryButton>
                    </button>
                  </div>

                  {location.pathname.includes('client') ? null : (
                    <p className='text-center text-sm sm:text-base'>
                      {t('authentication:new_to_smart_hire')}{' '}
                      <Link to='/auth/register'>
                        <span className='text-purple cursor-pointer hover:underline'>
                          {t('authentication:sign_up')}
                        </span>
                      </Link>
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReloginModal
        isReloginModal={showReloginModal}
        setIsReloginModal={setShowReloginModal}
        mutationLogin={handelReloginMudataion}
        loading={loginMutation.isLoading}
      />
    </>
  );
};

export default Login;
