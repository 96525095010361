/**
 * This file contains all the state and reducer logic for handeling applicants
 * This is responsible from a applicant after applying to hire (mostly modals)
 * IT works both for HR and Recruiter version of the website
 */

import React, { createContext, useEffect, useReducer } from 'react';
export const ApplicantsContext = createContext();

let initialState = {
  is_schedule_assessment_modal_visible: false,
  is_applicants_moved_next_stage_modal_visible: false,
  is_scheduling_same_stage: false,
  is_re_schedule_assessment_modal_visible: false,
  is_reject_applicant_modal_visible: false,
  is_confirm_move_applicant_modal_visble: false,
  is_confirm_move_applicant_to_client_modal_visble: false,
  is_rescheduled_success_modal: false,
  is_video_interview_modal_visible: false,
  is_reschedule_video_interview_modal_visible: false,
  is_interview_feedback_modal_visible: false,
  applicant_status: '',
  is_test_credentidal_modal_visible: false,
  is_reports_modal_visible: false,
  is_psy_report_modal_visible: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SETSCHEDULEASSESSMENTMODALVISIBLESTATE':
      return {
        ...state,
        is_schedule_assessment_modal_visible: action.payload,
        is_re_schedule_assessment_modal_visible: false,
      };
    case 'SETSCHEDULEVIDEOINTERVIEWMODALVISIBLESTATE':
      return {
        ...state,
        is_video_interview_modal_visible: action.payload,
        is_reschedule_video_interview_modal_visible: false,
      };
    case 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE':
      return {
        ...state,
        is_applicants_moved_next_stage_modal_visible: action.payload,
      };

    case 'SETSCHEDULINGSAMESTAGEMODALVISIBLESTATE':
      return {
        ...state,
        is_scheduling_same_stage: action.payload,
      };

    case 'SETRESCHEDULEASSESSMENTMODALVISIBLESTATE':
      return {
        ...state,
        is_schedule_assessment_modal_visible: false,
        is_re_schedule_assessment_modal_visible: action.payload,
      };

    case 'SETRESCHEDULEVIDEOINTERVIEWMODALVISIBLESTATE':
      return {
        ...state,
        is_video_interview_modal_visible: false,
        is_reschedule_video_interview_modal_visible: action.payload,
      };

    case 'SETREJECTAPPLICANTMODALVISIBLESTATE':
      return {
        ...state,
        is_reject_applicant_modal_visible: action.payload,
      };

    case 'SETCONFIRMMOVEAPPLICANTMODALVISIBLESTATE':
      return {
        ...state,
        is_confirm_move_applicant_modal_visble: action.payload,
      };
    case 'SETCONFIRMMOVEAPPLICANTTOCLIENTMODALVISIBLESTATE':
      return {
        ...state,
        is_confirm_move_applicant_to_client_modal_visble: action.payload,
      };

    case 'SETRESCHEDULEDSUCCESSMODALVISIBLESTATE':
      return {
        ...state,
        is_rescheduled_success_modal: action.payload,
      };

    case 'SETAPPLICANTSTATUS':
      return {
        ...state,
        applicant_status: action.payload,
      };

    case 'SETINTERVIEWFEEDBACKMODALVISIBLESTATE':
      return {
        ...state,
        is_interview_feedback_modal_visible: action.payload,
      };
    case 'SET_TEST_CREDENTIAL_MODAL_VISIBLE_STATE':
      return {
        ...state,
        is_test_credentidal_modal_visible: action.payload,
      };
    case 'SET_REPORTS_MODAL_VISIBLE':
      return {
        ...state,
        is_reports_modal_visible: action.payload,
      };
    case 'SET_PSY_REPORTS_MODAL_VISIBLE':
      return {
        ...state,
        is_psy_report_modal_visible: action.payload,
      };

    case 'RESETAPPLICANTSSTATE':
      return {
        is_schedule_assessment_modal_visible: false,
        is_applicants_moved_next_stage_modal_visible: false,
        is_scheduling_same_stage: false,
        is_re_schedule_assessment_modal_visible: false,
        is_reject_applicant_modal_visible: false,
        is_confirm_move_applicant_modal_visble: false,
        is_test_credentidal_modal_visible: false,
        is_reports_modal_visible: false,
        is_psy_report_modal_visible: false,
        applicant_status: '',
      };
    default:
      return state;
  }
};

const ApplicantsProvider = ({ children }) => {
  const [applicantsState, applicantsDispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <ApplicantsContext.Provider
      value={{
        applicantsState,
        applicantsDispatch,
      }}
    >
      {children}
    </ApplicantsContext.Provider>
  );
};

export default ApplicantsProvider;
