import React, { useState, useEffect, useContext } from 'react';
import { useWindowSize } from 'react-use';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Dialog } from 'primereact/dialog';
import { DateTimePicker } from '@atlaskit/datetime-picker';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import getJobStageNameForBackendQuery from 'helper/getJobStageNameForBackendQuery';
import getDisplaynameForJobStages from 'helper/getDisplaynameForJobStages';
import getModifiedStagename from 'helper/getModifiedStagename';

import { ApplicantsContext } from 'contexts/applicantsContext';

import queryKeys from 'reactQuery/constant';

import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

import dateFormatter from 'helper/dateFormatter';

import { ErrorToast } from 'services/toasterService';
import getSanitizeMarkupData from 'helper/getSanitizeMarkupData';
import { ReactComponent as SettingsIlls } from 'assets/images/Interviews/settings_ills.svg';

const utc = require('dayjs/plugin/utc');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const ScheduleAssessmentModal = ({
  prevStage,
  currentStage,
  nextStage,
  selectedApplicantsList,
  setSelectedApplicantsList,
  jobId,
  fromApplicantsDetailsPage,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['jobsAndApplicants', 'common']);
  const currentLanguageCode = cookies.get('i18next');

  const history = useHistory();
  const location = useLocation();

  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const [formDate, setFormDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [urlForEmailTemplates, setUrlForEmailTemplates] = useState(null);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const { height } = useWindowSize();

  const pathNameArray = location.pathname.split('/');

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const genarateClassName = () => {
    if (height < 650) {
      return 'min-h-80vh';
    } else if (height > 900) {
      return 'min-h-50vh';
    } else {
      return 'min-h-70vh';
    }
  };

  const handelCloseAssessmentScheduleModal = () => {
    setToDate(new Date());
    setFormDate(new Date());
    setSelectedDate(new Date());
    setUrlForEmailTemplates(null);
    setShowEmailTemplate(false);
    applicantsDispatch({
      type: 'SETSCHEDULEASSESSMENTMODALVISIBLESTATE',
      payload: false,
    });
    applicantsDispatch({
      type: 'SETSCHEDULINGSAMESTAGEMODALVISIBLESTATE',
      payload: false,
    });
    applicantsDispatch({
      type: 'SETRESCHEDULEASSESSMENTMODALVISIBLESTATE',
      payload: false,
    });
  };

  const handelOpenScheduleSuccessModal = () => {
    applicantsDispatch({
      type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
      payload: true,
    });
  };

  const checkIfEnabalingQueryForBufferDays = () => {
    let enable = false;
    if (
      nextStage?.includes('Technical') ||
      nextStage?.includes('HR') ||
      nextStage?.includes('Panel')
    ) {
      enable = false;
    }
    if (
      applicantsState.is_schedule_assessment_modal_visible ||
      applicantsState.is_re_schedule_assessment_modal_visible
    ) {
      enable = true;
    }

    return enable;
  };

  const jobStageCompletedDays = useQuery(
    [queryKeys.jobStageCompletedDays, jobId, nextStage, currentStage],
    async () => {
      let url = `${
        apiEndPoints.JOB_STAGE_COMPLETED_DAYS
      }?job_id=${jobId}&stage=${
        applicantsState.is_re_schedule_assessment_modal_visible
          ? getJobStageNameForBackendQuery(currentStage)
          : nextStage
      }`;
      const { data } = await makeAPIRequest.get(url);
      return data.data;
    },
    {
      enabled: checkIfEnabalingQueryForBufferDays(),
    }
  );

  const moveCandidatetonextStage = useMutation(async (data) => {
    const res = await makeAPIRequest.put(apiEndPoints.JOB_APPLICANTS, data);
    return res.data;
  });

  const scheduleInterverMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.APPLICANTS_CHEDULE_STAGE,
      data
    );
    return res.data;
  });

  const handelApplicantsInterviewScheduling = () => {
    let queryDataForMoveCandidate;
    let queryDataForSchedulingInterview;

    if (applicantsState.is_re_schedule_assessment_modal_visible) {
      queryDataForMoveCandidate = {
        stages: {
          [prevStage]: false,
          [getJobStageNameForBackendQuery(currentStage)]: true,
        },
        applicants: selectedApplicantsList.map((item) => item._id),
      };
    } else {
      queryDataForMoveCandidate = {
        stages: {
          [getJobStageNameForBackendQuery(currentStage)]: false,
          [nextStage]: true,
        },
        applicants: selectedApplicantsList.map((item) => item._id),
      };
    }

    if (applicantsState.is_re_schedule_assessment_modal_visible) {
      queryDataForSchedulingInterview = {
        stage: getDisplaynameForJobStages(currentStage),
        from_date: dayjs
          .utc(roundTimeQuarterHour(formDate))
          .format()
          .replace('T', ' ')
          .replace('Z', ''),
        applicants: selectedApplicantsList.map((item) => item._id),
      };
    } else {
      queryDataForSchedulingInterview = {
        stage: getDisplaynameForJobStages(nextStage),
        from_date: dayjs
          .utc(roundTimeQuarterHour(formDate))
          .format()
          .replace('T', ' ')
          .replace('Z', ''),
        applicants: selectedApplicantsList.map((item) => item._id),
      };
    }

    moveCandidatetonextStage.mutate(queryDataForMoveCandidate, {
      onSuccess: () => {
        handelScheduleStageMutation();
      },
    });

    const handelScheduleStageMutation = () => {
      scheduleInterverMutation.mutate(queryDataForSchedulingInterview, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.allApplicants &&
              query.queryKey[2] === nextStage,
          });
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === queryKeys.allApplicants &&
              query.queryKey[2] ===
                getJobStageNameForBackendQuery(currentStage),
          });
          queryClient.invalidateQueries(queryKeys.specificApplicant);
          if (setSelectedApplicantsList) {
            setSelectedApplicantsList([]);
          }
          queryClient.invalidateQueries(queryKeys.allJobs);
          handelCloseAssessmentScheduleModal();

          if (applicantsState.is_re_schedule_assessment_modal_visible) {
            applicantsDispatch({
              type: 'SETRESCHEDULEDSUCCESSMODALVISIBLESTATE',
              payload: true,
            });
          } else {
            handelOpenScheduleSuccessModal();
            setTimeout(() => {
              applicantsDispatch({
                type: 'SETMOVEDNEXTSTAGEMODALVISIBLESTATE',
                payload: false,
              });

              if (fromApplicantsDetailsPage) {
                if (
                  nextStage === 'Skill_Assessment' ||
                  nextStage === 'Holistic_Assessment'
                ) {
                  history.push(
                    `/recruiter/applicants/${jobId}/${nextStage}/${pathNameArray[5]}/${pathNameArray[6]}?tab=T/F`
                  );
                } else {
                  history.push(
                    `/recruiter/applicants/${jobId}/${nextStage}/${pathNameArray[5]}/${pathNameArray[6]}`
                  );
                }
              }
            }, 2000);
          }
        },
        onError: (error) => {
          moveCandidatetonextStage.mutate({
            stages: {
              [getJobStageNameForBackendQuery(currentStage)]: true,
              [nextStage]: false,
            },
            applicants: selectedApplicantsList.map((item) => item._id),
          });
          ErrorToast({ text: error?.response?.data?.message });
        },
      });
    };
  };

  // Get current hour of the day
  const getTimesFromDate = () => {
    var result = [];
    let nowHour = 0;
    let nowMinute = new Date().getMinutes();
    if (isValidDate(selectedDate)) {
      if (
        selectedDate.toISOString().split('T')[0] ===
        new Date().toISOString().split('T')[0]
      ) {
        nowHour = new Date().getHours();
      }

      for (var i = nowHour; i < 24; i++) {
        // let a =
        if (i === nowHour) {
          if (nowMinute < 15) {
            result.push(i + ':15');
            result.push(i + ':30');
            result.push(i + ':45');
          }
          if (nowMinute > 15 && nowMinute < 30) {
            result.push(i + ':30');
            result.push(i + ':45');
          }
          if (nowMinute > 30 && nowMinute < 45) {
            result.push(i + ':45');
          }
        } else {
          result.push(i + ':00');
          result.push(i + ':15');
          result.push(i + ':30');
          result.push(i + ':45');
        }
        // Put loop counter into array with "00" next to it
      }
    }

    return result;
  };

  function roundTimeQuarterHour(time) {
    var timeToReturn = new Date(time);

    timeToReturn.setMilliseconds(
      Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn;
  }

  const handelsetSelectedDate = (val) => {
    const hour =
      (new Date().getHours() < 10 ? '0' : '') + new Date().getHours();
    const min =
      (new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes();
    const value = `${dateFormatter(val, 'LL')} ${hour}:${min}`;

    setFormDate(new Date(value));

    setSelectedDate(new Date(value));
  };

  useEffect(() => {
    if (formDate !== '' && !jobStageCompletedDays.isLoading) {
      setToDate(
        new Date(
          formDate?.getTime() +
            jobStageCompletedDays?.data * 24 * 60 * 60 * 1000
        )
      );
    }
  }, [formDate, jobStageCompletedDays?.data, jobStageCompletedDays.isLoading]);

  const getQuryData = () => {
    const newData = {
      stage: applicantsState.is_re_schedule_assessment_modal_visible
        ? getDisplaynameForJobStages(currentStage)
        : getDisplaynameForJobStages(nextStage),
      from_date: dayjs
        .utc(roundTimeQuarterHour(formDate))
        .format()
        .replace('T', ' ')
        .replace('Z', ''),
      applicants: selectedApplicantsList.map((item) => item._id),
      reschedule: applicantsState.is_re_schedule_assessment_modal_visible,
    };
    return newData;
  };

  const emailTemplateData = useQuery(
    ['show-email-template', urlForEmailTemplates],
    async () => {
      const res = await makeAPIRequest.get(urlForEmailTemplates);
      return res;
    },
    {
      enabled: urlForEmailTemplates !== null,
      onSuccess: (data) => {
        setShowEmailTemplate(true);
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
        setUrlForEmailTemplates(null);
      },
    }
  );

  const getEmailForReschedulling = () => {
    const scheduleDetails = getQuryData();
    const url = `${apiEndPoints.JOB_SHOW_EMAIL_TEMPLATE}?stage=${
      scheduleDetails.stage
    }&from_date=${scheduleDetails.from_date}&applicant=${
      scheduleDetails.applicants
    }&reschedule=${scheduleDetails.reschedule ? 'True' : ''}`;
    setUrlForEmailTemplates(url);
  };

  const header = (
    <div className='relative'>
      <p className='text-xl text-center tracking-wide text-primary-rose mt-4'>
        {applicantsState.is_re_schedule_assessment_modal_visible
          ? 'Reschedule'
          : 'Schedule'}{' '}
        <span className='text-primary-rose'>
          {' '}
          {applicantsState.is_scheduling_same_stage ||
          applicantsState.is_re_schedule_assessment_modal_visible
            ? `${getModifiedStagename(currentStage, t)}`
            : getModifiedStagename(nextStage, t)}
        </span>
      </p>
      {showEmailTemplate ? null : (
        <p className='text-base font-normal text-center mt-2'>
          {t('jobsAndApplicants:schedule_a_timeline')}
        </p>
      )}

      <button
        className='absolute -top-8 right-0'
        onClick={handelCloseAssessmentScheduleModal}
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );

  const renderFooter = () => {
    return (
      <div className='mt-4 w-full flex justify-center'>
        <button onClick={handelApplicantsInterviewScheduling} className='mx-2'>
          <PrimaryButton
            isLoading={
              moveCandidatetonextStage.isLoading ||
              scheduleInterverMutation.isLoading
            }
          >
            {t('jobsAndApplicants:send')}
          </PrimaryButton>
        </button>
        <button onClick={handelCloseAssessmentScheduleModal} className='mx-2'>
          <BaseButton> {t('jobsAndApplicants:cancel')}</BaseButton>
        </button>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={
          applicantsState.is_schedule_assessment_modal_visible ||
          applicantsState.is_re_schedule_assessment_modal_visible
        }
        onHide={handelCloseAssessmentScheduleModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        footer={showEmailTemplate ? renderFooter() : null}
        className={`w-9/12 xl:w-1/2 mx-auto rounded-xl modal-with-footer ${genarateClassName()} `}
      >
        {showEmailTemplate ? (
          <div
            dangerouslySetInnerHTML={getSanitizeMarkupData({
              markup: emailTemplateData?.data?.data?.data,
              renderHTML: true,
            })}
          ></div>
        ) : (
          <>
            <div className='relative'>
              <div className='mt-6 flex justify-around w-11/12 lg:w-10/12 mx-auto'>
                <div
                  className={`mt-4 relative w-1/2 lg:w-5/12 ${
                    currentLanguageCode === 'ar'
                      ? 'ml-6 lg:ml-0'
                      : 'mr-6 lg:mr-0'
                  }`}
                >
                  <label htmlFor='form-date' className='block mb-1 text-sm'>
                    {t('jobsAndApplicants:from')}
                  </label>

                  <div
                    style={{
                      borderColor: '#6600C9 !important',
                    }}
                  >
                    <DateTimePicker
                      value={
                        isValidDate(formDate)
                          ? roundTimeQuarterHour(formDate)?.toISOString()
                          : ''
                      }
                      onChange={(value) => {
                        if (value) {
                          setFormDate(new Date(value));
                        } else {
                          setFormDate('');
                        }
                      }}
                      datePickerProps={{
                        minDate: new Date().toISOString().split('T')[0],
                        'aria-label': 'Select date',
                        placeholder: 'Select date',
                        onChange: handelsetSelectedDate,
                        dateFormat: 'DD/MM/YYYY',
                      }}
                      timePickerProps={{
                        times: getTimesFromDate(),
                        'aria-label': 'Select time',
                        placeholder: 'Select time',
                      }}
                      timeIsEditable={false}
                    />
                  </div>
                </div>
                <div
                  className={`mt-4 relative w-1/2 lg:w-5/12 ${
                    currentLanguageCode === 'ar'
                      ? 'ml-6 lg:ml-0'
                      : 'mr-6 lg:mr-0'
                  }`}
                >
                  <label htmlFor='to-date' className='block mb-1 text-sm'>
                    {t('jobsAndApplicants:to')}
                  </label>

                  {formDate === '' ? (
                    <DateTimePicker
                      isDisabled
                      datePickerProps={{
                        'aria-label': 'Select date',
                        placeholder: 'Select date',
                        dateFormat: 'DD/MM/YYYY',
                      }}
                      timePickerProps={{
                        'aria-label': 'Select time',
                        placeholder: 'Select time',
                      }}
                    />
                  ) : (
                    <DateTimePicker
                      value={
                        isNaN(toDate?.getTime())
                          ? ''
                          : roundTimeQuarterHour(toDate)?.toISOString()
                      }
                      isDisabled
                      datePickerProps={{
                        'aria-label': 'Select date',
                        placeholder: 'Select date',
                        dateFormat: 'DD/MM/YYYY',
                      }}
                      timePickerProps={{
                        'aria-label': 'Select time',
                        placeholder: 'Select time',
                      }}
                    />
                  )}
                </div>
              </div>
              <div className='flex justify-center mt-10 mb-6'>
                {formDate ? (
                  <button
                    className='w-28 z-50 mx-3'
                    onClick={
                      selectedApplicantsList.length <= 1
                        ? getEmailForReschedulling
                        : handelApplicantsInterviewScheduling
                    }
                  >
                    <PrimaryButton
                      isLoading={
                        emailTemplateData.isLoading ||
                        moveCandidatetonextStage.isLoading ||
                        scheduleInterverMutation.isLoading
                      }
                    >
                      <p className='text-base leading-5'>
                        {' '}
                        {t('jobsAndApplicants:submit')}
                      </p>
                    </PrimaryButton>
                  </button>
                ) : (
                  <button className='bg-primary-rose text-white  px-2 py-2  rounded-md cursor-not-allowed bg-opacity-50 w-28 mx-3'>
                    <p className='text-base leading-5'>
                      {' '}
                      {t('jobsAndApplicants:submit')}
                    </p>
                  </button>
                )}

                <button
                  className='w-28 z-50 mx-3'
                  onClick={handelCloseAssessmentScheduleModal}
                >
                  <BaseButton>
                    <p className='text-base leading-5'>
                      {' '}
                      {t('jobsAndApplicants:cancel')}
                    </p>
                  </BaseButton>
                </button>
              </div>
            </div>
            <div className=' w-full absolute left-0 bottom-6'>
              <SettingsIlls />
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ScheduleAssessmentModal;
