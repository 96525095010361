import React, { createContext, useEffect, useReducer } from 'react';
export const JobAddFormContext = createContext();

let initialState = {
  clientDetails: {
    name: 'Select',
  },
  post_job_on_google: false,
  jobtitle: '',
  location: '',
  isFirstStepCompleted: false,
  isSecondStepCompleted: false,
  employment_type: {
    name: 'Select',
  },
  no_of_openings: '',
  experience: {
    name: 'Select',
  },
  hide_salary_information: false,
  salary_currency: 'USD',
  salary_per: {
    name: 'Select',
  },
  salary_max: '',
  salary_min: '',
  jobdescription: '',
  key_skills: [],
  job_stages: '',
  job_stages_detailed_list: [],
  CV_screeninig_minimum_score: 0,
  CV_screeninig_assignees: [],
  Prehire_assessment_assignes: [],
  Prehire_assessment_completed_days: '',
  Skill_Assessment_total_question_count: '',
  Skill_Assessment_total_time: '',
  Skill_Assessment_question_count_list: [],
  Skill_Assessment_assignees: [],
  Skill_Assessment_Assessment_selection: 'Auto',
  Skill_Assessment_completed_within: '',
  Skill_Assessment_minimum_reject_score: 0,
  Skill_Assessment_Assessments_banks_list: [],
  Assessment_questions_id_list: [],
  Personality_Assessment_assignees: [],
  Personality_Assessment_completed_days: '',
  Psychometric_Assessment_total_question_count: '',
  Psychometric_Assessment_total_time: '',
  Psychometric_Assessment_assignees: [],
  Psychometric_Assessment_selection: 'Auto',
  Psychometric_Assessment_completed_days: '',
  Psychometric_Assessment_Assessments_banks_list: [],
  Psychometric_Assessment_question_count_list: [],
  Psychometric_Assessment_questions_id_list: [],
  Technical_interview_assignees: [],
  Technical_interview_interview_type: 'online',
  HR_interview_assignees: [],
  HR_interview_interview_type: 'online',
  Panel_interview_assignees: [],
  Panel_interview_interview_type: 'online',
  auto_schedule: false,
  auto_schedule_buffer: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FIRSTSTEP':
      return {
        ...state,
        jobtitle: action.payload.jobtitle,
        location: action.payload.location,
        employment_type: action.payload.employment_type,
        no_of_openings: action.payload.no_of_openings,
        experience: action.payload.experience,
        salary_currency: action.payload.salary_currency,
        salary_per: action.payload.salary_per,
        salary_max: action.payload.salary_max,
        salary_min: action.payload.salary_min,
        jobdescription: action.payload.jobdescription,
        key_skills: action.payload.key_skills,
        job_stages: action.payload.job_stages,
        job_stages_detailed_list: action.payload.job_stages_detailed_list,
        clientDetails: action.payload.clientDetails,
      };

    /**
     * This reducer is for setting job state
     * when it's in edit mode
     * it will set all the job second step data at once
     */
    case 'JOBSECONDSTEP':
      return {
        ...state,
        CV_screeninig_minimum_score: action.payload.CV_screeninig_minimum_score,
        CV_screeninig_assignees: action.payload.CV_screeninig_assignees,
        Technical_interview_assignees:
          action.payload.Technical_interview_assignees,
        Technical_interview_interview_type:
          action.payload.Technical_interview_interview_type,
        HR_interview_assignees: action.payload.HR_interview_assignees,
        HR_interview_interview_type: action.payload.HR_interview_interview_type,
        Panel_interview_assignees: action.payload.Panel_interview_assignees,
        Panel_interview_interview_type:
          action.payload.Panel_interview_interview_type,
        Personality_Assessment_assignees:
          action.payload.Personality_Assessment_assignees,
        Personality_Assessment_completed_days:
          action.payload.Personality_Assessment_completed_days,
        Psychometric_Assessment_assignees:
          action.payload.Psychometric_Assessment_assignees,
        Psychometric_Assessment_selection:
          action.payload.Psychometric_Assessment_selection,
        Psychometric_Assessment_completed_days:
          action.payload.Psychometric_Assessment_completed_days,
        Psychometric_Assessment_Assessments_banks_list:
          action.payload.Psychometric_Assessment_Assessments_banks_list,
        Psychometric_Assessment_question_count_list:
          action.payload.Psychometric_Assessment_question_count_list,
        Psychometric_Assessment_questions_id_list:
          action.payload.Psychometric_Assessment_questions_id_list,
        Skill_Assessment_question_count_list:
          action.payload.Skill_Assessment_question_count_list || [],
        Skill_Assessment_assignees: action.payload.Skill_Assessment_assignees,
        Skill_Assessment_Assessment_selection:
          action.payload.Skill_Assessment_Assessment_selection,
        Skill_Assessment_completed_within:
          action.payload.Skill_Assessment_completed_within,
        Skill_Assessment_Assessments_banks_list:
          action.payload.Skill_Assessment_Assessments_banks_list,
        Assessment_questions_id_list:
          action.payload.Assessment_questions_id_list || [],
        Prehire_assessment_assignes: action.payload.Prehire_assessment_assignes,
        Prehire_assessment_completed_days:
          action.payload.Prehire_assessment_completed_days,
        auto_schedule: action.payload.auto_schedule,
        auto_schedule_buffer: action.payload.auto_schedule_buffer,
      };

    case 'RESETJOBSTATE':
      return {
        clientDetails: {
          name: 'Select',
        },
        jobtitle: '',
        post_job_on_google: false,
        location: '',
        isFirstStepCompleted: false,
        isSecondStepCompleted: false,
        employment_type: {
          name: 'Select',
        },
        no_of_openings: '',
        experience: {
          name: 'Select',
        },
        salary_currency: 'USD',
        salary_per: {
          name: 'Select',
        },
        salary_max: '',
        salary_min: '',
        jobdescription: '',
        key_skills: [],
        job_stages: '',
        job_stages_detailed_list: [],
        hide_salary_information: false,
        CV_screeninig_minimum_score: 0,
        CV_screeninig_assignees: [],
        Skill_Assessment_total_question_count: '',
        Skill_Assessment_total_time: '',
        Skill_Assessment_question_count_list: [],
        Skill_Assessment_assignees: [],
        Skill_Assessment_Assessment_selection: 'Auto',
        Skill_Assessment_completed_within: '',
        Skill_Assessment_minimum_reject_score: 0,
        Skill_Assessment_Assessments_banks_list: [],
        Assessment_questions_id_list: [],
        Personality_Assessment_assignees: [],
        Personality_Assessment_completed_days: '',
        Psychometric_Assessment_total_question_count: '',
        Psychometric_Assessment_total_time: '',
        Psychometric_Assessment_assignees: [],
        Psychometric_Assessment_selection: 'Auto',
        Psychometric_Assessment_completed_days: '',
        Psychometric_Assessment_Assessments_banks_list: [],
        Psychometric_Assessment_question_count_list: [],
        Psychometric_Assessment_questions_id_list: [],
        Technical_interview_assignees: [],
        Technical_interview_interview_type: 'online',
        HR_interview_assignees: [],
        HR_interview_interview_type: 'online',
        Panel_interview_assignees: [],
        Panel_interview_interview_type: 'online',
        Prehire_assessment_assignes: [],
        Prehire_assessment_completed_days: '',
        auto_schedule: false,
        auto_schedule_buffer: 0,
      };

    case 'UPDATE_AUTO_SCHEDULE':
      return {
        ...state,
        auto_schedule: action.payload,
      };
    case 'UPDATE_AUTO_SCHEDULE_DAYS':
      return {
        ...state,
        auto_schedule_buffer: action.payload,
      };

    case 'JOBTITLE':
      return {
        ...state,
        jobtitle: action.payload,
      };
    case 'POSTJOBONGOOGLE':
      return {
        ...state,
        post_job_on_google: action.payload,
      };

    case 'JOBHIDESALARY':
      return {
        ...state,
        hide_salary_information: action.payload,
      };

    case 'JOBLOCATION':
      return {
        ...state,
        location: action.payload,
      };

    case 'JOBOPPENINGS':
      return {
        ...state,
        no_of_openings: action.payload,
      };

    case 'SALARYMIN':
      return {
        ...state,
        salary_min: action.payload,
      };

    case 'SALARYMAX':
      return {
        ...state,
        salary_max: action.payload,
      };

    case 'JOBDESC':
      return {
        ...state,
        jobdescription: action.payload,
      };

    case 'FIRSTSTEPCOMPLETED':
      return {
        ...state,
        isFirstStepCompleted: action.payload,
      };
    case 'SECONDSTEPCOMPLETED':
      return {
        ...state,
        isSecondStepCompleted: action.payload,
      };

    case 'ADDCVSCREENINGMINIMUMSCORE':
      return {
        ...state,
        CV_screeninig_minimum_score: action.payload,
      };

    case 'ADDCVSCREENINGASSIGNE':
      return {
        ...state,
        CV_screeninig_assignees: action.payload,
      };

    /**
     * All the reducers related to Prehire assesment
     */

    case 'ADDPREHIREASSESSMENTASSIGNE':
      return {
        ...state,
        Prehire_assessment_assignes: action.payload,
      };
    case 'ADDPREHIREASSESSMENTCOMPLETEDAYS':
      return {
        ...state,
        Prehire_assessment_completed_days: action.payload,
      };

    /**
     * All the reducers related to Skill assesment
     */

    case 'ADDSKILLASSESMENTTIMEANDQUESTIONCOUNT':
      return {
        ...state,
        Skill_Assessment_total_question_count: action.payload.total_question,
        Skill_Assessment_total_time: action.payload.total_time,
      };

    case 'ADDSKILLASSESMENTFOLDER':
      return {
        ...state,
        Skill_Assessment_Assessments_banks_list: action.payload,
      };
    case 'UPDATESKILLASSESMENTQUESTIONCOUNT':
      return {
        ...state,
        Skill_Assessment_question_count_list: action.payload,
      };

    case 'ADDSKILLASSESMENTMANUALQUESTIONS':
      return {
        ...state,
        Assessment_questions_id_list: action.payload,
      };

    case 'ADDSKILLASSESMENTASSIGNE':
      return {
        ...state,
        Skill_Assessment_assignees: action.payload,
      };
    case 'ADDSKILLASSESMENTCOMPLETEDTIME':
      return {
        ...state,
        Skill_Assessment_completed_within: action.payload,
      };
    case 'ADDSKILLASSESMENTREJECTSCORE':
      return {
        ...state,
        Skill_Assessment_minimum_reject_score: 0,
      };

    case 'ADDSKILLASSESMENTSELECTIONTYPE':
      return {
        ...state,
        Skill_Assessment_Assessment_selection: action.payload,
      };

    /**
     * All the reducers related to personality Profiling interview
     */

    case 'ADDPERSONALITYASSIGNE':
      return {
        ...state,
        Personality_Assessment_assignees: action.payload,
      };

    case 'ADDPERSONALITYCOMPLETEDDAYS':
      return {
        ...state,
        Personality_Assessment_completed_days: action.payload,
      };

    /**
     * All the reducers related to psychometric interview
     */

    case 'ADDPSYCHOMETRICASSESMENTTIMEANDQUESTIONCOUNT':
      return {
        ...state,
        Psychometric_Assessment_total_question_count:
          action.payload.total_question,
        Psychometric_Assessment_total_time: action.payload.total_time,
      };

    case 'ADDPSYCHOMETRICASSIGNE':
      return {
        ...state,
        Psychometric_Assessment_assignees: action.payload,
      };

    case 'ADDPSYCHOMETRICCOMPLETEDDAYS':
      return {
        ...state,
        Psychometric_Assessment_completed_days: action.payload,
      };

    case 'ADDPSYCHOMETRICQUESTIONTYPE':
      return {
        ...state,
        Psychometric_Assessment_selection: action.payload,
      };

    case 'ADDPSYCHOMETRICFOLDER':
      return {
        ...state,
        Psychometric_Assessment_Assessments_banks_list: action.payload,
      };
    case 'UPDATEPSYCHOMETRICQUESTIONCOUNT':
      return {
        ...state,
        Psychometric_Assessment_question_count_list: action.payload,
      };

    case 'ADDPSYCHOMETRICMANUALQUESTIONS':
      return {
        ...state,
        Psychometric_Assessment_questions_id_list: action.payload,
      };

    /**
     * All the reducers related to Technical interview
     */

    case 'ADDTECHNICALASSIGNE':
      return {
        ...state,
        Technical_interview_assignees: action.payload,
      };

    case 'ADDTECHNICALINTERVIEWTYPE':
      return {
        ...state,
        Technical_interview_interview_type: action.payload,
      };

    /**
     * All the reducers related to HR interview
     */

    case 'ADDHRASSIGNE':
      return {
        ...state,
        HR_interview_assignees: action.payload,
      };

    case 'ADDHRINTERVIEWTYPE':
      return {
        ...state,
        HR_interview_interview_type: action.payload,
      };

    /**
     * All the reducers regarding Panel Interview
     */

    case 'ADDPANELASSIGNE':
      return {
        ...state,
        Panel_interview_assignees: action.payload,
      };

    case 'ADDPANELINTERVIEWTYPE':
      return {
        ...state,
        Panel_interview_interview_type: action.payload,
      };

    default:
      return state;
  }
};

const JobAddFormProvider = ({ children }) => {
  const [jobAddformState, jobAddformDispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <JobAddFormContext.Provider
      value={{
        jobAddformState,
        jobAddformDispatch,
      }}
    >
      {children}
    </JobAddFormContext.Provider>
  );
};

export default JobAddFormProvider;
