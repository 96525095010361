import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/Button/primaryButton';

import { ReactComponent as SomethngWentWrongIlls } from 'assets/images/image_404.svg';

const FallBackUI = () => {
  const { t } = useTranslation(['errorMessages']);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <div className='w-full'>
      <div className='bg-white shadow-lg mx-auto max-w-main-page w-10/12 mt-10 py-4 border rounded h-auto absolute transform -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2'>
        <div className='w-1/2 mx-auto'>
          <SomethngWentWrongIlls />
        </div>
        <p className='text-2xl text-center my-3 mt-10'>
          {t('errorMessages:unable_to_connect')}
        </p>
        <p className='text-lg text-center'>
          {t('errorMessages:something_went_wrong_please_try_again_later')}
        </p>
        <div className='flex justify-center my-6'>
          <button className='mx-auto w-32' onClick={refreshPage}>
            <PrimaryButton>{t('errorMessages:refresh')}</PrimaryButton>
          </button>
        </div>
      </div>
    </div>
  );
};

const ComponentLevelErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={FallBackUI}>{children}</ErrorBoundary>
  );
};

export default ComponentLevelErrorBoundary;
