import toast from 'react-hot-toast';
import { ReactComponent as OkIcon } from 'assets/icons/ok.svg';
import { ReactComponent as FailIcon } from 'assets/icons/fail.svg';

const RemoveToast = (t) => {
  return (
    <div className='flex'>
      <button
        onClick={() => toast.remove(t.id)}
        className='w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium focus:outline-none -mt-1'
      >
        <svg
          width='40'
          height='40'
          viewBox='0 0 67 65'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='cursor-pointer'
        >
          <path
            d='M41.3962 24.3962L24.9824 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M24.9824 24.3962L41.3962 40.2906'
            stroke='#FF009C'
            strokeWidth='5.70168'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );
};

const SuccessToast = ({ text }) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      style={{ zIndex: 9999 }}
    >
      <div className='flex-1 w-0 p-4 mt-1'>
        <div className='flex items-start'>
          <div className='flex-shrink-0 pt-0.5'>
            <div className='h-6 w-6 rounded-full'>
              <OkIcon />
            </div>
          </div>
          <div className='ml-3 flex-1'>
            {/* <p className='text-sm font-medium text-gray-900'>Success</p> */}
            <p className='mt-1 text-sm text-gray-500'>{text}</p>
          </div>
        </div>
      </div>
      <RemoveToast t={t} />
    </div>
  ));
};

const ErrorToast = ({ text = 'Something went wrong!' }) => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      style={{ zIndex: 9999 }}
    >
      <div className='flex-1 w-0 p-4'>
        <div className='flex items-start mt-1'>
          <div className='flex-shrink-0 pt-0.5'>
            <div className='h-6 w-6 rounded-full'>
              <FailIcon />
            </div>
          </div>
          <div className='ml-3 flex-1'>
            {/* {heading !== null && (
              <p className='text-sm font-medium text-gray-900'>Failed</p>
            )} */}

            <p className='mt-1 text-sm text-gray-500'>{text}</p>
          </div>
        </div>
      </div>
      <RemoveToast t={t} />
    </div>
  ));
};

export { SuccessToast, ErrorToast };
