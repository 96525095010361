import React, { useState } from 'react';
import Loader from 'components/Loader';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';
import PrimaryButton from 'components/Button/primaryButton';
import ActivitySingle from './ActivitySingle';

import { ReactComponent as NoActivityIllus } from 'assets/images/dashboard/NoActivityIllus.svg';

function MyActivity({ setMyActivityPanelVisible }) {
  const [showAddActivity, setShowAddActivity] = useState(false);
  const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm();

  const getActivity = useQuery(['getActivity'], async () => {
    const resData = await makeAPIRequest.get(
      `${apiEndPoints.DASHBOARD_ACTIVITY_TRACKER}`
    );
    return resData?.data;
  });

  const createNewActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        reset({ activity: '' });
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const updateActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );
  const deleteActivity = useMutation(
    async (data) => {
      const res = await makeAPIRequest.delete(
        apiEndPoints.DASHBOARD_ACTIVITY_TRACKER,
        { data }
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getActivity');
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const onSubmit = (data) => {
    if (data?.activity?.length > 0 && !createNewActivity.isLoading) {
      createNewActivity.mutate(data);
    }
  };

  return (
    <>
      <div className='text-lg 2xl:text-xl font-bold pb-2 md:hidden mt-4 text-midnightBlue'>
        {t('my_checklist')}
      </div>
      <div className='w-full flex flex-col lg:w-4/12 bg-white rounded-lg border-t shadow-lg hover:shadow-xl px-6 py-4 md:mt-6 lg:mt-0'>
        <div className='hidden md:flex justify-between items-center  w-full'>
          <div className='text-lg 2xl:text-xl font-bold pb-2 text-midnightBlue'>
            {t('my_checklist')}
          </div>
          <div
            onClick={() => setMyActivityPanelVisible(true)}
            className='pb-2 text-primary-rose cursor-pointer hover:underline '
          >
            {t('view_more')}
          </div>
        </div>
        {getActivity.isLoading ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <div className='flex flex-1 min-h-80 max-h-80 flex-col scroller overflow-y-auto'>
              <div className='flex my-4'>
                <span
                  className={`w-6 h-6 rounded    ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1.5rem' width='1.5rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='20rem' />
                <span
                  className={`w-1 h-4 rounded    ${
                    currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                  }`}
                >
                  <Skeleton height='1rem' width='0.25rem' />
                </span>{' '}
              </div>
              <div className='flex my-4'>
                <span
                  className={`w-6 h-6 rounded    ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1.5rem' width='1.5rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='20rem' />
                <span
                  className={`w-1 h-4 rounded    ${
                    currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                  }`}
                >
                  <Skeleton height='1rem' width='0.25rem' />
                </span>{' '}
              </div>
              <div className='flex my-4'>
                <span
                  className={`w-6 h-6 rounded    ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1.5rem' width='1.5rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='20rem' />
                <span
                  className={`w-1 h-4 rounded    ${
                    currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                  }`}
                >
                  <Skeleton height='1rem' width='0.25rem' />
                </span>{' '}
              </div>
              <div className='flex my-4'>
                <span
                  className={`w-6 h-6 rounded    ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <Skeleton height='1.5rem' width='1.5rem' />
                </span>{' '}
                <Skeleton height='1.25rem' width='20rem' />
                <span
                  className={`w-1 h-4 rounded    ${
                    currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                  }`}
                >
                  <Skeleton height='1rem' width='0.25rem' />
                </span>{' '}
              </div>
            </div>
            <div className='flex my-2'>
              <Skeleton height='2.5rem' width='100%' />
            </div>
          </div>
        ) : getActivity?.data?.data.length || showAddActivity ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <div className='flex flex-1 min-h-80 max-h-80 flex-col scroller overflow-y-auto'>
              {getActivity?.data?.data.length ? (
                getActivity?.data?.data.map((activity, index) => {
                  if (index < 8) {
                    return (
                      <ActivitySingle
                        key={activity.id}
                        activity={activity}
                        isLoading={getActivity.isLoading}
                        updateActivity={updateActivity}
                        deleteActivity={deleteActivity}
                      />
                    );
                  }
                  return null;
                })
              ) : (
                <div className='flex h-80 mb-4 justify-center items-center flex-col'>
                  <NoActivityIllus />
                  <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                    {t('add_items_todo_text')}
                  </span>
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex items-center'>
                <div className='flex-1 searchFunctionality'>
                  <Controller
                    name='activity'
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t('start_typing_here_and_press_enter')}
                        className={` ${
                          currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                        }`}
                      />
                    )}
                  />
                </div>
                {createNewActivity.isLoading ? (
                  <div className='w-6 h-6'>
                    <Loader color='#6600C9' />
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        ) : (
          <div className=' flex justify-center items-center'>
            <div className='flex h-80 mb-4 justify-center items-center flex-col'>
              <NoActivityIllus />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('add_items_todo_text')}
              </span>
              <div className='mt-6'>
                <button
                  onClick={() => setShowAddActivity(true)}
                  className='w-24 md:w-32 text-center cursor-pointer mx-auto my-4'
                >
                  <PrimaryButton isLoading={false}>
                    <p className='text-sm '>{t('add_now')}</p>
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MyActivity;
