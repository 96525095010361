import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';

import { ApplicantsContext } from 'contexts/applicantsContext';

import PrimaryButton from 'components/Button/primaryButton';

import getModifiedStagename from 'helper/getModifiedStagename';
import Lottie from 'react-lottie-player';

import SuccessLottie from 'assets/lottieFiles/SuccessLottie.json';
import { ReactComponent as BG } from 'assets/images/Interviews/success_bg_modal.svg';

const RescheduledInterviewModal = ({ currentStage = '' }) => {
  const { t } = useTranslation(['common']);
  const { applicantsState, applicantsDispatch } = useContext(ApplicantsContext);

  const handelCloseRescheduleModal = () => {
    applicantsDispatch({
      type: 'SETRESCHEDULEDSUCCESSMODALVISIBLESTATE',
      payload: false,
    });
  };

  const header = (
    <p className='text-xl text-center tracking-wide text-black'>
      Rescheduled{' '}
      <span className='text-primary-rose'>
        {getModifiedStagename(currentStage, t)}
      </span>{' '}
      successfully
    </p>
  );

  return (
    <div>
      <Dialog
        visible={applicantsState.is_rescheduled_success_modal}
        onHide={handelCloseRescheduleModal}
        closable={false}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        header={header}
        className='w-10/12 md:w-1/2 mx-auto rounded-xl'
      >
        <div className='relative'>
          <div className='bg-F9F9F9 w-60 h-60  mx-auto rounded-full mb-4 mt-3 z-50'>
          <Lottie
              animationData={SuccessLottie}
              background='transparent'
              speed='1'
              loop
              play
            />
          </div>
          <div className='absolute w-full top-10'>
            <BG />
          </div>
          <div className='flex justify-center mt-10 mb-6'>
            <button className='w-28' onClick={handelCloseRescheduleModal}>
              <PrimaryButton>
                <p className='text-base'>{t('common:back')}</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default RescheduledInterviewModal;
